<template>
    <b-container fluid="lg">


      <!-- modal practical evaluation -->
      <b-modal
          v-model="modalPracticalEvaluation"
          persistent
          title=" Submit your practical evaluation"
          size="lg"
          centered
      >
        <b-container fluid>



          <b-row style="display: flex; justify-content: flex-start; align-items:center;" class="my-2 mx-2">
            <div  style="width: 90%">
                  <b-form-file size="sm" id="file-default"></b-form-file>
            </div>

            <div class="ml-1">
              <b-button size="sm"
                        class="btn-studioAPP"
                        variant="outline-warning"
              >
                Submit
              </b-button>
            </div>
          </b-row>
          <b-row style="display: flex; justify-content:flex-end; align-items:center;" class="my-2 mx-2">


          </b-row>

          <b-row class="m-1">
            <b-col cols="12" class="text-center">
              <b-card-text>

                <b-jumbotron style="color:green" lead="80% of success"> </b-jumbotron>
                <span>
                  You did great! just need to be more careful with the configuration of the tables you created, check
                  <a href="#" class="mx-1">this link</a>
                   to review the unit. You can continue to the next course.
                </span>

              </b-card-text>
            </b-col>
          </b-row>

        </b-container>



        <template #modal-footer>
          <div class="w-100">

            <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="hideModalBot()">
              Volver
            </b-button>
          </div>
        </template>

      </b-modal>


      <!-- modal nunito bot -->
      <b-modal
          v-model="modalBot"
          persistent
          title=" Ask Nunito, the assistant bot"
          size="lg"
          centered
      >
        <b-container fluid>

          <b-row class="m-1">
            <b-col cols="12">
              <b-card-text>
                <b-alert show variant="primary">
                  Couldn't find the share button
                </b-alert>
              </b-card-text>
            </b-col>
          </b-row>
          <b-row class="ml-5">
            <b-col cols="12">
              <b-card-text>
                <b-alert show variant="secondary">
                  OK! No worries!
                </b-alert>
              </b-card-text>
            </b-col>
          </b-row>
          <b-row class="ml-5">
            <b-col cols="12">
              <b-card-text>
                <b-alert show variant="secondary">
                 Let me give you a little more guidance
                </b-alert>
              </b-card-text>
            </b-col>
          </b-row>
          <b-row class="ml-5">
            <b-col cols="12">
              <b-card-text>
                <b-alert show variant="secondary">
                 Open <a class="mx-1" href="#">this link</a> and you'll find a guide to it
                </b-alert>
              </b-card-text>
            </b-col>
          </b-row>

          <b-row style="display: flex; justify-content: space-between; align-items:center;" class="my-2 mx-2">
            <div  style="width: 90%">
                      <b-form-input size="sm" style="width: 100%" placeholder="Envíanos tus dudas"></b-form-input>
            </div>
          <div>
              <b-button size="sm"
                        class="btn-studioAPP"
                        variant="outline-warning"
              >
                Enviar
              </b-button>
          </div>
          </b-row>


        </b-container>



        <template #modal-footer>
          <div class="w-100">

            <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="hideModalBot()">
              Volver
            </b-button>
          </div>
        </template>

      </b-modal>





<!-- modal entregable  confirmacion -->
    <b-modal
        v-model="modalEntrega"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
        <b-container fluid>
            <b-row class="m-1 text-left">
                    <b-col cols="12">  
                        ¿ Estás seguro de realizar la entrega del siguiente archivo desde tu drive ?
                    </b-col>   
            </b-row>
            <b-row class="m-1 text-left">
                    <b-col cols="12">  
                      <hr>
                        <b-card-text>
                         Nombre: <b-img :src="file.iconUrl"></b-img>  {{file.name}}
                        <br>
                        Tipo: {{file.type}}
                        </b-card-text> 
                      
                    </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalConfirmacionEntrega()">
                        Cancelar
                    </b-button>

                    <b-button
                        variant="success" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="hacerEntrega()">
                        ¡Hacer entrega!
                    </b-button>                    

            </div>
            </template>
            
        </b-modal>


<!-- modal rubrica eleccion -->
    <b-modal
        v-model="modalRubrica"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
            <b-row class="m-1 text-left">
                <b-col cols="12">  
                   Criterios de evaluación
                </b-col>   
            </b-row>
            <b-row class="my-5" style="display: flex; justify-content: space-around; ">    
                <Rubrica :rubricaId='this.rubrica_id'/> 
            </b-row>

          </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalRubrica()">
                        Cancelar
                    </b-button>                              
            </div>
            </template>
            
        </b-modal>


      <!-- modal Quiz -->
      <b-modal
          v-model="modalQuiz"
          persistent
          title="Evaluación"
          size="lg"
          centered
          hide-footer
      >
<!--        hide-footer="true"-->
        <b-container fluid>
          <b-row class="my-5" style="display: flex; justify-content: space-around; ">
            <Quiz :idUnidad="actividadIdQuiz"
                  :modulo="7"

                  :curricula  = "this.current_curso_curricula.id"
                  :curso      = "this.currentCurso.id"
                  :c_unidad     = "this.unidad"
                  :objetivo   = "this.objetivo"

                  :actividad  = "this.actividad"
                  :material   = "this.material"
                  :quiz       = "this.quiz"

                  @modalQuiz="modalQuiz = $event"></Quiz>
          </b-row>
        </b-container>
        <template #modal-footer>
        </template>
      </b-modal>

      <!--breadcrumb-item-->
    <b-row style="display: flex; justify-content: flex-start;">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/crear-contenido-curricula" class="">Currículas</router-link></li>
                <li class="breadcrumb-item">  <b-link @click="irCursosCurricula()">Cursos</b-link></li>
            </ol>
        </nav>
    </b-row>
  

<b-row class="my-3" style="display: flex; justify-content:space-between;">
     <b-form-checkbox class="ml-3" @change="someHandler" v-model="checkUnidades" name="check-button" switch>Ver unidades</b-form-checkbox>

  <b-button
    style="display:none;"
      @click="showModalBot()"
      pill
      class="btn-studioAPP"
      variant="outline-warning"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-robot" viewBox="0 0 16 16">
      <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z"/>
      <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z"/>
    </svg>
  </b-button>
</b-row>
<!---------------------------------------------------------------------------------------------------------------->

<b-row no-gutters>

    <!--main left section-->
    <b-col cols="12" lg="3" md="3" sm="12" class="collapse width" :class=" collaps ? 'show' : '' ">

        <div class="boxScroll">

            <span style="display:none">
                {{unidadVisible}}
            </span>

            <div  class="my-2 mr-2" 
                  v-for="(unidad_curso,index_unidad_curso) in current_curso_curricula.unidades_curso"  :key="index_unidad_curso" >
            
                    <b-button
                        style="width:100%"
                        class="btn-studioAPP collapsed"
                        variant="outline-warning"
                        v-b-toggle:index_unidad_curso.toString()
                        @click="switchUnidad(index_unidad_curso)"
                        >
                          <div class="text-left">
                              <div v-text="`${index_unidad_curso + 1}. ${unidad_curso.unidad.nombre_unidad}`"></div>
                          </div>
                          <p class="text-justify">
                              <small v-text="unidad_curso.unidad.descripcion"></small>
                          </p>
                    </b-button>
        
                <b-collapse :id="index_unidad_curso.toString()" accordion="my-accordion" v-model="visible[index_unidad_curso]" class="mt-2">
                    <b-card>
                        <b><b-jumbotron lead="Objetivos"></b-jumbotron></b>
                        <b-list-group>
                            
                            <div v-for="(objetivo_unidad,index_objetivo) in unidad_curso.unidad.objetivos_unidad" :key="index_objetivo">
                                    <b-list-group-item> 
                            
                                            <a href="#"
                                            @click="verMaterialObjetivo(objetivo_unidad.objetivo)"
                                            :class="((objetivo_unidad.active===1)?'primary--text':'')">
                                            {{
                                                `${index_unidad_curso + 1}.${index_objetivo + 1} ${objetivo_unidad.objetivo.nombre_objetivo}`
                                            }}
                                            </a>
                                    </b-list-group-item>
                                        <hr>
                            </div>
                            </b-list-group>

                              <b-jumbotron lead="Actividades"></b-jumbotron>
                              <span>Acciones</span>
                              <hr>
                                <b-card>
                                    <b-alert
                                        class="p-2"
                                        show
                                        variant="warning"
                                        dismissible>
                                        <small>
                                           Marca las realizadas
                                        </small>
                                    </b-alert>

                                    <div v-for="(actividad_unidad, index_actividad) in actividades_unidad_actual" :key="index_actividad">                         
                                        <div v-if="actividad_unidad.actividad_realizada > 0">
                                            <div class="form-inline">
<!--                                                <div class="ml-2 actividad_checkbox p-2 my-2 shadow-sm" style="text-decoration-line: line-through; border:1px #dedede solid;  border-radius: 15px;" >
                                               -->
                                              <div class="text-left ml-1 actividad_checkbox p-2 my-2 shadow-sm" style="overflow-y: auto;text-decoration-line: line-through; border:1px #dedede solid;  border-radius: 15px;" >

                                              <small>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                                        </svg>
                                                        {{ actividad_unidad.detalles_actividad }} 
                                                    </small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="actividad_checkbox p-3 my-1 shadow-sm" style="border:1px #dedede solid;  border-radius: 15px;">
<!--                                             class="actividad_checkbox p-2 my-3 shadow-sm" style="border:1px #dedede solid;  border-radius: 15px;">  -->

                                            <b-form-checkbox 
                                                class           ="my-1"
                                                :disabled       ="( (actividad_unidad.actividad_realizada > 0) )"
                                                :v-model        ="( (actividad_unidad.actividad_realizada > 0) ? activado=1 : activado=0 )"

                                                unchecked-value ="0"
                                                value           ="1"
                                                @change       ="completarActividad(actividad_unidad)"
                                                >
                                              <!--text-break se quitó esta clase porque se retiraron enlances y no se ncesita cortar las palabras-->
                                                   <span  class="" style="text-align:justify"> {{ actividad_unidad.detalles_actividad }}</span>
                                                    <p style="display:none">
                                                        actividad realizada -- {{actividad_unidad.actividad_realizada }}
                                                    </p>
<!--                                                    <small>
                                                        <a href="#!"
                                                                v-if="(actividad_unidad.items_actividad.length > 0)"
                                                            @click="openSubAcitivies(actividad_unidad)" class="ml-1" color="primary" small outlined>Detalles</a>
                                                    </small>-->
                                            </b-form-checkbox>
                                            
                                        </div><!--v-else-->
                                     
                                        <div v-if="actividad_unidad.alumno_entrego==1">
                                               <b-row>
                                                    <b-button variant="link" size="sm" class="my-1" type="button" disabled>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 1443.061 1249.993"><path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/><path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/><path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/></svg>
                                                        ¡Archivo entregado!</b-button>  
                                                    </b-row>
                                                <b-row >
                                                    <b-button disabled variant="success" size="sm" class="mr-1">
                                                        Entregar
                                                    </b-button>
                                                </b-row>  
                                        </div>

                                        <div v-else-if="actividad_unidad.alumno_entrego==0">
                                      <!-- 0 no entregable-->
                                            <div v-if="(actividad_unidad.entregable > 0)" class="text-center mt-2" >
                                              <div style="text-align: left">
                                                <span>Sección retos</span>
                                                <hr>
                                              </div>
                                                <b-row style="display: flex; justify-content: center;" class="mb-2" >
                                                    <b-button v-if="actividad_unidad.entregable == 3" variant="info" v-b-modal.modal-prevent-closing size="sm"
                                                            @click="showModalRubrica(actividad_unidad.actividad_rubrica.id)" class="mr-1">
                                                        Ver rúbrica
                                                    </b-button>
                                                </b-row>
                                                <div v-if="(actividad_unidad.entregable == 1 || actividad_unidad.entregable == 2 || actividad_unidad.entregable == 3)">
                                                    <b-row style="display: flex; justify-content: center; ">

                                                        <GDriveSelector v-on:handlePickerFiles="getData" />
                                                        <div v-if="file.id !=0 ">
                                                          <b-button @click="showModalConfirmacionEntrega(actividad_unidad)" variant="success" size="sm" class="mr-1">
                                                            Reto
                                                          </b-button>
                                                        </div>
                                                        <div v-else>
                                                          <b-button disabled variant="success" size="sm" class="mr-1">
                                                            Reto
                                                          </b-button>

                                                        </div>

                                                    </b-row>

                                                </div>
                                            </div>
<!--                                            <div v-else>
                                                <small> <i>No hay entregables</i></small>
                                            </div> -->
                                        </div><!--v-else-if-->

                                      <div v-if="actividad_unidad.quiz>0">
                                        <span>Evaluaciones</span>
                                        <hr>
                                        <div style="text-align: center">
                                        <b-button @click="showModalQuiz(actividad_unidad.id)" variant="success" size="sm" class="mr-1">
                                          Iniciar
                                        </b-button>
                                        </div>
                                      </div>
<!--                                      <div v-else>
                                        <span>No hay Evaluación</span>
                                      </div>-->
                                      <!--
                                      {{typeof actividad_unidad.actividad_quiz}}

                                      <div v-if="Array.isArray(actividad_unidad.actividad_quiz)">
                                        isArray {{typeof actividad_unidad.actividad_quiz}}
                                      </div>
                                      <div v-else>
                                        no isArray
                                      </div>
                                                                           <div v-if="actividad_unidad.actividad_quiz">
                                                                              <span>Quiz</span>
                                                                              Insertar componente aquí con actividad id: {{actividad_unidad.actividad_quiz}}
                                                                            </div>-->
                                      <!--                                      <div v-else-if="actividad_unidad.actividad_quiz.length>=1">
                                                                              <p>no quiz</p>
                                                                            </div>-->

                                    </div><!--v-for-->
                               
                                </b-card>

 
                    </b-card>
                    
                </b-collapse>
                
                 
            </div><!-- switch unidad-->




        </div><!--boxScroll-->
                
    </b-col>

    <!--main center section-->
    <b-col cols="12" :lg=" (!collaps) ? '12' : '9' " md="9" sm="12">
        <div class="box-scroll">

          <div style="text-align: center">
            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron :lead="youtubeStream.titulo_video"></b-jumbotron>
            </div>

            <div v-if="stream.stream_iniciado==1">
                <div style="z-index:99; background-color:transparent; width:100%; height:600px; position: absolute"></div>
                <div>
                  <youtube
                      :video-id="youtubeStream.youtube_video_id"
                      @ready="ready"
                      @playing="playing"
                      :player-vars="{ rel: 0, showinfo: 0, autoplay: 0 }"
                      player-width="100%"
                      player-height="600">
                  </youtube>
                </div>
            </div>
            <div v-else>
              <b-img  class="my-1" src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/breve.png" height="600" width="600" fluid></b-img>
            </div>
            <div class="my-2">
                <b-button @click="customStop()" v-if="corriendo==true" variant="primary" size="sm" class="ml-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stop-fill" viewBox="0 0 16 16">
                    <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z"/>
                  </svg>
                </b-button>


              <b-button @click="atrasar()" variant="success" size="sm" class="ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-rewind-fill" viewBox="0 0 16 16">
                  <path d="M8.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L8.404 7.304Z"/>
                  <path d="M.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L.404 7.304Z"/>
                </svg>
              </b-button>

                <b-button @click="pause()" v-if="corriendo==true" variant="secondary" size="sm" class="ml-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16">
                    <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
                  </svg>
                </b-button>

                <b-button @click="playVideo()" v-if="corriendo==false" variant="danger" size="sm" class="ml-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                  </svg>
                </b-button>
              <b-button @click="adelantar()" variant="success" size="sm" class="ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-fast-forward-fill" viewBox="0 0 16 16">
                  <path d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z"/>
                  <path d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z"/>
                </svg>
              </b-button>


              <b-button @click="mutear()" v-if="muteado===false" variant="primary" size="sm" class="ml-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-mute-fill" viewBox="0 0 16 16">
                  <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
                </svg>
              </b-button>
              <b-button @click="desMutear()" v-if="muteado===true" variant="dark" size="sm" class="ml-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-mute-fill" viewBox="0 0 16 16">
                  <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
                </svg>
              </b-button>

              <b-button @click="bajarVol()" variant="primary" size="sm" class="ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-down-fill" viewBox="0 0 16 16">
                  <path d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zm3.025 4a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z"/>
                </svg>
              </b-button>
              <b-button @click="subirVol()" variant="primary" size="sm" class="ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
                  <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                  <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                  <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
                </svg>
              </b-button>
            </div>

          </div>

            <div v-if="!current_objetivo">
                <b-alert
                    class="p-3 mx-5"
                    show
                    variant="warning"
                    dismissible>
                    <small>
                        Seleccione los materiales que desea ver desde cualquier objetivo
                    </small>
                </b-alert>
            </div>
            <div v-else>
                <p class="text-left mx-2">Material 
                     <strong > {{`${current_page_objetivo + 1}/${current_objetivo.materiales_objetivo.length}`}} </strong>
                </p>

                <div class="text-right my-2 mx-2">
                        <b-button :disabled="!(current_page_objetivo>0)"
                                    class="btn-studioAPP mx-1"
                                     variant="outline-warning"
                                    aria-controls="collapse-4"
                                    @click="anteriorMaterial">
                            Anterior
                        </b-button>

                    <b-button :disabled="!(((current_page_objetivo+1)!=current_objetivo.materiales_objetivo.length))"
                        class="btn-studioAPP"
                        variant="outline-warning"        
                        aria-controls="collapse-4"
                        @click="siguienteMaterial"
                        >
                    Siguiente material
                    </b-button>
                </div>
            
            <b-card
                :title="current_objetivo.nombre_objetivo"
                img-top
                tag="article"
                class="mx-2"
            >
            <b-card-text>
             Descripción objetivo:  {{ current_objetivo.descripcion }}
            </b-card-text>

              <div v-if="current_objetivo.materiales_objetivo[current_page_objetivo]">
                <h5 class="text-justify">
                  Material: {{ current_objetivo.materiales_objetivo[current_page_objetivo].nombre_material }}
                </h5>
                <p>
                  Instrucciones
                </p>
                <p class="text-justify">{{ current_objetivo.materiales_objetivo[current_page_objetivo].instrucciones }}</p>
                <!-- <p class="text-justify"> {{ current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento }}</p> -->

                <div v-if="current_objetivo.materiales_objetivo[current_page_objetivo].tipo_material_id==2"
                     :class=" collaps ? 'mt-5' : 'mt-5 text-center' "
                >
                  <!--                    <p><small>Para ver el video, da click <a target="_blank" :href="current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento">aquí</a></small></p>
                                     -->
                  <p>
                    <a class="mt-5 d-block" target="_blank" :href="current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento">
                      <b-button  variant="warning"    @click="botonAbrirMaterial( current_objetivo.materiales_objetivo[current_page_objetivo] )" x-large>Abrir material</b-button>
                    </a>

                  </p>
                </div>
                <div v-else-if="current_objetivo.materiales_objetivo[current_page_objetivo].tipo_material_id==3">
                  <a class="mt-5 d-block" target="_blank" :href="current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento">
                    <b-button variant="warning"   @click="botonAbrirMaterial( current_objetivo.materiales_objetivo[current_page_objetivo] )" x-large>Abrir material</b-button>
                  </a>

                </div>
                <div class="mt-5" v-else>

                  <a class="mt-5 d-block" target="_blank" :href="current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento">
                    <b-button variant="warning"  @click="logLiveEvents(9, 25)" x-large>Abrir material</b-button>
                  </a>

                  <!--                <div v-if="current_objetivo.materiales_objetivo[current_page_objetivo].length >0 ">-->


                  <!--                    <p>{{ current_objetivo.materiales_objetivo[current_page_objetivo].instrucciones }}</p>-->
                  <!--
                                      <p><small>Para ver el documento, da click <a target="_blank" :href="current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento">aquí</a></small></p>
                  -->

                  <!--                    <p>{{ current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento }}</p>-->
                </div>
              </div>
              <div v-else>
                <b-alert show variant="warning">
                   <p>
                    De momento, este objetivo no cuenta con materiales adjuntos, estos aparecerán cuando el coach los haya agregado.
                  </p>
                </b-alert>
              </div>


<!--                </div>-->
<!--              </div>-->




            </b-card>

            </div><!-- v-else -->
          <div v-if="stream.chat_habilitado == 1">

            <div v-if="$store.getters.usuario.role_id==1 || $store.getters.usuario.role_id==2">
              <chat :id_evento="youtubeStream.id" :es_coach="true"></chat>
            </div>
            <div v-if="$store.getters.usuario.role_id==3">
              <chat :id_evento="youtubeStream.id" :es_coach="false"></chat>
            </div>
          </div>
          <div v-else>
            <p><small>De momento, el chat está deshabilitado por el coach!</small></p>
            <hr>
            Puedes dejarnos tus dudas acá

            <div class="text-center mx-1" >

              <div v-if=" this.current_objetivo ">
                <b-form-textarea
                    v-model="duda_msj"
                    id="textarea"
                    placeholder="Déjanos tus dudas en esta sección..."
                    rows="2"
                    max-rows="3"
                >
                </b-form-textarea>
              </div>
              <div v-else>
                <b-form-textarea
                    disabled
                    placeholder="Déjanos tus dudas en esta sección..."
                    rows="2"
                    max-rows="3"
                >
                </b-form-textarea>
              </div>


              <div v-if="this.duda_msj">
                <b-button
                    @click="guardarDuda"
                    class="btn-studioAPP my-1 mx-5"
                    size="sm">Enviar duda
                </b-button>
              </div>
              <div v-else>
                <b-button
                    disabled
                    class="btn-studioAPP my-1 mx-5"
                    size="sm">Enviar duda
                </b-button>
              </div>
              <hr>
              <div v-if="dudas_ya_mostradas===true">
                <div v-if="dudas.length>0">
                  <div v-for="(duda, index ) in dudas" :key="index" >
                    <div class="container text-right">
                      <p><small> {{duda.duda_msj}}</small></p>
                    </div>
                    <div v-if="duda[`dudas_respuesta`]!=null">
                      <div class="container darker" style="margin-left:10% !important; width:90%" >
                        <p><small> {{  duda[`dudas_respuesta`].respuesta_duda_msj}}  </small></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="alert alert-warning" role="alert">
                    De momento no se cuentan con dudas
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="alert alert-warning" role="alert">
                  Para visualizar de manera activa el apartado de dudas, hay que elegir un objetivo
                </div>
              </div>

            </div>

          </div>


        </div><!--box-scroll-->

    </b-col>

    <!-- dudas-->
<!--    <b-col cols="12" :lg=" (!collaps) ? '4' : '2' "  md="7" sm="12" :class=" (collapsDudas ) ? '' : 'd-none' ">


        
    </b-col>-->
    
</b-row>

  </b-container>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import firebase from '@/firebaseConfRtdb/init';
//import firebaseMixin from '@/mixins/FirebaseMixin.js';
import GDriveSelector from "../../components/GDriveSelector";
import EntregableService from '@/services/EntregableService';
import Rubrica from '@/components/Rubricas/Rubrica';
import toast from "@/mixins/ToastMixin.js";
import { getIdFromURL} from 'vue-youtube-embed';
import Chat from "@/components/chat.vue";
import Quiz from "@/components/Quiz/Quiz.vue";
export default {
    name:'ContenidoCursoCurriculaVivo',
    mixins:[toast],
    mounted () {

               this.current_curricula       = this.$route.params.curricula;
               this.current_curso_curricula = this.$route.params.curso;
               this.oferta_actual           = this.$route.params.oferta_actual

                if( this.$route.params.curricula == undefined ) {
                  if (localStorage.getItem('current_curricula')) {
                    try {
                      this.current_curricula = JSON.parse( localStorage.getItem('current_curricula') );
                    } catch(e) {
                        localStorage.removeItem('current_curricula');
                    }
                  }
                }else{
                  const parsed = JSON.stringify(this.current_curricula);
                  localStorage.setItem('current_curricula', parsed);
                }

                if( this.$route.params.curso == undefined ) {
                  if (localStorage.getItem('current_curso_curricula')) {
                    try {
                      this.current_curso_curricula = JSON.parse( localStorage.getItem('current_curso_curricula') );
                    } catch(e) {
                      localStorage.removeItem('current_curso_curricula');
                    }
                  }
                }else{
                  this.getContenido(this.$route.params.curso.id);
                  const parsed2 = JSON.stringify(this.current_curso_curricula);
                  localStorage.setItem('current_curso_curricula', parsed2);
                }

                if( this.$route.params.oferta_actual == undefined ) {
                  if (localStorage.getItem('oferta_actual')) {
                    try {
                      this.oferta_actual = JSON.parse( localStorage.getItem('oferta_actual') );
                    } catch(e) {
                      localStorage.removeItem('oferta_actual');
                    }
                  }
                }else{
                  const parsed3 = JSON.stringify(this.oferta_actual);
                  localStorage.setItem('oferta_actual', parsed3);
                }

                this.currentCurso.name      = this.current_curso_curricula.nombre_curso;
             //   this.currentCurso.id        = this.current_curso_curricula.id;

                this.logLiveEvents(7,1);
                //console.log('this.$route.params');
               // console.log(this.$route.params);

      this.auth = firebase.auth();
      this.auth.onAuthStateChanged(firebaseUser => {
        if (firebaseUser) {
           this.initFirebase();
        } else {
          this.email_firebase = 'nivelando@g.nive.la';
          this.password_firebase = '123456789';
          this.registerUser(this.email_firebase, this.password_firebase);
        }
      });

    },
      components: {
            Chat,
            Rubrica,
            GDriveSelector
            ,Quiz
        },
    data() {
        return {
          modalBot:false,
          modalPracticalEvaluation:false,
          muteado:false,
          corriendo:false,
          auth_user: null,
          auth:null,

          stream: {
            stream_iniciado:0,
            chat_habilitado:0
          },
          youtubeStream:{
            id:0, //chat
            youtube_video_id:'pLbA_KLIW5Q',//id youtube
            titulo_video:'',
            descripcion_video:'',
            thumbnail_video:''
          },

            modalEntrega:false,
            file:{
                name:null,
                url:null,
                id:0,
                type:null,
                iconUrl:'',
                    unidad:0,   ///para efectos de guardarlos en la entrega
                    actividad:0 ///para efectos de guardarlos en la entrega
            },
            modalRubrica:false,
            modalQuiz:false,
            rubrica_id:0,
            dudas_ya_mostradas:false,
            dudas:[],
            duda_msj:'',

            checkDudas:true,

            checkUnidades:true,
            checked:null,
            collaps:true,
            currentCursoId:2,
            contador:0,

           cursos_current_curricula: [],

           prevCurso:{
               name:null,
               id:0,
           },

           currentCurso:{
               name:null,
               id:0,
           },
         
           nextCurso:{
               name:null,
               id:0,
           },

            antCurso:null,
            sigCurso:null,

            oferta_actual: [],
            activado:false,
            porcentaje_avance: 0,

            current_objetivo: null,
            current_unidad:null,
          current_actividad:null,
          current_material:null,
          current_quiz_id:null,

          //to send another components
          //curricula
          //curso

          objetivo : 0,
          unidad    : 0,
          actividad : 0,
          material : 0,
          quiz     : 0,

            current_page_objetivo: 0,
            actividades_unidad_actual: [],
            current_curso_curricula: [],

            actividadUnidad:[],               
            curriculas_navegacion       : [],
            progressBarCurrentValue     : 15,
            progressBarMaxValue         :100,

            visible                     :[false,false, false, false, false],
            unidadVisible               : false,
            actividadIdQuiz:0
        }
    },
    methods: {

      async getContenido(curso_id){

        const response = await this.$api.get(`/api/contenidos/pantallaEnVivo/${curso_id}`);
        let {
          resultSet,
          success,
        } = response.data;
        if(success){
          this.current_curso_curricula = resultSet;

          this.currentCurso.name      = this.current_curso_curricula.nombre_curso;
          this.currentCurso.id        = this.current_curso_curricula.id;  //aca curso_id    65
          await this.getCursoEventoStream(this.currentCurso.id);
        }
      },

      botonAbrirMaterial(material){
        this.current_material = material;
        this.logLiveEvents(7, 25);
      },
      async logLiveEvents(modulo, accion) {

        this.unidad    = ( this.current_unidad != null ) ? this.current_unidad.id : 0;
        this.objetivo  = ( this.current_objetivo != null ) ? this.current_objetivo.id : 0;
        this.actividad = ( this.current_actividad != null ) ? this.current_actividad : 0;
        this.material  = ( this.current_material != null ) ? this.current_material.id : 0;
        this.quiz      = ( this.current_quiz_id != null ) ? this.current_quiz_id : 0;

        await this.$api.get(`/api/log/${modulo}/${accion}/${this.$route.params.curricula.id}/${this.currentCurso.id}/${this.unidad}/${this.objetivo}/${this.actividad}/${this.material}/${this.quiz}`);

        this.current_actividad  = null;
        this.current_material   = null;
        this.current_quiz_id    = null;
      },

      async mutear(){
        this.muteado=true;
        await this.player.mute();
        await this.logLiveEvents(7, 11);
      },
      async subirVol(){
        let vol = await this.player.getVolume();
        this.player.setVolume(vol+20);
        if( this.player.isMuted() ){
          await this.player.unMute();
          this.muteado=false;
        }
        await this.logLiveEvents(7, 13);
      },
      async bajarVol(){
        let vol = await this.player.getVolume();
        this.player.setVolume(vol-20);
        let vol2 = await this.player.getVolume();
        if(vol2==0){
          this.muteado=true;
        }
        await this.logLiveEvents(7, 14);
      },
      async desMutear(){
        this.muteado=false;
        await this.player.unMute();
        await this.logLiveEvents(7, 12);
      },
      async customStop(){
        this.player.stopVideo();
        this.player.seekTo(0);
        await this.logLiveEvents(7, 8);
      },
      async atrasar(){
        let time = await this.player.getCurrentTime();
        this.player.seekTo(time-15);
        await this.logLiveEvents(7, 9);
      },
      async adelantar(){
        let time = await this.player.getCurrentTime();
        this.player.seekTo(time+15);
        await this.logLiveEvents(7, 10);
      },
      ready (event) {
        this.player = event.target;
      },
      playing (event) {
        console.log("playing event..." + event);
      },
      stop() {
        this.corriendo=false;
        this.player.stopVideo();
        this.logLiveEvents(7,8);
      },
      pause() {
        this.corriendo=false;
        this.player.pauseVideo();
        this.logLiveEvents(7,7);
      },
      async playVideo() {
        this.corriendo=true;
        await this.player.playVideo()
        await this.logLiveEvents(7, 6);
      },
      irCursosCurricula(){
        this.$router.push({name: 'CursosCurricula', params: {curricula: this.$route.params.curricula, is_dashboard: true}})
      },
      hideModalQuiz(){
        this.modalQuiz=false;
      },
      showModalQuiz(id){
        this.modalQuiz=true;
        this.actividadIdQuiz=id
        this.current_quiz_id = id;
        this.logLiveEvents(7, 26);
      },
      initFirebase() {

        let database = firebase.database().ref('streaming-events');
        // ponemos a escuchar firebase y si el evento se actualiza, ejecutamos updateStreamStatus()
        database.on('value', (snapshot) => {
          snapshot.forEach((childSnapshot) => {
            let varChild = childSnapshot.val();
            if ( varChild ) {
              if ( parseInt(varChild.evento_streaming_id) == this.youtubeStream.id ) {
                this.updateStreamStatus( varChild );
              }
            }
          })

        })

      },
      updateStreamStatus( eventStreamData = [] ) {
        // si el valor es 1, el streaming esta habilitado para su emision
        this.stream.stream_iniciado = parseInt(eventStreamData.stream_iniciado);
        this.stream.chat_habilitado = parseInt(eventStreamData.chat_habilitado);
      },
      loginUser(email, password) {
        //Llamamos al método de login de firebase
        this.auth.signInWithEmailAndPassword(email, password)
            .catch(err => {
              console.log(err);
            }).finally(() => {
          this.password_firebase = "";
        });
      }, //loginUser
      registerUser(email, password) {
        this.auth.createUserWithEmailAndPassword(email, password).then( () => {
          // console.log("createUserWithEmailAndPassword OK!!!");
        }).catch(err => {
          console.log(err)
          this.loginUser(this.email_firebase, this.password_firebase);
        }).finally(() => {
          this.password_firebase = "";
        });
      }, //register user
      async getDataStreamFB(stream_id){
        try {
          //actualizamos los controles en vmodel
          let database = firebase.database().ref('streaming-events');
          database.orderByChild('evento_streaming_id').equalTo( stream_id ).once("value").then( (snapshot) => {
            let eventStreamData = snapshot.val();
            let key = Object.keys(eventStreamData)[0];
            this.stream.stream_iniciado = eventStreamData[key].stream_iniciado;
            this.stream.chat_habilitado = eventStreamData[key].chat_habilitado;
          });
        } catch (error) {
          Bugsnag.notify(
              new Error('Método: getDataStreamFB()' + error)
          );
        }
      },
      async getCursoEventoStream(curso_id){

        const response = await this.$api.get(`/api/cursos/evento-stream/youtube/${curso_id}`);
        let {  youtube  } = response.data;
          this.youtubeStream.youtube_video_id   = youtube.youtube_video_id;
          this.youtubeStream.id                 =youtube.id;
          this.youtubeStream.titulo_video       =youtube.titulo_video;
          this.youtubeStream.descripcion_video  =youtube.descripcion_video;
          this.youtubeStream.thumbnail_video    =youtube.thumbnail_video;
          await this.getDataStreamFB(this.youtubeStream.id);
      },
      getCurrentVideoId(youtube_url) {
        return getIdFromURL(youtube_url)
      },
      async hacerEntrega(){
          let formData = new FormData();
              formData.append("curricula_id",     this.$route.params.curricula.id);
              formData.append("curso_id",         this.current_curso_curricula.id);
              formData.append("unidad_id",        this.file.unidad);
              formData.append("actividad_id",     this.file.actividad);
              formData.append("file_id",          this.file.id );
              formData.append("file_name",        this.file.name);
              formData.append("file_url",         this.file.url );
              formData.append("file_type",        this.file.type);
              formData.append("file_iconUrl",     this.file.iconUrl);
          try {
              const response = await EntregableService.saveEntregable(formData);
              let {   success,
                      message,
                      color
                  } = response.data;
              if(success){
                  this.file.name      = null;
                  this.file.url       = null;
                  this.file.id        = 0;
                  this.file.type      = null;
                  this.file.iconUrl   = null;

                  this.toast('b-toaster-bottom-center', true, message, color );
                  await this.logLiveEvents(7, 21);
              }
          } catch (error) {
               this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                  Bugsnag.notify(
                      new Error('Método: hacerEntrega() ' + error)
                  );
          }
          this.modalEntrega   = false;
      },

      showModalBot(){
        this.modalBot = true;
      },
      hideModalBot(){
        this.modalBot = false;
      },

      showModalPracticalEvaluation(){
        this.modalPracticalEvaluation = true;
      },
      hideModalPracticalEvaluation(){
        this.modalPracticalEvaluation = false;
      },

      showModalConfirmacionEntrega(actividad_unidad){
          this.file.unidad    = actividad_unidad.unidad_id;
          this.file.actividad = actividad_unidad.id;
          this.current_actividad  = this.file.actividad;
          this.modalEntrega   = true;
          this.logLiveEvents(7, 29);
      },
      hideModalConfirmacionEntrega(){
          this.modalEntrega = false;
          this.logLiveEvents(7, 30);
      },
      getData( data ) {
             // eslint-disable-next-line no-prototype-builtins
             if(data.hasOwnProperty("docs")){
                  this.file.id        = data.docs[0].id;
                  this.file.name      = data.docs[0].name;
                  this.file.url       = data.docs[0].url;
                  this.file.type      = data.docs[0].type;
                  this.file.iconUrl   = data.docs[0].iconUrl;
                  }
      },
      hideModalRubrica(){
          this.modalRubrica=false;
      },
      showModalRubrica(rubrica_id){
          this.rubrica_id=rubrica_id;
          this.modalRubrica=true;
          this.logLiveEvents(7, 18);
      },
      async getDudasObjetivo(item){
                  const response = await this.$api.get(`/api/contenidos/dudas/get/${item}`);
                  let {
                      dudas,
                      success,
                      } = response.data;
                  if(success){
                      this.dudas_ya_mostradas = true;
                      this.dudas = dudas;
                  }
       },
      async guardarDuda(){
          let formData = new FormData();
              formData.append("curricula_id",    this.current_curricula.id);
              formData.append("curso_id",        this.currentCurso.id);
               formData.append("unidad_id",      this.current_unidad.id);
              formData.append("objetivo_id",     this.current_objetivo.id);
              formData.append("duda_msj",        this.duda_msj);

      let url         = `/api/contenidos/dudas/store`;
      const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
      let {   success,
              message,
              color
              } = response.data;
      if(success==true){
          this.toast('b-toaster-bottom-center', true, message, color );
          this.duda_msj='';
          await this.getDudasObjetivo(this.current_objetivo.id);
          await this.logLiveEvents(7, 22);
        }
      },
      someHandler() {

          this.collaps = !this.collaps;
        },

/*        openSubAcitivies(activity = []) {
                this.current_activity.title = activity.detalles_actividad;
                this.current_activity.items = activity.items_actividad;
            },*/

      async switchUnidad(index_unidad_curso) {

          this.unidadVisible  = !this.unidadVisible;
          this.visible[index_unidad_curso]  = !this.visible[index_unidad_curso];

            try {

                this.actividades_unidad_actual = []
                this.current_unidad     = this.current_curso_curricula.unidades_curso[index_unidad_curso].unidad;
              console.log("current_unidad")
              console.log(this.current_unidad)
                await this.logLiveEvents(7, 16);
              //  this.current_objetivo   = this.current_curso_curricula.unidades_curso[index_unidad_curso].unidad.objetivos_unidad[0].objetivo;
                await this.progresoCursoAlumno()
            } catch (e) {
                console.log(e)
            }
          },
      async completarActividad(actividad = []) {
              let formData = new FormData();
                  formData.append("actividad_id"    , actividad.id);
                  this.current_actividad = actividad.id;
                  formData.append("curso_id"        , this.current_curso_curricula.id);
                  formData.append("curricula_id"    , this.current_curricula.id);
                  let url          = `/api/contenidos/progreso-alumno`;
                  const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
                      console.log(response.data+"-");
                  await this.progresoCursoAlumno();
                  await this.logLiveEvents(7, 17);
          },
      async progresoCursoAlumno(){

          let formData = new FormData();
              formData.append("curricula_id"  , this.current_curricula.id);
              formData.append("curso_id"        , this.current_curso_curricula.id);
              formData.append("unidad_id"        , this.current_unidad.id);

              let url          = `/api/contenidos/progreso-curso-alumno`;
              const res       = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
                  this.porcentaje_avance              = res.data.porcentaje_avance;
                  this.numero_progresos_por_terminar  = res.data;
                  this.actividades_unidad_actual      = res.data.unidades;
          },
      anteriorMaterial() {
          this.current_page_objetivo--
          },
      siguienteMaterial() {
          this.current_page_objetivo++
          },
      verMaterialObjetivo(objetivo){
          this.current_objetivo = objetivo;
          this.getDudasObjetivo(this.current_objetivo.id);
         // console.log(this.current_objetivo);
         this.logLiveEvents(7, 15);
      },
        //tentativamente no hace nada, revisar ya que se fusionó con el switchUnidad
      mostrarOpciones(item) {
          this.unidadVisible  = !this.unidadVisible;
          this.visible[item]  = !this.visible[item];
      },
      getEmbedYoutubeUrl(youtube_url = '') {
      let string = youtube_url.split('https://www.youtube.com/watch?v=')
      return 'https://www.youtube.com/embed/' + string[1]
      },
    },//methods
}
</script>


<style scoped>


/* mouse over link */
.actividad_checkbox:hover {
  background-color: #ecf0f1;
  transition:all .5s ease;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right:0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}

.txt_attachmnts{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px !important;
  font-weight: 400;

}

/* collaps*/




.collapse {
  visibility: hidden;
}
.collapse.show {
  visibility: visible;
  display: block;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: auto;
}
/* collaps*/
</style>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.boxScroll {
    height:800px;
    overflow-y: scroll;
}
.list-group-item.active {
    background-color: $studioAPPColor !important;
    border-color: $studioAPPColor !important;
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
    padding-left: 0 !important;

}
.content-page {
    margin-left: 0px !important;
    padding: 100px 0px 0px !important;

}

.btn-studioAPP{
     color: transparent !important;
     border:1px solid $studioAPPColor;
     background-color: $studioAPPColor !important;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: transparent !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* default*/

</style>