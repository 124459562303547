<template>

  <div>
    <!-- modal crear nuevo elemento -->
    <b-modal   no-close-on-backdrop centered size="lg"
               hide-footer
               ref="window_responder_visible"
               title="Contestando"
               v-model="window_responder_visible"
               persistent>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación especifique los siguientes campos
        </b-alert>
        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <b-form>
                <b-row class="d-flex align-items-start justify-content-start my-3">
                  <b-col>
                    <label> <small> Escribe tu comentario...</small></label>
                    <b-form-input
                        class="mt-1"
                        ref="form_curso.nombre_curso"
                        style="font-size:smaller;"
                        v-model="respuesta"
                        type="text"
                       >
                    </b-form-input>
                  </b-col>
                </b-row>

                <b-row class="d-flex align-items-start justify-content-start my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="close()"
                    >Cancelar
                    </b-button>

                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right mx-2"
                        @click="guardarRespuesta()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>

              </b-form>
            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>
    </b-modal>

    <b-card
        style="max-width: 100%;"
        class="mx-auto"
    >
      <b-container>
        <b-row>
          <b-col cols="12">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-evenly">
                <b-avatar>
                  <img
                      :src="usuario.avatar"
                      alt="NivelA"
                  >
                </b-avatar>

                <b-form-input
                    v-model="registro_pregunta.pregunta_contenido"
                    placeholder="Escribe tu comentario..."
                    class="ml-1 p-1"
                    style="font-size:smaller;"
                    @keyup.enter="sendPregunta()"
                    @click:append-outer="sendPregunta()"
                ></b-form-input>
              </b-list-group-item>
            </b-list-group>
          </b-col>


          <b-col  v-for="registro_pregunta in preguntas" :key="registro_pregunta.key"
                  cols="12">

            <b-col cols="12">
              <b-list-group>

                <b-list-group-item class="d-flex justify-content-evenly">

                    <b-avatar>
                      <img
                          :src="usuario.avatar"
                          alt="NivelA"
                      />
                    </b-avatar>

                    <b-form-textarea
                        style="font-size:smaller;"
                        ref="descripcion"
                        v-model="registro_pregunta.pregunta_contenido"
                        rows="2"
                        class="mb-1 mr-3"
                    ></b-form-textarea>

                </b-list-group-item>
                <b-list-group-item class="my-1" tyle="background-color: #ffffff !important" >
                  <span class="ml-10 mr-2" style="font-size: 11px">{{registro_pregunta.nombre}}</span>

                  <b-button
                      @click="votar(registro_pregunta.key)"
                      variant="primary"
                      size="sm"
                      class="mb-2"
                  >
                    <svg style="box-shadow: #ffffff" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
                      <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                    </svg>
                    <span>{{ registro_pregunta.votos }}</span>
                  </b-button>

                  <b-button
                      @click="responder(registro_pregunta.key)"
                      variant="primary"
                      size="sm"
                      v-if="es_coach"
                      class="ml-2 mb-2 mr-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    </svg>
                  </b-button>
                  <span class="ml-7" style="font-size: 10px">{{ convertir_fecha(registro_pregunta.fechahora) }}</span>
                  <!-- COMENTARIOS-->

                  <b-col  v-for="registro_comentario in registro_pregunta.comentarios"
                          :key="registro_comentario.key"
                          cols="12"
                  >

                    <b-list-group-item class="d-flex justify-content-evenly">
                      <b-avatar>
                        <img height="25px"
                             width="25px"
                             :src="registro_comentario.avatar"
                             alt="NivelA"
                        />
                      </b-avatar>

                      <b-form-textarea
                          style="font-size:smaller;"
                          ref="descripcion"
                          v-model="registro_comentario.comentario"
                          rows="2"
                          class="mb-1"
                      ></b-form-textarea>

                      <span class="mr-2" style="font-size: 11px">{{registro_comentario.nombre}}</span>

                    </b-list-group-item>

                  </b-col>
                  <!-- FIN COMENTARIOS -->




                </b-list-group-item>
              </b-list-group>
            </b-col>

          </b-col>

        </b-row>
      </b-container>


    </b-card>
  </div>







</template>

<script>
import { mapGetters } from 'vuex'
import firebaseApp from '@/firebaseConfRtdb/init';
import moment from "moment"

export default {
  name: 'chat',

  props:
      {
        //email_logueado: String,
        id_evento: Number,
        es_coach: Boolean,
      },
  data() {
    return {
      test_usuario: this.$store.getters.usuario.name,
      pregunta_contenido: "",
      email: null,
      email_logueado: null,
      activeChat: 1,
      votos: 0,
      window_responder_visible: false,
      key_pregunta_responder: null,
      correo: "",
      preguntas: [],
      respuesta: null,
      comentarios: [],
      model: 1,
      usuario: [],
      referencia_firebase: firebaseApp.database().ref('preguntas/'),
      registro_pregunta: {
        pregunta_contenido: '',
        votos: 0,
        email: null,
        evento_id: null,
        es_respuesta: false,
        comentarios: 0,
        nombre: '',
        avatar: '',
        fechahora: ''
      },
      items: [
        {
          color: '#1F7087',
          src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
          title: 'Supermodel',
          artist: 'Foster the People',
        },
        {
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        },
      ],
    }
  },
  beforeCreate() {
   // this.$store.commit('initialiseStore');
  },
  methods: {
    dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
      }
    },
    dynamicSortMultiple() {
      var props = arguments;
      return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
          result = this.dynamicSort(props[i])(obj1, obj2);
          i++;
        }
        return result;
      }
    },
    convertir_fecha(fechahora) {
      moment.locale();
      return moment(fechahora, "X").format("LLL")
    },
    getUsuarioConectado() {
       let url = '/api/users/get-user';
       this.$api.get(url)
           .then((response) => {
             this.usuario = response.data;
             this.email_logueado = this.usuario.email;
           })
           .catch(function (error) {
             console.log(error)
           });

     // console.log(this.$store.getters);
    //  this.$store.dispatch('fetchUsuario');
       ///console.log(this.$store.state.usuario);
      /*
      this.email_logueado = this.$store.getters.usuario.email;
      this.usuario.name   = this.$store.getters.usuario.name;
      this.usuario.avatar =  this.$store.getters.usuario.avatar;
      console.log(this.email_logueado);
*/
    },
    guardarRespuesta() {
      let total_comentarios = 0
      let comentario_usuario  = null;
      comentario_usuario  = this.respuesta
      comentario_usuario  = comentario_usuario.trim()
      if(comentario_usuario.length>0) {
        var postData = {
          comentario: this.respuesta,
          emailUsuario: this.email_logueado,
          nombre:  this.usuario.name,
          avatar:  this.usuario.avatar
        };
        //Respuesta
        firebaseApp.database().ref().child('preguntas/' + this.key_pregunta_responder + '/respuestas').push(postData);
        //obteniendo total de comentarios actuales actuales
        firebaseApp.database().ref().child('preguntas/' + this.key_pregunta_responder).on("value", function (snapshot) {
          total_comentarios = snapshot.val().total_comentarios
          //console.log(comentarios)
        });
        //incrementando en 1 el numero de comentarios y actualizando
        var database_actualizar = firebaseApp.database();
        const ref = database_actualizar.ref('preguntas/' + this.key_pregunta_responder);
        ref.update({
          total_comentarios: parseInt(total_comentarios) + 1,
        });
        this.key_pregunta_responder = null
        this.respuesta = null
        this.close()
      }
    },
    votar(id) {
      let usuarioVoto = 0
      try {
        var emailUsuario = this.email_logueado;
        firebaseApp.database().ref().child('preguntas/' + id + '/usuarios').orderByChild('correo').equalTo(emailUsuario).on("value", function (snapshot) {
          if (snapshot.val())
            usuarioVoto = 1
          /*snapshot.forEach(function(data) {
            console.log(data.key + " aqui2");
          });*/
        });
      } catch (err) {
        console.log(err);
      }
      //fin de validar si ya votó
      if (usuarioVoto == 0) {
        try {
          let votos = 0
          var postData = {
            correo: this.email_logueado,

          };
          //Agregando usuario que está votando
          firebaseApp.database().ref().child('preguntas/' + id + '/usuarios').push(postData);

          //obteniendo votos actuales
          firebaseApp.database().ref().child('preguntas/' + id).on("value", function (snapshot) {
            votos = snapshot.val().votos
          });

          //incrementando en 1 y actualizando
          var database_actualizar = firebaseApp.database();
          const ref = database_actualizar.ref('preguntas/' + id);
          ref.update({
            votos: parseInt(votos) + 1,
          });

        } catch (err) {
          console.log(err);
        }
      }
    },
    close() {
      this.registro_pregunta.pregunta_contenido='';
      this.window_responder_visible = false;
    },
    responder(id) {
      this.window_responder_visible = true;
      this.key_pregunta_responder = id;
    },
    sendPregunta(){
      moment.locale();
      let hora = Math.round((moment().format("x")) / 1000)
      let comentario_usuario = null
      //alert(this.registro_pregunta.comentarios)
      comentario_usuario  = this.registro_pregunta.pregunta_contenido
      comentario_usuario  = comentario_usuario.trim()
      if(comentario_usuario.length>0){
        this.registro_pregunta.evento_id = this.id_evento
        const registro_pregunta = {
          pregunta_contenido: comentario_usuario,
          email: this.email_logueado,
          votos: this.registro_pregunta.votos,
          nombre:  this.usuario.name,
          avatar: this.usuario.avatar,
          total_comentarios: this.registro_pregunta.comentarios,
          evento_id: this.registro_pregunta.evento_id,
          es_respuesta: false,
          fechahora: hora,
        };
        firebaseApp
            .database()
            .ref("preguntas")
            .push(registro_pregunta);
        this.registro_pregunta.pregunta_contenido = "";
      }
    },
    loadPreguntas() {
      let coach = this.es_coach
      this.referencia_firebase.orderByChild('votos').on('value', (snapshot) => {
        this.preguntas = [];
        //get comments
        //this.comentarios = [];
        let concentrado_comentarios = []

        //let idevento = this.id_evento
        snapshot.forEach((doc) => {
          if (doc.val().evento_id == this.id_evento) {
            //llenar el arreglo de respuestas
            //console.log(doc.key)

            firebaseApp.database().ref().child('preguntas/' + doc.key + '/respuestas').on("value", function (snapshot2) {
              if (snapshot2.val()) {
                //console.log("comentario si ingreso")
                //console.log(snapshot2.val())
                snapshot2.forEach((registros) => {
                  concentrado_comentarios.push({
                    comentario: registros.val().comentario,
                    emailUsuario: registros.val().emailUsuario,
                    nombre: registros.val().nombre,
                    avatar: registros.val().avatar,
                    key: registros.key,
                  })
                });
                //console.log("concentrado_comentarios")
                //console.log(concentrado_comentarios)
              }
            });
            this.preguntas.push({
              key: doc.key,
              email: doc.val().email,
              es_respuesta: doc.val().es_respuesta,
              nombre: doc.val().nombre,
              avatar: doc.val().avatar,
              evento_id: doc.val().evento_id,
              votos: doc.val().votos,
              total_comentarios: parseInt(doc.val().total_comentarios),
              pregunta_contenido: doc.val().pregunta_contenido,
              comentarios: concentrado_comentarios,
              fechahora: doc.val().fechahora
            })
            //ordernar a preguntas con los votos
            concentrado_comentarios = []
            // this.preguntas.sort(this.dynamicSortMultiple("votos"))
          }
          if (coach) {
            this.preguntas.sort(function (a, b) {
              return b.votos - a.votos;
            });
          } else {
            this.preguntas.sort(function (a, b) {
           //   return b.fechahora - a.fechahora;
              return b.votos - a.votos;
            });
          }
        });
        console.log(this.preguntas)
      });
    }
  },

  mounted() {
    /*
    this.test_usuario =this.$store.getters;
    console.log('---------------------');
    console.log( this.test_usuario);
    console.log('---------------------');
*/

    //console.log(this.formattedDate);


    this.getUsuarioConectado();
    this.loadPreguntas();
  },
  computed: {
    ...mapGetters(['formattedDate']),
    timestamp: function () {
      //return moment().locale()
      let utxtimes = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      return moment(utxtimes).format("X");
    }
  }
}
</script>
<style scoped>
/*.theme--light .v-card {
  border-left: 5px solid cadetblue !important;
}*/
</style>