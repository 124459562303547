<template>
    <b-container>

    <!-- modal borrar curso -->
      <!-- -->
    <b-modal
        hide-header-close
        v-model="ModalBorrarCurso"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar el curso: <b>"{{ form_curso.nombre_curso }}"</b> ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="cerrarModalBorrarConfirmacion()"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="borrarCurso()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>

    <!-- modal crear curso -->
     <b-modal   no-close-on-backdrop
                centered size="lg"
                hide-footer
                no-close-on-esc
                hide-header-close
                ref="ModalCrearCurso" 
                title="Cursos"
                v-model="ModalCrearCurso"
                persistent
                >
            <b-card
                    tag="article"
                    class="mb-2">
                    <b-alert show variant="info" class="p-3 text-justify">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                      Este apartado se utiliza para añadir nuevos cursos. Por favor, rellene todos los campos y recuerde que también puede agregar sus cursos desde la sección de Contenido de Currícula
                    </b-alert>
                <b-card-text>
                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                            <b-form-input
                                class="my-2"
                                ref="form_curso.nombre_curso"
                                v-model="form_curso.nombre_curso"
                                type="text"
                                placeholder="Nombre del curso">
                            </b-form-input>
                                <b-row>
                                    <b-col>

                                    <b-form-group
                                        class="my-2"
                                        id="input-group-1"
                                        label-for="input-1"
                                        style="font-style: italic;"
                                        description="Minutos estimados para tomar el curso (sólo números)">
                                    <b-form-input
                                            id="input-1"
                                            v-model="form_curso.minutos_curso"
                                            type="number"
                                    ></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                    <b-col>

                                      <b-form-checkbox
                                          class="mt-3"
                                          v-model="form_curso.cursoEnVivo"
                                          value="1">
                                        Hacer curso en vivo
                                      </b-form-checkbox>
                                    </b-col>
                                </b-row>


                              <b-row>
                                <b-col>
                                  <b-form-checkbox
                                      class="mt-1"
                                      v-model="form_curso.progreso_obligatorio"
                                      value="1">
                                    Este curso requiere haber completado previamente otros cursos
                                  </b-form-checkbox>
                                </b-col>
                              </b-row>
                        <b-row v-show="form_curso.progreso_obligatorio == 1">
                                <b-col>
                                  <div class="my-2">
                                     <small> <i>Cursos que serán NECESARIOS cursar previamente para poder acceder (pueden ser varios)</i> </small>
                                    <v-select
                                        class="my-3"
                                        placeholder="Listado de cursos "
                                        v-model="form_curso.cursos_requeridos"
                                        multiple
                                        :options="curso_items">
                                      <template slot="option" slot-scope="option">
                                        {{ option.label }}
                                      </template>

                                      <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                          No resultados para <em>{{ search }}</em
                                        >.
                                        </template>
                                        <em v-else style="opacity: 0.5">Elegir curso primero</em>
                                      </template>
                                    </v-select>

                                  </div>
                                </b-col>
                              </b-row>

                                <b-form-input
                                    ref="form_curso.descripcion"
                                    class="my-2"
                                    v-model="form_curso.clave_interna"
                                    type="text"
                                    placeholder="Clave interna del curso"                    
                                    ></b-form-input>

                                <b-form-textarea
                                    ref="form_curso.descripcion"
                                    v-model="form_curso.descripcion"
                                    placeholder="Descripción del curso..."
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>

                                <v-select
                                    class="my-3"
                                    placeholder="Elegir una o varias unidades "
                                    v-model="form_curso.unidades"
                                    multiple 
                                    :options="unidades_disponibles">
                                </v-select>

                            <div v-if="form_curso.editar && form_curso.imagen != '#' " style="display: flex; justify-content: space-evenly; align-items:center" class="my-2">
                                <b-button  @click="reemplazarImagen()" variant="outline-primary" class="btn-studioAPP" sm >Reemplazar imagen 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                                    <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                                    </svg>
                                </b-button>

                                <b-img rounded :src="form_curso.imagen" height="200" width="200" fluid></b-img>
                               
                            </div>

                            <div v-else>
                                <b-form-file class="mb-1"
                                    size="sm"
                                    v-model="form_curso.imagenNueva"
                                    :state="Boolean(form_curso.imagenNueva)"
                                    placeholder="Archivo adjunto..."
                                    accept="image/*"
                                    drop-placeholder="Arrastrar un archivo para adjuntar."
                                ></b-form-file>  
                            </div>

                        <b-row class="d-flex align-items-start justify-content-start my-1">
                          <b-col>
                            <b-form-datepicker locale="es"
                                               placeholder="Fecha vigencia inicial"
                                               v-model="form_curso.fecha_vigencia_inicio"
                                               size="sm"
                                               label-help=''
                                               label-today-button='Hoy'
                                               today-button
                                               label-no-date-selected='No se ha elegido fecha'
                            >
                            </b-form-datepicker>
                          </b-col>
                          <b-col>
                            <b-form-datepicker locale="es"
                                               placeholder="Fecha vigencia finalización"
                                               v-model="form_curso.fecha_vigencia_finalizacion"
                                               size="sm"
                                               label-help=''
                                               label-today-button='Hoy'
                                               today-button
                                               label-no-date-selected='No se ha elegido fecha'
                            >
                            </b-form-datepicker>
                          </b-col>
                        </b-row>

                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="w-100">
                            <b-button
                                    variant="primary"
                                    size="sm"
                                    class="float-right"
                                    @click="cerrarModalNuevoCurso()"
                                >
                                    Cancelar
                                </b-button>  
                                 
                                <b-button v-if="form_curso.editar==true"
                                    variant="info" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="actualizarCurso()"
                                >
                                Actualizar registro
                                </b-button>   
                                <b-button v-if="form_curso.editar==false"
                                    variant="success" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="guardarCurso()"
                                >
                                Guardar
                                </b-button>                             
                            </div>
                        </b-row>
                

                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

      <b-row>
          <b-col cols="12">
             <div class="card card-block">
                <div class="card-body p-2">
                  <div class="d-flex justify-content-between align-items-start p-2">
                    <b-jumbotron lead="Cursos"></b-jumbotron>
                  </div>

                  <b-alert
                      class="p-3"
                      show
                      variant="info"
                      dismissible
                  >
                    En esta sección se pueden crear cursos y añadirles unidades, tanto si son autogestionados como para sesiones en vivo.
                  </b-alert>

                  <b-row class="d-flex align-items-end justify-content-end my-3">
                          <div class="col-sm-12 text-right">
                                <b-button   @click="abrirModaNuevoCurso"
                                            class="btn-studioAPP" 
                                            variant="outline-warning"> Agregar nuevo curso
                                </b-button>
                          </div>
                  </b-row>
                   <b-row class="d-flex align-items-end justify-content-end my-1">
                       <div class="col-sm-12 my-2">
                       <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Buscar"
                                  >
                        </b-form-input>
                        </div>
                    </b-row>

                   <b-table class="table data-table"
                        striped="striped"
                        hover="hover"
                        outlined="outlined"
                        :items="curso_items"
                        :fields="curso_headers"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                            stacked="md"
                            show-empty
                            small
                       >
                    <template #empty="scope">
                        <h5 class="text-center">
                            {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                        </h5>
                    </template>        
                    <template #emptyfiltered="scope">
                        <h5 class="text-center">
                            {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                        </h5>
                    </template>    
                    <template #cell(progreso_obligatorio)="row">  
                            <div v-if="`${row.item.progreso_obligatorio}`!=='0' ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                </svg>
                            </div>       
                    </template>
                     <template #cell(cursoEnVivo)="row">
                       <div v-if="`${row.item.cursoEnVivo}`!=='0' ">
<!--                         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                           <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                         </svg>-->

                         <div class="d-flex justify-content-around align-items-center">
                           <b-button v-if="row.item.cursoEnVivo===1"
                                     variant="secondary" v-b-modal.modal-prevent-closing size="sm"
                                     @click="cursosEventos(row.item.id)" class="mr-1">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-reels-fill" viewBox="0 0 16 16">
                               <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                               <path d="M9 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                               <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7z"/>
                             </svg>
                           </b-button>
                         </div>

                       </div>
                     </template>



                    <template #cell(actions)="row">   
                        <div class="d-flex justify-content-around align-items-center">

                            <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                        @click="EditMode(row.item)" class="mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </b-button>

                            <b-button variant="danger" size="sm" @click="ShowmodalBorrarConfirmacion(row.item)" class="ml-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                            </b-button>

                        </div>               
                    </template>

                    </b-table>
                    <div class="d-flex justify-content-between row p-2">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <div>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                >
                                </b-pagination>
                            </div>
                            </div>
                    </div>

                </div>
             </div>
          </b-col>
      </b-row>

    </b-container>
  
</template>

<script>
import toast from "../../mixins/ToastMixin.js";
import CursoService from "../../services/CursoService";
import Bugsnag from "@bugsnag/js";

export default {
    name: 'Cursosall',
    mixins:[toast],
    mounted () {
        this.getUnidadesDisponibles();
        this.showCursos();
    },
    data() {
        return { 
          
          unidades_disponibles: [
             { label: 'One',      id: 1 },
             { label: 'Two',      id: 2 },
             { label: 'tres',     id: 3 },
             { label: 'cuatro',   id: 4 },
          ],

        ModalBorrarCurso:false,
        ModalCrearCurso:false,    
        curso_headers: [
                    {   label: 'Nombre',        key: 'nombre_curso', class: 'text-start'},
                    {   label: 'Clave',         key: 'clave_interna', class: 'text-center' },
                    {   label: 'Requisitos?',   key: 'progreso_obligatorio', class: 'text-center' },
                    {   label: '¿En vivo?',     key: 'cursoEnVivo', class: 'text-center' },
                    {  label: 'Fecha creación', key: 'fecha', class: 'text-center' },
                    {  label: 'Acciones',       key: 'actions', class: 'text-center' }
                ],
        curso_items: [],

        form_curso: {
                cursos_requeridos:[],
                minutos_curso: 0,
                nombre_curso: '',
                clave_interna: '',
                descripcion: '',
                unidades: [],
                progreso_obligatorio: 0,
                cursoEnVivo: 0,
                imagen                      :null,
                imagenNueva                 :null,
                reemplazarImagen            :false,
                id                          :0,
                editar                      :false,
                fecha_vigencia_inicio : '',
                fecha_vigencia_finalizacion : ''
            },    
            
            ///
            totalRows: 1,
            currentPage: 1,
            perPage: 8,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter: null,
            filterOn: [],
            search: '',  
        }
    },

    methods: {
      cursosEventos(curso_id){

        this.$router.push(`/curso-evento/${curso_id}`);
        /*
        this.$router.push({
          name: 'CursoEvento',
          params          : {
            curso_id        : id
          }
        });
        */
      },
      async borrarCurso(){
          try {
            let index       = this.curso_items.indexOf(this.form_curso.id);
            const response   = await CursoService.delete(this.form_curso.id);
            let {   success,
                    message,
                    color
                  }   = response.data;
            if(success==true){
              this.curso_items.splice(index, 1);
              this.toast('b-toaster-bottom-center', true, message, color );
              this.ModalBorrarCurso = false;
              await this.showCursos();
              this.resetForm();
            }

          } catch (error) {
            this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
            Bugsnag.notify(
                new Error('Método: borrarCurso() ' + error.message)
            );
          }

    },
    cerrarModalBorrarConfirmacion(){
        this.ModalBorrarCurso = false;
        this.resetForm();
    },
    ShowmodalBorrarConfirmacion(item){
        this.form_curso.nombre_curso = item.nombre_curso;
        this.form_curso.id           = item.id;
        this.ModalBorrarCurso = true;
    },
    async actualizarCurso(){
      try {

        let formData = new FormData();
        let arrCursos_requeridos = [];
        formData.append("id"                        , this.form_curso.id);
        formData.append("nombre_curso",               this.form_curso.nombre_curso);
        formData.append("descripcion"               , this.form_curso.descripcion);
        formData.append("minutos_curso"             , this.form_curso.minutos_curso);

        formData.append("clave_interna"            , this.form_curso.clave_interna);

        let arrUnidades = [];
        this.form_curso.unidades.forEach(el => {
          arrUnidades.push(el.id);
        });
        formData.append("unidades"                 , arrUnidades);

        if(this.form_curso.progreso_obligatorio===false || this.form_curso.progreso_obligatorio===0){
          formData.append("progreso_obligatorio", 0 );
        }else {
          formData.append("progreso_obligatorio", 1 );
          this.form_curso.cursos_requeridos.forEach(el => {
            arrCursos_requeridos.push(el.id);
          });
        }

        if(this.form_curso.cursoEnVivo===false || this.form_curso.cursoEnVivo===0){
          formData.append("cursoEnVivo", 0 );
        }else {
          formData.append("cursoEnVivo", 1 );
        }

        //  laravel lo recibe como imagen             imagen en form input file
        formData.append("imagen"                    , this.form_curso.imagenNueva);
        //true si se va a reemplazar false si no    this.form_curso.reemplazarImagen
        formData.append("reemplazarImagen"          , this.form_curso.reemplazarImagen);
        /// //URL DE LA IMAGEN ACTUAL
        formData.append("url_image"                 , this.form_curso.imagen);
        formData.append("requeridos",    arrCursos_requeridos);

        formData.append("fecha_vigencia_inicio",                 this.form_curso.fecha_vigencia_inicio);
        formData.append("fecha_vigencia_finalizacion",           this.form_curso.fecha_vigencia_finalizacion);

        formData.append("_method", "PUT");
        //const response   = await this.$api.post(url, formData, config );
        const response = await CursoService.update(this.form_curso.id, formData);
        let {   success,
          message,
          color
        } = response.data;
        if(success){
          await this.showCursos();
          this.ModalCrearCurso = false;
          this.resetForm();
        }
        this.toast('b-toaster-bottom-center', true, message, color );
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: actualizarCurso() ' + error.message)
        );
      }


    },
    EditMode(item){
        this.form_curso.editar=true;
        this.form_curso.nombre_curso            = item.nombre_curso;
        this.form_curso.descripcion             = item.descripcion;
        this.form_curso.clave_interna           = item.clave_interna;
        this.form_curso.fecha_vigencia_inicio           = item.fecha_seleccion_inicio;
        this.form_curso.fecha_vigencia_finalizacion     = item.fecha_seleccion_termino;
        this.form_curso.minutos_curso           = item.minutos_curso;
        this.form_curso.progreso_obligatorio    = item.progreso_obligatorio;
        this.form_curso.cursoEnVivo             = item.cursoEnVivo;
        this.form_curso.id                      = item.id;
        this.form_curso.unidades                = item.unidadescurso;

        let unidadesParaSelect = [];
        let unidadCompelta=[];
        for (var i = 0; i < item.unidadescurso.length; i++) {
            unidadCompelta = this.unidades_disponibles.find(element => element.id == item.unidadescurso[i]);
            unidadesParaSelect.push( {  id: unidadCompelta.id, label:unidadCompelta.nombre_unidad} );
        }
      //  console.log(unidadesParaSelect);
        this.form_curso.unidades                      = unidadesParaSelect;

        this.form_curso.imagen                  = item.imagen;
        this.ModalCrearCurso                    = true;
       
    },
    abrirModaNuevoCurso(){
        this.ModalCrearCurso=true;
    },
    cerrarModalNuevoCurso(){
        this.resetForm();
        this.ModalCrearCurso = false;
    },
    async showCursos() {

      try {
        let response = await CursoService.getCursos();

        let {   message,
                cursos,
                color,
                cantidad
        } = response.data;
        this.curso_items            = cursos;
        this.totalRows              = cantidad;

        this.toast('b-toaster-bottom-center', true, message, color);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: showCursos() ' + error.message)
        );
      }


     },
    async guardarCurso() {

      this.ModalCrearCurso = false;
      this.toast('b-toaster-bottom-center', true, "Creando curso...un momento", 'info' );
      try {
        let arrCursos_requeridos = [];
        let arrUnidades = [];
        this.form_curso.unidades.forEach(el => {
          arrUnidades.push(el.id);
        });

        let formData = new FormData();
            formData.append("nombre_curso",         this.form_curso.nombre_curso);
            formData.append("minutos_curso",        this.form_curso.minutos_curso);
          //  formData.append("progreso_obligatorio", (typeof this.form_curso.progreso_obligatorio === 'undefined' ? 0 : (this.form_curso.progreso_obligatorio) ? 1 : 0 ));
          //  formData.append("cursoEnVivo",          (typeof this.form_curso.cursoEnVivo === 'undefined' ? 0 : (this.form_curso.cursoEnVivo) ? 1 : 0 ));

            if(this.form_curso.progreso_obligatorio===false || this.form_curso.progreso_obligatorio===0){
              formData.append("progreso_obligatorio", 0 );
            }else {
              formData.append("progreso_obligatorio", 1 );
              this.form_curso.cursos_requeridos.forEach(el => {
                arrCursos_requeridos.push(el.id);
              });
            }

            if(this.form_curso.cursoEnVivo===false || this.form_curso.cursoEnVivo===0){
              formData.append("cursoEnVivo", 0 );
            }else {
              formData.append("cursoEnVivo", 1 );
            }

            formData.append("cursoEnVivo",          this.form_curso.cursoEnVivo );
            formData.append("clave_interna",        this.form_curso.clave_interna);
            formData.append("descripcion",          this.form_curso.descripcion);
            formData.append("unidades",             arrUnidades);
            formData.append("imagen",               this.form_curso.imagenNueva);
            formData.append("requeridos",    arrCursos_requeridos);

          formData.append("fecha_vigencia_inicio",                 this.form_curso.fecha_vigencia_inicio);
          formData.append("fecha_vigencia_finalizacion",           this.form_curso.fecha_vigencia_finalizacion);

        const response = await CursoService.save(formData);
        let {   success,
          message,
          color
        } = response.data;
        console.log('response.data');
        console.log(response.data);
        if(success===true){
          await this.showCursos();
          this.toast('b-toaster-bottom-center', true, message, color );
          this.resetForm();
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: guardarCurso() ' + error.message)
        );
      }


    },
    async getUnidadesDisponibles() {
        const response            = await this.$api.get(`/api/unidades`);
        this.unidades_disponibles = response.data.unidades;
    },
    resetForm(){
        this.form_curso.nombre_curso                    = '';
        this.form_curso.minutos_curso                   = '';
        this.form_curso.progreso_obligatorio            = 0;
        this.form_curso.cursoEnVivo                     = 0;
        this.form_curso.clave_interna                   = '';
        this.form_curso.descripcion                     = '';

        this.form_curso.fecha_vigencia_inicio           = '';
        this.form_curso.fecha_vigencia_finalizacion     = '';

        this.form_curso.unidades                        = [];
        this.form_curso.cursos_requeridos               = [];
        this.form_curso.imagen                          = null;
        this.form_curso.imagenNueva                     = null;
        this.form_curso.reemplazarImagen                = false;
        this.form_curso.id                              = '';
        this.form_curso.editar                          = false;
    },
    reemplazarImagen(){
        this.form_curso.imagen                = '#';
        this.form_curso.reemplazarImagen      = true;
    }
    },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}


</style>