<template>
  <b-row style="display: flex; justify-content: center; " class="my-3 mx-3">
    <b-col>
      <b-button @click="navegar()"
                variant="outline-success"> Navegar a Uno
      </b-button>
    </b-col>
    <b-col>
      {{this.curricula.nombre}}
    </b-col>



  </b-row>
</template>

<script>
export default {
  name: "LocalStorageDos",
  mounted() {
    this.curricula        = this.$route.params.curricula;
    this.curso            = this.$route.params.curso;

    //refresca y se pierde el item de navegación
    if( this.$route.params.curricula == undefined )
    {
            console.log( 'SE PERDIÓ: carga info de localstorage' );
            if (localStorage.getItem('curricula')) {
              try {
                this.curricula = JSON.parse( localStorage.getItem('curricula') );
              } catch(e) {
              //  localStorage.removeItem('cats');
              }
            }

    }else{
      console.log( 'SE TIENE: carga info de ROUTE PARAMS' );
      const parsed = JSON.stringify(this.curricula);
      localStorage.setItem('curricula', parsed);

    }

    console.log( this.curricula.nombre );


  },
  data() {
    return {
      cats: [],
      curricula: {},
      curso:{}
    }

  },
  methods: {
    addCat() {
      // asegurarse que el usuario efectivamente ha escrito algo
      if (!this.newCat) {
        return;
      }
      this.cats.push(this.newCat);
      this.newCat = '';
      this.saveCats();
    },
    removeCat(x) {
      this.cats.splice(x, 1);
      this.saveCats();
    },
    saveCats() {
      const parsed = JSON.stringify(this.cats);
      localStorage.setItem('cats', parsed);
    },
    navegar(){
      this.$router.push( {
        name: 'LocalStorageUno'
      });
    },
  },

}
</script>

<style scoped>

</style>