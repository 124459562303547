<template>
  <b-container>
    <b-row>
      <b-col cols="12">
          <div class="card card-block">
            <div class="card-body p-2">

              <div class="d-flex justify-content-between align-items-center p-2">
                <b-jumbotron lead="Vista del stream desde el docente"></b-jumbotron>
              </div>
              <b-alert
                  class="p-3"
                  show
                  variant="primary"
                  dismissible>
               Desde este apartado se puede gestionar el streaming en vivo mediante los controles para habilitarlo, habilitar encuestas  (proximamente)  y habilitar el chat
              </b-alert>
              <b-row style="display: flex; justify-content:flex-start ;" class="my-3 mx-3">
                <b-button @click="volver()"
                          class="btn-studioAPP"
                          variant="outline-warning"> Volver
                </b-button>
              </b-row>

              <div class="d-flex justify-content-center row p-2">
                <b-card
                    :title="stream.titulo_video"
                    :img-src="stream.thumbnail_video"
                    img-alt="Image"
                    img-top
                    style="max-width: 30rem;"
                    class="mb-2"
                >
                  <b-card-text>
                  <p>{{ stream.descripcion_video }}</p>
                    <div class="d-flex justify-content-between">
                      <b-form-checkbox :value="( (`${stream.stream_iniciado}`!=0) ? '1':0  )" :checked="( (`${stream.stream_iniciado}`!=0) ? '1':null  )" switch size="lg" @change=" streamVisibility(stream.id) "> Habilitar stream</b-form-checkbox>
                      <b-form-checkbox :value="( (`${stream.chat_habilitado}`!=0) ? '1':0  )" :checked="( (`${stream.chat_habilitado}`!=0) ? '1':null  )" switch size="lg" @change=" chatVisibility(stream.id) "> Habilitar chat</b-form-checkbox>
                    </div>

                  </b-card-text>
                </b-card>

              </div>

              <div v-if="stream.chat_habilitado == 1">
<!--                  <chat :id_evento="33" :es_coach="true"></chat>-->
                <chat :id_evento="stream.id" :es_coach="true"></chat>
              </div>
              <div v-else>

              </div>

            </div><!--card-body p-2-->
          </div><!--card card-block-->


      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import firebase from '@/firebaseConfRtdb/init';
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";
import Chat from "@/components/chat.vue";
import EventoStreamService from "../../../../../services/Evento/EventoStreamService";
export default {
  components : {Chat},
  name: "CoachStreamEnVivo",
  mixins:[toast]

  ,mounted() {

    this.auth = firebase.auth();
    this.auth.onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
         this.initFirebase();
      } else {
        this.email_firebase = 'nivelando@g.nive.la';
        this.password_firebase = '123456789';
        this.registerUser(this.email_firebase, this.password_firebase);
      }
    });
    //console.log(this.$route.params.eventoStream);
    this.stream.id                = this.$route.params.eventoStream.id;
    this.stream.evento_id         = this.$route.params.eventoStream.evento_id;
    this.stream.titulo_video      = this.$route.params.eventoStream.titulo_video;
    this.stream.thumbnail_video   = this.$route.params.eventoStream.thumbnail_video;
    this.stream.descripcion_video = this.$route.params.eventoStream.descripcion_video;
    this.getData(this.stream.id);
    this.logUser();

  },
  data() {
    return {
      auth_user: null,
      auth:null,

      stream: {
        id:0,
        evento_id:0,
        titulo_video:'',
        thumbnail_video:'',
        descripcion_video:'',
        stream_iniciado:null,
        chat_habilitado:null
      }
    }
  },
  methods: {

    async logUser(){
      try {
        let response = await EventoStreamService.log();
        console.log(response+"");
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getStreamingVideos() ' + error.message)
        );
      }


    },

    initFirebase() {

      let database = firebase.database().ref('streaming-events');
      // ponemos a escuchar firebase y si el evento se actualiza, ejecutamos updateStreamStatus()
      database.on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          let varChild = childSnapshot.val();
          if ( varChild ) {
            if ( parseInt(varChild.evento_streaming_id) == this.stream.id ) {
              this.updateStreamStatus( varChild );
            }
          }
        })

      })

    },

    updateStreamStatus( eventStreamData = [] ) {
      console.log("entra a updateStreamStatus");
      console.log(eventStreamData);
      // si el valor es 1, el streaming esta habilitado para su emision
      this.stream.stream_iniciado = parseInt(eventStreamData.stream_iniciado);
      this.stream.chat_habilitado = parseInt(eventStreamData.chat_habilitado);
    },

    loginUser(email, password) {
      //Llamamos al método de login de firebase
      this.auth.signInWithEmailAndPassword(email, password)
          .catch(err => {
            console.log(err);
          }).finally(() => {
        this.password_firebase = "";
      });
    }, //loginUser

    registerUser(email, password) {
      this.auth.createUserWithEmailAndPassword(email, password).then( () => {
        // console.log("createUserWithEmailAndPassword OK!!!");
      }).catch(err => {
        console.log(err)
        this.loginUser(this.email_firebase, this.password_firebase);
      }).finally(() => {
        this.password_firebase = "";
      });
    }, //register user
    getData(stream_id){
      try {
        //actualizamos los controles en vmodel
        let database = firebase.database().ref('streaming-events');
        database.orderByChild('evento_streaming_id').equalTo( stream_id ).once("value").then( (snapshot) => {
          let eventStreamData = snapshot.val();
          let key = Object.keys(eventStreamData)[0];
          this.stream.stream_iniciado = eventStreamData[key].stream_iniciado;
          this.stream.chat_habilitado = eventStreamData[key].chat_habilitado;
        });
      } catch (error) {
        Bugsnag.notify(
            new Error('Método: getData()' + error)
        );
      }
    },
    streamVisibility(eventoStream_id){
      try {
        let database = firebase.database().ref('streaming-events');
        database.orderByChild('evento_streaming_id').equalTo(eventoStream_id).once("value").then( (snapshot) => {
          let eventStreamData = snapshot.val();
          let key = Object.keys(eventStreamData)[0];
          eventStreamData[key].stream_iniciado = ( eventStreamData[key].stream_iniciado == 1 ) ? 0 : 1 ;
          firebase.database().ref('streaming-events'+ "/" + key).update( eventStreamData[key] )
        });
      } catch (error) {
        Bugsnag.notify(
            new Error('Método: streamVisibility()' + error)
        );
      }
    },
    chatVisibility(eventoStream_id){
      try {
        let database = firebase.database().ref('streaming-events');
        database.orderByChild('evento_streaming_id').equalTo(eventoStream_id).once("value").then( (snapshot) => {
          let eventStreamData = snapshot.val();
          let key = Object.keys(eventStreamData)[0];
          eventStreamData[key].chat_habilitado = ( eventStreamData[key].chat_habilitado == 1 ) ? 0 : 1 ;
          this.stream.chat_habilitado = eventStreamData[key].chat_habilitado;
          firebase.database().ref('streaming-events'+ "/" + key).update( eventStreamData[key] )
        });
      } catch (error) {
        Bugsnag.notify(
            new Error('Método: chatVisibility()' + error)
        );
      }
    },
    volver(){
        this.$router.push({name: 'EventosStream'});
    },
  },

}//export default
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>