import api from '@/services/api';

export default{
    get(){
        return api({
            url: `/api/eventos-stream`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getEventosSesiones(){
        return api({
            url: `/api/eventos-stream/get-sesiones-for-stream`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getYoutubeVideos(){
        return api({
            url: `/api/eventos-stream/search-youtube`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    save(formData){
        let url          = `/api/eventos-stream/store`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    update(id, formData){
        return api({
            url:  `/api/eventos-stream/update/${id}`,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    delete(id){
        let url = `/api/eventos-stream/destroy/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    log(){
        let url          = `/api/eventos-stream/log`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    },

}