export default {
    created() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    destroyed() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    methods: {
        async handleBeforeUnload(event) {
/*            const userId = this.$store.getters.usuario.id;
            this.$store.dispatch('updateIsOnline', userId);*/
            await this.$store.dispatch('updateIsOnline', this.$store.getters.usuario.id);
            event.preventDefault();
            await this.$store.dispatch('updateIsOnline', this.$store.getters.usuario.id);
        }
    }
};

