<template>
  <b-container fluid="lg">

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Analítica 📊"></b-jumbotron>
            </div>

            <div>
              <b-tabs content-class="mt-3">
                <div class="my-2 w-25 d-flex justify-content-between">
                  <v-select
                      style="width: 300px"
                      size="lg"
                      class="my-3"
                      placeholder="Elegir grupo .... "
                      v-model="grupo"
                      :options="updatedGruposItems">
                  </v-select>
                </div>

                <b-tab title="Generales" active>
                  <b-form v-if="show">
                    <div class="border">

                    <div class="d-inline-block" >
                      <b-button
                          variant="primary"
                          size="sm"
                          class="mx-2 my-2"
                          @click="getDatosGeneralesByGrupo()"
                      >
                        Reporte a partir del grupo
                      </b-button>
                      <br>
                    <label>
                      🏫 Usuarios que han iniciado sesión:  <span class="result"> {{data.res1}} </span>
                    </label>
                      <br>
                     <small> <i> Usuarios que ya han iniciado, por lo menos, alguna vez sesión en la plataforma Aprende</i></small>
                    </div>
                    <br>
                    <div class="my-3 d-inline-block">
                      <label>
                        ⚠️ Usuarios que NO han iniciado sesión:  <span class="result"> {{data.res2}} </span>
                      </label>
                      <br>
                      <small> <i> Usuarios que NO han iniciado sesión NINGUNA vez en la plataforma Aprende</i></small>
                      <br>
                    </div>
                    <br>

                    <div class="my-3 d-inline-block">
                      <label>
                        👤 Cantidad de Usuarios únicos realizando actividades:  <span class="result"> {{data.res3}} </span>
                      </label>
                      <br>
                      <small> <i> Cantidad de usuarios sin repetir, que actualmente, están haciendo actividades</i></small>
                      <br>
                    </div>
                    <br>

                    <div class="my-3 d-inline-block">
                      <label>
                        👥 Usuarios que han completado al menos un curso: <span class="result"> {{data.res4}} </span>
                      </label>
                      <br>
                      <small> <i> Cantidad de usuarios que al menos, an completado algún curso en la plataforma</i></small>
                      <br>
                    </div>
                    <br>

                    <div class="my-3 d-inline-block">
                      <label>
                        👨‍🏫 Cursos completados en total por todos los alumnos:  <span class="result"> {{data.res5}} </span>
                      </label>
                      <br>
                      <small> <i> Cursos completados en total por todos los alumnos actualmente en la plataforma (usuario/curso)</i></small>
                      <br>
                    </div>
                    <br>

                    <div class="my-3 d-inline-block">
                      <label>
                        ✅ Actividades en total realizadas :   <span class="result">{{data.res6}} </span>
                      </label>
                      <br>
                      <small> <i> Total de actividades realizadas por todos los alumnos, cada checkbox palomeado por todos</i></small>
                      <br>
                    </div>
                    </div>
                    <br>
                  </b-form>

                </b-tab>
                <b-tab title="Cursos">
                  <b-row class="d-flex align-items-end justify-content-end my-1">
                    <div class="col-sm-12">
                      <b-button
                          variant="primary"
                          size="sm"
                          class="mx-2 my-2"
                          @click="getDatosGeneralesByGrupo()"
                      >
                        Reporte a partir del grupo
                      </b-button>

                      <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Buscar"
                      >
                      </b-form-input>
                    </div>
                  </b-row>
                  <b-table class="table data-table"
                           striped="striped"
                           hover="hover"
                           outlined="outlined"
                           :items="data.res7"
                           :fields="res7_headers"
                           :current-page="currentPage"
                           :per-page="perPage"
                           :filter="filter"
                           :filter-included-fields="filterOn"
                           stacked="md"
                           show-empty
                           small
                  >

                    <template #empty="scope">
                      <h5 class="text-center">
                        {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                      </h5>
                    </template>
                    <template #emptyfiltered="scope">
                      <h5 class="text-center">
                        {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                      </h5>
                    </template>

                  </b-table>
                  <div class="d-flex justify-content-between row p-2">
                    <div class="col-sm-12 col-md-6 d-flex align-items-center">

                      <b-button  @click="exportPDF(1)"  variant="outline-primary" class="btn-studioAPP" sm>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                        </svg>
                        Export</b-button>

                    </div>
                    <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                      <div>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                        >
                        </b-pagination>
                      </div>
                    </div>
                  </div>


                </b-tab>
                <b-tab title="Alumnos">
                  <b-row class="d-flex align-items-end justify-content-end my-1">
                    <div class="col-sm-12">
                      <b-button
                          variant="primary"
                          size="sm"
                          class="mx-2 my-2"
                          @click="getDatosGeneralesByGrupo()"
                      >
                        Reporte a partir del grupo
                      </b-button>
                      <b-form-input
                          id="filter-input"
                          v-model="filter2"
                          type="search"
                          placeholder="Buscar"
                      >
                      </b-form-input>
                    </div>
                  </b-row>
                  <b-table class="table data-table"
                           striped="striped"
                           hover="hover"
                           outlined="outlined"
                           :items="data.res8"
                           :fields="res8_headers"
                           :current-page="currentPage2"
                           :per-page="perPage2"
                           :filter="filter2"
                           :filter-included-fields="filterOn2"
                           stacked="md"
                           show-empty
                           small
                  >
                    <template #empty="scope">
                      <h5 class="text-center">
                        {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                      </h5>
                    </template>
                    <template #emptyfiltered="scope">
                      <h5 class="text-center">
                        {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                      </h5>
                    </template>
                  </b-table>
                  <div class="d-flex justify-content-between row p-2">
                    <div class="col-sm-12 col-md-6 d-flex align-items-center">
                      <b-button  @click="exportPDF(2)"  variant="outline-primary" class="btn-studioAPP" sm>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                        </svg>
                        Export</b-button>
                    </div>
                    <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                        <b-pagination
                            v-model="currentPage2"
                            :total-rows="totalRows2"
                            :per-page="perPage2"
                            align="fill"
                            size="sm"
                            class="my-0"
                        >
                        </b-pagination>
                    </div>
                  </div>

                </b-tab>
                <b-tab title="Avances currícula">
                  <b-row class="d-flex align-items-end justify-content-end my-1">
                    <div class="col-sm-12 my-2">
                      Currículas <small><i>(todas si se deja vacío)</i></small>⚠️
                      <div class="d-flex align-items-center justify-content-center">
                        <b-button @click="cleanCurriculaSelection()" variant="outline-primary" class="btn-studioAPP" size="sm" pill >X</b-button>
                        <v-select
                            class="my-3 ml-3 flex-grow-1"
                            placeholder="Elegir una o varias curriculas "
                            v-model="curriculas"
                            multiple
                            :options="curriculas_items">
                        </v-select>
                      </div>
                      Alumnos <small><i>(todos si se deja vacío)</i></small>⚠️
                      <div class="d-flex align-items-center justify-content-center">
                      <b-button @click="cleanAlumnosSelection()" variant="outline-primary" class="btn-studioAPP" size="sm" pill >X</b-button>
                      <v-select
                          class="my-3 ml-3 flex-grow-1"
                          placeholder="Elegir una o varios alumnos "
                          v-model="alumnos"
                          multiple
                          :options="alumnos_items">
                      </v-select>
                    </div>

                      <div class="d-flex align-items-center justify-content-end">
                        🔝 Con avance en currícula:
                        <b-form-select
                            size="sm"
                            style="width: 100px"
                            class="my-3 mx-3 "
                            v-model="advance_criteria"
                            :options="advance_items2">
                        > </b-form-select>
                        <b-form-input
                            style="width: 100px"
                            type="number"
                            step="1"
                            v-model="advance"
                            placeholder="Porcentaje de avance"
                        >
                        </b-form-input>
                        <b-button  @click="generarReporte()"  variant="outline-primary" class=" mx-2 btn-studioAPP" sm>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled-fill" viewBox="0 0 16 16">
                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1H6v2h7v1H6v2H5v-2H3v-1h2v-2H3V9z"/>
                          </svg>
                          Generar
                        </b-button>
                      </div>


                      <hr>
                      <b-form-input
                          id="filter-input"
                          v-model="filter3"
                          type="search"
                          placeholder="Buscar"
                      >
                      </b-form-input>
                    </div>


                    <b-table class="table data-table mx-1"
                             striped="striped"
                             hover="hover"
                             outlined="outlined"
                             :items="reportes.items"
                             :fields="reportes.headers"
                             :current-page="currentPage3"
                             :per-page="perPage3"
                             :filter="filter3"
                             :filter-included-fields="filterOn3"
                             stacked="md"
                             show-empty
                             small
                    >
                      <template #empty="scope">
                        <h5 class="text-center">
                          {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                        </h5>
                      </template>
                      <template #emptyfiltered="scope">
                        <h5 class="text-center">
                          {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                        </h5>
                      </template>

                    </b-table>



                    <div class="d-flex justify-content-between row p-2">
                      <div class="col-sm-12 col-md-6 d-flex align-items-center">

                        <b-button  @click="exportPDF(3)"  variant="outline-primary" class="btn-studioAPP" sm>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                          </svg>
                          Export</b-button>

                      </div>
                      <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                        <div>
                          <b-pagination
                              v-model="currentPage3"
                              :total-rows="totalRows3"
                              :per-page="perPage3"
                              align="fill"
                              size="sm"
                              class="my-0"
                          >
                          </b-pagination>
                        </div>
                      </div>
                    </div>

                  </b-row>


                </b-tab>

              </b-tabs>
            </div>


          </div>
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import jsPDF from "jspdf";
import 'jspdf-autotable';
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";
import ReportesService from '@/services/ReportesService';

export default {
  name: 'reportes',
  mixins:[toast],


  mounted () {
  this.getData();
  this.getGrupos();
  this.getCurriculas();
  this.getAlumnos();
  },
  computed: {
    updatedGruposItems() {
      const customOption = { id: 0, label: 'Todos los grupos' };
      return [customOption, ...this.grupos_items];
    },
  },
  data() {
    return {

      grupo:{ id: 0, label: 'Todos los grupos...' },
      grupos_items: [],

      advance:80,
      advance_criteria:'<=',
      advance_items2:[
        { value: '=', text: '=' },
        { value: '>=', text: '>=' },
        { value: '<=', text: '<=' },
      ],
      alumnos:[],
      alumnos_items:[],
      curriculas:[],
      curriculas_items:[],

        show : false,
        data : {
          res1:0,
          res2:0,
          res3:0,
          res4:0,
          res5:0,
          res6:0,
          res7:[],
          res8:[],
          res9:0,
        },

      reportes:{
        items:[],
        headers:[
          {   label: 'Alumno',        key: 'nombre', class: 'text-start'},
          {   label: 'Curricula',         key: 'nombre_curricula', class: 'text-start' },
          {   label: 'Avance',         key: 'advance_percentage', class: 'text-center' }
        ]
      },

      res7_headers: [
        {   label: 'Nombre curso',        key: 'curso_name', class: 'text-start'},
        {   label: 'Cantidad alumnos que lo terminaron',         key: 'cantidad', class: 'text-center' }
      ],
      res8_headers: [
        {   label: 'Nombre',        key: 'alumnos', class: 'text-start'},
        {   label: 'Cantidad de cursos terminados',         key: 'cantidad', class: 'text-center' }
      ],

      /// cursos

      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: '',


      /// alumnos
      totalRows2: 1,
      currentPage2: 1,
      perPage2: 8,
      pageOptions2: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter2: null,
      filterOn2: [],
      search2: '',


      /// avances currícula
      totalRows3: 1,
      currentPage3: 1,
      perPage3: 8,
      pageOptions3: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter3: null,
      filterOn3: [],
      search3: ''
    }
  },
  methods: {
  async getDatosGeneralesByGrupo(){

    let formData = new FormData();
        formData.append("grupo_id",               this.grupo.id);
    try {
      const response = await ReportesService.getDataByGroups(formData);
      let {   resultSet,
              success,
              message,
              color
      } = response.data;
      this.show = success;

      if(success){
        this.toast('b-toaster-bottom-center', true, message, color );
             this.data.res1 = resultSet.res1 ? resultSet.res1 : 0;
             this.data.res2 = resultSet.res2 ? resultSet.res2 : 0;
             this.data.res3 = resultSet.res3 ? resultSet.res3 : 0;
             this.data.res4 = resultSet.res4 ? resultSet.res4 : 0;
             this.data.res5 = resultSet.res5 ? resultSet.res5 : 0;
             this.data.res6 = resultSet.res6 ? resultSet.res6 : 0;
             this.data.res7 = resultSet.res7.data ? resultSet.res7.data : 0;
             this.totalRows = resultSet.res7.cantidad ? resultSet.res7.cantidad : 0;
             this.data.res8 = resultSet.res8.data ? resultSet.res8.data : 0;
             this.totalRows2 = resultSet.res8.cantidad ? resultSet.res8.cantidad : 0;
      }

    } catch (error) {
      this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
      Bugsnag.notify(
          new Error('Método: getDatosGeneralesByGrupo() ' + error.message)
      );
    }
  },

    async getGrupos(){
      let url = `/api/dominiosServ`;
      const response = await this.$api.get(url);
      let {   resultSet,
              success,
              message,
              color
      } = response.data;
      if(success){
        this.toast('b-toaster-bottom-center', true, message, color );
        this.grupos_items=resultSet;
      }
    },

    async generarReporte(){
      let formData = new FormData();
      formData.append("advance",               this.advance);
      formData.append("advance_criteria"               , this.advance_criteria);
      formData.append("grupo_id",               this.grupo.id);

      let arrCurriculas = [];
      this.curriculas.forEach(el => {
        arrCurriculas.push(el.id);
      });
      formData.append("curriculas"                 , arrCurriculas);

      let arrAlumnos = [];
      this.alumnos.forEach(el => {
        arrAlumnos.push(el.id);
      });
      formData.append("alumnos"                 , arrAlumnos);

      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }
          const response = await ReportesService.generateReportCurricula(formData);

          let { resultSet,
              success,
                message,
                cantidad,
                color
          } = response.data;

      if(success===true){
        this.reportes.items = resultSet;
        this.totalRows3=cantidad;
      }
      this.toast('b-toaster-bottom-center', true, message, color );
    },
    cleanAlumnosSelection(){
      this.alumnos = [];
    },
    cleanCurriculaSelection(){
      this.curriculas=[];
    },
    async getAlumnos(){
  let url = `/api/analitica/users`;
  const response = await this.$api.get(url);
  let {   resultSet,
          success,
          message,
          color
  } = response.data;
  if(success){
    this.toast('b-toaster-bottom-center', true, message, color );
    this.alumnos_items=resultSet;
  }
},
    async getCurriculas() {
      let url = `/api/curriculas/select`;
      const response = await this.$api.get(url);
      let {   Curriculas,
              success,
              message,
              color
      } = response.data;
      if(success){
        this.toast('b-toaster-bottom-center', true, message, color );
        this.curriculas_items=Curriculas;
      }

    },

    exportPDF(key) {
      let columns = [];
      var vm = this
      var doc = new jsPDF('p', 'pt');
      doc.text('Analitica', 40, 40);
      if(key == 2){
         columns = [
          {title: "Nombre", dataKey: "alumnos"},
          {title: "Cantidad", dataKey: "cantidad"}
        ];
        doc.autoTable(columns, vm.data.res8, {
          margin: {top: 60},
        });
      }else if (key == 1){
         columns = [
          {title: "Nombre", dataKey: "curso_name"},
          {title: "Cantidad", dataKey: "cantidad"}
        ];
        doc.autoTable(columns, vm.data.res7, {
          margin: {top: 60},
        });
      }else if (key == 3){
        columns = [
          {title: "Alumno", dataKey: "nombre"},
          {title: "Curricula", dataKey: "nombre_curricula"},
          {title: "Avance", dataKey: "advance_percentage"},
        ];
        doc.autoTable(columns, vm.reportes.items, {
          margin: {top: 60},
        });
      }



      doc.save('Reporte.pdf');
    },
    async getData(){

      try {
        const response = await ReportesService.getData();
        let {   resultSet,
                success,
                message,
                color
              } = response.data;
        this.show = success;
        if(success){
          this.toast('b-toaster-bottom-center', true, message, color );
          this.data.res1 = resultSet.res1 ? resultSet.res1 : 0;
          this.data.res2 = resultSet.res2 ? resultSet.res2 : 0;
          this.data.res3 = resultSet.res3 ? resultSet.res3 : 0;
          this.data.res4 = resultSet.res4 ? resultSet.res4 : 0;
          this.data.res5 = resultSet.res5 ? resultSet.res5 : 0;
          this.data.res6 = resultSet.res6 ? resultSet.res6 : 0;
          this.data.res7 = resultSet.res7.data ? resultSet.res7.data : 0;
          this.totalRows = resultSet.res7.cantidad ? resultSet.res7.cantidad : 0;
          this.data.res8 = resultSet.res8.data ? resultSet.res8.data : 0;
          this.totalRows2 = resultSet.res8.cantidad ? resultSet.res8.cantidad : 0;
        }


      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: update() ' + error.message)
        );
      }


    }

  },


}

</script>




<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

.result {
  text-decoration: underline;

  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: larger;
  font-style: italic;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}
</style>
