<template>
   <b-container>
 

    <!-- modal borrar -->
    <b-modal
        v-model="modal_borrarNoticia"
        persistent
        title="Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar esta noticia: <b>"{{ form_noticia.titulo }}"</b> ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="cerrarModalEliminarNoticia()"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="eliminarNoticia()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>

    <!-- modal crear noticia -->
     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="modal_noticia" 
                :title="modal_cabecera"
                v-model="modal_noticia"
                persistent>
            <b-card
                    tag="article"
                    class="mb-2">
                    <b-alert show variant="info" class="p-3 text-justify">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                           {{modal_hint}} 
                    </b-alert>
                <b-card-text>

                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                                <b-form-input
                                    class="my-2"
                                    ref="form_noticia.titulo"
                                    v-model="form_noticia.titulo"
                                    type="text"
                                    placeholder="Título de la noticia">
                                </b-form-input>
                                <b-row>
                                    <b-col col lg="6" class="my-4 text-right">
                                            <label for="">Categoría de la noticia :  </label>
          
                                    </b-col>
                                    <b-col class="my-4 text-center">
                                          <b-form-select 
                                                    size="sm"
                                                    placeholder="Tipo de noticia"
                                                    v-model="form_noticia.tipo"
                                                    :options="tipos_noticias">
                                                <template #first>
                                                    <b-form-select-option :value="0" disabled>-- Elegir una opción --</b-form-select-option>
                                                </template>
                                            </b-form-select>                                           
                                        <!--  
                                        <b-form-checkbox
                                            class="mt-3"
                                            ref="form_noticia.publicado"
                                            v-model="form_noticia.publicado"
                                            value="1">
                                            ¿Publicar?
                                        </b-form-checkbox>
                                        -->
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col col lg="6">
                                        <span>Vigencia</span>
                                    </b-col>
                                </b-row>
                                <b-row class="d-flex align-items-start justify-content-start my-3">
                                      <b-col col lg="6">
                                        <b-form-datepicker locale="es" 
                                                    placeholder="Fecha de inicio"
                                                    v-model="form_noticia.vigencia_fecha_inicio"
                                                    size="sm" 
                                                    label-help=''
                                                    label-today-button='Hoy'
                                                    today-button
                                                    label-no-date-selected='No se ha elegido fecha'>
                                                    </b-form-datepicker>
                                    </b-col>
                                    <b-col col lg="6">
                                        <b-form-datepicker locale="es" 
                                                    placeholder="Fecha fin"
                                                    v-model="form_noticia.vigencia_fecha_fin"
                                                    size="sm" 
                                                    label-help=''
                                                    label-today-button='Hoy'
                                                    today-button
                                                    label-no-date-selected='No se ha elegido fecha'>
                                                    </b-form-datepicker>
                                    </b-col>

                                </b-row>

                                <b-row class=" mb-4">
                                     <b-col col lg="12">
                                    <vue-editor     v-model         ="form_noticia.descripcion" 
                                                    :editorToolbar  ="customToolbar" 
                                                    :placeholder    ="placeholder"
                                                    >
                                        </vue-editor>
                                    </b-col>   
                                </b-row>

                                <b-row class="d-flex align-items-start justify-content-start my-1">
                                        <b-col col lg="12">
                                            <b-button
                                                variant="primary"
                                                size="sm"
                                                class="float-right"
                                                @click="cerrarModalNoticia()">
                                                Cancelar
                                            </b-button>  
                                            
                                            <b-button v-if="form_noticia.editar==true"
                                                variant="info" 
                                                size="sm" 
                                                class="float-right mx-2"
                                                @click="actualizarNoticia()">
                                                Actualizar registro
                                            </b-button>   

                                            <b-button v-if="form_noticia.editar==false"
                                                variant="success" 
                                                size="sm" 
                                                class="float-right mx-2"
                                                @click="guardarNoticia()">
                                                Guardar
                                            </b-button>                             
                                        </b-col>
                                </b-row>
                
                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

            </b-card>
     </b-modal>


    <b-row>
          <b-col cols="12">
             <div class="card card-block">
                <div class="card-body p-2">
                    <div class="d-flex justify-content-between align-items-center p-2">
                        <b-jumbotron lead="Noticias"></b-jumbotron>
                    </div>
                    <b-alert
                            class="p-3"
                            show
                            variant="primary"
                            dismissible>
                       En esta sección se pueden gestionar las noticias, crearlas como novedades, noticias refernetes a mantenimientos o mejores, así también como problemas que se hayan resuelto o presentado.
                    </b-alert>


                 <b-row class="d-flex align-items-end justify-content-end my-3">
                          <div class="col-sm-12 text-right">

                            <b-button @click="showModalCrearNoticia()"
                                        class="btn-studioAPP" 
                                        variant="outline-warning"> Crear nueva
                              </b-button>
                          </div>
                  </b-row>
                   <b-row class="d-flex align-items-end justify-content-end my-1">
                       <div class="col-sm-12 my-2">
                       <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Buscar"
                                  >
                        </b-form-input>
                        </div>
                    </b-row>


                   <b-table class="table data-table"
                        striped="striped"
                        hover="hover"
                        outlined="outlined"
                        :items="items_noticias"
                        :fields="headers_noticias"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                            stacked="md"
                            show-empty
                            small
                       >
                    <template #empty="scope">
                        <h5 class="text-center">
                            {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                        </h5>
                    </template>        
                    <template #emptyfiltered="scope">
                        <h5 class="text-center">
                            {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                        </h5>
                    </template>    

                    <template #cell(publicado)="row">  
                            <div v-if="`${row.item.publicado}`!=0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                </svg>
                            </div>       
                    </template>

                    <template #cell(fecha)="row">  
                       <div class="form-text text-muted">
          
                            <b-card-text> <span style="color:#28a745">{{row.item.fecha_inicio}}</span>  
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mx-1 bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                            </svg>
                             <span style="color:#dc3545">{{row.item.fecha_finalizacion}}</span> </b-card-text>
                        </div>
                    </template>

                    <template #cell(actions)="row">   
                        <div class="d-flex justify-content-around align-items-center">

                            <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                        @click="EditMode(row.item)" class="mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </b-button>

                            <b-button variant="danger" size="sm" @click="showModalEliminarNoticia(row.item)" class="ml-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                            </b-button>

                        </div>               
                    </template>

                    </b-table>

                </div>
             </div><!--card -->

          </b-col>

    </b-row>
  
  
  </b-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Bugsnag from '@bugsnag/js';
export default {
        components: {
                VueEditor
        },
    mounted () {
        this.getNovedades();
        this.navegacionModulo();
    },
    data() {
        return {

            modal_borrarNoticia:false,

            modal_noticia:false,
            modal_cabecera:'',
            modal_hint:'',
            tipos_noticias: [
                { text: 'Novedades',          value: 1 },
                { text: 'Mantenimiento',      value: 2 },
                { text: 'Problemas',          value: 3 }
            ],

        headers_noticias: [
                    {   label: 'Título',            key: 'titulo', class: 'text-right'},
                //    {   label: 'Descripción',       key: 'descripcion', class: 'text-left' },
                    {   label: 'Tipo',              key: 'novedadtipo.name', class: 'text-center' },
                    //{   label: 'Publicado',         key: 'publicado', class: 'text-center' },
                    {   label: 'Vigencia',          key: 'fecha', class: 'text-center' },
                    {   label: 'Acciones',          key: 'actions', class: 'text-center' }
                ],
       items_noticias:[],
       form_noticia: 
            {
                titulo: '',
                descripcion: '',
                vigencia_fecha_inicio: '',
                vigencia_fecha_fin: '',
                tipo: [],
                publicado: 0,
                id       :0,
                editar   :false
            },

           ///
            totalRows: 1,
            currentPage: 1,
            perPage: 8,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter: null,
            filterOn: [],
            search: '',  

            
        placeholder:"Escriba aquí la noticia al usuario",
        customToolbar: 
      [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" }
          ],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          ["link", "video"], // ["link", "image", "video"],
          ["clean"]
      ],
        }
    },
methods: {
            async navegacionModulo(){
                try {
                     const response = await this.$api.get(`/api/novedades/navegacionModulo/5/7`);
                     console.log(response.data);
                 } catch (error) {
                            Bugsnag.notify(
                                new Error('Método: navegacionModulo() ' + error)
                            );
                }
            },
            async eliminarNoticia(){
                try {
                    let url      = `/api/novedades/novedades/${this.form_noticia.id}` ;
                    let formData = new FormData();   
                        formData.append("_method", "DELETE");                
                    const response  = await this.$api({
                                                    url: url,
                                                    method: 'post',
                                                    headers: {
                                                        'content-type': 'multipart/form-data'
                                                    },
                                                    data: formData
                                                });
                    let {   success,
                            message,
                            color
                            } = response.data;
                        if(success==true){
                                    this.getNovedades();
                                    this.toast('b-toaster-bottom-center', true, message, color );
                                    this.cerrarModalEliminarNoticia();
                                }
                } catch (error) {
                            Bugsnag.notify(
                                new Error('Método: eliminarNoticia() ' + error)
                            );
                }

            },

            cerrarModalEliminarNoticia(){
                this.resetForm();
                this.modal_borrarNoticia=false;
            },

            showModalEliminarNoticia(item){
                this.modal_borrarNoticia    = true;
                this.form_noticia.titulo    = item.titulo;
                this.form_noticia.id        = item.id;
            },

            async actualizarNoticia(){
                try {
                 let formData = new FormData();
                    formData.append("titulo",               this.form_noticia.titulo);
                    formData.append("descripcion",          this.form_noticia.descripcion);
                    formData.append("tipo",                 this.form_noticia.tipo);
                    formData.append("fecha_inicio",         this.form_noticia.vigencia_fecha_inicio);
                    formData.append("fecha_finalizacion",   this.form_noticia.vigencia_fecha_fin);
                    formData.append("publicado",    (typeof this.form_noticia.publicado === 'undefined' ? 0 : (this.form_noticia.publicado) ? 1 : 0 )  );
                    formData.append("_method", "PUT");
                 let url                    = `/api/novedades/novedades/${this.form_noticia.id}` ;
                 const response             = await this.$api({
                                                          url: url,
                                                          method: 'post',
                                                          headers: {
                                                             'content-type': 'multipart/form-data'
                                                          },
                                                          data: formData
                                                      });
                    let {   success,
                            message,
                            color
                            } = response.data;
                        if(success==true){
                                    this.getNovedades();
                                    this.toast('b-toaster-bottom-center', true, message, color );
                                    this.cerrarModalNoticia();
                                }
                     } catch (error) {
                            Bugsnag.notify(
                                new Error('Método: actualizarNoticia() ' + error)
                            );
                }            
            },

            EditMode(item){
                this.modal_hint                          = 'La función de está sección es la de EDITAR una noticia actual.';
                this.modal_cabecera                      = 'Editando noticia';
                this.modal_noticia                       = true;
                this.form_noticia.editar                 = true;
                this.form_noticia.id                     = item.id;
                this.form_noticia.titulo                 = item.titulo;
                this.form_noticia.descripcion            = item.descripcion;
                this.form_noticia.tipo                   = item.tipo_novedad;
                this.form_noticia.publicado              = item.publicado;
                this.form_noticia.vigencia_fecha_inicio  = item.fecha_inicio;
                this.form_noticia.vigencia_fecha_fin     = item.fecha_finalizacion;

            }, 
        
            async guardarNoticia(){
                try {
                    let formData = new FormData();
                        formData.append("titulo",               this.form_noticia.titulo);
                        formData.append("descripcion",          this.form_noticia.descripcion);
                        formData.append("tipo",                 this.form_noticia.tipo);
                        formData.append("fecha_inicio",         this.form_noticia.vigencia_fecha_inicio);
                        formData.append("fecha_finalizacion",   this.form_noticia.vigencia_fecha_fin);
                        formData.append("publicado",            (typeof this.form_noticia.publicado === 'undefined' ? 0 : (this.form_noticia.publicado) ? 1 : 0 )  );
                    let url          = `/api/novedades/novedades`;

                const response            = await this.$api({
                                                            url: url,
                                                            method: 'post',
                                                            headers: {
                                                                'content-type': 'multipart/form-data'
                                                            },
                                                            data: formData
                                                        });
                    let {   success,
                                message,
                                color
                                } = response.data;
                if(success==true){
                            this.getNovedades();
                            this.toast('b-toaster-bottom-center', true, message, color );
                            this.cerrarModalNoticia();
                        }
                    } catch (error) {
                            Bugsnag.notify(
                                new Error('Método: guardarNoticia() ' + error)
                            );

                           
                }
            },

            showModalCrearNoticia(){
                this.modal_hint         ='Este apartado sirve para crear nuevas noticias, es necesario el título de cabecera de la noticia y una descripción. Para que realmente APAREZCA en los desplegables de los usuarios, es necesario deja SELECCIONADO la opción de PUBLICAR';
                this.modal_cabecera     ='Creando una nueva noticia';
                this.modal_noticia      =true;
            },
            cerrarModalNoticia(){
                this.modal_noticia=false;
                this.resetForm();
            },

           async getNovedades(){
               try {
                    const response          = await this.$api.get(`/api/novedades`);
                    let {  novedades }      = response.data;
                    this.items_noticias     = novedades;
                } catch (error) {
                    Bugsnag.notify(
                        new Error('Método: getNovedades() ' + error)
                    );
                }
            },

            resetForm(){
                this.form_noticia.titulo                            = '';
                this.form_noticia.descripcion                       = '';
                this.form_noticia.progreso_obligatorio              = 0;           
                this.form_noticia.tipo                              = [];
                this.form_noticia.vigencia_fecha_inicio             ='';
                this.form_noticia.vigencia_fecha_fin                ='';


                this.form_noticia.id                                = 0;            
                this.form_noticia.editar                            = false;
            },
        toast(toaster, append = false,content,color) {
            this.counter++
            this.$bvToast.toast(content, {
                title: 'Nivelando',
                autoHideDelay: 3500,
                toaster: toaster,
                variant: color,
                appendToast: append
            });
        },            

},
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>