<template>
    <b-container>

<!-- modal borrar objetivo de la unidad -->
    <b-modal
        v-model="ModalBorrarMaterial"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar el material: <b>"{{ materialObjetivo_form.nombre_material }}"</b> ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="cerrarModalBorrarMaterial()"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="borrarMaterial()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>

<!-- modal crear nuevo elemento -->
     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="ModalCrearObjetivo" 
                title="Materiales del objetivo"
                v-model="ModalCrearMaterial"
                persistent
        >
            <b-card
                    tag="article"
                    class="mb-2">
                <b-alert show variant="primary" class="p-3 text-justify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                       A continuación especifique los siguientes campos
                </b-alert>
                <b-card-text>
                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                         <b-form-input
                                ref="materialObjetivo_form.nombre_material"
                                class="my-2"
                                v-model="materialObjetivo_form.nombre_material"
                                type="text"
                                placeholder="Ingresa el nombre del material para este objetivo"          
                                >
                        </b-form-input>
                        <b-form-textarea
                                ref="materialObjetivo_form.instrucciones"
                                v-model="materialObjetivo_form.instrucciones"
                                placeholder="Agregar breves instrucciones"
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>
                                <!--
        <v-select
        class="my-3"
          placeholder="Elegir una o varias unidades "
           v-model="materialObjetivo_form.tipo_material"
             
            :options="TipoMateriales">
        </v-select>-->
                        <hr>
                              <b-form-select :selected="materialObjetivo_form.tipo_material"
                                    v-model="materialObjetivo_form.tipo_material"
                                    label="Tipo de material"
                                    :options="TipoMateriales"
                            ></b-form-select>
                         <b-form-input 
                                ref="materialObjetivo_form.ruta_documento"
                                class="my-2"
                                v-model="materialObjetivo_form.ruta_documento"
                                type="text"
                                placeholder="Especifique una ruta para el documento"          
                                >
                        </b-form-input>

                        
                         <b-form-input
                                ref="materialObjetivo_form.orden_visualizacion"
                                class="my-2"
                                v-model="materialObjetivo_form.orden_visualizacion"
                                type="text"
                                placeholder="Indique el orden de visualización"          
                                >
                        </b-form-input>


                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="w-100">
                            <b-button
                                    variant="primary"
                                    size="sm"
                                    class="float-right"
                                    @click="cerrarModalCrearMaterial()"
                                >
                                    Cancelar
                                </b-button>  

                                 
                                <b-button v-if="materialObjetivo_form.editar==true"
                                    variant="info" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="actualizarMaterial()"
                                >
                                Actualizar registro
                                </b-button>   
                                   <b-button v-if="materialObjetivo_form.editar==false"
                                    variant="success" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="guardarMaterial()"
                                >
                                Guardar
                                </b-button>                             
                            </div>
                        </b-row>
                

                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

         <b-col cols="12">
            <div class="card card-block">
                    <b-alert
                            class="p-3"
                            show
                            variant="info"
                            dismissible
                        >
                        <div>Aquí podrá <strong>administrar</strong> los materiales y agregarlos a el <strong> objetivo</strong> en cuestión</div>
                    </b-alert>
                  <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/dashboardObjetivos" class="">Objetivos</router-link></li>
                        <li class="breadcrumb-item"><a href="#">{{ nombre_objetivo }}</a></li>
                    </ol>
                    </nav>
                  <b-row class="d-flex align-items-end justify-content-end my-3">
                          <div class="col-sm-12 col-md-6 text-right ">
                               <b-button 
                                        @click="showModalCrearMaterial"
                                        class="btn-studioAPP" 
                                        variant="outline-warning"> Crear nuevo material para el objetivo
                              </b-button>
                          </div>
                  </b-row>
                  <b-row class="d-flex align-items-end justify-content-end my-1">
                       <div class="col-sm-12 my-2">
                       <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Buscar"
                                  >
                        </b-form-input>
                        </div>
                  </b-row>


                    <b-table    class="table data-table"
                                striped="striped"
                                hover="hover"
                                outlined="outlined"
                                :items="materialesObjetivo"
                                :fields="headersMaterialesObjetivo"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                    stacked="md"
                                    show-empty
                                    small
                                >

                                <template #cell(ruta_documento)="row">   
                                    <div class="d-flex justify-content-around align-items-center">
                                            <i> 
                                                <a target="_BLANK" :href="row.item.ruta_documento"> Acceso</a>
                                            </i>
                                    </div>               
                                </template>

                                <template #empty="scope">
                                    <h5 class="text-center">
                                        {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                                    </h5>
                                </template>        
                                <template #emptyfiltered="scope">
                                    <h5 class="text-center">
                                        {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda .' }}
                                    </h5>
                                </template> 

                                <template #cell(actions)="row">   
                                    <div class="d-flex justify-content-around align-items-center">

                                        <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                                    @click="EditMode(row.item)" class="mr-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path
                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                            <path fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                            </svg>
                                        </b-button>

                                        <b-button variant="danger" size="sm" @click="showModalBorrarMaterial(row.item)" class="ml-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                            </svg>
                                        </b-button>
                                    </div>               
                                </template>

                    </b-table>
                    <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div style="float:right">
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                    >
                                    </b-pagination>
                                </div>
                                </div>
                        </div>  
            </div><!--card card-block-->
         </b-col>                                          
    </b-container>
</template>

<script>
export default {
data() {
    return {
        ModalBorrarMaterial:false,
        ModalCrearMaterial:false,
        materialObjetivo_form: {
                nombre_material: '',
                instrucciones: '',
                tipo_material: [],
                ruta_documento: '',
                orden_visualizacion: '',
                id:0,
                editar:false,
        },
      
        TipoMateriales:[],
        materialesObjetivo:[],
        headersMaterialesObjetivo: [
                    { label: 'Id',align: 'start',             key: 'id'},
                    { label: 'Nombre',                       key: 'nombre_material'},
                    { label: 'Instrucciones',                key: 'instrucciones' },
                    { label: 'Tipo de material',             key: 'tipomaterial_concentrado.nombre_tipo_material' },
                    { label: 'Ruta documento',               key: 'ruta_documento' },
                    { label: 'Orden de visualización',       key: 'orden_visualizacion' },
                    { label: 'Acciones',                     key: 'actions', class: 'text-center'},
                ],
//      vienen de la pantalla objetivos
        idObjetivo: 0,
        nombre_objetivo: '',
 ///
            totalRows: 1,
            currentPage: 1,
            perPage: 8,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter: null,
            filterOn: [],
            search: '', 
    }

},
mounted () {
        this.idObjetivo         = this.$route.query.idObjetivo;
        this.nombre_objetivo    = this.$route.query.nombre_objetivo;
        this.getMateriales(this.idObjetivo);
        this.getTipoMaterial();
},methods: {

    async borrarMaterial(){
                const response   = await this.$api.delete(`/api/materiales/destroy/${this.materialObjetivo_form.id}`);
                let {   success,   
                        message,
                        color
                    } = response.data;
                if(success){
                            this.getMateriales(this.idObjetivo);
                            this.cerrarModalBorrarMaterial();
                }
                this.toast('b-toaster-bottom-center', true, message, color );
    },

    cerrarModalBorrarMaterial(){
        this.ModalBorrarMaterial=false;
        this.resetForm();
    },
    showModalBorrarMaterial(item){
        this.materialObjetivo_form.nombre_material = item.nombre_material;
        this.materialObjetivo_form.id = item.id;
        this.ModalBorrarMaterial=true;
    },

    async actualizarMaterial(){
          let url = `/api/materiales/update/${ this.materialObjetivo_form.id}`;
                const response   = await this.$api.put(url, 
                                                {   nombre_material:    this.materialObjetivo_form.nombre_material,
                                                    instrucciones:         this.materialObjetivo_form.instrucciones ,
                                                    tipo_material_id:      this.materialObjetivo_form.tipo_material ,
                                                    ruta_documento:       this.materialObjetivo_form.ruta_documento,
                                                    orden_visualizacion:     this.materialObjetivo_form.orden_visualizacion,
                                                });
               let {    success,   
                        message,
                        color
                    } = response.data;
                if(success){
                           this.getMateriales(this.idObjetivo);
                            this.ModalCrearMaterial = false;
                            this.resetForm();
                }
                this.toast('b-toaster-bottom-center', true, message, color );
    },
    EditMode(item){

        this.materialObjetivo_form.nombre_material      = item.nombre_material;
        this.materialObjetivo_form.instrucciones        = item.instrucciones;
        this.materialObjetivo_form.tipo_material        = item.tipo_material_id;
        this.materialObjetivo_form.ruta_documento       = item.ruta_documento;
        this.materialObjetivo_form.orden_visualizacion  = item.orden_visualizacion;
        this.materialObjetivo_form.id                   = item.id;
        this.materialObjetivo_form.editar                   = true;
        this.ModalCrearMaterial=true;
    },
    cerrarModalCrearMaterial(){
         this.ModalCrearMaterial = false;
         this.resetForm();
    },
    showModalCrearMaterial(){
        this.ModalCrearMaterial = true;
    },
   async getMateriales(item) {
               const response = await this.$api.get(`/api/materiales/show/${item}`);
                let {   materiales,
                        success,
                        message,
                        cantidad,
                        color,     
                        } = response.data;
                if(success){
                    this.materialesObjetivo   = materiales;
                    this.totalRows            = cantidad;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
    },
    async guardarMaterial(){
        
             let formData = new FormData();
                    formData.append("nombre_material"  , this.materialObjetivo_form.nombre_material);
                    formData.append("instrucciones"  , this.materialObjetivo_form.instrucciones);
                    formData.append("tipo_material_id"  , this.materialObjetivo_form.tipo_material);
                    formData.append("ruta_documento"  , this.materialObjetivo_form.ruta_documento);
                    formData.append("orden_visualizacion"  , this.materialObjetivo_form.orden_visualizacion);
                    formData.append("objetivo_id"  ,        this.idObjetivo);

                        let url          = `/api/materiales/store`;
                        const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
                        let {   success,
                                message,
                                color
                                } = response.data;
                        if(success){
                            this.getMateriales(this.idObjetivo);
                            this.ModalCrearMaterial = false;
                            this.resetForm();
                        }
                        this.toast('b-toaster-bottom-center', true, message, color ); 
    },
    async getTipoMaterial(){
            const response = await this.$api.get(`/api/tipoMaterial`);
                            let {   TipoMateriales,
                                    success,
                                    } = response.data;
                            if(success){
                                this.TipoMateriales   = TipoMateriales;
                            }

    },
    resetForm(){
        this.materialObjetivo_form.nombre_material      = '';
        this.materialObjetivo_form.instrucciones        = '';
        this.materialObjetivo_form.tipo_material        = '';
        this.materialObjetivo_form.ruta_documento       = '';
        this.materialObjetivo_form.orden_visualizacion  = '';
        this.materialObjetivo_form.id                   = '';
        this.materialObjetivo_form.editar               = false;
    },
        //método del toast
    toast(toaster, append = false,content,color) {
    this.counter++
    this.$bvToast.toast(content, {
        title: 'Nivelando',
        autoHideDelay: 3500,
        toaster: toaster,
        variant: color,
        appendToast: append
    });
    },
},
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>