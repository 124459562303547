<template>
  <b-container fluid="lg">
    <b-modal v-model="showConfirmationModal" @ok="confirmAction" @cancel="cancelAction">
      <template #modal-title>Confirmación</template>
      <template>
        <p>Estás a punto de actualizar tu información en la plataforma Aprende.
        Por favor, asegúrate de que los datos que has introducido son correctos antes de continuar.</p>
        <p style="text-align: right;">
          ¿Estás seguro de los datos que has introducido?</p>
      </template>
      <template #modal-footer>
        <b-button variant="secondary" @click="cancelAction()">Cancelar</b-button>
        <b-button variant="primary" @click="save()">Actualizar</b-button>
      </template>
    </b-modal>

    <b-row>
      <div class="card card-block">
        <div class="card-body p-2">

          <b-jumbotron class="p-5">
            <template #header> ¡Bienvenido a <i> Aprende!</i></template>

            <template #lead>
              Estás en la sección donde podrás completar tus datos para disfrutar al máximo de nuestra plataforma educativa. Es importante que verifiques cada uno de los campos y asegures que la información proporcionada sea precisa. Esto nos ayudará a personalizar tu experiencia de aprendizaje y brindarte contenido relevante.
              <br>
              Recuerda que tus datos son confidenciales y se utilizarán únicamente con fines educativos dentro de EstudioAPP. Queremos ofrecerte una experiencia de aprendizaje enriquecedora y adaptada a tus necesidades, por lo que contar con información precisa es fundamental.
              <br><br>
              Si tienes alguna pregunta o necesitas ayuda durante este proceso, no dudes en contactar a nuestro equipo de soporte. Estamos aquí para ayudarte.
              <br>
            </template>

            <hr class="my-4">
            <p>
              ¡Gracias por confiar en EstudioAPP: Aprende! Esperamos que disfrutes tu experiencia de aprendizaje y logres tus metas educativas.
            </p>

          </b-jumbotron>

          <b-row class="m-2 p-2 m-1 border">

            <b-col cols="12 ">

              <b-input-group prepend class="my-3" >
                <div class="input-group-text" style="width: 170px; text-align: right !important">
                  Nombres
                </div>
                <b-form-input v-model="user.name" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanUserNombre()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text" style="width: 170px; text-align: right !important">
                  Apellido Paterno
                </div>
                <b-form-input v-model="user.lastName" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanUserApellidoPaterno()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>

                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                  Apellido Materno
                </div>
                <b-form-input v-model="user.lastName2" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanUserApellidoMaterno()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text" style="width: 170px; text-align: right !important">
                 Correo alternativo
                </div>
                <b-form-input  @input="handleEmailInput" v-model="user.alternativeEmail" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanUserCorreoAlternativo()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-row  style="display: flex; justify-content:end" class="my-3 mx-3">

                <b-button
                    @click="openConfirmationModal()"
                    class="btn-studioAPP"
                    variant="outline-warning"> Actualizar mi información
                </b-button>
              </b-row>

            </b-col>
          </b-row>

        </div><!--card-body-->

      </div><!-- card card-block-->

    </b-row>
  </b-container>
</template>

<script>
import Service from '@/services/CentroEscolar/CentroEscolarService.js';
import toast from "@/mixins/ToastMixin.js";
export default {
  name: "AltaDocente",
  mixins:[toast],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.id = to.params.id; // Access the user ID from the route parameter
      // You can perform any necessary logic with the user ID here

      // Clean the params.id from the URL
      const cleanedTo = Object.assign({}, to);
      delete cleanedTo.params.id;
      next(cleanedTo);
    });
  },
  data() {
    return {
      showConfirmationModal: false,
      user:{
        id: this.$route.params.id,
        name:"",
        lastName:"",
        lastName2:"",
        alternativeEmail:""
      },
      isValidEmail: true
    }
  },

  methods: {

    openConfirmationModal() {
      if (!this.isValid()) {
        return;
      }
      this.showConfirmationModal = true;
    },
    confirmAction() {
      // Perform the action when the user confirms
      console.log('Action confirmed');
      this.showConfirmationModal = false;
    },
    cancelAction() {
      // Handle the cancel action
      console.log('Action canceled');
      this.showConfirmationModal = false;
    },

  async save(){
    const { id, name, lastName, lastName2, alternativeEmail } = this.user;
    let formData = new FormData();
      formData.append("id",                id);
      formData.append("name",              name);
      formData.append("apellido_p",          lastName);
      formData.append("apellido_m",         lastName2);
      formData.append("correo_alternativo",  alternativeEmail );
      formData.append("_method", "PUT");

    const response = await Service.updateUser( id, formData);
    const { success, message, color } = response.data;

    if (success)
    {
       this.showConfirmationModal = false;
      this.toast('b-toaster-bottom-center', true, message, color);
       await this.$router.push({path: `/crear-contenido-curricula`});
    }
  },
    handleEmailInput() {
      this.isValidEmail = this.validateEmail(this.user.alternativeEmail);
    },
    validateEmail(email) {
      // Regular expression for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    isValid(){
      if (!this.user.name) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el nombre', 'danger');
        return false;
      }
      if (!this.user.lastName) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el apellido paterno', 'danger');
        return false;
      }
      if (!this.user.lastName2) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el apellido materno', 'danger');
        return false;
      }
      if (!this.user.alternativeEmail) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar algún correo alternativo', 'danger');
        return false;
      }
      if (!this.isValidEmail) {
        this.toast('b-toaster-bottom-center', true, 'Usar una dirección de correo válida', 'danger');
        return false;
      }

      return true;
    },

    cleanUserNombre() {
      this.user.name="";
    },
    cleanUserApellidoPaterno() {
      this.user.lastName="";
    },
    cleanUserApellidoMaterno() {
      this.user.lastName2="";
    },
    cleanUserCorreoAlternativo() {
      this.user.alternativeEmail="";
    }
  },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP
{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active
{
  background-color: $studioAPPColor !important;
  color: white !important;
}

.criterio
{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border:2px #dedede solid;
  border-radius: 15px;
}

.custom_inputs{
  width: 170px;
  text-align: right !important
}

.input-group-text {
  display: inline-block !important;
}

</style>