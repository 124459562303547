import api from '@/services/api';

export default{



    getRoles(){
        return api({
            url: `/api/gestion-u/roles/`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getData(search){
        return api({
            url: '/api/gestion-u',
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                search: search
            }
        });
    },

    save(formData){
        return api({
            url: '/api/gestion-u/store',
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    update(id, formData){
        return api({
            url:  `/api/gestion-u/update/${id}`,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },
}