<template>
  <b-container fluid="lg">
    <b-row>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">

            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron lead="Centro escolar"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              Esta sección te permite configurar la información de tu plantel y dar de alta a los docentes que tendrán acceso a la plataforma EstudioAPP : Aprende.
            </b-alert>

            <b-row  style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">

              <b-button
                  @click="volver()"
                  class="btn-studioAPP"
                  variant="outline-warning"> Volver
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                </svg>
              </b-button>

              <b-button
                  @click="save"
                  class="btn-studioAPP"
                  variant="outline-warning"> Actualizar información
                💾
              </b-button>
            </b-row>

            <b-row class="p-2 m-1 border">
              <b-col cols="12 ">

                <b-input-group prepend class="my-3" >
                  <div class="input-group-text custom_inputs" >
                    Nombre de plantel
                  </div>
                  <b-form-input v-model="plantel.nombre" aria-label="Plantel"></b-form-input>
                  <b-input-group-append>
                    <b-button @click="cleanplantelNombre()" variant="outline-secondary btn-studioAPP">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                      </svg>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <b-input-group prepend class="my-3" >
                  <div class="input-group-text custom_inputs" >
                    Ciudad
                  </div>
                  <b-form-input v-model="plantel.ciudad" aria-label="Plantel"></b-form-input>
                  <b-input-group-append>
                    <b-button @click="cleanplantelCiudad()" variant="outline-secondary btn-studioAPP">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                      </svg>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <b-input-group prepend class="my-3" >
                  <div class="input-group-text custom_inputs" >
                    Dirección
                  </div>
                  <b-form-input v-model="plantel.direccion" aria-label="Plantel"></b-form-input>
                  <b-input-group-append>
                    <b-button @click="cleanplantelDireccion()" variant="outline-secondary btn-studioAPP">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                      </svg>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <b-input-group prepend class="my-3" >
                  <div class="input-group-text custom_inputs" >
                    # Teléfono <small style="font-size: 7px"><i>{{digitCount}}</i></small>
                  </div>
                  <b-form-input @input.native="handleTelefonoInput" placeholder="10 dígitos" v-model="plantel.telefono" aria-label="Plantel"></b-form-input>

                  <b-input-group-append>
                    <b-button @click="cleanplantelTelefono()" variant="outline-secondary btn-studioAPP">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                      </svg>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>


                <b-alert
                    class="mt-3 my-2 p-3"
                    show
                    variant="primary"
                    dismissible>
                  En la siguiente sección, podrás dar de alta hasta X cantidad de correos de los docentes que deseas invitar a la plataforma. Una vez enviado el correo, cada docente recibirá un enlace para registrarse y acceder a la plataforma EstudioAPP: Aprende. Los docentes deberán completar sus datos para completar el proceso de registro.

                  Agradecemos tu colaboración en la invitación de los docentes y estamos seguros de que juntos crearemos un entorno educativo enriquecedor.

                  ¡No dudes en contactarnos si tienes alguna pregunta o necesitas ayuda adicional!
                  <br>
                  Saludos cordiales,
                  <br>
                  <br>
                  El equipo de EstudioAPP
                </b-alert>

                <b-row  style="display: flex; justify-content: end; align-items:end;" class="my-3 mx-3">
                  <b-button
                      @click="sendInvitations()"
                      class="btn-studioAPP"
                      variant="outline-warning"> Enviar invitaciones 📩
                  </b-button>
                </b-row>

                <div v-for="(input, k) in inputs.slice().reverse()" :key="k">
                  <div class="mx-3 mt-3 p-2 criterio" >

                    <div class="d-flex justify-content-center" >

                      <b-form-input
                          ref="tituloCriterio"
                          class="my-2"
                          v-model="input.emails"
                          type="text"
                          placeholder="Correo del docente a invitar"
                      >
                      </b-form-input>
                      <div
                          @click="add(k)"
                          v-show="k === 0"
                          class=" m-1"
                      >
                        <svg style="color : #2980b9;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>

                      </div>
                      <div
                          @click="remove(k)"
                          v-show="k > 0"
                          class="m-1"
                      >
                        <svg style="color : #c0392b;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </div>

                    </div>

                  </div>

                </div> <!-- for inputs-->


              </b-col>


            </b-row>

          </div>

        </div>

      </b-col>
    </b-row>


  </b-container>
</template>

<script>

import Service from '@/services/CentroEscolar/CentroEscolarService.js';
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";

export default {
  name: "AdminCentroEscolar",
  mixins:[toast],

  mounted() {
  this.getPlantelData();
    },
  data() {
    return {

     plantel : {
       nombre : "",
       ciudad : "",
       direccion : "",
       telefono : ""
     },
      isValidPhoneNumber: true,
      digitCount:'',
      inputs : [
        { emails : '' }
      ]

    }
  },

  methods: {

  async sendInvitations(){
/*    if (!this.areEmailsValid()) {
      return;
    }*/

    console.log("entra");

    const emails = this.inputs.map(input => input.emails);
    const formData = new FormData();
    formData.append("emails", JSON.stringify(emails));

    const response = await Service.sendInvitations(formData);
    const { success, message, color } = response.data;

    if (success)
    {
      console.log("success");
      this.toast('b-toaster-bottom-center', true, message, color);

    this.inputs = [
      { emails : '' }
    ];
      //  await this.$router.push({path: `/centro-escolar`});
    }
  },

    async getPlantelData(){
      try {
        let response = await Service.get();
        let { resultSet, success } = response.data;

        if (success)
        {
          this.plantel.ciudad=resultSet.ciudad;
          this.plantel.direccion=resultSet.direccion;
          this.plantel.nombre=resultSet.nombre;
          this.plantel.telefono=resultSet.telefono;
        }

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getPlantelData() ' + error.message)
        );
      }
    },

    volver(){
      this.$router.push({
        path:`/centro-escolar`
      });
    },
    handleTelefonoInput() {
      this.plantel.telefono = this.plantel.telefono.replace(/\D/g, '').slice(0, 10);
      this.digitCount = this.plantel.telefono.length;
      if( this.digitCount == 0) {
        this.digitCount=''
      }
    },

    areEmailsValid(){
      for (let index = 0; index < this.inputs.length; index++) {
        if (!this.inputs[index].emails) {
          this.toast('b-toaster-bottom-center', true, 'El campo correo NO debe ir vacío no lo haga', 'danger');
          return false;
        }
      }
    },

    isValid(){

      if (!this.plantel.nombre) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el nombre', 'danger');
        return false;
      }

/*      const regex = /^[0-9]{10}$/; // Assumes a 10-digit phone number
      if (!regex.test(this.phoneNumber)) {
        this.toast('b-toaster-bottom-center', true, 'Número de teléfono inválido', 'danger');
        return false;
      }*/

      return true;

    },

    async save(){

     if (!this.isValid()) {
        return;
      }
      const { nombre, ciudad, direccion, telefono } = this.plantel;

      const formData = new FormData();
      formData.append("nombre", nombre);
      formData.append("ciudad", ciudad);
      formData.append("direccion", direccion);
      formData.append("telefono", telefono);

      try {
        const response = await Service.save(formData);
        const { success, message, color } = response.data;

        if (success)
        {
          this.toast('b-toaster-bottom-center', true, message, color);
        //  await this.$router.push({path: `/centro-escolar`});
        }
      } catch (error)
      {
        console.error(error);
      }

    },

    cleanplantelNombre() {
      this.plantel.nombre="";
    },
    cleanplantelCiudad() {
      this.plantel.ciudad="";
    },
    cleanplantelDireccion() {
      this.plantel.direccion="";
    },
    cleanplantelTelefono() {
      this.plantel.telefono="";
    },

    add() {
        this.inputs.push(  {    emails : '' } );
    },//add more emails

    remove(index) {
      this.inputs.splice(index, 1);
    },

  },//methods
}
</script>


<style lang="scss" scoped>

  $studioAPPColor:#952f81;

  .btn-studioAPP
  {
    color: $studioAPPColor !important;
    border:1px solid $studioAPPColor;
  }

  .btn-studioAPP:hover, .btn-studioAPP:active
  {
    background-color: $studioAPPColor !important;
    color: white !important;
  }

  .criterio
  {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border:2px #dedede solid;
    border-radius: 15px;
  }

  .custom_inputs{
    width: 170px;
    text-align: right !important
  }

  .input-group-text {
    display: inline-block !important;
  }

</style>
