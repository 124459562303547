<template>
  <b-container>
    <span class="fixed-div">
      <h1>{{ counter }}</h1>
    </span>

    <button @click="startCountdown">Iniciar</button>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      counter: 360,
      interval: null
    };
  },
  created() {
    const storedCounter = localStorage.getItem('counter');

    if (storedCounter) {
      this.counter = parseInt(storedCounter);
    } else {
      this.counter = 180;
    }

    if (this.counter > 0) {
      this.startCountdown();
    }
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        this.counter--;

        localStorage.setItem('counter', this.counter.toString());

        if (this.counter > 0) {
          console.log('Hello World');
        } else {
          clearInterval(this.interval);
          console.log('TIME OVER FRIEND');
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    localStorage.removeItem('counter');
  }
};
</script>

<style scoped>
.fixed-div {
  position: fixed;
  top: 75px;
  left: 200px;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #000000;
}
</style>
