<template>
                        <b-card :title="rubrica.nombre" sub-title="Criterios de la rúbrica">
                                <div v-for="(input,k) in inputs" :key="k">
                                    <b-card-text style="border:1px #dedede solid;  border-radius: 15px; width:100%" class="mb-2">
                                         <b-jumbotron class="ml-2" :lead="input.tituloCriterio">
                                         </b-jumbotron>
                                         <span class="ml-2">   {{input.desCriterio}} </span>
                                        <hr class="my-1">
                                        <div v-for="(answer,j) in input.answers" :key="j">
                                            <span class="ml-3"> Puntos:   {{answer.puntosNivel}} </span>
                                            <span class="ml-3">  Título:  {{answer.tituloNivel}} </span>
                                            <span class="ml-3">  Descripción: {{answer.descripcionNivel}} </span>
                                        </div>                                    
                                    </b-card-text>

                                </div>
                        </b-card>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from '@bugsnag/js';
import RubricaService from '@/services/RubricaService';
export default {
    mixins:[toast],
    name: 'Rubrica',
    data() {
        return {
            inputs: [
                { tituloCriterio: '', desCriterio:'' , answers: [] }
        ],
            rubrica:{
                id:0,
                nombre:'', 
            },
        }
    },
    props: {
            rubricaId:Number,
        },
    mounted () {
        this.getData(this.rubricaId);
    },
    methods: {
            async getData(rubricaId) {
            try {
                let response = await RubricaService.getRubricaEdit(rubricaId);
            
                let {   success,
                        rubrica,
                        message,
                        color
                        } = response.data;

                if(success){
                    this.rubrica.nombre     = rubrica.nombre;
                    this.inputs             = rubrica.criterios;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
            } catch (error) {
                 this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                        Bugsnag.notify(
                            new Error('File: Rubrica.vue, Método-> getData() ' + error)
                        );
            }

        },
        },
    }

</script>

<style>

</style>