<template>

  <b-container>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Usuarios en línea"></b-jumbotron>
            </div>

            <input v-model="searchUserId" placeholder="Enter User ID" />
            <button @click="searchUser">Search</button>
                      {{this.seaerchedUser}}
            <hr>
            <b-card class="user-card" v-for="(user, key) in users" :key="key">
              <div class="d-flex align-items-center">
                <div class="mr-3">

                  <div :class="{'user-status-indicator': true, 'user-online': user.isOnline == 1, 'user-offline': user.isOnline == 0}"></div>

                </div>
                <div>
                  <span> {{ user.isOnline }} {{ user.username }} ({{ user.userId }}) - {{ user.email }}</span>
                  <button @click="setUserOffline(user)">Set Offline</button>
                  <button @click="setUserOnline(user)">Set Online</button>
                </div>
              </div>
            </b-card>

          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import firebase from '@/firebaseConfRtdb/init';

export default {
  name: "FirebaseUseCase",
  data() {
    return {
      newUser: {
        userId: "",
        roleId: 2,
        username: "",
        email: "",
        isOnline:1

      },
      users: {},
      currentUsername: "",
      currentUserId: "",
      currentUserEmail: "",
      editingUser: false,
      editingUserKey: "",


      searchUserId: "", // Add searchUserId property
      seaerchedUser:null
    };
  },
  created() {
    this.databaseRef = firebase.database().ref('users');
    this.fetchUsers();
  },
  methods: {

    searchUser() {

      const userId = this.searchUserId.trim();
      if (userId !== "") {
        const user = Object.values(this.users).find(user => user.userId === userId);
        if (user) {
          console.log(user);
          this.seaerchedUser=user;
        } else {
          this.seaerchedUser='';
          console.log("User not found");
        }
      }
    },


    fetchUsers() {
      this.databaseRef.on('value', (snapshot) => {
        this.users = snapshot.val();
      });
    },

    setUserOffline(user) {

      this.newUser = { ...user, isOnline: '0' };
      this.editingUserKey = Object.keys(this.users).find(key => this.users[key] === user);
      const userRef = this.databaseRef.child(this.editingUserKey);
      userRef.update(this.newUser);

    },
    setUserOnline(user) {
      this.newUser = { ...user, isOnline: '1' };
      this.editingUserKey = Object.keys(this.users).find(key => this.users[key] === user);
      const userRef = this.databaseRef.child(this.editingUserKey);
      userRef.update(this.newUser);
    }
  }
};
</script>

<style scoped>
.user-card {
  max-width: 200px;
}
.user-status-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.user-online {
  background-color: green;
}

.user-offline {
  background-color: gray;
}
</style>
