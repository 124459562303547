<template>
  <b-container style="text-align: center">

    <button @click="closeAll">ocultar todos</button>
        <br>
        <br>
        <br>

          <b-button

                    class="btn-studioAPP "
                    block
                    v-b-toggle:1
          >Accordion 1
          </b-button>

        <b-collapse
                  v-model="visible[0]"
                  id="1"
                  accordion="my-accordion"
                  role="tabpanel">
        >
           I start opened because true
        </b-collapse>

      <b-button
              v-b-toggle:2
              block
              class="btn-studioAPP "
              >Accordion 2
      </b-button>

      <b-collapse
              id="2"
              v-model="visible[1]"
              accordion="my-accordion"
              role="tabpanel">
          <b-card-text>{{ text }}</b-card-text>
      </b-collapse>

      <b-button
              class="btn-studioAPP "
              block
              v-b-toggle:3
              >Accordion 3
      </b-button>

      <b-collapse
                    id="3"
                    v-model="visible[2]"
                    accordion="my-accordion"
                    role="tabpanel">
            <b-card-text>{{ text }}</b-card-text>
        </b-collapse>

      <br>
      <hr>
      {{visible }}

  </b-container>

</template>

<script>

export default {
  name: "accordion",
  data() {
    return {

      visible : [ false, false, false ],
      text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `
    }
  },
  methods: {
    closeAll() {
      this.$root.$emit('bv::toggle::collapse', '1');
          //console.log('close all');
       /*   console.log(this.visible);
            this.visible[0]=false;
            this.visible[1]=false;
            this.visible[2]=false;
          console.log(this.visible);
        */
/*        for(let i = 0; i<=2;i++){
          console.log(this.visible[i]);
          this.visible[i]=false;
          console.log(this.visible[i]);
        }*/
    },
  },




}
</script>



<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: white !important;
  border:1px solid $studioAPPColor;
  background-color: $studioAPPColor !important;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* default*/

</style>

