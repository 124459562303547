<template>
    <b-container>


<!-- modal borrar objetivo de la unidad -->
    <b-modal
        v-model="ModalBorrarCurricula"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar la currícula: <b>"{{ currentCurriculaNombre }}"</b> ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click=" ModalBorrarCurricula= false"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="borrarCurricula()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>

<!-- modal crear nuevo elemento -->
     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="ModalCrearCurricula" 
                title="Creando currícula nueva"
                v-model="ModalCrearCurricula"
                persistent
        >
            <b-card
                    tag="article"
                    class="mb-2">
                <b-alert show variant="primary" class="p-3 text-justify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                       A continuación especifique un nombre descriptivo y una descripción de su currícula
                </b-alert>
                <b-card-text>
                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                         <b-form-input
                                ref="curricula_form.nombre_curricula"
                                class="my-2"
                                v-model="curricula_form.nombre_curricula"
                                type="text"
                                placeholder="Ingresa el nombre de la Currícula"          
                                >
                        </b-form-input>

                        <b-form-select
                            class="my-2"
                            :selected="curricula_form.tipo"
                            v-model="curricula_form.tipo"
                            label="Tipo de curricula"
                            :options="tipo_curricula"
                        ></b-form-select>

                        <b-form-textarea
                                ref="curricula_form.descripcion"
                                v-model="curricula_form.descripcion"
                                placeholder="Descripción de la currícula (deje en blanco si no lo requiere)..."
                                rows="3"
                                max-rows="6"
                                >
                        </b-form-textarea>

                        <b-row class="d-flex align-items-start justify-content-start my-2">
                          <b-col>
                            <b-form-datepicker locale="es"
                                               placeholder="Fecha vigencia inicial"
                                               v-model="curricula_form.fecha_vigencia_inicio"
                                               size="sm"
                                               label-help=''
                                               label-today-button='Hoy'
                                               today-button
                                               label-no-date-selected='No se ha elegido fecha'
                            >
                            </b-form-datepicker>
                          </b-col>
                          <b-col>
                            <b-form-datepicker locale="es"
                                               placeholder="Fecha vigencia finalización"
                                               v-model="curricula_form.fecha_vigencia_finalizacion"
                                               size="sm"
                                               label-help=''
                                               label-today-button='Hoy'
                                               today-button
                                               label-no-date-selected='No se ha elegido fecha'
                            >
                            </b-form-datepicker>
                          </b-col>
                        </b-row>

                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="w-100">
                            <b-button
                                    variant="primary"
                                    size="sm"
                                    class="float-right"
                                    @click="cerrarModalCrearCurricula()"
                                >
                                    Cancelar
                                </b-button>  

                                <b-button v-if="curricula_form.editar==true"
                                    variant="info" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="actualizarCurricula()"
                                >
                                Actualizar registro
                                </b-button>   
                                   <b-button v-if="curricula_form.editar==false"
                                    variant="success" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="guardarCurricula()"
                                >
                                Guardar
                                </b-button>                             
                            </div>
                        </b-row>
                

                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>
      <b-row>
         <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
              <div class="d-flex justify-content-between align-items-start p-2">

                  <div class="my-5">
                    <span class="txt_title">Rutas activas</span>
                  </div>

              </div>

                      <b-alert  v-if="$store.getters.usuario.role_id==1"
                            class="p-3"
                            show
                            variant="info"
                            dismissible
                        >
                        <div>Puede administrar sus currículas y así administrar el contenido de cada una de ella</div>
                    </b-alert>


                      <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Currículas</a></li>
                          <li class="breadcrumb-item"><a href="#"></a></li>
                    </ol>
                    </nav>

                  <b-row 
                    v-if="$store.getters.usuario.role_id==1"
                    class="d-flex align-items-end justify-content-end my-3">
                          <div class="col-sm-12 col-md-6 text-right ">
                               <b-button 
                                        @click="showModalCrearCurricula"
                                        class="btn-studioAPP" 
                                        variant="outline-warning"> Crear nueva currícula
                              </b-button>
                          </div>
                  </b-row>

                  <b-row class="d-flex align-items-end justify-content-end my-1">
                       <div class="col-sm-12 my-2">
                       <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Buscar"
                                  >
                        </b-form-input>
                        </div>
                  </b-row>

              <div v-if="isLoading==true">
                <b-alert
                          class=" mx-4 p-4"
                          show
                          variant="primary"
                          dismissible
                >
                  <div>Unos segundos, estamos cargando la información</div>
                </b-alert>

              </div>
              <div v-if="isLoading==false">
                    <b-table class="table data-table table-borderless"
                              hover="hover"
                              :items="itemsCurriculas"
                              :fields="($store.getters.usuario.role_id==1) ? headersCurriculas : headersCurriculasAlumno"
                              :current-page="currentPage"
                              :per-page="perPage"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                                  stacked="md"
                                  show-empty
                                  small
                       >

                    <template #empty="scope">
                        <h5 class="text-center">
                            {{ scope.emptyText='Por el momento no hay información que mostrar. Espere por indicaciones de su gestor escolar.' }}
                        </h5>
                    </template>        
                    <template #emptyfiltered="scope">
                        <h5 class="text-center">
                            {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                        </h5>
                    </template>

                      <template #cell(nombre_curricula)="row">

                        <div v-if="row.item.expired==1">


                            <b class="txt_subTitle">{{ row.item.nombre_curricula }} </b>
                          <small> <i>- Expirada</i> ⚠️</small>
                            <small>
                              <p>
                                <i>
                                  {{ row.item.descripcion }}
                                </i>
                              </p>
                            </small>


                        </div>
                        <div v-else>
                          <div @click="irCursosCurricula(row.item)">
                            <b class="txt_subTitle">{{ row.item.nombre_curricula }} </b>
                            <small>
                              <p>
                                <i>
                                  {{ row.item.descripcion }}
                                </i>
                              </p>
                            </small>
                          </div>
                        </div>



                      </template>


                    <template #cell(gestionar_contenido)="row">

                        <div v-if="$store.getters.usuario.role_id==1">
                            <b-button variant="outline-primary" size="sm" @click="irCursosCurricula(row.item)" class="ml-1">
                                Gestionar
                            </b-button>
                        </div>
<!--                      <div v-if="$store.getters.usuario.role_id==2">
                        <b-button variant="outline-primary" size="sm" @click="irCursosCurricula(row.curricula_id)" class="ml-1">
                          Navegación coach {{row.item.curricula_id}}
                        </b-button>
                      </div>-->
                        <div v-else>

                          <div v-if="row.item.expired==1">
                            <b-button variant="link" size="sm" disabled class="ml-1">
                              <small>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                              </svg>
                              <i> Expirada</i></small>
                            </b-button>
                          </div>
                          <div v-else>
                            <b-button variant="link" size="sm" @click="irCursosCurricula(row.item)" class="ml-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill="gray" fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                              </svg>
                            </b-button>
                          </div>

                        </div>


                    </template>

                    <template #cell(actions)="row" v-if="$store.getters.usuario.role_id==1">   
                        <div class="d-flex justify-content-around align-items-center">

                            <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                        @click="EditMode(row.item)" class="mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </b-button>

                            <b-button variant="danger" size="sm" @click="ShowModalBorrarCurricula(row.item)" class="ml-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                            </b-button>
                        </div>               
                    </template>
                    </b-table>
                    <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div style="float:right">
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                    >
                                    </b-pagination>
                                </div>
                                </div>
                        </div>
              </div>
              </div>
            </div><!-- card block -->

         </b-col>
      </b-row>
    </b-container>
</template>

<script>
import toast from "../../mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";

export default {
  mixins:[toast],
        mounted() {
            this.getCurriculas();
        },
    data() {
        return {
          tipo_curricula: [
            { value: '0', text: 'Elegir tipo de curricula', disabled: true },
            { value: '1', text: 'Autogestivo' },
            { value: '2', text: 'En vivo' },
            { value: '3', text: 'Mixto' },

          ],
            isLoading:true,
            currentCurriculaId:0,
            currentCurriculaNombre:'',
            ModalBorrarCurricula:false,

            curricula_form: {
                nombre_curricula: '',
                descripcion: '',
                tipo:0,
                editar:false,
                fecha_vigencia_inicio : '',
                fecha_vigencia_finalizacion : ''
            },
            ModalCrearCurricula: false,
            headersCurriculas: [
              {label: '#',               key: 'id',},
                {label: 'Nombre',               key: 'nombre_curricula',},
                {label: 'Fecha de creación',    key: 'fecha',},
                {label: 'Contenido',  key: 'gestionar_contenido', class: 'text-center'},
                {label: 'Acciones',             key: 'actions', class: 'text-center'},
            ],
            headersCurriculasAlumno: [
                {label: 'Nombre',               key: 'nombre_curricula',},
                {label: '',  key: 'gestionar_contenido', class: 'text-center'},

            ],
            itemsCurriculas: [],
            
            totalRows: 1,
            currentPage: 1,
            perPage: 8,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter: null,
            filterOn: [],
            search: '', 
        }
    },
methods: {
    irCursosCurricula( curricula = [] ){
     this.$router.push({name: 'CursosCurricula',
          params:
              {
                curricula: curricula, is_dashboard: true

              }
     });
    }
    ,async actualizarCurricula(){
                let url = `/api/curriculas/update/${this.currentCurriculaId}`;
                const response   = await this.$api.put(url, {fecha_vigencia_finalizacion: this.curricula_form.fecha_vigencia_finalizacion ,fecha_vigencia_inicio: this.curricula_form.fecha_vigencia_inicio, nombre_curricula: this.curricula_form.nombre_curricula,  descripcion: this.curricula_form.descripcion, tipo: this.curricula_form.tipo } );
               
               let {   success,   
                        message,
                        color
                    } = response.data;
                if(success){
                    await this.getCurriculas();
                    this.resetForm()
                    this.curricula_form.editar = false;
                    this.ModalCrearCurricula    =false;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
    }
    ,EditMode(item){
        this.curricula_form.editar=true;
        this.curricula_form.nombre_curricula    =item.nombre_curricula;
        this.curricula_form.tipo                =item.tipo;
        this.curricula_form.descripcion         =item.descripcion;

        this.curricula_form.fecha_vigencia_inicio           = item.fecha_seleccion_inicio;
        this.curricula_form.fecha_vigencia_finalizacion     = item.fecha_seleccion_termino;

        this.currentCurriculaId                 =item.id;
        this.ModalCrearCurricula                =true;
    },
    async borrarCurricula(){
                const response   = await this.$api.delete(`/api/curriculas/destroy/${this.currentCurriculaId}`);
                let {   success,   
                        message,
                        color
                    } = response.data;
                if(success){
                    await this.getCurriculas();
                    this.ModalBorrarCurricula=false;
                    this.currentCurriculaId     = 0;
                    this.currentCurriculaNombre = '';
                }
                this.toast('b-toaster-bottom-center', true, message, color );
    }
    ,ShowModalBorrarCurricula(item){
        this.currentCurriculaId     = item.id;
        this.currentCurriculaNombre = item.nombre_curricula;
        this.ModalBorrarCurricula   =true;
    },
    resetForm(){
        this.curricula_form.nombre_curricula = '';
        this.curricula_form.tipo = 0;
        this.curricula_form.descripcion = '';
        this.curricula_form.fecha_vigencia_finalizacion ='';
        this.curricula_form.fecha_vigencia_inicio ='';
    },
    async guardarCurricula(){
      try {

        let formData = new FormData();
          formData.append("nombre_curricula"  , this.curricula_form.nombre_curricula);
          formData.append("descripcion"       , this.curricula_form.descripcion);
          formData.append("tipo"       , this.curricula_form.tipo);

          formData.append("fecha_vigencia_inicio",                 this.curricula_form.fecha_vigencia_inicio);
          formData.append("fecha_vigencia_finalizacion",           this.curricula_form.fecha_vigencia_finalizacion);

        let url          = `/api/curriculas/store`;
        const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
        let {   success,
                message,
                color
        } = response.data;

        if(success){
            await this.getCurriculas();
            this.ModalCrearCurricula = false;
            this.resetForm();
        }
        this.toast('b-toaster-bottom-center', true, message, color );

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: guardarCurricula() ' + error.message)
        );
      }
    },
    cerrarModalCrearCurricula(){
        this.ModalCrearCurricula = false;
        this.curricula_form.editar=false;
    },
    showModalCrearCurricula() {
        this.ModalCrearCurricula = true;
    },
    async getCurriculas() {
            this.isLoading = true;
            let url = `/api/curriculas/index`;
            const response = await this.$api.get(url);
            let {   curricula,
                    success,
                    message,
                    color
                    } = response.data;

              console.log('response.data.curricula');
              console.log(response.data.curricula);

            if(success){
                this.itemsCurriculas   = curricula;
                this.totalRows         = curricula.length;
            }
            this.toast('b-toaster-bottom-center', true, message, color );
            this.isLoading = false;
     },
    //método del toast
/*    toast(toaster, append = false,content,color) {
    this.counter++
    this.$bvToast.toast(content, {
        title: 'Nivelando',
        autoHideDelay: 3500,
        toaster: toaster,
        variant: color,
        appendToast: append
    });
    },*/

},
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.table-borderless table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

/* To remove borders on the first and last columns (lateral borders) */
.table-borderless th:first-child,
.table-borderless td:first-child,
.table-borderless th:last-child,
.table-borderless td:last-child {
  border-right: none !important;
  border-left: none !important;
}

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}
.txt_title{
  font-size: 44px;
  font-weight: bold;
  font-family: "Roboto Light";
}

.txt_subTitle{
  font-size: 24px;
  font-weight: bold;
  font-family: "Roboto Light";
}
</style>