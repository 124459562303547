<template>
  <b-container>

 <b-row>
        <b-col cols="12">
            <div class="card card-block">
                <div class="card-body p-2">
                    <div class="d-flex justify-content-between align-items-center p-2">
                        <b-jumbotron lead="Mi Salón"></b-jumbotron>
                    </div>
                    <b-alert
                            class="p-3"
                            show
                            variant="primary"
                            dismissible>
                       En esta sección se muestran todos los distintos grupos que están en el curso.
                    </b-alert>
                        <b-row style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">
                          <b-button @click="general()"
                                    class="btn-studioAPP"
                                    variant="outline-warning"> Entregas Generales
                          </b-button>
                        </b-row>
                            <div class="col-lg-12 my-2">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Buscar"
                                    >
                                </b-form-input>
                            </div>
                            <b-table class="table data-table"
                                :items="usuario_items"
                                :fields="usuario_fields"                            
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                    stacked="md"
                                    show-empty
                                    small
                                >
                                <template #empty="scope">
                                    <b-alert show variant="info">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</b-alert>
                                </template>
                                <template #emptyfiltered="scope">
                                    <b-alert show variant="warning">
                                        {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                                    </b-alert>
                                </template>

                                <template #cell(actions)="row">   
                                    <div class="d-flex justify-content-around align-items-center">
                                        <b-button variant="info" size="sm" @click="irCursosGrupo(row.item.id)" class="ml-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                            </svg>
                                        </b-button>  
                                    </div>               
                                </template> 
                            </b-table>
                            <div class="d-flex justify-content-between row p-2">
                                    <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                    <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                    <div>
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="fill"
                                            size="sm"
                                            class="my-0"
                                        >
                                        </b-pagination>
                                    </div>
                                    </div>
                            </div>
      
                </div><!--card-body p-2-->
            
            </div><!--card--->   
          
        </b-col>
  </b-row>

  </b-container>
</template>

<script>
import toast from "../../../mixins/ToastMixin.js";
export default {
 mixins:[toast],
 name: "Grupos",
        data() {
            return {
                usuario_fields: [      
                        {   label: 'Grupo',         key: 'nombre_grupo',        sortable: true,    class: 'text-start' },
                        {   label: 'Descripción',   key: 'descripcion_grupo',   sortable: true,    class: 'text-start' },
                        {   label: 'Acciones',      key: 'actions',             sortable: true,    class: 'text-center'}
                    ],
                usuario_items: [
                        { id:'1', nombre_grupo: 'Bienvenido Nivelando', descripcion_grupo: 'Para manual' },
                        { id:'2', nombre_grupo: 'Testing Lab', descripcion_grupo: 'Testing lab' },
                        { id:'3', nombre_grupo: 'Sab Tes', descripcion_grupo: 'Sabadito testing' },
                        { id:'4', nombre_grupo: 'nivelAndo - en vivo', descripcion_grupo: 'Grupo de Piloto nivelAndo - en vivo 2022' },  
                        { id:'5', nombre_grupo: 'Grupo Gmail 2', descripcion_grupo: 'Prueba' },
                        { id:'6', nombre_grupo: 'Grupo Demo', descripcion_grupo: 'Grupo para demostraciones' },
                        { id:'7', nombre_grupo: 'Bienvenido Nivelando', descripcion_grupo: 'Grupo de prueba piloto en nivelAndo para Coaches' },
                        { id:'8', nombre_grupo: 'Cuentas Desarrollo', descripcion_grupo: 'Demo' },
                    ],

                    /////////////
                    totalRows: 1,
                    currentPage: 1,
                    perPage: 8,
                    pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
                    filter: null,
                    filterOn: [],
                    search: '', 
            }
    },
mounted () {
    this.getGruposUsuarios();
},
    methods: {
      general(){
        this.$router.push({
         path:'/salon-cursos'
          // path:`/entregas-general`
        });
      },
      irCursosGrupo(id){
                  this.$router.push({
                    //  path:`/entregables/${id}`
                    path:`/salon-cursos-grupo/${id}`
                  });
        },
    async getGruposUsuarios(){
            const response   = await this.$api.get(`/api/users/get-grupos-usuarios-aprende`);
            let {   success,
                    message,
                    color,
                    grupos_usuarios,
                } = response.data;
                    console.log(grupos_usuarios);
                    this.totalRows      = await grupos_usuarios.length;
                    this.usuario_items = await grupos_usuarios;
                    
            if(success){
                this.toast('b-toaster-bottom-center', true, message, color );   
            }
    },
    },


}//export default
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>