export default [
    {
        "idUser":45,
        "nombre": "Elisa Lopez",
        "idTarea1":1,
        "tarea": "Ensayo Ecosistema",
        "tareaURL": "https://docs.google.com/spreadsheets/d/1Nm9pomyJBV3pBdvjZi5qkyZD1CzPBIknQJW5hmkceVo/edit#gid=0",

    },
    {
        "idUser":91,
        "nombre": "Dulce Maribel",
        "idTarea1":1,
        "tarea": "Ensayo Ecosistema",
        "tareaURL": "https://docs.google.com/spreadsheets/d/1Nm9pomyJBV3pBdvjZi5qkyZD1CzPBIknQJW5hmkceVo/edit#gid=0",

    },
    {
        "idUser":91,
        "nombre": "Dulce Maribel",
        "idTarea1":3,
        "tarea": "Resumen Entropía",
        "tareaURL": "https://docs.google.com/spreadsheets/d/1Nm9pomyJBV3pBdvjZi5qkyZD1CzPBIknQJW5hmkceVo/edit#gid=0",

    },
    {
        "idUser":45,
        "nombre": "Melissa perez",
        "idTarea1":1,
        "tarea": "Ensayo Ecosistema",
        "tareaURL": "https://docs.google.com/spreadsheets/d/1Nm9pomyJBV3pBdvjZi5qkyZD1CzPBIknQJW5hmkceVo/edit#gid=0",
    },
    {
        "idUser":45,
        "nombre": "Melissa perez",
        "idTarea1":3,
        "tarea": "Resumen Entropía",
        "tareaURL": "https://docs.google.com/spreadsheets/d/1Nm9pomyJBV3pBdvjZi5qkyZD1CzPBIknQJW5hmkceVo/edit#gid=0",

    },

    {
        "idUser":72,
        "nombre": "Isabel Villa",
        "idTarea1":1,
        "tarea": "Ensayo Ecosistema",
        "tareaURL": "https://docs.google.com/spreadsheets/d/1Nm9pomyJBV3pBdvjZi5qkyZD1CzPBIknQJW5hmkceVo/edit#gid=0",
    },
    {
        "idUser":72,
        "nombre": "Isabel Villa",
        "idTarea1":2,
        "tarea": "Ensayo Tipos de Redes",
        "tareaURL": "https://docs.google.com/spreadsheets/d/1Nm9pomyJBV3pBdvjZi5qkyZD1CzPBIknQJW5hmkceVo/edit#gid=0",

    },
    {
        "idUser":72,
        "nombre": "Isabel Villa",
        "idTarea1":3,
        "tarea": "Resumen Entropía",
        "tareaURL": "https://docs.google.com/spreadsheets/d/1Nm9pomyJBV3pBdvjZi5qkyZD1CzPBIknQJW5hmkceVo/edit#gid=0",

    }

]
