<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              En esta sección se enlistan las preguntas creadas.
            </b-alert>
            <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
            </b-row>

          </div>
          <b-form>
            <b-form-textarea
                ref="reactivo"
                class="my-2"
                v-model="reactivo"
                type="text"
                rows="2"
                max-rows="3"
                placeholder="Ingresa la pregunta"
            >
            </b-form-textarea>
            <div class="col-sm-12 my-2">

              <b-button
                  v-if="!editarActividadUnidad"
                  variant="success"
                  size="sm"
                  class="float-right mx-2 my-1"
                  @click="guardarPregunta()"
              >
                Guardar Pregunta
              </b-button>
            </div>

            <b-table class="table data-table"
                     :items="preguntas_de_la_unidad"
                     :fields="reactivos_fields"
                     :current-page="currentPage_reactivos"
                     :per-page="perPage_reactivos"
                     :filter="filter_reactivos"
                     :filter-included-fields="filterOn_reactivos"
                     stacked="md"
                     :striped="true"
                     hover
                     show-empty
                     outlined
                     :small="true"
            >
              <template #cell(tiporeactivo_id)="data">
                <div v-if="data.item.tiporeactivo_id==1">Opción múltiple (única respuesta)</div>
                <div v-else-if="data.item.tiporeactivo_id==2">Opción múltiple (varias respuesta)</div>
              </template>
              <template #cell(pregunta)="data">
                <span v-html="data.item.descripcion"></span>
              </template>
              <template #empty="scope">
                <b-alert show variant="info">{{
                    scope.emptyText = 'Por el momento no hay registros que mostrar'
                  }}
                </b-alert>
              </template>
              <template #emptyfiltered="scope">
                <b-alert show variant="warning">
                  {{ scope.emptyFilteredText = 'No hay registros que concuerden con los parámetros de búsqueda.' }}
                </b-alert>
              </template>
              <template #cell(actions)="row">
                <div class="text-center">
                  <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                            @click="showModalRespuestas(row.item.id)" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-list" viewBox="0 0 16 16">
                      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                      <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                    </svg>
                  </b-button>
                  <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm" @click="ShowModalborrarPregunta(row.item.id)" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
                  </b-button>
                </div>
              </template>
            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage_reactivos"
                      :total-rows="totalRows_reactivos"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Bugsnag from '@bugsnag/js';
import UnidadServicio from '@/services/UnidadServicio';
import ActividadService from '@/services/ActividadService';
import PreguntaService from '@/services/PreguntaService';
import RespuestaService from '@/services/RespuestaService';
import RubricaService from '@/services/RubricaService';
import toast from "@/mixins/ToastMixin.js";

export default {
  mixins:[toast],
  components: {

  },
  data() {
    return {
      /* var respuestas*/
      checkSelected:false,
      idPregunta:null,
      idRespuesta:null,
      dataRespuesta:{
        id:0,
        respuesta:'',
        reactivo_id:null,
        correcta:false
      },
      modalRespuestas:false,
      /* fin var respuestas */

      modalBorrarReactivoConfirmacion:false,
      modalBorrarRespuestaConfirmacion:false,
      idActividadReactivos:null,
      reactivo:null,
      tieneRubrica:false,
      entregableActividad:0,
      modalReactivos:false,
      modalEntregableEleccionTipo:false,
      modalRubricaEleccion:false,



      rubrica:{
        id:0,
        nombre:'',
      },

      modalBorrarItemConfirmacion:false,
      item_descripcion_actividad:'',
      idItem:0,
      editarItem:false,
      idActividad:0,
      nombre_item_a_actividad:'',
      items_de_la_actividad:[],
      headersItems: [
        {label: 'Nombre item', key: 'descripcion_actividad'},
        {label: 'Acciones', key: 'actions'}
      ],
      modalAgregarItemsActividad:false,
      ModalAsignarActividades:false,
      editarActividadUnidad: false,
      idActividadUnidad:0,

      modalBorrarActividadUnidadConfirmacion: false,
      nombre_actividad_unidad:'',
      actividades_de_la_unidad:[],
      preguntas_de_la_unidad:[],
      respuestas_de_la_unidad:[],

      headersActividades: [
        {label: 'id',      key: 'id'    },
        {label: 'Instrucción',      key: 'detalles_actividad'    },
        {label: 'Cantidad de instrucciones',  key: 'numero_items'      ,class: 'text-left'     },
        {label: 'Editar',                     key: 'editar_actividad'  ,class: 'text-left'   },
        {label: 'Agregar items',              key: 'items_actividad'   , class: 'text-center'   },
        {label: 'Rúbrica',                    key: 'items_rubrica'     ,class: 'text-center'     },
        {label: 'Entregables',                key: 'entregable'        ,class: 'text-center' }
        ,{  label: 'Quiz',                    key: 'reactivos'         ,class: 'text-center' }
        ,{  label: 'Resultados',              key: 'resultados'        ,class: 'text-center' }
      ],
      nombre_actividad_objetivo:'',   //nombre de la actividad a asignarse a la unidad
      modalActualizarConfirmacion:false,
      modalBorrarConfirmacion:false,
      selectedUnidadId:0,
      nombreBorrar:'',
      form_unidad: {
        nombre_unidad: '',
        clave_interna: '',
        descripcion: '',
      },
      Unidadesfields: [
        {label: 'id',      key: 'id'    },
        {label: 'Nombre',        key: 'nombre_unidad', class: 'text-start'},
        {label: 'Clave',         key: 'clave_interna', class: 'text-center' },
        {label: 'Actividades',    key: 'actividades', class: 'text-center' },
        {label: 'Objetivos',      key: 'objetivos', class: 'text-center' },
        {label: 'Acciones',      key: 'actions', class: 'text-center' }
      ],
      itemsUnidades: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: '',
      //VARS REACTIVOS
      reactivos_fields: [
        {label: 'Pregunta', key: 'pregunta', class: 'text-center'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      respuestas_fields: [
        {label: 'Respuesta', key: 'descripcion', class: 'text-center'},
        {label: 'Correcta', key: 'correcta', class: 'text-center'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      reactivos: [],
      currentPage_reactivos: 1
      ,currentPage_respuestas: 1
      ,perPage_reactivos:8
      ,perPage_respuestas:8
      ,filter_reactivos: null
      ,filter_respuestas: null
      ,filterOn_reactivos: []
      ,filterOn_respuestas: []
      ,totalRows_reactivos: 1
      ,totalRows_respuestas: 1
    }
  },
  mounted () {
    this.getUnidades();
  },
  methods: {
    async checkRespuestaCorrecta(idRespuesta){
      this.wait()
      const response =  await RespuestaService.updateCheckRespuestaCorrecta(idRespuesta,this.idPregunta);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getRespuestas(this.idPregunta);
      }

      this.toast('b-toaster-bottom-center', true, message, color );
    },
    pressBtnCorrect(value){
      console.log("valor del check seleccionado:" + value)

      this.dataRespuesta.correcta = value
    },

    redirectPreguntaMutipleUnicaRespuesta(){

    },

    async showModalEntregableEleccionTipo(activityId, entregableActividad){
      this.activityId                     = activityId;
      this.entregableActividad            = entregableActividad;
      try {
        let response = await RubricaService.checkRubricaExistsByAct(activityId);
        let { data } = response.data;
        this.tieneRubrica = (data == true) ? 1 : 0;
        //console.log(this.tieneRubrica);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
        Bugsnag.notify(
            new Error('Método: navegacionModulo() ' + error)
        );
      }
      this.modalEntregableEleccionTipo    = true;
    },
    hideModalEntregableEleccionTipo(){
      this.modalEntregableEleccionTipo=false;
      this.tieneRubrica=0;


    },
    async updateEntregable(tipoEntregable){
      const response = await ActividadService.updateEntregable( this.activityId, tipoEntregable);
      this.toast('b-toaster-bottom-center', true, response.data.message, response.data.color );
      this.hideModalEntregableEleccionTipo();
      this.CerrarModalActividades();
    },

    async getData(rubrica_id) {
      try {
        let response = await RubricaService.getRubricaEdit(rubrica_id);

        let {   success,
          message,
          color,
          rubrica
        } = response.data;

        if(success){
          this.rubrica.nombre     = rubrica.nombre;
          this.inputs             = rubrica.criterios;
        }
        this.toast('b-toaster-bottom-center', true, message, color );
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
        Bugsnag.notify(
            new Error('Método: navegacionModulo() ' + error)
        );
      }

    },

    async getRubricaId(){
      //   console.log(this.idActividad);
      //    console.log(this.selectedUnidadId);
      let url                   = `/api/rubricas/show/${this.selectedUnidadId}/act/${this.idActividad}`;
      const response            = await this.$api.get(url);

      let {   success,
        rubrica
      } = response.data;

      if(success){
        this.rubrica.id=rubrica;
        // this.getData(this.rubrica.id);
      }

    },

    irNuevaRubrica(){

      this.$router.push(`/rubricas/nueva/${this.selectedUnidadId}/act/${this.idActividad}`);
    },
    irEditRubrica(){
      this.$router.push(`/rubricas/edit/${this.rubrica.id}`);
    },

    hideModalRubricaEleccion(){
      this.modalRubricaEleccion=false;
      this.rubrica.id=0;
      this.rubrica.nombre='';
      this.inputs =   [
        { tituloCriterio: '', desCriterio:'' , answers: [] }
      ];
    },
    hideModalReactivos(){
      this.modalReactivos=false;
    },
    hideModalRespuestas(){
      this.modalRespuestas=false;
    },

    showModalRubricaEleccion(activityId){

      this.idActividad = activityId;
      this.getRubricaId();
      this.modalRubricaEleccion=true;
    },
    showModalReactivos(activityId){
      this.idActividad = activityId;
      this.getPreguntas(this.idActividad);
      this.modalReactivos=true;
      //reset vars dialog
      this.preguntas_de_la_unidad=[]
      this.currentPage_reactivos=1
      this.perPage_reactivos=8
      this.filter_reactivos=null
      this.filterOn_reactivos=[]
      this.totalRows_reactivos=1
    },
    showModalRespuestas(idPregunta){
      console.log("idPregunta:" + idPregunta)
      this.idPregunta = idPregunta
      this.dataRespuesta.reactivo_id = idPregunta;
      this.getRespuestas(idPregunta);
      this.modalRespuestas=true;
      //reset vars dialog
      this.respuestas_de_la_unidad=[]
      this.currentPage_respuestas=1
      this.perPage_respuestas=8
      this.filter_respuestas=null
      this.filterOn_respuestas=[]
      this.totalRows_respuestas=1
    },

    irUnidadesObjetivos(id){
      this.$router.push(`/dashboardUnidadesObjetivos/${id}`);
    },
    async borrarItemActividad(){
      const response = await ActividadService.deleteItemActivity(this.idItem);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getItemsActividad( this.idActividad );
        this.modalBorrarItemConfirmacion=false;
      }
      this.toast('b-toaster-bottom-center', true, message, color );
    },
    ShowModalborrarItemDeActividad(idItem){
      this.idItem                     = idItem;
      this.modalBorrarItemConfirmacion = true;
    },
    editInputItemDeActividad(idItem,item_descripcion_actividad){
      this.idItem                     = idItem;
      this.nombre_item_a_actividad    =item_descripcion_actividad;
      this.editarItem                 = true;
    },
    async ActualizarItemDeActividad(){

      const response = await ActividadService.updateItemActivity(this.idItem, this.nombre_item_a_actividad);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getItemsActividad( this.idActividad );
        this.nombre_item_a_actividad='';
        this.editarItem                 = false;
      }
      this.toast('b-toaster-bottom-center', true,  message, color );

    },
    async guardaItemEnActividad() {

      let formData = new FormData();
      formData.append("unidad_actividad_id",       this.idActividad);
      formData.append("descripcion_actividad",     this.nombre_item_a_actividad);

      const response = await ActividadService.saveItemActividad(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getItemsActividad( this.idActividad );
        this.nombre_item_a_actividad='';
      }
      this.toast('b-toaster-bottom-center', true, message, color );
    },
    async getItemsActividad(idActividad) {
      this.idActividad = idActividad;
      const response = await ActividadService.getItemsEnActividad(idActividad);
      this.items_de_la_actividad  = response.data.items;
    },
    ShowModalAgregarItemsActividad(idActividad){
      this.getItemsActividad(idActividad);
      this.modalAgregarItemsActividad=true;
    },
    EditInputActividadUnidad(idActividadUnidad, detalles_actividad){
      this.editarActividadUnidad      =true;
      this.idActividadUnidad          = idActividadUnidad;
      this.nombre_actividad_unidad    = detalles_actividad;
    },
    async ActualizarUnidadActividad(){
      const response = await ActividadService.updateUnidadActividad(this.idActividadUnidad,this.nombre_actividad_unidad);
      let {   message,
        color
      } = response.data;
      this.toast('b-toaster-bottom-center', true, message, color );
      this.getUnidadActivities(this.selectedUnidadId);
      this.nombre_actividad_unidad   ='';
      this.idActividadUnidad         = 0;
      this.editarActividadUnidad     =false;

    },
    ShowModalborrarActividadUnidad(idActividadUnidad){
      this.idActividadUnidad = idActividadUnidad;
      this.modalBorrarActividadUnidadConfirmacion = true;
    },
    ShowModalborrarPregunta(idActividadUnidad){
      this.idActividadReactivos = idActividadUnidad;
      this.modalBorrarReactivoConfirmacion = true;
    },
    ShowModalborrarRespuesta(idRespuesta){
      this.idRespuesta = idRespuesta;
      this.modalBorrarRespuestaConfirmacion = true;
    },
    async borrarActividadUnidad(){
      const response = await ActividadService.deleteActividadUnidad(this.idActividadUnidad);
      let {   message,
        color
      } = response.data;
      this.toast('b-toaster-bottom-center', true, message, color );
      this.idActividadUnidad = 0;
      this.modalBorrarActividadUnidadConfirmacion = false;
      this.getUnidadActivities(this.selectedUnidadId);
    },

    async borrarReactivo(){
      const response = await PreguntaService.deletePregunta(this.idActividadReactivos);
      //this.wait()
      let {   message,
        color
      } = response.data;
      this.toast('b-toaster-bottom-center', true, message, color );
      this.modalBorrarReactivoConfirmacion = false;
      await this.getPreguntas(this.idActividad);
    },

    async borrarRespuesta(){
      this.wait()
      const response = await RespuestaService.deleteRespuesta(this.idRespuesta);
      let {   message,
        color
      } = response.data;
      this.toast('b-toaster-bottom-center', true, message, color );
      this.modalBorrarRespuestaConfirmacion = false;
      await this.getRespuestas(this.idPregunta);
    },



    async guardarRespuesta()
    {
      if( this.dataRespuesta.respuesta === '' || this.dataRespuesta.respuesta === null  || this.dataRespuesta.respuesta.length === 0 ){
        this.$refs.respuesta.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la respuesta!', 'danger' );
        return false;
      }
      let formData = new FormData();
      let escorrecta = 0
      if(this.dataRespuesta.correcta===true){
        escorrecta = 1
      }
      formData.append("respuesta", this.dataRespuesta.respuesta);
      formData.append("reactivo_id",  this.idPregunta);
      formData.append("correcta",  escorrecta);
      this.wait()
      const response = await RespuestaService.saveRespuesta(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getRespuestas(this.idPregunta);
      }
      this.toast('b-toaster-bottom-center', true, message, color );
      this.dataRespuesta.respuesta = '';
      this.dataRespuesta.id=0;
      this.dataRespuesta.reactivo_id=null
      //this.dataRespuesta.correcta=[]
      this.checkSelected=false

    },




    async guardarPregunta()
    {
      if( this.reactivo === '' || this.reactivo === null  || this.reactivo.length === 0 ){
        this.$refs.reactivo.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta!', 'danger' );
        return false;
      }
      let formData = new FormData();
      formData.append("reactivo", this.reactivo);
      formData.append("actividad_id",  this.idActividad);
      const response = await PreguntaService.savePregunta(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        //console.log("guardado")
        this.getPreguntas(this.idActividad);
      }
      this.toast('b-toaster-bottom-center', true, message, color );
      this.reactivo = ' ';
    },
    async guardarUnidadActividad(){
      if( this.nombre_actividad_unidad === '' || this.nombre_actividad_unidad === null  || this.nombre_actividad_unidad === 0 || !this.nombre_actividad_unidad){
        this.$refs.nombre_actividad_unidad.$el.focus();
        this.toast('b-toaster-bottom-center', true, 'Campo nombre es requerido!', 'danger' );
        return false;
      }
      let formData = new FormData();
      formData.append("nombre_actividad_unidad", this.nombre_actividad_unidad);
      formData.append("unidad_id",  this.selectedUnidadId);
      const response = await ActividadService.saveUnidadActividad(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getUnidadActivities(this.selectedUnidadId);
      }
      this.toast('b-toaster-bottom-center', true, message, color );
      this.nombre_actividad_unidad = ' ';
    },
    async getUnidadActivities( itemId ){
      const response = await ActividadService.getUnidadActivitidades(itemId);
      this.actividades_de_la_unidad = response.data;
      //console.log(this.actividades_de_la_unidad);
    },
    async getPreguntas( itemId ){
      this.wait()
      this.preguntas_de_la_unidad=[]
      const response = await PreguntaService.getPreguntasActivities(itemId);
      this.preguntas_de_la_unidad = response.data;
    },

    async getRespuestas(itemId){
      const response               = await RespuestaService.getRespuestas(itemId);
      this.respuestas_de_la_unidad = response.data;
    },

    ShowModalActividades(itemId){

      this.selectedUnidadId = itemId;
      //this.idActividadReactivos = itemId
      this.getUnidadActivities(itemId);
      //console.log("unidad"+itemId)
      //this.$refs['unidad_actividades'].show();
      this.ModalAsignarActividades=true;
    },
    CerrarModalActividades(){
      //console.log('cerrar modal actividades');
      this.actividades_de_la_unidad=[];
      this.selectedUnidadId = 0;
      this.ModalAsignarActividades=false;
      //this.$refs['unidad_actividades'].hide();
    },
    validateForm(){
      let valid = true;
      if( this.form_unidad.nombre_unidad == '' || this.form_unidad.nombre_unidad == null  || this.form_unidad.nombre_unidad == 0 || !this.form_unidad.nombre_unidad){
        this.toast('b-toaster-bottom-center', true, 'Campo nombre de la unidad es requerido', 'danger' );
        this.$refs.nombre_unidad.$el.focus();
        valid = false;
      }

      if( this.form_unidad.clave_interna == '' || this.form_unidad.clave_interna == null  || this.form_unidad.clave_interna == 0 || !this.form_unidad.clave_interna){
        this.toast('b-toaster-bottom-center', true, 'Campo clave de la unidad es requerido', 'danger' );
        this.$refs.clave_interna.$el.focus()
        valid = false;
      }

      if( this.form_unidad.descripcion == '' || this.form_unidad.descripcion == null  || this.form_unidad.descripcion == 0 || !this.form_unidad.descripcion){
        this.toast('b-toaster-bottom-center', true, 'Agregar una breve descripción de favor', 'danger' );
        this.$refs.descripcion.$el.focus();
        valid = false;
      }
      return valid;
    },
    cerrarActualizarModal(){
      this.resetFormCurso();
      this.modalActualizarConfirmacion = false;
      this.selectedUnidadId = 0;
    },
    showEditModal(item) {
      this.selectedUnidadId   = item.id;
      this.modalActualizarConfirmacion = true;
      this.form_unidad.nombre_unidad = item.nombre_unidad;
      this.form_unidad.clave_interna = item.clave_interna;
      this.form_unidad.descripcion = item.descripcion;
    },
    async actualizarUnidad(){
      if( !this.validateForm() ){return false;}
      const response = await UnidadServicio.updateUnidad(this.selectedUnidadId,this.form_unidad.nombre_unidad, this.form_unidad.descripcion );
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getUnidades();
      }

      this.toast('b-toaster-bottom-center', true, message, color );
      this.cerrarActualizarModal();
    },
    ShowmodalBorrarConfirmacion(item) {
      this.selectedUnidadId   = item.id;
      this.nombreBorrar       = item.nombre_unidad;
      this.modalBorrarConfirmacion=true;
    },
    async deleteUnidad(item) {
      let index       = this.itemsUnidades.indexOf(item);
      const response = await UnidadServicio.deleteUnidad(item);

      let {   success,
        message,
        color
      }   = response.data;
      if(success){
        this.itemsUnidades.splice(index, 1);
        this.getUnidades();
      }
      this.modalBorrarConfirmacion = false;
      this.toast('b-toaster-bottom-center', true, message, color );

    },
    resetFormCurso(){

      this.form_unidad = {
        nombre_unidad: '',
        clave_interna: '',
        descripcion: '',
      }
      this.nombre_actividad_objetivo == ''
      this.selectedUnidadId=0;
    },
    async guardarUnidad(){

      if( !this.validateForm() ){return false;}

      let formData = new FormData();
      formData.append("nombre_unidad",         this.form_unidad.nombre_unidad);
      formData.append("clave_interna",        this.form_unidad.clave_interna);
      formData.append("descripcion",          this.form_unidad.descripcion);
      const response = await UnidadServicio.saveUnidad(formData );

      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getUnidades();
      }
      this.toast('b-toaster-bottom-center', true, message, color );

      this.cerrarModal();
    },
    async getUnidades() {
      const response = await UnidadServicio.getUnidades( );
      let {   success,
        message,
        color,
        unidades
      }   = response.data;
      if(success){
        this.itemsUnidades        = unidades;
        this.totalRows            = unidades.length;
      }
      this.toast('b-toaster-bottom-center', true, message, color );
    },
    abrirModaNuevaUnidad(){
      this.$refs['nuevaUnidad'].show();
    },
    cerrarModal(){
      this.resetFormCurso();
      this.$refs['nuevaUnidad'].hide()
    },
  },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>