<template>
  <div>
   
      <b-button variant="link" size="sm" class="my-1" type="button" @click="driveIconClicked();">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 1443.061 1249.993"><path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/><path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/><path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/></svg>
      </b-button>
   
   <!-- <AttachmentList :tempAttachments="getTempAttachments"/> -->
    <small class="txt_attachmnts"> {{this.fileName}} </small>
  
  </div>
</template>

<script>
//import AttachmentList from "./AttachmentList";
export default {
  name: "Attachment",
  data() {
    return {
      fileName:'',
      tempAttachments   : [],
      attachments       : [],
      pickerApiLoaded   : false,
      developerKey      : 'AIzaSyDJAMJfkPQIaabIywld3hC8lWpQsBQJdto',
      clientId          : "1051628437023-rigu3hsmuorpohl9u333nkk571oq3mm3.apps.googleusercontent.com",
      appId             : "1051628437023",
      scope             : "https://www.googleapis.com/auth/drive.readonly",
      oauthToken        : null
    };
  },
  //old
/*  developerKey      : 'AIzaSyAG6tR1HV3Z2M-Q2nBFjD-5vX-_6R6eoQU',
  clientId          : "95089445529-nalgm9qt29jbfnsoph7hk04ol1bv5uo2.apps.googleusercontent.com",
  appId             : "95089445529",*/

  //nivelAndo
  //developerKey    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
  //clientId    1051628437023-rigu3hsmuorpohl9u333nkk571oq3mm3.apps.googleusercontent.com
  //appId             : "95089445529",
  components: {
  //  AttachmentList: AttachmentList,
  },
  mounted() {
    let gDrive = document.createElement("script");
    gDrive.setAttribute("type", "text/javascript");
    gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
    document.head.appendChild(gDrive);
  },
  methods: {
    // function called on click of drive icon
    async driveIconClicked() {
// eslint-disable-next-line no-undef
      await gapi.load("auth2", () => {
        // eslint-disable-next-line no-undef
        gapi.auth2.authorize(
            {
              client_id: this.clientId,
              scope: this.scope,
              immediate: false
            },
            this.handleAuthResult
        );
      });
      // eslint-disable-next-line no-undef
      gapi.load("picker", () => {
        this.pickerApiLoaded = true;
        this.createPicker();
      });
    },

    handleAuthResult(authResult) {
      console.log("Handle Auth result");
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },

    // Create and render a Picker object for picking user Photos.
    createPicker() {
      console.log("Create and render a Picker object for picking user Photos.");
      if (this.pickerApiLoaded && this.oauthToken) {
        // eslint-disable-next-line no-undef
        var picker = new google.picker.PickerBuilder()
            .setLocale('es')
            // eslint-disable-next-line no-undef
           // .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            // eslint-disable-next-line no-undef
            .enableFeature(google.picker.Feature.MINE_ONLY)
            // eslint-disable-next-line no-undef
            .addView(google.picker.ViewId.DOCS)
            .setOAuthToken(this.oauthToken)
            .setDeveloperKey("AIzaSyDJAMJfkPQIaabIywld3hC8lWpQsBQJdto") // developer key
            .setCallback(this.pickerCallback)
            .build();
        picker.setVisible(true);
      }
    },

     async pickerCallback(data = 0) {
      //console.log("PickerCallback Files : ", data);
    this.$emit('handlePickerFiles', data);

    this.$parent.$emit('handlePickerFiles', data);
    
 // eslint-disable-next-line no-prototype-builtins
    if(data.hasOwnProperty("docs")){
      this.fileName = data.docs[0].name;
    }

      /*
      console.log(data.docs[0].name);
      console.log(data.docs[0].id);
      console.log(data.docs[0].url);
      */

      // eslint-disable-next-line no-undef,no-unused-vars
      var url = "nothing";
      var name = "nothing";
      // eslint-disable-next-line no-undef
      if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        // eslint-disable-next-line no-undef
        var doc = data[google.picker.Response.DOCUMENTS][0];
        // eslint-disable-next-line no-undef
        url = doc[google.picker.Document.URL];
        name = doc.name;
        let docs = data.docs;
        // eslint-disable-next-line no-undef,no-unused-vars
        var param = { fileId: doc.id, oAuthToken: this.oauthToken, name: name };
        let attachments = [];
        for (let i = 0; i < docs.length; i++) {
          let attachment = {};
          attachment._id = docs[i].id;
          attachment.title = docs[i].name;
          attachment.name = docs[i].name + "." + docs[i].mimeType.split("/")[1];
          attachment.type = "gDrive";
          attachment.description = "Shared with GDrive";
          attachment.extension =
              "." +
              docs[i].mimeType.substring(docs[i].mimeType.lastIndexOf(".") + 1);
          attachment.iconURL = docs[i].iconUrl;
          attachment.size = docs[i].sizeBytes;
          attachment.user = JSON.parse(localStorage.getItem("user"));
          attachment.thumb = null;
          attachment.thumb_list = null;
          attachments.push(attachment);
        }
        this.tempAttachments = [...attachments];
      }
      this.oauthToken = null;
      this.pickerApiLoaded = false;

      
    }
  },
  computed: {
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.txt_attachmnts{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px !important;
  font-weight: 400;

}
</style>
