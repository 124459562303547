<template>
  <b-container>
    <b-row>
          <div class="my-5">
            <span class="txt_title">Rutas activas</span>
          </div>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="12">

        <div class="my-5" style="display: flex; justify-content:space-between" >
          <span class="txt_subTitle">Fluency Tutor y WriQ</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
        <hr>

        <div class="my-5" style="display: flex; justify-content:space-between" >
          <span class="txt_subTitle">Autogestivo Educador Certificado Google Nivel 2 (TI)</span>

          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
        <hr>

        <div class="my-5" style="display: flex; justify-content:space-between" >
          <span class="txt_subTitle">Read&Write</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
        <hr>
        <div class="my-5" style="display: flex; justify-content:space-between" >
          <span class="txt_subTitle">Simulacro para el examen de Educador Certificado de Google Nivel 1</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
        <hr>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Index"
}
</script>

<style scoped>

.txt_title{
  font-size: 44px;
  font-weight: bold;
  font-family: "Roboto Light";
}

.txt_subTitle{
  font-size: 24px;
  font-weight: bold;
  font-family: "Roboto Light";
}

</style>