<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Quiz"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="info"
                dismissible
            >
              Lee cuidadosamente y <b class="mx-1">seleccionar</b> la respuesta correcta, una vez finalizado el Quiz pulsar el botón
              <b class="mx-1">Finalizar Examen</b>
            </b-alert>

            <b-form >

              <div v-for="input in preguntas_de_la_unidad" :key="input.id">
                <div class="mx-3 mt-3 p-2 criterio" >
                  <b-jumbotron :lead=input.pregunta></b-jumbotron>
                  <b-form-radio-group >
                  <b-row>
                    <!--<div v-for="(input,k) in preguntas_de_la_unidad" :key="k">-->
<!--                    <b-col sm="12"  v-for="(answer,j) in input.respuestas" >-->

                    <b-col sm="12"  v-for="descripcion in input.respuestas" :key="descripcion.id">
                      <b-card style="border:1px #dedede solid;  border-radius: 15px;" >
                        <b-card-text>
                          <b-form-radio
                              :id="'respuesta-' + descripcion.id"
                              :value="descripcion.id"
                              @change="saveResponses(descripcion.reactivo_id, descripcion.id)"
                          >{{ descripcion.respuesta }}
                          </b-form-radio>
                        </b-card-text>
                      </b-card>
                    </b-col>

                  </b-row>
                  </b-form-radio-group>
                </div>
              </div>
              <b-row  style="display: flex; justify-content: flex-end; align-items:center;" class="my-3 mx-3">

                <b-button @click="finalizarexamen()"
                          class="btn-studioAPP"
                          variant="outline-warning"> Finalizar examen
                </b-button>
              </b-row>
            </b-form>
          </div><!--card-body-->
        </div><!--card-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PreguntaService from '@/services/PreguntaService';
import RubricaService from '@/services/RubricaService';
import toast from "@/mixins/ToastMixin.js";
import RespuestaService from '@/services/RespuestaService';

export default {
  mixins:[toast],
  data() {
    return {
      preguntas_de_la_unidad:[],
      respuestas_de_la_unidad:[],
      nombreRubrica:null,
      inputs: [
        { tituloCriterio: '', desCriterio:'' , answers: [] }
      ],
      unidad:{
        id:5,
        actividad:{
          id:122,
        }
      }
    }
  },//data

  mounted () {
    let idUnidad;
    idUnidad=122
    this.inputs[0].answers.push( {  puntosNivel: '', tituloNivel:'', descripcionNivel:'' } );
    //cargar la preguntas
    this.getPreguntas(idUnidad)
  },
  computed: {
    fillRespuestas (idPregunta) {
      this.getRespuestas(idPregunta)
      return this.respuestas_de_la_unidad
    },
  },
  methods: {
    finalizarexamen(){

    },
    async saveResponses(idPregunta,idRespuesta){
      let formData = new FormData();
      formData.append("pregunta_id", idPregunta);
      formData.append("respuesta_id",  idRespuesta);
      formData.append("unidad_id",  1);
      formData.append("actividad_id",  1);

      //const response = await PreguntaService.saveResponses(formData);
      await PreguntaService.saveResponses(formData);
/*      let {
        success
      } = response.data;*/
      // if(success){
      //   this.getPreguntas(this.idActividad);
      // }
    },
    async getRespuestas(itemId){
      const response               = await RespuestaService.getRespuestas(itemId);
      this.respuestas_de_la_unidad = response.data;
    },
    async getPreguntas( itemId ){
      this.wait()
      this.preguntas_de_la_unidad=[]
      const response = await PreguntaService.getAllPreguntasRespuestas(itemId);
      this.preguntas_de_la_unidad = response.data;
      console.log(this.preguntas_de_la_unidad)
     // console.log(this.preguntas_de_la_unidad.respuestas)
    },
    volver(){
      this.$router.push({
        path:`/dashboardUnidades`
      });
    },

    isValid(){

      if( (this.nombreRubrica === "" || !this.nombreRubrica || this.nombreRubrica==null) )
      {
        this.toast('b-toaster-bottom-center', true,  'Hace falta especificar un nombre para esta rúbrica', 'danger' );
        return false;
      }

      for (let index = 0; index <  this.inputs.length; index++) {
        if( (this.inputs[index].tituloCriterio === "" || !this.inputs[index].tituloCriterio) )
        {
          this.toast('b-toaster-bottom-center', true,  'El título del criterio NO DEBE estar vacío', 'danger' );
          return
        }

        if( (this.inputs[index].desCriterio === "" || !this.inputs[index].desCriterio) )
        {
          this.toast('b-toaster-bottom-center', true,  'Hace falta la descripción en un criterio', 'danger' );
          return
        }



        for (let j = 0; j <  this.inputs[index].answers.length; j++)
        {
          if( this.inputs[index].answers[j].puntosNivel === "" || !this.inputs[index].answers[j].puntosNivel )
          {
            this.toast('b-toaster-bottom-center', true,  'Hace falta definir el puntaje en un nivel de un criterio', 'danger' );
            return
          }

          if( this.inputs[index].answers[j].tituloNivel === "" || !this.inputs[index].answers[j].tituloNivel )
          {
            this.toast('b-toaster-bottom-center', true,  'Hace falta definir el título del nivel de un criterio', 'danger' );
            return
          }

          if( this.inputs[index].answers[j].descripcionNivel === "" || !this.inputs[index].answers[j].descripcionNivel )
          {
            this.toast('b-toaster-bottom-center', true,  'Hace falta agregar una descripción del nivel de un criterio', 'danger' );
            return
          }

        }

      }
    },

    async guardarRubrica(){

      this.isValid();

      let nombreRubrica   = (this.nombreRubrica)          ? this.nombreRubrica            : 0;
      let criterios       = (this.inputs)                 ? this.inputs                   : 0;
      let unidad          = (this.unidad.id)              ? this.unidad.id                : 0;
      let actividad       = (this.unidad.actividad.id)    ? this.unidad.actividad.id      : 0;

      let response = await RubricaService.saveRubrica(nombreRubrica,criterios,unidad,actividad);

      let {   success,
        message,
        color,
      } = response.data;

      if(success){
        this.toast('b-toaster-bottom-center', true, message, color );
        this.$router.push({
          path:`/dashboardUnidades`
        });
      }

    },


    remove2(respuesta,pregunta) {
      this.inputs[pregunta].answers.splice(respuesta,1);
    },

    add() {
      this.inputs.push(  {    tituloCriterio: '',
            desCriterio:'',
            answers: [ {
              puntosNivel:'',
              tituloNivel:'',
              descripcionNivel:'',
            }
            ]
          }
      );

    },//add

    add2(index2) {
      this.inputs[index2].answers.push( {  puntosNivel: '', tituloNivel:'', descripcionNivel:'' } );
    },

    remove(index) {
      this.inputs.splice(index, 1);
    },
  },//methods

}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.criterio{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border:2px #dedede solid;
  border-radius: 15px;
}

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>