<template>
    <b-container>
    
    <iframe 
            width="900"
            height="800"
            src="https://docs.google.com/presentation/d/1E1hXYnT5RFPsehEnaNnZSNIixMIg3RVmQAyk0CnNqAM/preview?usp=drive_web" frameborder="1">
    </iframe>
  </b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>