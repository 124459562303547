import api from '@/services/api';

export default{

    getUnidades(){
        let response   = api({
            url: '/api/unidades',
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    deleteUnidad(id){
        console.log('deleteUnidad');
        let url          = `/api/unidades/destroy/${id}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    saveUnidad(formData){
      let response     = api({
            url: `/api/unidades/store`,
            method: 'POST',
            headers: {'content-type': 'multipart/form-data'},
            data: formData       
        });
        return response;
    },

    updateUnidad(selectedUnidadId,nombre_unidad, descripcion){
        console.log('updateUnidad servicio');
        let url          = `/api/unidades/update/${selectedUnidadId}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                'nombre_unidad':nombre_unidad,
                'descripcion'   : descripcion
            }         
             
        });

        return response;
    }




}