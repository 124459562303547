<template>
  <b-container>
    <!-- inicio modal Resultados-->
    <b-modal v-model="modalResultadosQuiz"  persistent title="Resultados" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  En esta sección se enlistan los resultados obtenidos de la evaluación. <br>Actividad: {{nombreActividad}}
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                  <b-button :href="sheetResultadoEvaluaciones" target="_blank"
                            class="btn-studioAPP" variant="outline-warning">
                    Importar
                  </b-button>
                </b-row>

              </div>
                <b-table class="table data-table"
                         :items="resultados_evaluaciones"
                         :fields="headersResultadoEvaluaciones"
                         :current-page="currentPage_resultados_evaluaciones"
                         :per-page="perPage_resultados_evaluaciones"
                         :filter="filter_resultados_evaluaciones"
                         :filter-included-fields="filterOn_resultados_evaluaciones"
                         stacked="md"
                         :striped="true"
                         hover
                         show-empty
                         outlined
                         :small="true"
                >
                  <template #empty="scope">
                    <b-alert show variant="info">{{
                        scope.emptyText = 'Por el momento no hay registros que mostrar'
                      }}
                    </b-alert>
                  </template>
                  <template #emptyfiltered="scope">
                    <b-alert show variant="warning">
                      {{ scope.emptyFilteredText = 'No hay registros que concuerden con los parámetros de búsqueda.' }}
                    </b-alert>
                  </template>
                </b-table>
                <div class="d-flex justify-content-between row p-2">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div>
                      <b-pagination
                          v-model="currentPage_resultados_evaluaciones"
                          :total-rows="totalRows_resultaciones_evaluaciones"
                          :per-page="perPage_resultados_evaluaciones"
                          align="fill"
                          size="sm"
                          class="my-0"
                      >
                      </b-pagination>
                    </div>
                  </div>
                </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalResultadoEvaluaciones()">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL DE RESULTADOS --->
<!-- modal entregable tipo -->
    <b-modal
        v-model="modalEntregableEleccionTipo"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
            <b-col cols="12">  
                Elegir el tipo de <b> entregable</b> a evaluar para esta actividad de esta Unidad
              <br>
                <b-alert show variant="info" class="p-3 text-justify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                        El entregable básico se basa en evaluar si el alumno hace o NO entrga
                     <br>
                        El entregable calificable se basa en evaluar a criterio del docente
                     <br>
                        El entregable mediante rúbrica se basa en utilizar una rúbrica como criterio
                    <br>

                </b-alert>
            </b-col>   
        </b-row>
        <b-row class="my-5" style="display: flex; justify-content: space-around; ">

                        <b-button 
                            v-if="this.entregableActividad!=1"
                            @click="updateEntregable(1)"
                            class="btn-studioAPP" 
                            variant="outline-warning"> Básico
                        </b-button>
                        <b-button v-if="this.entregableActividad==1"
                        disabled
                            class="btn-studioAPP" 
                            variant="outline-warning"> Básico activo ✔️
                        </b-button>                        

                         <b-button v-if="this.entregableActividad!=2"
                            @click="updateEntregable(2)"
                            class="btn-studioAPP" 
                            variant="outline-warning"> Calificable  
                        </b-button>
                         <b-button v-if="this.entregableActividad==2"
                            disabled
                            class="btn-studioAPP" 
                            variant="outline-warning"> Calificable activo ✔️

                          <!--  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                            </svg>-->

                        </b-button>

                    
                    <div v-if="this.tieneRubrica==1">
                            <b-button v-if="this.entregableActividad!=3"
                                @click="updateEntregable(3)"
                                class="btn-studioAPP" 
                                variant="outline-warning"> Rúbrica
                            </b-button>
                            <b-button v-if="this.entregableActividad==3"
                                disabled
                                class="btn-studioAPP" 
                                variant="outline-warning"> Rúbrica activo ✔️
                                   <!-- <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                    </svg>-->
                            </b-button>
                    </div>
                    <div v-else>
                          <b-button
                                disabled
                                class="btn-studioAPP" 
                                variant="outline-warning">  ¡No hay rúbrica creada para esta actividad! ❌
                            </b-button>
                               
                    </div>

        </b-row>
        <hr>
        <b-row class="my-5" style="display: flex; justify-content: space-around; ">

          <b-alert show variant="info" class="p-3 text-justify">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
            </svg>
            El entregable OBLIGATORIO se contempla para el avance del curso y es mandatorio para el 100%, a diferencia del opcional, que NO se contempla, por defecto, todos son opcionales.
            <br>
          </b-alert>


          <div v-if="this.tieneEntregable!=0">

            <b-button
                disabled
                v-if="this.entregableObligatorio==1"
                class="btn-studioAPP"
                variant="outline-warning"> Opcional activo ✔️
            </b-button>

            <b-button
                v-if="this.entregableObligatorio==2"
                @click="updateEntregableOpcionaloMandatorio(1)"
                class="btn-studioAPP"
                variant="outline-warning"> Opcional
            </b-button>

          </div><!--this.tieneEntregable!=0-->

          <b-button
              v-if="this.tieneEntregable==0"
              disabled
              class="btn-studioAPP"
              variant="outline-warning"> Opcional<small><i> --No entregable activo</i></small>
          </b-button>



          <div v-if="this.tieneEntregable!=0">

            <b-button
                v-if="this.entregableObligatorio==1"
                @click="updateEntregableOpcionaloMandatorio(2)"
                class="btn-studioAPP"
                variant="outline-warning"> Obligatorio
            </b-button>
            <b-button
                disabled
                v-if="this.entregableObligatorio==2"
                class="btn-studioAPP"
                variant="outline-warning"> Obligatorio activo ✔️
            </b-button>
          </div>


          <b-button
              v-if="this.tieneEntregable==0"
              disabled
              class="btn-studioAPP"
              variant="outline-warning"> Obligatorio<small><i> --No entregable activo</i></small>
          </b-button>

        </b-row>
        <hr>
        <b-row class="my-5" style="display: flex; justify-content: flex-end; ">
                        <b-button v-if="this.entregableActividad!=0"
                            @click="updateEntregable(0)"
                            variant="outline-danger"> Desactivar la entrega
                        </b-button>
                        <b-button v-if="this.entregableActividad==0"
                            disabled
                            variant="outline-danger">Entrega inactiva

<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg>

                        </b-button>
            <!--  <b-form-checkbox :value="( (`${row.item.entregable}`!=0) ? '1':0  )" :checked="( (`${row.item.entregable}`!=0) ? '1':null  )" switch size="lg" @change="showModalEntregableEleccionTipo(row.item.id)"/>
            -->
        </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    @click="hideModalEntregableEleccionTipo()">
                    Cancelar
                </b-button>                              
            </div>
            </template>
            
    </b-modal>

<!-- modal rubrica eleccion -->
    <b-modal
        v-model="modalRubricaEleccion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
      <b-container fluid>
            <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Que desea hacer ?
                </b-col>   
            </b-row>
            <b-row class="my-5" style="display: flex; justify-content: space-around; ">
                    
                    <div v-if="this.rubrica.id!=0">
                        <b-button 
                            disabled
                            class="btn-studioAPP" 
                            variant="outline-warning"> Crear rúbrica 
                        </b-button>
                    </div>
                    <div v-else>
                        <b-button 
                            @click="irNuevaRubrica()"
                            class="btn-studioAPP" 
                            variant="outline-warning"> Crear rúbrica 
                        </b-button>
                    </div>
                    <div v-if="this.rubrica.id !=0">
                        <b-button @click="irEditRubrica()" class="ml-2" href="#" variant="outline-primary">
                            Editar existente
                        </b-button>
                    </div>
                    <div v-else>
                      <b-button disabled class="ml-2" href="#" variant="outline-primary">
                            Editar existente
                        </b-button>
                    </div>   
                            
            </b-row>
            <b-row style="display: flex; justify-content: center; ">
                    <div class="mt-2" v-if="this.rubrica.id !=0"  style="width: 80%;" >
                        <Rubrica :rubricaId='this.rubrica.id'/>
                    </div> 
                    <div v-else>
                        <small>
                            <i>
                                ¡No hay rúbrica creada para esta actividad!
                            </i>
                        </small>
                    </div>  
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalRubricaEleccion()">
                        Cancelar
                    </b-button>                              
            </div>
            </template>
            
    </b-modal>

<!-- modal borrar item de la actividad -->
    <b-modal
        v-model="modalBorrarItemConfirmacion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar el item de la actividad ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="modalBorrarItemConfirmacion = false"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="borrarItemActividad()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
    </b-modal>

<!-- modal crear nueva unidad elemento -->
     <b-modal no-close-on-backdrop centered size="xl" 
                hide-footer
                v-model="ModalAsignarActividades"
                title="Asignando Actividades a la unidad">
            <b-card
                    tag="article"
                    class="mb-2">
                <b-alert show variant="info" class="p-3 text-justify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                        Este apartado sirve para agregar nuevas actividades a una unidad.
                </b-alert>
                <b-card-text>
                 <b-row>
                    <div class="col-sm-12">
                      <b-form>
                         <b-form-input
                                ref="nombre_actividad_unidad"
                                class="my-2"
                                v-model="nombre_actividad_unidad"
                                type="text"
                                placeholder="Ingresa el nombre de la actividad"          
                                >
                        </b-form-input>
<!--                        <b-row class="d-flex align-items-start justify-content-start my-1">-->
                                <div class="col-sm-12 my-2">
                                    <b-button
                                        v-if="editarActividadUnidad"
                                        variant="primary" 
                                        size="sm" 
                                        class="float-right mx-2 my-1"
                                        @click="ActualizarUnidadActividad()"
                                    >
                                    Actualizar el nombre
                                    </b-button>
                                    <b-button 
                                        v-if="!editarActividadUnidad"
                                        variant="success" 
                                        size="sm" 
                                        class="float-right mx-2 my-1"
                                        @click="guardarUnidadActividad()"
                                    >
                                    Guardar nueva actividad
                                    </b-button>    
                                </div>
                      </b-form>
                    </div>
                 </b-row>
                  <b-row>
<!--                        </b-row>-->
                            <b-table class="table data-table"
                                :striped="true"
                                hover
                                outlined
                                :items="actividades_de_la_unidad"
                                :fields="headersActividades"
                                     stacked="md"
                                    show-empty
                                     :small="true"
                                   >

                              <template #cell(detalles_actividad)="row">
                                  <small>{{ (row.item.detalles_actividad).substr(0,133) }}</small>
                              </template>
                              <template #empty="scope">
                                <p class="text-center">
                                  {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                                </p>
                              </template>

                            <template #cell(editar_actividad)="row">
<!--                                   <div class="d-flex justify-content-evenly">-->
                              <div class="text-center">
                                        <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"  @click="EditInputActividadUnidad(row.item.id, row.item.detalles_actividad)"
                                                    class="mr-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path
                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                            <path fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                            </svg>
                                        </b-button>

                                        <b-button variant="danger" size="sm" @click="ShowModalborrarActividadUnidad(row.item.id)" class="mr-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                            </svg>
                                        </b-button>
                              </div>
<!--                                   </div>-->
                                </template>

                                <template #cell(items_actividad)="row">
                                        <b-button variant="outline-primary" size="sm" @click="ShowModalAgregarItemsActividad(row.item.id, row.item.detalles_actividad)" class="ml-1">
                                            Administrar
                                        </b-button>
                                </template>

                                <template #cell(items_rubrica)="row">
                                        <b-button variant="info" v-b-modal.modal-prevent-closing size="sm"
                                                    @click="showModalRubricaEleccion(row.item.id)" class="mr-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-binary" viewBox="0 0 16 16">
                                                    <path d="M7.05 11.885c0 1.415-.548 2.206-1.524 2.206C4.548 14.09 4 13.3 4 11.885c0-1.412.548-2.203 1.526-2.203.976 0 1.524.79 1.524 2.203zm-1.524-1.612c-.542 0-.832.563-.832 1.612 0 .088.003.173.006.252l1.559-1.143c-.126-.474-.375-.72-.733-.72zm-.732 2.508c.126.472.372.718.732.718.54 0 .83-.563.83-1.614 0-.085-.003-.17-.006-.25l-1.556 1.146zm6.061.624V14h-3v-.595h1.181V10.5h-.05l-1.136.747v-.688l1.19-.786h.69v3.633h1.125z"/>
                                                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                                    </svg>
                                        </b-button>
                                </template>

                              <template #cell(reactivos)="row">
                                <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                          @click="showModalReactivos(row.item.id)" class="mr-1" title="Preguntas">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                  </svg>
                                </b-button>
                              </template>
                              <template #cell(resultados)="row">
                                <b-button variant="warning" v-b-modal.modal-prevent-closing size="sm"
                                          @click="showModalResultadosQuiz(row.item.id)" class="mr-1">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                  </svg>
                                </b-button>
                              </template>
                                <template #cell(entregable)="row">
                                        <!-- distinto de 0 por los dif tipos de entregable en la unidad
                                    <b-form-checkbox :value="( (`${row.item.entregable}`!=0) ? '1':0  )" :checked="( (`${row.item.entregable}`!=0) ? '1':null  )" switch size="lg" @change="showModalEntregableEleccionTipo(row.item.id)"/>
                               -->

                                <b-button variant="primary" v-b-modal.modal-prevent-closing size="sm"
                                                @click="showModalEntregableEleccionTipo(row.item.id, row.item.entregable)" class="mr-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-binary" viewBox="0 0 16 16">
                                                <path d="M7.05 11.885c0 1.415-.548 2.206-1.524 2.206C4.548 14.09 4 13.3 4 11.885c0-1.412.548-2.203 1.526-2.203.976 0 1.524.79 1.524 2.203zm-1.524-1.612c-.542 0-.832.563-.832 1.612 0 .088.003.173.006.252l1.559-1.143c-.126-.474-.375-.72-.733-.72zm-.732 2.508c.126.472.372.718.732.718.54 0 .83-.563.83-1.614 0-.085-.003-.17-.006-.25l-1.556 1.146zm6.061.624V14h-3v-.595h1.181V10.5h-.05l-1.136.747v-.688l1.19-.786h.69v3.633h1.125z"/>
                                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                                </svg>
                                </b-button>
                                </template>
                             </b-table>
                            <div class="w-100 my-2">
                                <b-button
                                        variant="primary"
                                        size="sm"
                                        class="float-right"
                                        @click="CerrarModalActividades()"
                                    >
                                        Cancelar
                                </b-button>   
                            </div>



                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

<!-- modal agregar ITEMS a la ACTIVIDAD dentro de la UNIDAD -->
    <b-modal
        v-model="modalAgregarItemsActividad"
        persistent
        title=" Agregando items a la actividad"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    
                <b-form>
                    <b-form-input
                        ref="nombre_item_a_actividad"
                        class="my-2"
                        v-model="nombre_item_a_actividad"
                        type="text"
                        placeholder="Ingresa el nombre del item a asignarse a la actividad"          
                        >
                    </b-form-input>

                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="col-sm-12 my-2">

                                    <b-button 
                                        v-if="editarItem"
                                        variant="primary" 
                                        size="sm" 
                                        class="float-right mx-2 my-1"
                                        @click="ActualizarItemDeActividad()"
                                    >
                                    Actualizar el nombre del item
                                    </b-button>    
                                    
                                    <b-button 
                                        v-if="!editarItem"
                                        variant="success" 
                                        size="sm" 
                                        class="float-right mx-2 my-1"
                                        @click="guardaItemEnActividad()"
                                    >
                                    Asignar nuevo item
                                    </b-button>    
                                </div>
                        </b-row>

                      </b-form>
              
                    <hr>
                    
                    <b-table class="table data-table"
                            striped="striped"
                            hover="hover"
                            outlined="outlined"
                            :items="items_de_la_actividad"
                            :fields="headersItems"
                                stacked="md"
                                show-empty
                                small
                       >
                    <template #empty="scope">
                        <p class="text-center">
                            {{ scope.emptyText='Por el momentos no hay registros que mostrar.' }}
                        </p>
                    </template>        
                    
                    <template #cell(actions)="row">   
                       
                            <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"  @click="editInputItemDeActividad(row.item.id, row.item.descripcion_actividad)" 
                                        class="mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </b-button>

                            <b-button variant="danger" size="sm" @click="ShowModalborrarItemDeActividad(row.item.id)" class="ml-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                            </b-button>
                    </template>
                    </b-table>
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="modalAgregarItemsActividad = false"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="actualizarActividadUnidad()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
    </b-modal>

<!-- modal borrar actividad de la unidad -->
    <b-modal
        v-model="modalBorrarActividadUnidadConfirmacion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar la actividad de la unidad ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="modalBorrarActividadUnidadConfirmacion = false"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="borrarActividadUnidad()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
    </b-modal>

<!-- modal crear nueva unidad elemento -->
     <b-modal no-close-on-backdrop centered size="xl" 
                hide-footer
                ref="nuevaUnidad" 
                title="Creando una nueva unidad">
            <b-card
                    tag="article"
                    class="mb-2">
                <b-alert show variant="info" class="p-3 text-justify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                        Este apartado sirve para agregar nuevas unidades, estás a su vez, posteriormente podrán ser anexadas a los distintos cursos a según sea el caso.
                </b-alert>
                <b-card-text>
                 <b-row>
                        <div class="col-sm-1"></div>
                   <div class="col-sm-10">
                      <b-form>
                        <b-form-input
                                ref="nombre_unidad"
                                class="my-2"
                                v-model="form_unidad.nombre_unidad"
                                type="text"
                                placeholder="Nombre de la unidad"          
                                >
                        </b-form-input>
                        <b-form-input
                                ref="clave_interna"
                                class="my-2"
                                v-model="form_unidad.clave_interna"
                                type="text"
                                placeholder="Clave interna"                    
                                >
                        </b-form-input>
                            <b-form-textarea
                                ref="descripcion"
                                v-model="form_unidad.descripcion"
                                placeholder="Breve descripción de la unidad..."
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>
                        <div class="col-sm-1"></div>
                        <hr>
                            <div class="w-100">
                            <b-button
                                    variant="primary"
                                    size="sm"
                                    class="float-right"
                                    @click="cerrarModal()"
                                >
                                    Cancelar
                                </b-button>   
                                <b-button
                                    variant="success" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="guardarUnidad()"
                                >
                                Guardar
                                </b-button>                             
                            </div>

                      </b-form>
                   </div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

<!-- modal borrar unidad -->
    <b-modal
        v-model="modalBorrarConfirmacion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar el registro  <b> {{nombreBorrar}} </b> ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="modalBorrarConfirmacion = false"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="deleteUnidad(selectedUnidadId)"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
    </b-modal>

<!-- modal actualizar unidad -->
 <b-modal no-close-on-backdrop centered size="xl" 
                v-model="modalActualizarConfirmacion"
                hide-footer
                ref="actualizarUnidad" 
                title="Actualizando unidad">
            <b-card
                    tag="article"
                    class="mb-2">
                <b-card-text>
                 <b-row>
                        <div class="col-sm-1"></div>
                   <div class="col-sm-10">
                      <b-form>
                        <b-form-input
                                ref="nombre_unidad"
                                class="my-2"
                                v-model="form_unidad.nombre_unidad"
                                type="text"
                                placeholder="Nombre de la unidad"          
                                >
                        </b-form-input>
                        <b-form-input
                                ref="clave_interna"
                                class="my-2"
                                disabled
                                v-model="form_unidad.clave_interna"
                                type="text"                 
                                >
                        </b-form-input>
                            <b-form-textarea
                                ref="descripcion"
                                v-model="form_unidad.descripcion"
                                placeholder="Breve descripción de la unidad..."
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>
                        <div class="col-sm-1"></div>
                        <hr>
                            <div class="w-100">
                            <b-button
                                    variant="primary"
                                    size="sm"
                                    class="float-right"
                                    @click="cerrarActualizarModal()"
                                >
                                    Cancelar
                                </b-button>   
                                <b-button
                                    variant="success" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="actualizarUnidad()"
                                >
                                Guardar
                                </b-button>                             
                            </div>

                      </b-form>
                   </div>
                 </b-row>
                </b-card-text>

                </b-card>
 </b-modal>

<!-- modal respuestas -->
    <b-modal v-model="modalRespuestas"  persistent title=" Respuestas" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  En esta sección se enlistan las respuestas creadas.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>
              </div>
              <b-form>
                <b-form-textarea
                    ref="respuesta"
                    class="my-2"
                    v-model="dataRespuesta.respuesta"
                    type="text"
                    rows="2"
                    max-rows="3"
                    placeholder="Ingresa la respuesta"
                >
                </b-form-textarea>

                <div class="col-sm-12 my-2">
                    <b-form-checkbox
                        id="checkSelected"
                        v-model="checkSelected"
                        name="checkSelected"
                        @input="pressBtnCorrect"
                        :disabled=dataRespuesta.disabled
                    >Es correcta
                    </b-form-checkbox>
                </div>

                <div class="col-sm-12 my-2">

                  <b-button
                      v-if="!editarActividadUnidad"
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="guardarRespuesta()"
                  >
                    Guardar Respuesta
                  </b-button>
                </div>

                <b-table class="table data-table"
                         :items="respuestas_de_la_unidad"
                         :fields="respuestas_fields"
                         :current-page="currentPage_respuestas"
                         :per-page="perPage_respuestas"
                         :filter="filter_respuestas"
                         :filter-included-fields="filterOn_respuestas"
                         stacked="md"
                         :striped="true"
                         hover
                         show-empty
                         outlined
                         :small="true"
                >
                  <template #cell(correcta)="data">
                    <div v-if="data.item.correcta==true">Correcta</div>
                    <div v-else-if="data.item.correcta==false"></div>
                  </template>
                  <template #cell(descripcion)="data">
                    <span v-html="data.item.respuesta"></span>
                  </template>
                  <template #empty="scope">
                    <b-alert show variant="info">{{
                        scope.emptyText = 'Por el momento no hay registros que mostrar'
                      }}
                    </b-alert>
                  </template>
                  <template #emptyfiltered="scope">
                    <b-alert show variant="warning">
                      {{ scope.emptyFilteredText = 'No hay registros que concuerden con los parámetros de búsqueda.' }}
                    </b-alert>
                  </template>
                  <template #cell(actions)="row">
                    <div class="text-center">
                      <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                @click="checkRespuestaCorrecta(row.item.id)" class="mr-1" title="Clic para seleccionar Respuesta Correcta">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                          <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                        </svg>
                      </b-button>
                      <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm" @click="ShowModalborrarRespuesta(row.item.id)" class="mr-1" title="Eliminar Respuesta">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                        </svg>
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <div class="d-flex justify-content-between row p-2">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div>
                      <b-pagination
                          v-model="currentPage_respuestas"
                          :total-rows="totalRows_respuestas"
                          :per-page="perPage_respuestas"
                          align="fill"
                          size="sm"
                          class="my-0"
                      >
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalRespuestas()">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
<!-- fin modal respuestas -->

<!-- inicio modal Reactivos-->
    <b-modal v-model="modalReactivos"  persistent title=" Quiz" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  En esta sección se enlistan las preguntas creadas.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>
              <b-form>
                <b-form-textarea
                    ref="reactivo"
                    class="my-2"
                    v-model="reactivo"
                    type="text"
                    rows="2"
                    max-rows="3"
                    placeholder="Ingresa la pregunta"
                >
                </b-form-textarea>
                <div class="col-sm-12 my-2">

                  <b-button
                      v-if="!editarActividadUnidad"
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="guardarPregunta()"
                  >
                    Guardar Pregunta
                  </b-button>
                </div>

              <b-table class="table data-table"
                       :items="preguntas_de_la_unidad"
                       :fields="reactivos_fields"
                       :current-page="currentPage_reactivos"
                       :per-page="perPage_reactivos"
                       :filter="filter_reactivos"
                       :filter-included-fields="filterOn_reactivos"
                       stacked="md"
                       :striped="true"
                       hover
                       show-empty
                       outlined
                       :small="true"
              >
                <template #cell(tiporeactivo_id)="data">
                  <div v-if="data.item.tiporeactivo_id==1">Opción múltiple (única respuesta)</div>
                  <div v-else-if="data.item.tiporeactivo_id==2">Opción múltiple (varias respuesta)</div>
                </template>
                <template #cell(pregunta)="data">
                  <span v-html="data.item.descripcion"></span>
                </template>
                <template #empty="scope">
                  <b-alert show variant="info">{{
                      scope.emptyText = 'Por el momento no hay registros que mostrar'
                    }}
                  </b-alert>
                </template>
                <template #emptyfiltered="scope">
                  <b-alert show variant="warning">
                    {{ scope.emptyFilteredText = 'No hay registros que concuerden con los parámetros de búsqueda.' }}
                  </b-alert>
                </template>
                <template #cell(actions)="row">
                  <div class="text-center">
                    <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                              @click="showModalRespuestas(row.item.id)" class="mr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-list" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                        <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                      </svg>
                    </b-button>
                    <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm" @click="ShowModalborrarPregunta(row.item.id)" class="mr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                      </svg>
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="d-flex justify-content-between row p-2">
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                  <div>
                    <b-pagination
                        v-model="currentPage_reactivos"
                        :total-rows="totalRows_reactivos"
                        :per-page="perPage_reactivos"
                        align="fill"
                        size="sm"
                        class="my-0"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalReactivos()">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
<!-- FIN MODAL DE REACTIVOS --->

    <!-- modal confirmacion eliminar Pregunta -->
    <b-modal
        v-model="modalBorrarReactivoConfirmacion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar la Pregunta ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalBorrarReactivoConfirmacion = false"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="borrarReactivo()"
          >
            Eliminar
          </b-button>
        </div>
      </template>

    </b-modal>
    <!-- fin Modal confirmacion eliminar Pregunta -->

    <!-- modal confirmacion eliminar Respuesta -->
    <b-modal
        v-model="modalBorrarRespuestaConfirmacion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar la Respuesta ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalBorrarRespuestaConfirmacion = false"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="borrarRespuesta()"
          >
            Eliminar
          </b-button>
        </div>
      </template>

    </b-modal>
    <!-- fin Modal confirmacion eliminar respuesta -->

 <b-row>
          <b-col cols="12">
             <div class="card card-block">
                <div class="card-body p-2">

                    <div class="d-flex justify-content-between align-items-start p-2">
                        <b-jumbotron lead="Unidades"></b-jumbotron>
                    </div>

                      <b-alert
                            class="p-3"
                            show
                            variant="info"
                            dismissible
                        >
                        Aquí podrá administrar las unidades que formarán parte de sus cursos
                    </b-alert>

                  <b-row class="d-flex align-items-end justify-content-end my-3">
                          <div class="col-sm-12 col-md-6 text-right ">
                               <b-button 
                                        @click="abrirModaNuevaUnidad"
                                        class="btn-studioAPP" 
                                        variant="outline-warning"> Agregar nueva unidad
                              </b-button>
                          </div>
                  </b-row>
                  <b-row class="d-flex align-items-end justify-content-end my-1">
                       <div class="col-sm-12 my-2">
                       <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Buscar"
                                  >
                        </b-form-input>
                        </div>
                  </b-row>
                 <b-table class="table data-table"
                    striped="striped"
                    hover="hover"
                    outlined="outlined"
                    :items="itemsUnidades"
                    :fields="Unidadesfields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        small
                       >

                    <template #empty="scope">
                        <h5 class="text-center">
                            {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                        </h5>
                    </template>        
                    <template #emptyfiltered="scope">
                        <h5 class="text-center">
                            {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                        </h5>
                    </template> 

                    <template #cell(actividades)="row">   
                        <div class="d-flex justify-content-around align-items-center">
                            <b-button variant="warning" size="sm" @click="ShowModalActividades(row.item.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-checklist" viewBox="0 0 16 16">
                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </b-button>
                        </div>               
                    </template>


                    <template #cell(objetivos)="row">   
                        <div class="d-flex justify-content-around align-items-center">
                            <b-button variant="primary" size="sm" @click="irUnidadesObjetivos(row.item.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                </svg>
                            </b-button>
                        </div>               
                    </template>

  
                    <template #cell(actions)="row">   
                        <div class="d-flex justify-content-around align-items-center">
                            <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                        @click="showEditModal(row.item)" class="mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </b-button>
                            <b-button variant="danger" size="sm" @click="ShowmodalBorrarConfirmacion(row.item)" class="ml-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                            </b-button>
                        </div>               
                    </template>

                 </b-table>
                  <div class="d-flex justify-content-between row p-2">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <div>
                                <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                                >
                                </b-pagination>
                            </div>
                            </div>
                    </div>
                </div>
            </div><!-- card block-->
          </b-col>

 </b-row>

   </b-container>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import UnidadServicio from '@/services/UnidadServicio';
import ActividadService from '@/services/ActividadService';
import PreguntaService from '@/services/PreguntaService';
import RespuestaService from '@/services/RespuestaService';
import RubricaService from '@/services/RubricaService';
import toast from "@/mixins/ToastMixin.js";
import Rubrica from '@/components/Rubricas/Rubrica';
export default {
        mixins:[toast],
        components: {
            Rubrica,
        },
data() {
    return {
      /* var respuestas*/
      sheetResultadoEvaluaciones:null,
      nombreActividad:null,
      checkSelected:false,
      idPregunta:null,
      idRespuesta:null,
        dataRespuesta:{
            id:0,
            respuesta:'',
            reactivo_id:null,
            correcta:false
        },
        modalRespuestas:false,
      /* fin var respuestas */

        modalBorrarReactivoConfirmacion:false,
        modalBorrarRespuestaConfirmacion:false,
        idActividadReactivos:null,
        reactivo:null,
        tieneRubrica:false,
        tieneEntregable:false,
        entregableObligatorio:0,
        entregableActividad:0,
        modalReactivos:false,
        modalEntregableEleccionTipo:false,
        modalRubricaEleccion:false,
        modalResultadosQuiz:false,


        rubrica:{
            id:0,
            nombre:'', 
        },
       
        modalBorrarItemConfirmacion:false,
        item_descripcion_actividad:'',
        idItem:0,
        editarItem:false,
        idActividad:0,
        nombre_item_a_actividad:'',
        items_de_la_actividad:[],
        headersItems: [
            {label: 'Nombre item', key: 'descripcion_actividad'},
            {label: 'Acciones', key: 'actions'}
        ],
        modalAgregarItemsActividad:false,
        ModalAsignarActividades:false,
        editarActividadUnidad: false,
        idActividadUnidad:0,
      
        modalBorrarActividadUnidadConfirmacion: false,
        nombre_actividad_unidad:'',
        actividades_de_la_unidad:[],
        preguntas_de_la_unidad:[],
        respuestas_de_la_unidad:[],
        resultados_evaluaciones:[],
        headersResultadoEvaluaciones: [
          {label: 'Nombre usuario',                key: 'user.name'        ,class: 'text-left'}
          ,{  label: 'Email',                    key: 'user.email'         ,class: 'text-left' }
          ,{  label: 'Resultados',              key: 'calificacion'        ,class: 'text-left' }
        ],
        headersActividades: [
          {label: 'id',      key: 'id'    },
          {label: 'Instrucción',      key: 'detalles_actividad'    },
          {label: 'Cantidad de instrucciones',  key: 'numero_items'      ,class: 'text-left'},
          {label: 'Editar',                     key: 'editar_actividad'  ,class: 'text-left'},
          {label: 'Agregar items',              key: 'items_actividad'   , class: 'text-left'},
          {label: 'Rúbrica',                    key: 'items_rubrica'     ,class: 'text-left' },
          {label: 'Entregables',                key: 'entregable'        ,class: 'text-left' }
          ,{  label: 'Quiz',                    key: 'reactivos'         ,class: 'text-left' }
          ,{  label: 'Resultados',              key: 'resultados'        ,class: 'text-left' }
        ],
        nombre_actividad_objetivo:'',   //nombre de la actividad a asignarse a la unidad
        modalActualizarConfirmacion:false,
        modalBorrarConfirmacion:false,
        selectedUnidadId:0,
         nombreBorrar:'',
          form_unidad: {
            nombre_unidad: '',
            clave_interna: '',
            descripcion: '',
        },
        Unidadesfields: [     
            {label: 'id',      key: 'id'    },
            {label: 'Nombre',        key: 'nombre_unidad', class: 'text-start'},
            {label: 'Clave',         key: 'clave_interna', class: 'text-center' },
            {label: 'Actividades',    key: 'actividades', class: 'text-center' },
            {label: 'Objetivos',      key: 'objetivos', class: 'text-center' },
            {label: 'Acciones',      key: 'actions', class: 'text-center' }
        ],
        itemsUnidades: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 8,
        perPage_reactivos: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
        filter: null,
        filterOn: [],
        search: '',
      //VARS REACTIVOS
        reactivos_fields: [
          {label: 'Pregunta', key: 'pregunta', class: 'text-center'},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
         respuestas_fields: [
          {label: 'Respuesta', key: 'descripcion', class: 'text-center'},
          {label: 'Correcta', key: 'correcta', class: 'text-center'},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        reactivos: [],
        currentPage_reactivos: 1
        ,currentPage_respuestas: 1
        ,currentPage_resultados_evaluaciones: 1
        ,perPage_respuestas:8
        ,perPage_resultados_evaluaciones:10
        ,filter_reactivos: null
        ,filter_resultados_evaluaciones: null
        ,filter_respuestas: null
        ,filterOn_reactivos: []
        ,filterOn_resultados_evaluaciones: []
        ,filterOn_respuestas: []
        ,totalRows_reactivos: 1
        ,totalRows_respuestas: 1
        ,totalRows_resultaciones_evaluaciones: 1
    }
},
mounted () {
    this.getUnidades();
},
methods: {
          async checkRespuestaCorrecta(idRespuesta){
            this.wait()
            const response =  await RespuestaService.updateCheckRespuestaCorrecta(idRespuesta,this.idPregunta);
            let {   success,
              message,
              color
            } = response.data;
            if(success){
              this.getRespuestas(this.idPregunta);
            }

            this.toast('b-toaster-bottom-center', true, message, color );
          },
          pressBtnCorrect(value){
            console.log("valor del check seleccionado:" + value)

            this.dataRespuesta.correcta = value
          },

            redirectPreguntaMutipleUnicaRespuesta(){

            },

            async showModalEntregableEleccionTipo(activityId, entregableActividad){
                this.activityId                     = activityId;
                this.entregableActividad            = entregableActividad;
                try {
                    let response = await RubricaService.checkRubricaExistsByAct(activityId);
                    let { data,tieneEntregable,entregableObligatorio } = response.data;
                    this.tieneRubrica = (data == true) ? 1 : 0;
                    this.tieneEntregable = (tieneEntregable== true) ? 1 : 0;
                    this.entregableObligatorio=entregableObligatorio;

                } catch (error) {
                    this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                            Bugsnag.notify(
                                new Error('Método: showModalEntregableEleccionTipo() ' + error)
                            );
                }
                this.modalEntregableEleccionTipo    = true;
            },
            hideModalEntregableEleccionTipo(){
                this.modalEntregableEleccionTipo=false;
                this.tieneRubrica=0;


            },

          async updateEntregableOpcionaloMandatorio(tipoEntregable){

            const response = await ActividadService.updateUnidadActividadEntregableOpcional( this.activityId, tipoEntregable);
            this.toast('b-toaster-bottom-center', true, response.data.message, response.data.color );

            this.hideModalEntregableEleccionTipo();
            this.CerrarModalActividades();
          },

            async updateEntregable(tipoEntregable){
                const response = await ActividadService.updateEntregable( this.activityId, tipoEntregable);
                this.toast('b-toaster-bottom-center', true, response.data.message, response.data.color );
                this.hideModalEntregableEleccionTipo();
                this.CerrarModalActividades();
            },

            async getData(rubrica_id) {
                try {
                    let response = await RubricaService.getRubricaEdit(rubrica_id);
                
                    let {   success,
                            message,
                            color,
                            rubrica
                            } = response.data;

                    if(success){
                        this.rubrica.nombre     = rubrica.nombre;
                        this.inputs             = rubrica.criterios;
                    }
                    this.toast('b-toaster-bottom-center', true, message, color );
                } catch (error) {
                    this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                            Bugsnag.notify(
                                new Error('Método: navegacionModulo() ' + error)
                            );
                }

            },

            async getRubricaId(){
             //   console.log(this.idActividad);
            //    console.log(this.selectedUnidadId);
                let url                   = `/api/rubricas/show/${this.selectedUnidadId}/act/${this.idActividad}`;
                const response            = await this.$api.get(url);

                let {   success,
                        rubrica
                        } = response.data;

                if(success){
                    this.rubrica.id=rubrica;
                   // this.getData(this.rubrica.id);
                }

            },

            irNuevaRubrica(){
  
                this.$router.push(`/rubricas/nueva/${this.selectedUnidadId}/act/${this.idActividad}`);
            },
            irEditRubrica(){
                this.$router.push(`/rubricas/edit/${this.rubrica.id}`);
            },
            hideModalRubricaEleccion(){
                this.modalRubricaEleccion=false;
                this.rubrica.id=0;
                this.rubrica.nombre='';
                this.inputs =   [
                                    { tituloCriterio: '', desCriterio:'' , answers: [] }
                                ];
            },
            hideModalResultadoEvaluaciones(){
              this.modalResultadosQuiz=false;
            },
            hideModalReactivos(){
                this.modalReactivos=false;
            },
            hideModalRespuestas(){
                this.modalRespuestas=false;
            },
            showModalRubricaEleccion(activityId){

                this.idActividad = activityId; 
                this.getRubricaId();
                this.modalRubricaEleccion=true;
            },
            showModalReactivos(activityId){
                this.idActividad = activityId;

                this.modalReactivos=true;
                //reset vars dialog
                this.preguntas_de_la_unidad=[]
                this.currentPage_reactivos=1
                this.perPage_reactivos=5
                this.filter_reactivos=null
                this.filterOn_reactivos=[]
                this.totalRows_reactivos=1
                this.getPreguntas(this.idActividad);
            },
            showModalResultadosQuiz(activityId){
              this.idActividad = activityId;
              this.modalResultadosQuiz=true;
              //reset vars dialog
              this.resultados_evaluaciones=[]
              this.currentPage_resultados_evaluaciones=1
              this.perPage_resultados_evaluaciones=8
              this.filter_resultados_evaluaciones=null
              this.filterOn_resultados_evaluaciones=[]
              this.totalRows_resultaciones_evaluaciones=1
              this.getsheetResultadoEvaluacion(this.idActividad);
              this.getResultados(this.idActividad);
              this.getNombreActividad(this.idActividad);
            },
            showModalRespuestas(idPregunta){
                //console.log("idPregunta:" + idPregunta)
                this.idPregunta = idPregunta
                this.dataRespuesta.reactivo_id = idPregunta;
                this.getRespuestas(idPregunta);
                this.modalRespuestas=true;
                //reset vars dialog
                this.respuestas_de_la_unidad=[]
                this.currentPage_respuestas=1
                this.perPage_respuestas=8
                this.filter_respuestas=null
                this.filterOn_respuestas=[]
                this.totalRows_respuestas=1
            },

            irUnidadesObjetivos(id){
                this.$router.push(`/dashboardUnidadesObjetivos/${id}`);  
            },
            async borrarItemActividad(){
                const response = await ActividadService.deleteItemActivity(this.idItem);
                let {   success,   
                        message,
                        color
                    } = response.data;
                if(success){
                    this.getItemsActividad( this.idActividad );
                    this.modalBorrarItemConfirmacion=false;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
            },
            ShowModalborrarItemDeActividad(idItem){
                this.idItem                     = idItem;
                this.modalBorrarItemConfirmacion = true;
            },
            editInputItemDeActividad(idItem,item_descripcion_actividad){
                this.idItem                     = idItem;
                this.nombre_item_a_actividad    =item_descripcion_actividad;
                this.editarItem                 = true;
            },
            async ActualizarItemDeActividad(){
              
                const response = await ActividadService.updateItemActivity(this.idItem, this.nombre_item_a_actividad);               
                let {   success,   
                        message,
                        color
                    } = response.data;
                    if(success){
                        this.getItemsActividad( this.idActividad );
                        this.nombre_item_a_actividad='';
                        this.editarItem                 = false;
                    }
                    this.toast('b-toaster-bottom-center', true,  message, color );
                
            },
            async guardaItemEnActividad() {

                let formData = new FormData();
                    formData.append("unidad_actividad_id",       this.idActividad);
                    formData.append("descripcion_actividad",     this.nombre_item_a_actividad);

                const response = await ActividadService.saveItemActividad(formData);               
                let {   success,
                        message,
                        color
                        } = response.data;
                if(success){
                    this.getItemsActividad( this.idActividad );
                    this.nombre_item_a_actividad='';
                }
                this.toast('b-toaster-bottom-center', true, message, color );
            },
            async getItemsActividad(idActividad) {
                this.idActividad = idActividad;
                const response = await ActividadService.getItemsEnActividad(idActividad);           
                this.items_de_la_actividad  = response.data.items;
            },
            ShowModalAgregarItemsActividad(idActividad){
                this.getItemsActividad(idActividad);
                this.modalAgregarItemsActividad=true;
            },
            EditInputActividadUnidad(idActividadUnidad, detalles_actividad){
                this.editarActividadUnidad      =true;
                this.idActividadUnidad          = idActividadUnidad;
                this.nombre_actividad_unidad    = detalles_actividad;     
            },
            async ActualizarUnidadActividad(){     
                const response = await ActividadService.updateUnidadActividad(this.idActividadUnidad,this.nombre_actividad_unidad);           
                let {   message,
                        color
                        } = response.data;
                this.toast('b-toaster-bottom-center', true, message, color );
                this.getUnidadActivities(this.selectedUnidadId); 
                this.nombre_actividad_unidad   ='';
                this.idActividadUnidad         = 0;
                this.editarActividadUnidad     =false;

            },
            ShowModalborrarActividadUnidad(idActividadUnidad){
                this.idActividadUnidad = idActividadUnidad;
                this.modalBorrarActividadUnidadConfirmacion = true;
            },
            ShowModalborrarPregunta(idActividadUnidad){
              this.idActividadReactivos = idActividadUnidad;
              this.modalBorrarReactivoConfirmacion = true;
            },
            ShowModalborrarRespuesta(idRespuesta){
              this.idRespuesta = idRespuesta;
              this.modalBorrarRespuestaConfirmacion = true;
            },
            async borrarActividadUnidad(){
                    const response = await ActividadService.deleteActividadUnidad(this.idActividadUnidad);
                    let {   message,
                            color
                        } = response.data;
                    this.toast('b-toaster-bottom-center', true, message, color );
                    this.idActividadUnidad = 0;
                    this.modalBorrarActividadUnidadConfirmacion = false;
                    this.getUnidadActivities(this.selectedUnidadId); 
            },

            async borrarReactivo(){
              const response = await PreguntaService.deletePregunta(this.idActividadReactivos);
              //this.wait()
              let {   message,
                color
              } = response.data;
              this.toast('b-toaster-bottom-center', true, message, color );
              this.modalBorrarReactivoConfirmacion = false;
              await this.getPreguntas(this.idActividad);
            },

            async borrarRespuesta(){
            this.wait()
              const response = await RespuestaService.deleteRespuesta(this.idRespuesta);
              let {   message,
                color
              } = response.data;
              this.toast('b-toaster-bottom-center', true, message, color );
              this.modalBorrarRespuestaConfirmacion = false;
              await this.getRespuestas(this.idPregunta);
            },



            async guardarRespuesta()
            {
              if( this.dataRespuesta.respuesta === '' || this.dataRespuesta.respuesta === null  || this.dataRespuesta.respuesta.length === 0 ){
                this.$refs.respuesta.$el.focus();
                this.toast('b-toaster-bottom-center', true, '¡Debe capturar la respuesta!', 'danger' );
                return false;
              }
              let formData = new FormData();
              let escorrecta = 0
              if(this.dataRespuesta.correcta===true){
                escorrecta = 1
              }
              formData.append("respuesta", this.dataRespuesta.respuesta);
              formData.append("reactivo_id",  this.idPregunta);
              formData.append("correcta",  escorrecta);
              this.wait()
              const response = await RespuestaService.saveRespuesta(formData);
              let {   success,
                message,
                color
              } = response.data;
              if(success){
                this.getRespuestas(this.idPregunta);
              }
              this.toast('b-toaster-bottom-center', true, message, color );
              this.dataRespuesta.respuesta = '';
              this.dataRespuesta.id=0;
              this.dataRespuesta.reactivo_id=null
              //this.dataRespuesta.correcta=[]
              this.checkSelected=false

            },




            async guardarPregunta()
            {
              if( this.reactivo === '' || this.reactivo === null  || this.reactivo.length === 0 ){
                this.$refs.reactivo.$el.focus();
                this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta!', 'danger' );
                return false;
              }
              let formData = new FormData();
              formData.append("reactivo", this.reactivo);
              formData.append("actividad_id",  this.idActividad);
              const response = await PreguntaService.savePregunta(formData);
              let {   success,
                message,
                color
              } = response.data;
              if(success){
                //console.log("guardado")
                this.getPreguntas(this.idActividad);
              }
              this.toast('b-toaster-bottom-center', true, message, color );
              this.reactivo = ' ';
            },
            async guardarUnidadActividad(){
                    if( this.nombre_actividad_unidad === '' || this.nombre_actividad_unidad === null  || this.nombre_actividad_unidad === 0 || !this.nombre_actividad_unidad){
                        this.$refs.nombre_actividad_unidad.$el.focus();
                        this.toast('b-toaster-bottom-center', true, 'Campo nombre es requerido!', 'danger' );
                        return false;
                    }
                    let formData = new FormData();
                        formData.append("nombre_actividad_unidad", this.nombre_actividad_unidad);
                        formData.append("unidad_id",  this.selectedUnidadId);                       
                    const response = await ActividadService.saveUnidadActividad(formData);            
                    let {   success,
                            message,
                            color
                            } = response.data;
                            if(success){
                                this.getUnidadActivities(this.selectedUnidadId);
                            }
                    this.toast('b-toaster-bottom-center', true, message, color );
                    this.nombre_actividad_unidad = ' '; 
            },
            async getUnidadActivities( itemId ){
                    const response = await ActividadService.getUnidadActivitidades(itemId); 
                    this.actividades_de_la_unidad = response.data;   
                    //console.log(this.actividades_de_la_unidad);
            },
            async getsheetResultadoEvaluacion( itemId ){
              this.resultados_evaluaciones=[]
              const response = await PreguntaService.getsheetResultadoEvaluacion(itemId);

              this.sheetResultadoEvaluaciones = response.data.urlSheet;

            },
            async getNombreActividad( itemId ){
              this.resultados_evaluaciones=[]
              this.nombreActividad=null
              const response = await PreguntaService.getNombreActividad(itemId);
              this.nombreActividad = response.data.nombreActividad;
            },
            async getResultados( itemId ){
              this.resultados_evaluaciones=[]
              this.wait()
              const response = await PreguntaService.getResultadosEvaluaciones(itemId);

              this.resultados_evaluaciones = response.data.resultadosQuiz;
              //console.log(this.resultados_evaluaciones)
              this.totalRows_resultaciones_evaluaciones = response.data.cantidad;
            },
            async getPreguntas( itemId ){
              this.preguntas_de_la_unidad=[]
              const response = await PreguntaService.getPreguntasActivities(itemId);

              this.preguntas_de_la_unidad = response.data.preguntas;
              this.totalRows_reactivos = response.data.cantidad;

            },

            async getRespuestas(itemId){
              const response               = await RespuestaService.getRespuestas(itemId);
              this.respuestas_de_la_unidad = response.data;
            },

            ShowModalActividades(itemId){
        
                this.selectedUnidadId = itemId;
                //this.idActividadReactivos = itemId
                this.getUnidadActivities(itemId);
                //console.log("unidad"+itemId)
                //this.$refs['unidad_actividades'].show();
                this.ModalAsignarActividades=true;
            },
            CerrarModalActividades(){
                //console.log('cerrar modal actividades');
                this.actividades_de_la_unidad=[];
                this.selectedUnidadId = 0;
                this.ModalAsignarActividades=false;
                //this.$refs['unidad_actividades'].hide();
            },
            validateForm(){
            let valid = true;
            if( this.form_unidad.nombre_unidad == '' || this.form_unidad.nombre_unidad == null  || this.form_unidad.nombre_unidad == 0 || !this.form_unidad.nombre_unidad){
                   this.toast('b-toaster-bottom-center', true, 'Campo nombre de la unidad es requerido', 'danger' );
                   this.$refs.nombre_unidad.$el.focus();
                   valid = false;
            }

            if( this.form_unidad.clave_interna == '' || this.form_unidad.clave_interna == null  || this.form_unidad.clave_interna == 0 || !this.form_unidad.clave_interna){
                    this.toast('b-toaster-bottom-center', true, 'Campo clave de la unidad es requerido', 'danger' );
                   this.$refs.clave_interna.$el.focus()
                  valid = false;
            }

/*            if( this.form_unidad.descripcion == '' || this.form_unidad.descripcion == null  || this.form_unidad.descripcion == 0 || !this.form_unidad.descripcion){
                    this.toast('b-toaster-bottom-center', true, 'Agregar una breve descripción de favor', 'danger' );
                   this.$refs.descripcion.$el.focus();
                   valid = false;
            }*/
            return valid;
            },
            cerrarActualizarModal(){
                this.resetFormCurso();
                this.modalActualizarConfirmacion = false;
                this.selectedUnidadId = 0;
            },
            showEditModal(item) {
                this.selectedUnidadId   = item.id;
                this.modalActualizarConfirmacion = true;
                this.form_unidad.nombre_unidad = item.nombre_unidad;
                this.form_unidad.clave_interna = item.clave_interna;
                this.form_unidad.descripcion = item.descripcion;
            },
            async actualizarUnidad(){
                    if( !this.validateForm() ){return false;}
                    const response = await UnidadServicio.updateUnidad(this.selectedUnidadId,this.form_unidad.nombre_unidad, this.form_unidad.descripcion );
                    let {   success,
                            message,
                            color
                            } = response.data;
                    if(success){
                        this.getUnidades();
                    }
                              
                    this.toast('b-toaster-bottom-center', true, message, color );
                    this.cerrarActualizarModal();
            },
            ShowmodalBorrarConfirmacion(item) {
                this.selectedUnidadId   = item.id;
                this.nombreBorrar       = item.nombre_unidad;
                this.modalBorrarConfirmacion=true;
            },
            async deleteUnidad(item) {
                let index       = this.itemsUnidades.indexOf(item);
                const response = await UnidadServicio.deleteUnidad(item);
                                
                let {   success,
                        message,
                        color
                        }   = response.data;
                    if(success){
                            this.itemsUnidades.splice(index, 1);
                            await this.getUnidades();
                    }
                    this.modalBorrarConfirmacion = false;
                    this.toast('b-toaster-bottom-center', true, message, color );
                    
            },
            resetFormCurso(){
               
                this.form_unidad = {
                    nombre_unidad: '',
                    clave_interna: '',
                    descripcion: '',
                }
                this.nombre_actividad_objetivo == ''
                this.selectedUnidadId=0;
            },
            async guardarUnidad(){
                
                    if( !this.validateForm() ){return false;}

                    let formData = new FormData();
                            formData.append("nombre_unidad",         this.form_unidad.nombre_unidad);
                            formData.append("clave_interna",        this.form_unidad.clave_interna);
                            formData.append("descripcion",          this.form_unidad.descripcion);
                    const response = await UnidadServicio.saveUnidad(formData );
                                     
                                let {   success,
                                        message,
                                        color
                                        } = response.data;
                                if(success){
                                    this.getUnidades();
                                }
                                this.toast('b-toaster-bottom-center', true, message, color );
                                
                                this.cerrarModal();
            },
            async getUnidades() {
                const response = await UnidadServicio.getUnidades( );
                let {   success,
                        message,
                        color,
                        unidades
                    }   = response.data;
                    if(success){
                        this.itemsUnidades        = unidades;      
                        this.totalRows            = unidades.length;
                    }
                    this.toast('b-toaster-bottom-center', true, message, color );
            },
            abrirModaNuevaUnidad(){
                this.$refs['nuevaUnidad'].show();
            },
            cerrarModal(){
                this.resetFormCurso();
                this.$refs['nuevaUnidad'].hide()
            },
},
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>