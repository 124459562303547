<template>
  <Quiz :idUnidad="376" ></Quiz>
</template>

<script>
import Quiz from "@/components/Quiz/Quiz.vue";
export default {
  components: {
    Quiz
  },
  data() {
    return {

    }
  },//data
  methods: {
  },//methods
}
</script>
