<template>
  <chat :id_evento="id_evento" :es_coach="true"></chat>
</template>

<script>
import Chat from "@/components/chat.vue";
export default {
  components : {Chat},
  name: "ChatView",
  data() {
    return {
      id_evento: 261
    }
  },
}
</script>

<style scoped>

</style>