<template>
  <b-container >

<!-- modal borrar sesion -->
    <b-modal
        v-model="ModalBorrarSesion"
        persistent
        title="Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar la sesión ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalBorrarConfirmacion()"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="eliminarSesion()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>

<!-- modal crear nuevo elemento -->
     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="ModalCrearSesion" 
                title="Creando nueva sesión"
                v-model="ModalCrearSesion"
                persistent>
            <b-card
                    tag="article"
                    class="mb-2">
                <b-alert show variant="primary" class="p-3 text-justify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                       A continuación especifique los siguientes campos
                </b-alert>
                <b-card-text>
                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>

                            <b-row class="d-flex align-items-start justify-content-start my-3">
                                <b-col>
                              <label for=""> <small> Oferta académica - Curricula</small></label>
                              <b-form-select 
                                    placeholder="Elegir "
                                    :selected="sesion_form.ofertaAcademicaCurricula"
                                     v-model="sesion_form.ofertaAcademicaCurricula"
                                    :options="ofertaAcademicaCurricula_items"
                                     @change="getCursosDisponibles($event)"
                            >
                                  <template #first>
                                    <b-form-select-option disabled value="0"> Elegir opción </b-form-select-option>  
                                  </template>
                            </b-form-select>   

     

                                </b-col>
                                 
                                  <!-- 
                                    <b-col>
                                <label for=""> <small>Curso</small></label>
                                  <b-form-select 
                                        placeholder="Elegir "
                                        :selected="sesion_form.curso"
                                        v-model="sesion_form.curso"
                                        :options="cursos_disponibles"
                                  >
                                  <template #first>
                                    <b-form-select-option disabled value="0"> Elegir opción </b-form-select-option>  
                                  </template>
                                </b-form-select>
                                </b-col>
                                -->
                                
                            </b-row>
                        <label for=""> <small> Cursos disponibles</small></label>
                            <v-select
                                class="my-3"
                                placeholder="Elegir"
                                v-model="sesion_form.curso"
                                :options='cursos_disponibles'
                            >
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                    No resultados para <em>{{ search }}</em
                                      >.
                                    </template>
                                    <em v-else style="opacity: 0.5">Elegir currícula primero.</em>
                                  </template>
                            </v-select>
                         
                         <b-form-input
                                ref="sesion_form.nombre_objetivo"
                                class="my-2"
                                v-model="sesion_form.nombre"
                                type="text"
                                placeholder="Ingresa el nombre de la sesión"          
                                >
                        </b-form-input>

                        <b-form-textarea
                                ref="sesion_form.descripcion"
                                v-model="sesion_form.descripcion"
                                placeholder="Descripción breve de esta sesión"
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>


                            <div v-if="sesion_form.editar && sesion_form.imagen != '#' " style="display: flex; justify-content: space-evenly; align-items:center" class="my-2">
                                <b-button  @click="fn_reemplazarImagen()" variant="outline-primary" class="btn-studioAPP" sm >Reemplazar imagen
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                                    <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                                    </svg>
                                </b-button>
                                <b-img rounded  :src="sesion_form.imagen" height="200" width="200" fluid></b-img>
                            </div>

                            <div v-else>
                                <b-form-file class="my-3"
                                    size="sm"
                                     v-model="sesion_form.imagenNueva"
                                    :state="Boolean(sesion_form.imagenNueva)"
                                    placeholder="Archivo adjunto..."
                                    accept="image/*"
                                    drop-placeholder="Arrastrar un archivo para adjuntar."
                                ></b-form-file>  
                            </div>

                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="w-100">
                                <b-button
                                      variant="primary"
                                      size="sm"
                                      class="float-right"
                                      @click="HideModalCrearSesion()"
                                  >Cancelar
                                </b-button>

                                  <b-button v-if="sesion_form.editar==true"
                                            variant="info"
                                            size="sm"
                                            class="float-right mx-2"
                                            @click="actualizarSesion()"
                                  >
                                    Actualizar registro
                                  </b-button>


                                <b-button v-if="sesion_form.editar==false"
                                    variant="success" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="guardar()"
                                >
                                Guardar
                                </b-button>                             
                            </div>
                        </b-row>
                

                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron lead="Sesiones Académicas"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              En esta sección se muestran todas los distintos grupos que están en el curso.
            </b-alert>
            <b-row style="display: flex; justify-content:flex-end ;" class="my-3 mx-3">
              <b-button @click="ShowModalCrearSesion()"
                        class="btn-studioAPP"
                        variant="outline-warning"> Crear nueva sesión
              </b-button>
            </b-row>

            <div class="col-lg-12 my-2">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Buscar"
              >
              </b-form-input>
            </div>
            <b-table class="table data-table"
                     :items="sesiones_items"
                     :fields="sesiones_fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <b-alert show variant="info">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</b-alert>
              </template>
              <template #emptyfiltered="scope">
                <b-alert show variant="warning">
                  {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                </b-alert>
              </template>

              <template #cell(detalles)="row">   
                      <b-button variant="primary" size="sm" @click="irDetallesSesion(row.item)" class="ml-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                              class="bi bi-trash-fill" viewBox="0 0 20 20">
                       <path d="M14.999,8.543c0,0.229-0.188,0.417-0.416,0.417H5.417C5.187,8.959,5,8.772,5,8.543s0.188-0.417,0.417-0.417h9.167C14.812,8.126,14.999,8.314,14.999,8.543 M12.037,10.213H5.417C5.187,10.213,5,10.4,5,10.63c0,0.229,0.188,0.416,0.417,0.416h6.621c0.229,0,0.416-0.188,0.416-0.416C12.453,10.4,12.266,10.213,12.037,10.213 M14.583,6.046H5.417C5.187,6.046,5,6.233,5,6.463c0,0.229,0.188,0.417,0.417,0.417h9.167c0.229,0,0.416-0.188,0.416-0.417C14.999,6.233,14.812,6.046,14.583,6.046 M17.916,3.542v10c0,0.229-0.188,0.417-0.417,0.417H9.373l-2.829,2.796c-0.117,0.116-0.71,0.297-0.71-0.296v-2.5H2.5c-0.229,0-0.417-0.188-0.417-0.417v-10c0-0.229,0.188-0.417,0.417-0.417h15C17.729,3.126,17.916,3.313,17.916,3.542 M17.083,3.959H2.917v9.167H6.25c0.229,0,0.417,0.187,0.417,0.416v1.919l2.242-2.215c0.079-0.077,0.184-0.12,0.294-0.12h7.881V3.959z"></path> </svg>
                      </b-button>                         
              </template>

              <template #cell(agregar_material)="row">   
                      <b-button variant="success" size="sm" @click="irAgregarMaterial(row.item)" class="ml-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                              class="bi bi-trash-fill" viewBox="0 0 20 20">
                        
                   <path d="M14.467,1.771H5.533c-0.258,0-0.47,0.211-0.47,0.47v15.516c0,0.414,0.504,0.634,0.802,0.331L10,13.955l4.136,4.133c0.241,0.241,0.802,0.169,0.802-0.331V2.241C14.938,1.982,14.726,1.771,14.467,1.771 M13.997,16.621l-3.665-3.662c-0.186-0.186-0.479-0.186-0.664,0l-3.666,3.662V2.711h7.994V16.621z"></path>
                        </svg>
                      </b-button>                         
              </template>

              <template #cell(eventos)="row">   
                      <b-button variant="warning" size="sm" @click="irEventos(row.item)" class="ml-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                              class="bi bi-trash-fill" viewBox="0 0 20 20">
                          <path d="M10.25,2.375c-4.212,0-7.625,3.413-7.625,7.625s3.413,7.625,7.625,7.625s7.625-3.413,7.625-7.625S14.462,2.375,10.25,2.375M10.651,16.811v-0.403c0-0.221-0.181-0.401-0.401-0.401s-0.401,0.181-0.401,0.401v0.403c-3.443-0.201-6.208-2.966-6.409-6.409h0.404c0.22,0,0.401-0.181,0.401-0.401S4.063,9.599,3.843,9.599H3.439C3.64,6.155,6.405,3.391,9.849,3.19v0.403c0,0.22,0.181,0.401,0.401,0.401s0.401-0.181,0.401-0.401V3.19c3.443,0.201,6.208,2.965,6.409,6.409h-0.404c-0.22,0-0.4,0.181-0.4,0.401s0.181,0.401,0.4,0.401h0.404C16.859,13.845,14.095,16.609,10.651,16.811 M12.662,12.412c-0.156,0.156-0.409,0.159-0.568,0l-2.127-2.129C9.986,10.302,9.849,10.192,9.849,10V5.184c0-0.221,0.181-0.401,0.401-0.401s0.401,0.181,0.401,0.401v4.651l2.011,2.008C12.818,12.001,12.818,12.256,12.662,12.412"></path>
                          </svg>
                      </b-button>                         
              </template>              

              <template #cell(actions)="row"> 

                  <div class="d-flex justify-content-around align-items-center">

                    <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                @click="EditMode(row.item)" class="mr-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-pencil-square" viewBox="0 0 20 20">
                        <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                    </b-button>
                    
                    <b-button variant="danger" size="sm" @click="showModalBorrarConfirmacion(row.item)" class="ml-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-trash-fill" viewBox="0 0 20 20">
                        <path
                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                        </svg>
                    </b-button>
                  </div>  
              </template>

                    
            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>

          </div><!--card-body p-2-->
        </div><!--card--->
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from '@bugsnag/js';
import SesionService from '@/services/SesionService';

export default {
  mixins:[toast],
  name: "Sesiones",
  data() {
    return {
            ModalBorrarSesion:false,
            sesion_form: {
                id                        : 0,
                ofertaAcademicaCurricula  : 0,
                curso                     : { label:'Elegir', id: 0 },
                nombre                    : '',
                descripcion               : '',
                imagen                    :null,  // src="http://url"
                imagenNueva               :null,  //input file
                reemplazarImagen          :false, //true-false
                editar                    :false,
            },
            cursos_disponibles:[],
            ofertaAcademicaCurricula_items:[],
            
            ModalCrearSesion:false,
            sesiones_fields: [
                {   label: 'Sesión',  key: 'nombre_sesion',        class: 'text-start' },
                {   label: 'Oferta Académica',  key: 'ofertascurriculas.oferta_academica.nombre_oferta_academica',        class: 'text-start' },
                { label: 'Currícula',            key: 'ofertascurriculas.curricula_curso.nombre_curricula' },
                { label: 'Curso',                key: 'curso_sesion.nombre_curso', },
                
                { label: 'Detalles', key: 'detalles',class: 'text-center'},
                { label: 'Agregar material', key: 'agregar_material',class: 'text-center'},
                { label: 'Eventos', key: 'eventos',class: 'text-center'},
                { label: '', key: 'actions',class: 'text-center'}
      ],
      sesiones_items: [],

      /////////////
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: ''
    }
  },

  mounted() {
    this.getSesiones();
    this.getOfertasCurriculas();
  },
  methods: {
    irEventos(item){
      if(item){
        this.$router.push({
              name: 'SesionEventos',
              params   : {
                          sesion   : item
                        }
          });   
      }
    },
    fn_reemplazarImagen(){
      //  this.sesion_form.imagenNueva = '#';
        this.sesion_form.imagen             = '#';
        this.sesion_form.reemplazarImagen   = true;

    },   
    EditMode(item){
            this.getCursosDisponibles(item.curricula_oferta_id);
            this.sesion_form = {
                    id                :item.id,
                    ofertaAcademicaCurricula: item.curricula_oferta_id,
                    curso             : { label: item.curso_sesion.nombre_curso, id: item.curso_sesion.id },
                    nombre            : item.nombre_sesion,
                    descripcion       : item.descripcion_sesion,
                    imagen            :item.imagen,
                    editar            :true,
                    reemplazarImagen   :false,
                    imagenNueva        :null,
                }
                this.ModalCrearSesion = true;
    },
    hideModalBorrarConfirmacion(){
            this.ModalBorrarSesion = false;
    },
    showModalBorrarConfirmacion(item){
      this.sesion_form.id = item.id;
      this.ModalBorrarSesion = true;
    },
    HideModalCrearSesion(){
        this.ModalCrearSesion=false;
        this.resetForm();
    },
    ShowModalCrearSesion(){
        this.ModalCrearSesion=true;
        
    },
    resetForm(){
                this.sesion_form = {
                    id:0,
                    ofertaAcademicaCurricula: 0,
                    curso             : { label:'Elegir', id: 0 },
                    nombre            : '',
                    descripcion       : '',
                    imagen            :null,
                    imagenNueva       :null,
                    reemplazarImagen  :false,
                    editar            :false
                }
    },
    async guardar(){
      try{
       
         let formData = new FormData();
             formData.append("ofertaAcademicaCurricula",       this.sesion_form.ofertaAcademicaCurricula);
             formData.append("curso",         this.sesion_form.curso.id);
             formData.append("nombre",         this.sesion_form.nombre);
             formData.append("descripcion",         this.sesion_form.descripcion);
             formData.append("imagen",         this.sesion_form.imagenNueva);
        const response = await SesionService.saveSesion(formData);
                let {   success,
                        message,
                        color
                        } = response.data;
                if(success){
                  this.resetForm();
                  await this.getSesiones();
                }
                this.toast('b-toaster-bottom-center', true, message, color )
                this.HideModalCrearSesion();
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: guardar() ' + error)
        );
      }
    },
    async actualizarSesion(){
      try{

        let formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("id",                         this.sesion_form.id);
            formData.append("ofertaAcademicaCurricula",   this.sesion_form.ofertaAcademicaCurricula);
            formData.append("curso",                      this.sesion_form.curso.id);
            formData.append("nombre",                     this.sesion_form.nombre);
            formData.append("descripcion",                this.sesion_form.descripcion);

            //image management--------------
            //  laravel lo recibe como imagen             imagen en form input file
            formData.append("imagen"                    , this.sesion_form.imagenNueva);

/*
            if(this.sesion_form.reemplazarImagen === false && this.sesion_form.imagenNueva !== null ){
              console.log('se inserta una nueva imagen en el registro que no tenía');
            }
            if(this.sesion_form.reemplazarImagen === false && this.sesion_form.imagenNueva === null ){
                console.log('se queda la img como esta');
            }
            if(this.sesion_form.reemplazarImagen === true && this.sesion_form.imagenNueva === null ){
              console.log('se elimina la imagen actual');
            }
            if(this.sesion_form.reemplazarImagen === true && this.sesion_form.imagenNueva  !== null ){
              console.log('se reemplaza por la imagen actual');
            }
*/
            //true si se va a reemplazar false si no    this.form_curso.reemplazarImagen
            formData.append("reemplazarImagen"          , this.sesion_form.reemplazarImagen);
            /// //URL DE LA IMAGEN ACTUAL
            formData.append("url_image"                 , this.sesion_form.imagen);
            //image management--------------
          const response = await SesionService.updateSesion( this.sesion_form.id, formData);

        let {   success,
                message,
                color
        } = response.data;

        console.log(response.data);

        if(success){
          this.toast('b-toaster-bottom-center', true, message, color );
          await this.getSesiones();
        }
        this.HideModalCrearSesion();

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: actualizarSesion() ' + error.message)
        );
      }


    },
    async getOfertasCurriculas(){
      try{
      let response = await SesionService.getOfertasCurriculas();
        let {   resultSet,
              } = response.data;
          this.ofertaAcademicaCurricula_items  = resultSet;
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getOfertasCurriculas() ' + error)
        );
      }

    },
    async getSesiones(){

      try {
        let response = await SesionService.getSesiones();
        let {   message,
                color,
                resultSet,
        } = response.data;
        this.sesiones_items = resultSet;
        this.toast('b-toaster-bottom-center', true, message, color );
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getSesiones() ' + error)
        );
      }
    },
    async getCursosDisponibles(curricula_id) {    
          console.log(curricula_id);
            this.cursos_disponibles=[];
            try {
              let response = await SesionService.getCursosDisponibles(curricula_id);
              let {   message,
                      color,
                      resultSet,
                    } = response.data;
      
        this.cursos_disponibles=resultSet;
       // console.log(this.cursos_disponibles);
        this.toast('b-toaster-bottom-center', true, message, color );
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getCursos() ' + error)
        );
      }
      
    },
    async eliminarSesion(){
      try {
        const response   = await SesionService.deleteSesion(this.sesion_form.id);
        let {   success,
                message,
                color
              } = response.data;
        if(success){
          this.hideModalBorrarConfirmacion();
          await this.getSesiones();
        }
        this.toast('b-toaster-bottom-center', true, message, color );

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
        Bugsnag.notify(
            new Error('Método: eliminarSesion() ' + error)
        );
      }
    }
  },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>