import api from '@/services/api';

export default{
    updateEntregable(activityId, tipoEntregable ){
    
        let url                   = `/api/actividades/update/entregable/${activityId}/${tipoEntregable}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }     
        });
        return response;
        
    },
    updateItemActivity(idItem,descripcion_actividad){
        let url = `/api/actividad/items/update/${idItem}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                descripcion_actividad : descripcion_actividad,
            }          
        });
        return response;
    }
    ,deleteItemActivity(id){
        let url = `/api/actividad/items/destroy/${id}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
 
        return response;
    },
    saveItemActividad(formData){
        let url          = `/api/actividad/items/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData 
        });
 
        return response;
    },
    getItemsEnActividad(idActividad){
        let url        = `/api/actividad/items/show/${idActividad}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    updateUnidadActividad(idActividadUnidad,nombre_actividad_unidad){
        let url          = `/api/actividades/actividad-unidad/update/${idActividadUnidad}`; 
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                nombre_actividad_unidad : nombre_actividad_unidad,
            }          
             
        });
        return response;
    },
    updateUnidadActividadEntregableOpcional(idActividadUnidad,entregable_opcional){
        let url          = `/api/entregablesOpcional/update/${idActividadUnidad}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                entregable_opcional : entregable_opcional,
            }

        });
        return response;
    },
    deleteActividadUnidad(idActividadUnidad){
                
        let url          = `/api/actividades/actividad-unidad/destroy/${idActividadUnidad}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    saveUnidadActividad(formData){
        let url          = `/api/actividades/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData 
        });
        return response;
    },
    getUnidadActivitidades(itemId){
        let url          = `/api/actividades/actividad-unidad/all-actividades/${itemId}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    }

}