<template>
  <b-container>


<!-- modal rubrica eleccion -->
    <b-modal
        v-model="modal"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>


            <b-row class="my-5">    
             <GDriveSelector v-on:handlePickerFiles="getData" />
            </b-row>

          </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalRubrica()">
                        Cancelar
                    </b-button>                              
            </div>
            </template>
            
        </b-modal>


    <div>picker google</div>

            <b-button variant="warning" v-b-modal.modal-prevent-closing size="sm"
                                                        @click="showModalRubrica()" class="mr-1">
                                                    MODAL 
            </b-button>
<hr>
      <div v-for="(item, i) in arr" :key=i >
      {{arr[i] }}
      <GDriveSelector v-on:handlePickerFiles="getData" />
      </div>

  <hr>
  <GDriveSelector v-on:handlePickerFiles="getData" />
  
  
    <div    @click="switchUnidad()"
                    class="my-2 mr-2 p-4"
                    style="background-color:red" 
                  >
                     <GDriveSelector v-on:handlePickerFiles="getData" />
      </div>
  
  
  
  </b-container>
</template>

<script>

import GDriveSelector from "../../components/GDriveSelector";

export default {
  name:"GooglePicker",
  components:{
    GDriveSelector
  },
  data() {
    return {
      modal:false,
       arr : ["Gato", "Perro", "Pájaro", "Dinosaurio"],

    }
  },
  methods: {
    switchUnidad(){
      console.log('method switchUnidad clicked')
    },
          hideModalRubrica(){
          this.modal=false;
        },
        showModalRubrica(){
            
            this.modal=true;
        },
    getData( data ) {
        console.log('method getData fired!!');
      console.log(data);
     /* console.log(data.docs[0].id);
      console.log(data.docs[0].name);
      console.log(data.docs[0].url);
     */
    }
  },
}
</script>

<style>

</style>