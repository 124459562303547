<template>
  <b-container>
    <b-modal
        v-model="modalShowResultado"
        persistent
        no-close-on-esc
        hide-header-close
        title="Resultado obtenido."
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            <div>
<!--              <b-jumbotron header="Tu calificación es:" lead="">-->
                <div v-if="calificacionExamen>=60" >
                  <h4 style="text-align: center">Tu calificación es:</h4><br>
                  <h2 style="text-align: center">
                    {{calificacionExamen}}
                  </h2>
                </div>
                <div v-else >
                  <h4 style="text-align: center">Tu calificación es:</h4><br>
                  <h2 style="text-align: center">
                    {{calificacionExamen}}
                  </h2>
                </div>
<!--              </b-jumbotron>-->
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="success"
              size="sm"
              class="float-right mx-2"
              @click="closeModalShowResultado()"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>


    <!-- modal Esperar Resultado -->
    <b-modal
        v-model="modalWaitResultado"
        persistent
        no-close-on-esc
        hide-header-close
        hide-footer
        title="Favor de esperar un momento"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            Evaluando, espera tu resultado
            <div class="text-center">
              <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
            </div>
          </b-col>
        </b-row>
      </b-container>

    </b-modal>
    <!--FIN modal Mensaje Procesando información de examen...-->



    <!-- modal confirmar finalizar evaluacion -->
    <b-modal
        v-model="activarModalMsjProcesando"
        persistent
        no-close-on-esc
        hide-header-close
        hide-footer
        title="Favor de esperar un momento"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            Preparando información de examen
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!--FIN modal Mensaje Procesando información de examen...-->

    <!-- modal para iniciar examen  02-12-22-->
    <b-modal
        v-model="activarModalExamen"
        persistent
        no-close-on-esc
        hide-header-close
        hide-footer
        title=" Confirmación"
        size="lg"
        centered
    >
    <b-container fluid>
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
<!--            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Quiz"></b-jumbotron>
            </div>-->
            <b-alert
                class="p-1"
                show
                variant="info"
                dismissible
            >Lee cuidadosamente y selecciona la respuesta correcta, una vez finalizada la Evaluación, pulsar el botón Finalizar
            </b-alert>

            <b-form>

              <div v-for="input in preguntas_de_la_unidad" :key="input.id">
                <div class="mx-3 mt-3 p-2 criterio" >
                  <b-jumbotron :lead=input.pregunta></b-jumbotron>
                  <b-form-radio-group >
                    <b-row>
                      <!--<div v-for="(input,k) in preguntas_de_la_unidad" :key="k">-->
                      <!--                    <b-col sm="12"  v-for="(answer,j) in input.respuestas" >-->

                      <b-col sm="12"  v-for="descripcion in input.respuestas" :key="descripcion.id">
                        <b-card style="border:1px #dedede solid;  border-radius: 15px;" >
                          <b-card-text>
                            <b-form-radio
                                :id="'respuesta-' + descripcion.id"
                                :value="descripcion.id"
                                @change="saveResponses(descripcion.reactivo_id, descripcion.id)"
                                required
                            >{{ descripcion.respuesta }}
                            </b-form-radio>
                          </b-card-text>
                        </b-card>
                      </b-col>

                    </b-row>
                  </b-form-radio-group>
                </div>
              </div>
              <b-row  style="display: flex; justify-content: space-around; align-items:center;" class="my-3 mx-3">

                <b-button @click="cancelarExamen()"
                          class="btn-studioAPP"
                          variant="outline-warning">Cancelar
                </b-button>
                <b-button @click="finalizarexamen()"
                          class="btn-studioAPP"
                          variant="outline-warning"> Finalizar Evaluación
                </b-button>
              </b-row>
            </b-form>
          </div><!--card-body-->
        </div><!--card-->
      </b-col>
    </b-row>
    </b-container>
    </b-modal>
    <!-- fin de modal para examen-->


    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <!--            <div class="d-flex justify-content-between align-items-start p-2">
                          <b-jumbotron lead="Quiz"></b-jumbotron>
                        </div>-->
            <b-alert
                class="p-1"
                show
                variant="info"
                dismissible
            >Lee cuidadosamente y selecciona la respuesta correcta, una vez finalizada la Evaluación, pulsar el botón Finalizar
            </b-alert>

            <b-form>

              <div v-for="input in preguntas_de_la_unidad" :key="input.id">
                <div class="mx-3 mt-3 p-2 criterio" >
                  <b-jumbotron :lead=input.pregunta></b-jumbotron>
                  <b-form-radio-group >
                    <b-row>
                      <!--<div v-for="(input,k) in preguntas_de_la_unidad" :key="k">-->
                      <!--                    <b-col sm="12"  v-for="(answer,j) in input.respuestas" >-->

                      <b-col sm="12"  v-for="descripcion in input.respuestas" :key="descripcion.id">
                        <b-card style="border:1px #dedede solid;  border-radius: 15px;" >
                          <b-card-text>
                            <b-form-radio
                                :id="'respuesta-' + descripcion.id"
                                :value="descripcion.id"
                                @change="saveResponses(descripcion.reactivo_id, descripcion.id)"
                                required
                            >{{ descripcion.respuesta }}
                            </b-form-radio>
                          </b-card-text>
                        </b-card>
                      </b-col>

                    </b-row>
                  </b-form-radio-group>
                </div>
              </div>
              <b-row  style="display: flex; justify-content: space-around; align-items:center;" class="my-3 mx-3">

                <b-button @click="cancelarExamen()"
                          class="btn-studioAPP"
                          variant="outline-warning">Cancelar
                </b-button>
                <b-button @click="finalizarexamen()"
                          class="btn-studioAPP"
                          variant="outline-warning"> Finalizar Evaluación
                </b-button>
              </b-row>
            </b-form>
          </div><!--card-body-->
        </div><!--card-->
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import PreguntaService from '@/services/PreguntaService';
import toast from "@/mixins/ToastMixin.js";
import RespuestaService from '@/services/RespuestaService';
import EntregableService from '@/services/EntregableService';
export default {
  mixins:[toast],
  props:
      {
        //email_logueado: String,
        idUnidad: Number,
        modulo:Number,

        curricula:Number,
        curso:Number,
        c_unidad:Number,
        objetivo:Number,
        actividad:Number,
        material:Number,
        quiz:Number

      },
  data() {
    return {
      calificacionExamen:null,
      modalWaitResultado:false,
      modalShowResultado:false,
      activarModalExamen:false,
      activarModalMsjProcesando:false,
      modalBorrarItemConfirmacion:false,
      presentarExam:false,
      preguntas_de_la_unidad:[],
      respuestas_de_la_unidad:[],
      nombreRubrica:null,
      inputs: [
        { tituloCriterio: '', desCriterio:'' , answers: [] }
      ],
      unidad:{
        id:(this.$route.params.id),
        actividad:{
          id:122,
        }
      }
    }
  },//data

  mounted () {
    //
    // console.log("Entrando al QUIZ... y la unidad ID")
    // console.log(this.idUnidad)

    this.logLiveEvents(this.modulo,19);
    this.getVerificarPresentarExam(this.idUnidad);
    //cargar la preguntas

  },
  computed: {
    fillRespuestas (idPregunta) {
      this.getRespuestas(idPregunta)
      return this.respuestas_de_la_unidad
    },
  },
  methods: {
/*    async getVerificarPresentarExamen(actividadId) {
      this.presentarExam = true
      const response = await EntregableService.getVerificarPresentarExam(actividadId);

    },*/

    //getVerificarPresentarExam
    async getVerificarPresentarExam(itemId){
      this.activarModalMsjProcesando=true
      const response = await EntregableService.getVerificarPresentarExam(itemId);
      this.activarModalMsjProcesando=false
      this.presentarExam = response.data.presentarExam;
      this.calificacionExamen = response.data.calificacion;
      if(this.calificacionExamen==-1){
        //no ha presentado examen
        this.getPreguntas(this.idUnidad)
      }
      else{
        //ya presentó examen el usuario es necesario mostrarle su calificacion
        this.activarModalExamen=false
        this.modalShowResultado=true
      }

    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
     },
    async logLiveEvents(modulo, accion) {
      await this.$api.get(`/api/log/${modulo}/${accion}/${this.curricula}/${this.curso}/${this.c_unidad}/${this.objetivo}/${this.idUnidad}/${this.material}/${this.quiz}`);
    },
    cancelarExamen(){
      this.logLiveEvents(this.modulo,27);
      this.$emit("modalQuiz", false);
    },
    finalizarexamen(){
      //mandara calificar el examen
      //enviar mensaje en pantalla: "Favor de esperar...calificando examen

      //mostrar calificacion en modal


      this.logLiveEvents(this.modulo,20);
      this.calificarExamenUser();
      //this.$emit("modalQuiz", false);
    },
    async calificarExamenUser(){
      let formData = new FormData();
      formData.append("actividad_id",  this.idUnidad);
      this.modalWaitResultado=true
      const response = await PreguntaService.checkQuiz(formData);
            let {
              success,
              calificacionFinal
            } = response.data;
      if(success){
        this.modalWaitResultado=false
        this.modalShowResultado=true
        this.calificacionExamen = calificacionFinal
      }

    },
    closeModalShowResultado(){
      this.modalShowResultado=false
      this.$emit("modalQuiz", false);
    },






    async saveResponses(idPregunta,idRespuesta){
      let formData = new FormData();
      formData.append("pregunta_id", idPregunta);
      formData.append("respuesta_id",  idRespuesta);
      formData.append("unidad_id",  this.c_unidad);
      formData.append("actividad_id",  this.idUnidad);

      //const response = await PreguntaService.saveResponses(formData);
      await PreguntaService.saveResponses(formData);
      /*      let {
              success
            } = response.data;*/
      // if(success){
      //   this.getPreguntas(this.idActividad);
      // }
    },
    async getRespuestas(itemId){
      const response               = await RespuestaService.getRespuestas(itemId);
      this.respuestas_de_la_unidad = response.data;
    },
    async getPreguntas( itemId ){
/*      console.log("itemId")
      console.log(itemId)*/
      this.wait()
      //this.activarModalMsjProcesando=true
      this.preguntas_de_la_unidad=[]
      const response = await PreguntaService.getAllPreguntasRespuestas(itemId);
      //this.activarModalMsjProcesando=false
      //this.activarModalExamen=true
      this.preguntas_de_la_unidad = response.data;

    },
    volver(){
      this.$router.push({
        path:`/dashboardUnidades`
      });
    },

    isValid(){

      if( (this.nombreRubrica === "" || !this.nombreRubrica || this.nombreRubrica==null) )
      {
        this.toast('b-toaster-bottom-center', true,  'Hace falta especificar un nombre para esta rúbrica', 'danger' );
        return false;
      }

      for (let index = 0; index <  this.inputs.length; index++) {
        if( (this.inputs[index].tituloCriterio === "" || !this.inputs[index].tituloCriterio) )
        {
          this.toast('b-toaster-bottom-center', true,  'El título del criterio NO DEBE estar vacío', 'danger' );
          return
        }

        if( (this.inputs[index].desCriterio === "" || !this.inputs[index].desCriterio) )
        {
          this.toast('b-toaster-bottom-center', true,  'Hace falta la descripción en un criterio', 'danger' );
          return
        }



        for (let j = 0; j <  this.inputs[index].answers.length; j++)
        {
          if( this.inputs[index].answers[j].puntosNivel === "" || !this.inputs[index].answers[j].puntosNivel )
          {
            this.toast('b-toaster-bottom-center', true,  'Hace falta definir el puntaje en un nivel de un criterio', 'danger' );
            return
          }

          if( this.inputs[index].answers[j].tituloNivel === "" || !this.inputs[index].answers[j].tituloNivel )
          {
            this.toast('b-toaster-bottom-center', true,  'Hace falta definir el título del nivel de un criterio', 'danger' );
            return
          }

          if( this.inputs[index].answers[j].descripcionNivel === "" || !this.inputs[index].answers[j].descripcionNivel )
          {
            this.toast('b-toaster-bottom-center', true,  'Hace falta agregar una descripción del nivel de un criterio', 'danger' );
            return
          }

        }

      }
    },

    remove2(respuesta,pregunta) {
      this.inputs[pregunta].answers.splice(respuesta,1);
    },

    add() {
      this.inputs.push(  {    tituloCriterio: '',
            desCriterio:'',
            answers: [ {
              puntosNivel:'',
              tituloNivel:'',
              descripcionNivel:'',
            }
            ]
          }
      );

    },//add

    add2(index2) {
      this.inputs[index2].answers.push( {  puntosNivel: '', tituloNivel:'', descripcionNivel:'' } );
    },

    remove(index) {
      this.inputs.splice(index, 1);
    },
  },//methods

}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.criterio{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border:2px #dedede solid;
  border-radius: 15px;
}

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>