<template>
  <b-container>
    <b-row>
      <b-col cols="12">

        <h1>test videojs</h1>

        <b-row>
          <b-col cols="12">
            <video
                ref="videoPlayer"
                class="video-js vjs-default-skin"
                preload="auto"
                width="840"
                height="560"
                controls
                @contextmenu.prevent
            >
              <source :src="url" type="video/mp4" />
            </video>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-progress class="custom-progress-bar"  variant="warning btn-studioAPP-bar" style="width: 840px" :value="currentTime" :max="duration" animated></b-progress>
          <div style="">
            <small>  Tiempo  <span>{{ formatTime(currentTime) }} </span>minutos de  <b>{{formatTime(videoDuration)}}</b> minutos</small>
          </div>
          </b-col>

        </b-row>


        <div class="my-2">
          <b-button @click="stop()" v-if="corriendo==true" variant="primary" size="sm" class="ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stop-fill" viewBox="0 0 16 16">
              <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z"/>
            </svg>
          </b-button>

          <b-button @click="fullScreen()"  variant="primary" size="sm" class="ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>
            </svg>
          </b-button>

          <b-button @click="exitfullScreen()"  variant="primary" size="sm" class= "button-overlay ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
              <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
            </svg>
          </b-button>
          <b-button @click="atrasar()" variant="success" size="sm" class="ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-rewind-fill" viewBox="0 0 16 16">
              <path d="M8.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L8.404 7.304Z"/>
              <path d="M.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L.404 7.304Z"/>
            </svg>
          </b-button>

          <b-button @click="pause()" v-if="corriendo==true" variant="secondary" size="sm" class="ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16">
              <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
            </svg>
          </b-button>

          <b-button @click="playVideo()" v-if="corriendo==false" variant="danger" size="sm" class="ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
              <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
            </svg>
          </b-button>
          <b-button @click="adelantar()" variant="success" size="sm" class="ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-fast-forward-fill" viewBox="0 0 16 16">
              <path d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z"/>
              <path d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z"/>
            </svg>
          </b-button>


          <b-button @click="mutear()" v-if="muteado===false" variant="primary" size="sm" class="ml-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-mute-fill" viewBox="0 0 16 16">
              <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>
          <b-button @click="desMutear()" v-if="muteado===true" variant="dark" size="sm" class="ml-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-mute-fill" viewBox="0 0 16 16">
              <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>

          <b-button @click="bajarVol()" variant="primary" size="sm" class="ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-down-fill" viewBox="0 0 16 16">
              <path d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zm3.025 4a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z"/>
            </svg>
          </b-button>
          <b-button @click="subirVol()" variant="primary" size="sm" class="ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
              <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
              <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
              <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
            </svg>
          </b-button>
        </div>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
export default {
  name: "VideojsTest",
  computed: {
    value() {
      return (this.currentTime / this.duration) * 100;
    }
  },
  data() {
    return {
      currentTime: 0,
      duration: 0,
      videoDuration:0,
      muteado:false,
      corriendo:false,
      player: null,
      url:'https://storage.googleapis.com/aprende_videos/curso_sample.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=nivelandoaprenderun%40estudioapp2.iam.gserviceaccount.com%2F20230713%2Fauto%2Fstorage%2Fgo\n' +
          'og4_request&X-Goog-Date=20230713T184509Z&X-Goog-Expires=3600&X-Goog-SignedHeaders=host&X-Goog-Signature=66867368e66a40f77f2937508d64f3ab9fac604fb9cb22cefade71f28da8a3ef13009edded8f3916287811975ff92a97bb9d3\n' +
          '83c05662c5f9d1e1167f5b5d032374cd8d686d279b0001ab9767c5b6ae7c8af68260f151f555c71cec9ac2137c3f0312dbe955fc18acfd4e7adc8c6d20e24258ecfe7d2b2141c745574c76dfe652d09ea572ce2b846650b628b9fb9b10239f8ee0ce5841b587e\n' +
          '3443f5698a013afa01322aa6514c57009a927cd664fd3794fa309d87ea66980236dd120bc71d2aa2f1499919c2052d87991e9d0f90aeff358de803bd2a2c9591d888e7dd06feb775e968d4d575ee184138df1599f081fe893b90507807b7fed2bf1fd3b1551c2\n' +
          '5\n'
    };
  },
  mounted() {
    /*const options = {
      controls: false, // Disable the default controls
      controlBar: {
        seekBar: false, // Show the seek bar/navigation bar
        progressControl: true, // Hide the progress bar
        playToggle: false, // Hide the play/pause button
        remainingTimeDisplay: false, // Hide the remaining time display
        fullscreenToggle: false, // Hide the fullscreen button
        volumePanel:false,
        pictureInPictureToggle: false,
        currentTimeDisplay:false,
        durationDisplay: false,
        timeDivider: false,
        liveDisplay: false,
        playbackRateMenuButton: false,

      },
    };*/
    //this.player = videojs(this.$refs.videoPlayer, {}, function onPlayerReady() {
     console.log('-----');
      this.player = videojs(this.$refs.videoPlayer);
      this.player.on('timeupdate', () => {
        this.currentTime = this.player.currentTime();
      });
      this.player.on('loadedmetadata', () => {
        this.duration = this.player.duration();
      });

    this.$refs.videoPlayer.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });

   // });

  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    formatTime(time) {
      // Format the time in hh:mm:ss or mm:ss format
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },

    fullScreen(){
      this.player.requestFullscreen();
    },
    exitfullScreen(){
      this.player.exitFullscreen();
    },
    stop(){
      this.player.pause();
      this.player.currentTime(0);
      this.corriendo=false;
    },
    playVideo() {

      this.player.play()
      this.corriendo=true;

      this.videoDuration = this.player.el().querySelector('video').duration;
      console.log(this.videoDuration);

    },
    pause() {
      this.corriendo=false;
      this.player.pause();
    },
    atrasar(){
      this.player.currentTime(this.player.currentTime() - 30); // Delays playback by 30 seconds
    },
    adelantar(){
      this.player.currentTime(this.player.currentTime() + 30); // Skips forward by 30 seconds
    },
    bajarVol(){
      this.player.volume(this.player.volume() + 0.1); // Increases the volume by 0.1
      if(this.player.volume()==0){
        this.muteado=true;
      }
    },
    subirVol(){
      this.player.volume(this.player.volume() - 0.1); // Decreases the volume by 0.1
      this.muteado=false;
    },
    mutear(){
      this.muteado=true;
      this.player.muted(true);
    },
    desMutear(){
      this.muteado=false;
      this.player.muted(false);
    }

  },
}
</script>

<style lang="scss" scoped>
$studioAPPColor:#952f81;

.btn-studioAPP{
  color: white !important;
  border:1px solid $studioAPPColor;
  background-color: $studioAPPColor !important;
}

.btn-studioAPP, .btn-studioAPP:hover, .btn-studioAPP2:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

.button-overlay {
  position: absolute;
  top: 120px; /* Adjust the top position as needed */
  right: 120px; /* Adjust the right position as needed */
  z-index: 999; /* Ensure the button appears above the video */
}

/* CSS */
.vjs-control-bar .vjs-big-play-button {
  display: none !important;
}

.custom-progress-bar {
  background-color: #952f81;
  border-color: #952f81;
}

</style>