<template>
    <b-container fluid="lg">

<!-- MODAL PARA EVALUACION-->
      <b-modal
          v-model="modalRubrica"
          persistent
          title="Quiz"
          size="lg"
          centered
      >
        <b-container fluid>
          <b-row class="m-1 text-left">
            <b-col cols="12">
              Quiz de Actividad
            </b-col>
          </b-row>
          <b-row class="my-5" style="display: flex; justify-content: space-around; ">
            <!--<Rubrica :rubricaId='this.rubrica_id'/>-->
          </b-row>

        </b-container>
        <template #modal-footer>
          <div class="w-100">
            <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="hideModalRubrica()">
              Cancelar
            </b-button>
          </div>
        </template>

      </b-modal>
<!-- FIN MODAL PARA EVALUACION-->

<!-- modal entregable  confirmacion -->
    <b-modal
        v-model="modalEntrega"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
        <b-container fluid>
            <b-row class="m-1 text-left">
                    <b-col cols="12">
                        ¿ Estás seguro de realizar la entrega del siguiente archivo desde tu drive ?
                    </b-col>
            </b-row>
            <b-row class="m-1 text-left">
                    <b-col cols="12">
                      <hr>
                        <b-card-text>
                         Nombre: <b-img :src="file.iconUrl"></b-img>  {{file.name}}
                        <br>
                        Tipo: {{file.type}}
                        </b-card-text>

                    </b-col>
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">

                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalConfirmacionEntrega()">
                        Cancelar
                    </b-button>

                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right mx-2"
                        @click="hacerEntrega()">
                        ¡Hacer entrega!
                    </b-button>

            </div>
            </template>

        </b-modal>

<!-- modal rubrica eleccion -->
    <b-modal
        v-model="modalRubrica"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
            <b-row class="m-1 text-left">
                <b-col cols="12">
                   Criterios de evaluación
                </b-col>
            </b-row>
            <b-row class="my-5" style="display: flex; justify-content: space-around; ">
                <Rubrica :rubricaId='this.rubrica_id'/>
            </b-row>

          </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalRubrica()">
                        Cancelar
                    </b-button>
            </div>
            </template>

        </b-modal>


      <!-- modal esperar a mostrar calificacion -->
      <b-modal
          v-model="modalQuiz"
          persistent
          title="Evaluación"
          size="lg"
          centered
          hide-footer
          no-close-on-esc
          hide-header-close
      >
        <!--        hide-footer="true"-->
        <b-container fluid>
          <b-row class="my-5" style="display: flex; justify-content: space-around; ">
            <Quiz :idUnidad   = "actividadIdQuiz"
                  :modulo     = "10"

                  :curricula  = "this.current_curso_curricula.id"
                  :curso      = "this.currentCurso.id"
                  :c_unidad     = "this.unidad"
                  :objetivo   = "this.objetivo"

                  :actividad  = "this.actividad"
                  :material   = "this.material"
                  :quiz       = "this.quiz"

                  @modalQuiz="modalQuiz = $event"></Quiz>
          </b-row>
        </b-container>
        <template #modal-footer>
        </template>
      </b-modal>



      <!-- menu curricula>curso-->
    <b-row style="display: flex; justify-content: flex-start;">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/crear-contenido-curricula" class="">Currículas</router-link></li>
              <li class="breadcrumb-item">  <b-link @click="irCursosCurricula()">Cursos</b-link></li>
            </ol>
        </nav>
    </b-row>

      <!--menu navegación entre cursos de hasta arriba-->
<b-row class="mb-5" style="display: flex; justify-content: space-around; align-items:center; background-color:#952f81; border-radius: 10px; ">

    <div v-if="this.antCurso != null">
        <b-button @click="switchCursoPrev" variant="link">
            <span style="color:white"> <b> Curso autogestivo anterior</b>  </span><br>
              <small> <i style="color:white"> {{  (this.antCurso.nombre_curso) ? (this.antCurso.nombre_curso) : '' }} </i> </small>
        </b-button>
    </div>
    <div v-else>
        <b-button variant="link">
            <span style="color:white"> <b> Curso autogestivo  anterior</b>  </span><br>
              <small> <i style="color:white"> No hay </i> </small>
        </b-button>
    </div>
    <div>
        <b-button variant="link"> <span style="color:white"> <b> Curso autogestivo actual </b>  </span> <br>  <small><i style="color:white">{{ this.currentCurso.name }} </i> </small></b-button>
    </div>
    <div v-if="this.sigCurso != null">
        <b-button @click="switchCursoNext" variant="link">
           <span style="color:white"> <b> Curso autogestivo  siguiente  </b>  </span><br>
            <small> <i style="color:white"> {{  (this.sigCurso.nombre_curso) ? (this.sigCurso.nombre_curso) : '' }} </i> </small>
        </b-button>
    </div>
    <div v-else>
        <b-button variant="link">
           <span style="color:white"> <b> Curso autogestivo  siguiente  </b>  </span><br>
            <small> <i style="color:white"> No hay </i> </small>
        </b-button>
    </div>
</b-row>

      <!-- switch ver unidades y ver Dudas-->
<b-row class="my-3" style="display: flex; justify-content: space-between;">
     <b-form-checkbox class="ml-3" @change="someHandler" v-model="checkUnidades" name="check-button" switch>Ver unidades</b-form-checkbox>

<!--    <button @click="colapsarAcordion">
      colapsar activo {{this.index_unidad_curso.toString()}}
    </button>-->

    <b-form-checkbox class="mr-3"
        @change="someHandlerDudas" v-model="checkDudas" name="check-button" switch>
        Dudas
    </b-form-checkbox>
</b-row>

<b-row no-gutters>
    <!--- curriculas menú izquierdo no visible ni funcional-->
    <b-col cols="12" lg="2" md="2" sm="12" style="display:none">
        <div class="boxScroll">

            <div v-for="(curricula_navegacion, index) in curriculas_navegacion" :key="index">
                <div class="mb-4 mr-2">
                    <small><i>Currícula:</i></small>
                    <b-jumbotron class="text-left" :lead="curricula_navegacion.nombre_curricula"></b-jumbotron>
                    <b-list-group>
                        <div v-if="curricula_navegacion.cursos_curricula.length>0">
                            <small><i>Cursos:</i></small>
                            <div v-for="(curso, i) in curricula_navegacion['cursos_curricula'] " :key="i">
                                <div v-for="(test, k) in  curso.curso_curricula " :key="k">
                                    <div v-if=" k=='nombre_curso' ">
                                        <div v-if="curso.curso_id == currentCursoId">
                                            <b-list-group-item active >  {{test}} </b-list-group-item>
                                        </div >
                                        <div v-else>
                                        <b-list-group-item>  {{test}} </b-list-group-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <small><i>De momento, esta currícula no tiene cursos</i></small>
                        </div>
                    </b-list-group>
                </div>
            </div>
        </div><!-- box scroll -->
    </b-col>

    <!--main left section-->
    <b-col cols="12" lg="3" md="3" sm="12" class="collapse width" :class=" collaps ? 'show' : '' ">

        <div class="boxScroll">
            Progreso del curso:
            <b-progress :value="porcentaje_avance" max="100" variant="warning" class=" mr-2"></b-progress>
            <hr>
            <span style="display:none">
                {{unidadVisible}}
            </span>

            <div class="my-2 mr-2"
                  v-for="(unidad_curso,index_unidad_curso) in current_curso_curricula.unidades_curso"  :key="index_unidad_curso" >

                    <b-button
                        style="width:100%"
                        class="btn-studioAPP collapsed"
                        variant="outline-warning"
                        v-b-toggle:index_unidad_curso.toString()
                        @click="switchUnidad(index_unidad_curso)"
                        >
                            <div class="text-left">
                                <div v-text="`${index_unidad_curso + 1}. ${unidad_curso.unidad.nombre_unidad}`"></div>
                            </div>
                            <p class="text-justify">
                                <small v-text="unidad_curso.unidad.descripcion"></small>
                            </p>
                    </b-button>

                <b-collapse :id="index_unidad_curso.toString()" accordion="my-accordion" v-model="visible[index_unidad_curso]" class="mt-2">
                    <b-card>
                        <b><b-jumbotron lead="Objetivos"></b-jumbotron></b>
                        <b-list-group>

                            <div v-for="(objetivo_unidad,index_objetivo) in unidad_curso.unidad.objetivos_unidad" :key="index_objetivo">
                                    <b-list-group-item>

                                            <a href="#!"
                                            @click="verMaterialObjetivo(objetivo_unidad.objetivo)"
                                            :class="((objetivo_unidad.active===1)?'primary--text':'')">
                                            {{
                                                `${index_unidad_curso + 1}.${index_objetivo + 1} ${objetivo_unidad.objetivo.nombre_objetivo}`
                                            }}
                                            </a>
                                    </b-list-group-item>
                                        <hr>
                            </div>
                            </b-list-group>


                                <b-jumbotron lead="Actividades"></b-jumbotron>

                                <b-card>
                                    <b-alert
                                        class="p-2"
                                        show
                                        variant="warning"
                                        dismissible>
                                        <small>
                                            Marca las actividades realizadas
                                        </small>
                                    </b-alert>

                                    <div v-for="(actividad_unidad, index_actividad) in actividades_unidad_actual" :key="index_actividad">
                                      <div>
<!--                                        <b>id actividad {{actividad_unidad.id}} </b>-->
                                      </div>
                                        <div v-if="actividad_unidad.actividad_realizada > 0">
                                            <div class="form-inline">
                                                <div class="ml-1 actividad_checkbox p-2 my-2 shadow-sm" style="overflow-y: auto;text-decoration-line: line-through; border:1px #dedede solid;  border-radius: 15px;" >
                                                    <small>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                                        </svg>
                                                        {{ actividad_unidad.detalles_actividad }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-else
                                             class="actividad_checkbox p-3 my-1 shadow-sm" style="border:1px #dedede solid;  border-radius: 15px;">

                                            <b-form-checkbox
                                                class           ="my-1"
                                                :disabled       ="( (actividad_unidad.actividad_realizada > 0) )"
                                                :v-model        ="( (actividad_unidad.actividad_realizada > 0) ? activado=1 : activado=0 )"
                                                unchecked-value ="0"
                                                value           ="1"
                                                @change="completarActividad(actividad_unidad)"
                                                >
                                              <!--text-break se quitó esta clase porque se retiraron enlances y no se ncesita cortar las palabras-->
                                                   <span class="" style="text-align:justify"> {{ actividad_unidad.detalles_actividad }}</span>
                                                    <p style="display:none">
                                                        actividad realizada -- {{actividad_unidad.actividad_realizada }}
                                                    </p>
<!--                                                    <small>
                                                        <a href="#!"
                                                                v-if="(actividad_unidad.items_actividad.length > 0)"
                                                            @click="openSubAcitivies(actividad_unidad)" class="ml-1" color="primary" small outlined>Detalles</a>
                                                    </small>-->
                                            </b-form-checkbox>

                                        </div><!--v-else-->

                                        <div v-if="actividad_unidad.alumno_entrego==1">
                                               <b-row>
                                                    <b-button variant="link" size="sm" class="my-1" type="button" disabled>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 1443.061 1249.993"><path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/><path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/><path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/></svg>
                                                        ¡Archivo entregado!</b-button>
                                                    </b-row>
                                                <b-row >
                                                    <b-button disabled variant="success" size="sm" class="mr-1">
                                                        Entregar
                                                    </b-button>
                                                </b-row>
                                        </div>
                                        <div v-else-if="actividad_unidad.alumno_entrego==0">
                                      <!-- 0 no entregable-->
                                            <div v-if="(actividad_unidad.entregable > 0)" class="text-center mt-2" >
                                                <span>Acciones</span>
                                                <hr>
                                                <b-row>
                                                    <div v-if=" actividad_unidad.actividad_rubrica != null ">
                                                      <b-button style="display: none;" variant="info" v-b-modal.modal-prevent-closing size="sm"
                                                                @click="showModalRubrica(actividad_unidad.actividad_rubrica.id)" class="mr-1">
                                                        Ver rúbrica
                                                      </b-button>
                                                    </div>
                                                </b-row>
                                                <div v-if="(actividad_unidad.entregable == 1 || actividad_unidad.entregable == 2 || actividad_unidad.entregable == 3)">
                                                    <b-row>
                                                            <GDriveSelector v-on:handlePickerFiles="getData" />
                                                    </b-row>
                                                    <b-row >
                                                        <div v-if="file.id !=0 ">
                                                            <b-button @click="showModalConfirmacionEntrega(actividad_unidad)" variant="success" size="sm" class="mr-1">
                                                                Entregar
                                                            </b-button>
                                                        </div>
                                                        <div v-else>
                                                            <b-button disabled variant="success" size="sm" class="mr-1">
                                                                Entregar
                                                            </b-button>
                                                            <span class="txt_attachmnts"> Hay que elegir un archivo del drive!</span>
                                                        </div>
                                                    </b-row>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <!--<small> <i>No hay entregables</i></small>-->
                                                <small> <i></i></small>
                                            </div>
                                        </div><!--v-else-if-->
                                      <div v-if="actividad_unidad.quiz>0">
                                        <span>Evaluaciones</span>
                                        <hr>
                                        <div style="text-align: center">

                                          <b-button @click="showModalQuiz(actividad_unidad.id)" variant="success" size="sm" class="mr-1">
                                            Iniciar
                                          </b-button>
                                        </div>
                                      </div>
                                    </div><!--v-for-->
                                </b-card>
                    </b-card>
                </b-collapse>
            </div><!-- switch unidad-->
        </div><!--boxScroll-->
    </b-col>
    <!--main center section-->
    <b-col cols="12" :lg=" (!collaps && !collapsDudas) ? '12' : (collapsDudas==false ? '9':'7') " md="7" sm="12">
        <div class="box-scroll">
            <div v-if="!current_objetivo">
                <b-alert
                    class="p-3 mx-5"
                    show
                    variant="warning"
                    dismissible>
                    <small>
                        Seleccione los materiales que desea ver desde cualquier objetivo
                    </small>
                </b-alert>
            </div>
            <div v-else>
                <p class="text-left mx-2">Material
                     <strong > {{`${current_page_objetivo + 1}/${current_objetivo.materiales_objetivo.length}`}} </strong>
                </p>

                <div class="text-right my-2 mx-2">
                        <b-button :disabled="!(current_page_objetivo>0)"
                                    class="btn-studioAPP mx-1"
                                        variant="outline-warning"
                                    aria-controls="collapse-4"
                                    @click="anteriorMaterial">
                            Anterior
                        </b-button>

                    <b-button :disabled="!(((current_page_objetivo+1)!=current_objetivo.materiales_objetivo.length))"
                        class="btn-studioAPP"
                        variant="outline-warning"
                        aria-controls="collapse-4"
                        @click="siguienteMaterial"
                        >
                    Siguiente material
                    </b-button>
                </div>

            <b-card
                :title="current_objetivo.nombre_objetivo"
                img-top
                tag="article"
                class="mx-2"
            >
              <b-card-text>
                Descripción objetivo:  {{ current_objetivo.descripcion }}
              </b-card-text>

              <div v-if="current_objetivo.materiales_objetivo[current_page_objetivo]">
<!--                <h5 class="text-justify">
                  Material: {{ current_objetivo.materiales_objetivo[current_page_objetivo].nombre_material }}
                </h5>-->
                <p>
                  Instrucciones
                </p>
                <p class="text-justify">{{ current_objetivo.materiales_objetivo[current_page_objetivo].instrucciones }}</p>

                <div v-if="current_objetivo.materiales_objetivo[current_page_objetivo].tipo_material_id==2"
                     :class=" collaps ? 'mt-5' : 'mt-5 text-center' "
                >

                  <div style="z-index:99; background-color:transparent; width:100%; height:600px; position: absolute"></div>
                  <youtube
                      :video-id="getCurrentVideoId(current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento)"
                      @ready="ready"
                      @playing="playing"
                      :player-vars="{ rel: 0, showinfo: 0, autoplay: 0 }"
                      player-width="100%"
                      player-height="600">
                  </youtube>
                  <div class="my-2">
                    <b-button @click="customStop()" v-if="corriendo==true" variant="primary" size="sm" class="ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stop-fill" viewBox="0 0 16 16">
                        <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z"/>
                      </svg>
                    </b-button>


                    <b-button @click="atrasar()" variant="success" size="sm" class="ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-rewind-fill" viewBox="0 0 16 16">
                        <path d="M8.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L8.404 7.304Z"/>
                        <path d="M.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L.404 7.304Z"/>
                      </svg>
                    </b-button>

                    <b-button @click="pause()" v-if="corriendo==true" variant="secondary" size="sm" class="ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16">
                        <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
                      </svg>
                    </b-button>

                    <b-button @click="playVideo()" v-if="corriendo==false" variant="danger" size="sm" class="ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                        <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                      </svg>
                    </b-button>
                    <b-button @click="adelantar()" variant="success" size="sm" class="ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-fast-forward-fill" viewBox="0 0 16 16">
                        <path d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z"/>
                        <path d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z"/>
                      </svg>
                    </b-button>


                    <b-button @click="mutear()" v-if="muteado===false" variant="primary" size="sm" class="ml-5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-mute-fill" viewBox="0 0 16 16">
                        <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
                      </svg>
                    </b-button>
                    <b-button @click="desMutear()" v-if="muteado===true" variant="dark" size="sm" class="ml-5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-mute-fill" viewBox="0 0 16 16">
                        <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
                      </svg>
                    </b-button>

                    <b-button @click="bajarVol()" variant="primary" size="sm" class="ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-down-fill" viewBox="0 0 16 16">
                        <path d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zm3.025 4a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z"/>
                      </svg>
                    </b-button>
                    <b-button @click="subirVol()" variant="primary" size="sm" class="ml-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
                        <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                        <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                        <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
                      </svg>
                    </b-button>
                  </div>


                </div>
                <div v-else-if="current_objetivo.materiales_objetivo[current_page_objetivo].tipo_material_id==3">
                  <a class="mt-5 d-block" target="_blank" :href="current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento">
                    <b-button class="primary" @click="botonAbrirMaterial( current_objetivo.materiales_objetivo[current_page_objetivo] )" x-large>Abrir material</b-button>
                  </a>
                </div>
                <div class="mt-5" v-else>
                  <p>{{ current_objetivo.materiales_objetivo[current_page_objetivo].instrucciones }}</p>
                  <p>{{ current_objetivo.materiales_objetivo[current_page_objetivo].ruta_documento }}</p>
                </div>

              </div>
              <div v-else>
                <b-alert show variant="warning">
                  <p>
                    De momento, este objetivo no cuenta con materiales adjuntos, estos aparecerán cuando el coach los haya agregado.
                  </p>
                </b-alert>
              </div>


            </b-card>
            </div><!-- v-else -->

        </div><!--box-scroll-->

    </b-col>

    <!-- dudas chat-->

      <b-col v-if="collapsDudas===true" cols="12" :lg=" (!collaps) ? '4' : '2' "  md="7" sm="12" :class=" (collapsDudas ) ? '' : 'd-none' ">

                <div class="text-center mx-1" >

                    <div v-if=" this.current_objetivo ">
                         <b-form-textarea
                                    v-model="duda_msj"
                                    id="textarea"
                                    placeholder="Déjanos tus dudas en esta sección..."
                                    rows="2"
                                    max-rows="3"
                                    >
                    </b-form-textarea>
                    </div>
                    <div v-else>
                             <b-form-textarea
                                    disabled
                                    placeholder="Déjanos tus dudas en esta sección..."
                                    rows="2"
                                    max-rows="3"
                                    >
                    </b-form-textarea>
                    </div>



                    <div v-if="this.duda_msj">
                        <b-button
                            @click="guardarDuda"
                            class="btn-studioAPP my-1 mx-5"
                            size="sm">Enviar duda
                        </b-button>
                    </div>
                    <div v-else>
                        <b-button
                            disabled
                            class="btn-studioAPP my-1 mx-5"
                            size="sm">Enviar duda
                        </b-button>
                    </div>
                        <hr>
                        <div v-if="dudas_ya_mostradas==true">
                            <div v-if="dudas.length>0">
                                <div v-for="(duda, index ) in dudas" :key="index" >
                                    <div class="container text-right">
                                        <p><small> {{duda.duda_msj}}</small></p>
                                    </div>
                                    <div v-if="duda[`dudas_respuesta`]!=null">
                                        <div class="container darker" style="margin-left:35px !important; " :style=" collaps ? 'width:170px' : 'width:382px' ">
                                            <p><small> {{  duda[`dudas_respuesta`].respuesta_duda_msj}}  </small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="alert alert-warning" role="alert">
                                De momento no se cuentan con dudas
                                </div>
                            </div>
                        </div>
                        <div v-else>
                                <div class="alert alert-warning" role="alert">
                                  Para visualizar el apartado de dudas, hay que elegir un objetivo
                                </div>
                        </div>

            </div>

    </b-col>

</b-row>

  </b-container>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import GDriveSelector from "../../components/GDriveSelector";
import EntregableService from '@/services/EntregableService';
import Rubrica from '@/components/Rubricas/Rubrica';
import toast from "@/mixins/ToastMixin.js";
import {getIdFromURL} from "vue-youtube-embed";
import Quiz from "@/components/Quiz/Quiz.vue";
export default {
    mixins:[toast],
    mounted () {
        this.current_curricula       = this.$route.params.curricula;
       // this.current_curso_curricula = this.$route.params.curso;
        this.oferta_actual           = this.$route.params.oferta_actual;

      if( this.$route.params.curricula == undefined ) {
        if (localStorage.getItem('current_curricula')) {
          try {
            this.current_curricula = JSON.parse( localStorage.getItem('current_curricula') );
          } catch(e) {
            localStorage.removeItem('current_curricula');
          }
        }
      }else{
        const parsed = JSON.stringify(this.current_curricula);
        localStorage.setItem('current_curricula', parsed);
      }


/*MOVER ACA*/
      if( this.$route.params.curso == undefined ) {
        if (localStorage.getItem('current_curso_curricula')) {
          try {
            this.current_curso_curricula = JSON.parse( localStorage.getItem('current_curso_curricula') );
          } catch(e) {
            localStorage.removeItem('current_curso_curricula');
          }
        }
      }else{
        this.getContenido(this.$route.params.curso.id);
        const parsed2 = JSON.stringify(this.current_curso_curricula);
        localStorage.setItem('current_curso_curricula', parsed2);
      }
      /*MOVER ACA*/



      if( this.$route.params.oferta_actual == undefined ) {
        if (localStorage.getItem('oferta_actual')) {
          try {
            this.oferta_actual = JSON.parse( localStorage.getItem('oferta_actual') );
          } catch(e) {
            localStorage.removeItem('oferta_actual');
          }
        }
      }else{
        const parsed3 = JSON.stringify(this.oferta_actual);
        localStorage.setItem('oferta_actual', parsed3);
      }
              /*  console.log( "this.current_curso_curricula.id" );
                console.log(this.current_curso_curricula.id);     //aca curricula id 19

                console.log( "this.$route.params.curricula.id" );
                console.log( this.$route.params.curricula.id );*/

                this.currentCurso.name      = this.current_curso_curricula.nombre_curso;  //Introducción a Google Workspace for Education

                this.getCursosCurricula( this.$route.params.curricula.id );
                this.logLiveEvents(10,1);

    },

      components: {
            Rubrica,
            GDriveSelector,
            Quiz
        },
    data() {
        return {
          index_unidad_curso:0,
          muteado:false,
          corriendo:false,
          presentarExam:false,
            modalEntrega:false,
            file:{
                name:null,
                url:null,
                id:0,
                type:null,
                iconUrl:'',
                unidad:0,   ///para efectos de guardarlos en la entrega
                actividad:0 ///para efectos de guardarlos en la entrega
            },
            modalRubrica:false,
            modalQuiz:false,
            rubrica_id:0,
            dudas_ya_mostradas:false,
            dudas:[],
            duda_msj:'',

            collapsDudas:true,
            checkDudas:true,

            checkUnidades:true,
            checked:null,
            collaps:true,
            currentCursoId:2,
            contador:0,

           cursos_current_curricula: [],

           prevCurso:{
               name:null,
               id:0,
           },

           currentCurso:{
               name:null,
               id:0,
           },

           nextCurso:{
               name:null,
               id:0,
           },

            antCurso:null,
            sigCurso:null,

            oferta_actual: [],
            activado:false,
            porcentaje_avance: 0,

          //locally to compare
            current_objetivo: null,
            current_unidad:null,
            current_actividad:null,
            current_material:null,
            current_quiz_id:null,

          //to send another components
          //curricula
          //curso

            objetivo : 0,
            unidad    : 0,
            actividad : 0,
            material : 0,
            quiz     : 0,

            current_page_objetivo: 0,
            actividades_unidad_actual: [],
            current_curso_curricula: [],

            actividadUnidad:[],
            curriculas_navegacion       : [],
            progressBarCurrentValue     : 15,
            progressBarMaxValue         :100,

            visible                     :[false,false, false, false, false],
            unidadVisible               : false,
            actividadIdQuiz:0
        }
    },
    methods: {

     async getContenido(curso_id){

           const response = await this.$api.get(`/api/contenidos/pantallaAutogestivo/${curso_id}`);
           let {
             resultSet,
             success,
           } = response.data;
           if(success){

             this.current_curso_curricula = resultSet;
             this.currentCurso.name      = this.current_curso_curricula.nombre_curso;
             this.currentCurso.id        = this.current_curso_curricula.id;  //aca curso_id    65
           }
      },


      even(arr) {
        //re acomodo en base a position
        return arr.slice().sort(function(a, b) {
          return a.position - b.position;
        });
      },
      botonAbrirMaterial(material){
      this.current_material = material;
      this.logLiveEvents(10, 25);
      },

    //ACA
      async logLiveEvents(modulo, accion) {

        this.unidad    = ( this.current_unidad != null ) ? this.current_unidad.id : 0;
        this.objetivo  = ( this.current_objetivo != null ) ? this.current_objetivo.id : 0;
        this.actividad = ( this.current_actividad != null ) ? this.current_actividad : 0;
        this.material  = ( this.current_material != null ) ? this.current_material.id : 0;
        this.quiz      = ( this.current_quiz_id != null ) ? this.current_quiz_id : 0;

        await this.$api.get(`/api/log/${modulo}/${accion}/${this.$route.params.curricula.id}/${this.currentCurso.id}/${this.unidad}/${this.objetivo}/${this.actividad}/${this.material}/${this.quiz}`);

        this.current_actividad  = null;
        this.current_material   = null;
        this.current_quiz_id    = null;
      },

      colapsarAcordion(){
        //console.log('colapsarAcordion');
        this.$root.$emit('bv::toggle::collapse', this.index_unidad_curso.toString() );
      },

      showModalQuiz(id){
        this.modalQuiz       = true;
        this.actividadIdQuiz = id;
        this.current_quiz_id = id;
       // this.logLiveEvents(10, 26);
      },

      async mutear(){
        this.muteado=true;
        await this.player.mute();
        await this.logLiveEvents(10, 11);
      },
      async subirVol(){
        let vol = await this.player.getVolume();
        this.player.setVolume(vol+20);
        if( this.player.isMuted() ){
          await this.player.unMute();
          this.muteado=false;
        }
        await this.logLiveEvents(10, 13);
      },
      async bajarVol(){
        let vol = await this.player.getVolume();
        this.player.setVolume(vol-20);
        let vol2 = await this.player.getVolume();
        if(vol2==0){
          this.muteado=true;
        }
        await this.logLiveEvents(10, 14);
      },
      async desMutear(){
        this.muteado=false;
        await this.player.unMute();
        await this.logLiveEvents(10, 12);
      },
      async customStop(){
        this.player.stopVideo();
        this.player.seekTo(0);
        await this.logLiveEvents(10, 8);
      },
      async atrasar(){
        let time = await this.player.getCurrentTime();
        this.player.seekTo(time-15);
        await this.logLiveEvents(10, 9);
      },
      async adelantar(){
        let time = await this.player.getCurrentTime();
        this.player.seekTo(time+15);
        await this.logLiveEvents(10, 10);
      },
      ready (event) {
        this.player = event.target;
      },
      playing (event) {
        console.log("playing event..." + event);
      },
      stop() {
        this.corriendo=false;
        this.player.stopVideo();
        this.logLiveEvents(10,8);
      },
      pause() {
        this.corriendo=false;
        this.player.pauseVideo();
        this.logLiveEvents(10,7);
      },
      async playVideo() {
        this.corriendo=true;
        await this.player.playVideo();
        await this.logLiveEvents(10, 6);
      },
      irCursosCurricula(){
        this.$router.push( {name: 'CursosCurricula', params: {curricula: this.$route.params.curricula, is_dashboard: true} });
      },
      async hacerEntrega(){
        this.modalEntrega   = false;
        this.toast('b-toaster-bottom-center', true, "Se está haciendo la entrega...", "info" );
            let formData = new FormData();
                formData.append("curricula_id",     this.$route.params.curricula.id);
                formData.append("curso_id",         this.current_curso_curricula.id);     //aca
                formData.append("unidad_id",        this.file.unidad);
                formData.append("actividad_id",     this.file.actividad);
                formData.append("file_id",          this.file.id );
                formData.append("file_name",        this.file.name);
                formData.append("file_url",         this.file.url );
                formData.append("file_type",        this.file.type);
                formData.append("file_iconUrl",     this.file.iconUrl);
            try {
                const response = await EntregableService.saveEntregable(formData);
                let {   success,
                        message,
                        color
                    } = response.data;
                if(success){
                    this.file.name      = null;
                    this.file.url       = null;
                    this.file.id        = 0;
                    this.file.type      = null;
                    this.file.iconUrl   = null;

                    this.toast('b-toaster-bottom-center', true, message, color );
                    await this.logLiveEvents(10, 21);
                }
            } catch (error) {
                 this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                    Bugsnag.notify(
                        new Error('Método: hacerEntrega() ' + error)
                    );
            }

        },
      showModalConfirmacionEntrega(actividad_unidad){
          this.file.unidad        = actividad_unidad.unidad_id;
          this.file.actividad     = actividad_unidad.id;
          this.current_actividad  = this.file.actividad;
          this.modalEntrega   = true;
        this.logLiveEvents(10, 29);
      },
      hideModalConfirmacionEntrega(){
          this.modalEntrega = false;
        this.logLiveEvents(10, 30);
      },
      getData( data ) {
             // eslint-disable-next-line no-prototype-builtins
             if(data.hasOwnProperty("docs")){
                  this.file.id        = data.docs[0].id;
                  this.file.name      = data.docs[0].name;
                  this.file.url       = data.docs[0].url;
                  this.file.type      = data.docs[0].type;
                  this.file.iconUrl   = data.docs[0].iconUrl;
                  }
      },
      hideModalRubrica(){
          this.modalRubrica=false;
      },
      showModalRubrica(rubrica_id){
          this.rubrica_id=rubrica_id;
          this.modalRubrica=true;
        this.logLiveEvents(10, 18);
      },
      async getDudasObjetivo(item){
                  const response = await this.$api.get(`/api/contenidos/dudas/get/${item}`);
                  let {
                      dudas,
                      success,
                      } = response.data;
                  if(success){
                      this.dudas_ya_mostradas = true;
                      this.dudas = dudas;
                  }
       },
      async guardarDuda(){

          let formData = new FormData();
              formData.append("curricula_id",    this.current_curricula.id);
              formData.append("curso_id",        this.currentCurso.id);
              formData.append("unidad_id",      this.current_unidad.id);
              formData.append("objetivo_id",     this.current_objetivo.id);
              formData.append("duda_msj",        this.duda_msj);

          let url         = `/api/contenidos/dudas/store`;
      const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
      let {   success,
              message,
              color
              } = response.data;
      if(success==true){
          this.toast('b-toaster-bottom-center', true, message, color );
          this.duda_msj='';
          await this.getDudasObjetivo(this.current_objetivo.id);
        await this.logLiveEvents(10, 22);
        }
      },
      async switchCursoPrev(){

        this.muteado =false;
        this.corriendo = false;
        this.current_objetivo   = null;
            await this.progresoCursoAlumno();
              this.colapsarAcordion();
              this.current_curso_curricula  = this.antCurso;
              this.currentCurso.name        = this.antCurso.nombre_curso;
              this.currentCurso.id          = this.antCurso.id;
              await this.getCursosCurricula(this.current_curricula.id);
              this.dudas=[];
              await this.logLiveEvents(10, 24);
      },

        async switchCursoNext(){
          this.muteado =false;
          this.corriendo = false;
          this.current_objetivo   = null;
                this.colapsarAcordion();
                await this.progresoCursoAlumno();
                this.current_curso_curricula = this.sigCurso;
                this.currentCurso.name      = this.sigCurso.nombre_curso;
                this.currentCurso.id        = this.sigCurso.id;
                await this.getCursosCurricula(this.current_curricula.id);
                this.dudas=[];
                await this.logLiveEvents(10, 23);
        },
      //arma la barra de navegación morada del auto gestivo arriba
       async getCursosCurricula( item ) {
                  const response = await this.$api.get(`/api/contenidos/curriculas/cursos/autogestivo/nav/${item}`);
          //      const response = await this.$api.get(`/api/contenidos/curriculas/cursos/${item}/vivo/0`);
                let {
                        cursos_curricula,
                        success,
                        } = response.data;
                if(success){
                 this.cursos_current_curricula = cursos_curricula;
                  console.log('this.cursos_current_curricula');
                 console.log(this.cursos_current_curricula);

                  this.cursos_current_curricula = this.even( this.cursos_current_curricula);
                 for(let i = 0; i<this.cursos_current_curricula.length; i++){

                    if(this.currentCurso.id == this.cursos_current_curricula[i].id){

                        if(i != 0){
                            this.antCurso = this.cursos_current_curricula[i-1];

                            this.prevCurso.name = this.cursos_current_curricula[i-1].nombre_curso;
                            this.prevCurso.id   = this.cursos_current_curricula[i-1].id;
                        }

                        if(i == 0){
                            this.antCurso = null;
                            this.prevCurso.name = null;
                            this.prevCurso.id   = null;
                        }

                        if(i != this.cursos_current_curricula.length){

                            this.sigCurso=this.cursos_current_curricula[i+1];

                            this.nextCurso.name     = this.cursos_current_curricula[i+1].nombre_curso;
                            this.nextCurso.id       = this.cursos_current_curricula[i+1].id;
                        }

                        if(i == this.cursos_current_curricula.length){

                            this.sigCurso=null;
                            this.nextCurso.name     = null;
                            this.nextCurso.id       = null;
                        }

                    }

                 }

                }

        },
        someHandlerDudas() {
            this.collapsDudas= !this.collapsDudas;
          },
        someHandler() {
            this.collaps = !this.collaps;
          },

/*        openSubAcitivies(activity = []) {
                this.current_activity.title = activity.detalles_actividad;
                this.current_activity.items = activity.items_actividad;
            },*/
       async switchUnidad(index_unidad_curso) {

             this.muteado =false;
             this.corriendo = false;
              this.index_unidad_curso = index_unidad_curso;//para acordion
              this.unidadVisible  = !this.unidadVisible;
              this.visible[index_unidad_curso]  = !this.visible[index_unidad_curso];

              try {
                  this.actividades_unidad_actual = []
                  this.current_unidad     = this.current_curso_curricula.unidades_curso[index_unidad_curso].unidad;
                  console.log("current_unidad")
                  console.log(this.current_unidad)
                  this.current_objetivo   = this.current_curso_curricula.unidades_curso[index_unidad_curso].unidad.objetivos_unidad[0].objetivo;
                  await this.progresoCursoAlumno();
                  await this.logLiveEvents(10, 16);
              } catch (e) {
                  console.log(e)
              }
       },

        async completarActividad(actividad = [] ) {
               /* event.preventDefault()*/
                let formData = new FormData();
                    formData.append("actividad_id"    , actividad.id);
                    this.current_actividad = actividad.id;
                    formData.append("curso_id"        , this.current_curso_curricula.id);   //aca
                    formData.append("curricula_id"    , this.current_curricula.id);
                    formData.append("unidad_id"        , this.current_unidad.id);
                    let url          = `/api/contenidos/progreso-alumno`;
                    const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
                        console.log(response.data+"");
                     await this.progresoCursoAlumno();
                     await this.logLiveEvents(10, 17);
            },
        async progresoCursoAlumno(){

            let formData = new FormData();
                formData.append("curricula_id"  , this.current_curricula.id);
                formData.append("curso_id"        , this.current_curso_curricula.id); //aca
                formData.append("unidad_id"        , this.current_unidad.id);

                //ACA
                let url          = `/api/contenidos/progreso-curso-alumno`;
                const res       = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
                    this.porcentaje_avance              = res.data.porcentaje_avance;
                    this.numero_progresos_por_terminar  = res.data;
                    this.actividades_unidad_actual      = res.data.unidades;

                  //  console.log( this.actividades_unidad_actual );
            },
        anteriorMaterial() {
          this.muteado =false;
          this.corriendo = false;
            this.current_page_objetivo--
            },
        siguienteMaterial() {
          this.muteado =false;
          this.corriendo = false;
            this.current_page_objetivo++
            },
        verMaterialObjetivo(objetivo){
          this.muteado =false;
          this.corriendo = false;
          this.current_objetivo = objetivo;
          this.getDudasObjetivo(this.current_objetivo.id);
          this.logLiveEvents(10, 15);
        },

        //tentativamente no hace nada, revisar ya que se fusionó con el switchUnidad
        mostrarOpciones(item) {
          //  console.log('method mostrarOpciones fired!');
            this.unidadVisible  = !this.unidadVisible;
            this.visible[item]  = !this.visible[item];
        },

        async curriculasNavegacion(){
                const response = await this.$api.get(`/api/contenidos/curriculasNavegacion`);
                let {   message,
                        color,
                        success,
                        curriculas_navegacion,
                        } = response.data;
                if(success){
                    this.curriculas_navegacion = curriculas_navegacion;
                }
                 this.toast('b-toaster-bottom-center', true, message, color );
        },

        getEmbedYoutubeUrl(youtube_url = '') {
          let string = youtube_url.split('https://www.youtube.com/watch?v=')
            return 'https://www.youtube.com/embed/' + string[1]
        },
        getCurrentVideoId(youtube_url) {
          return getIdFromURL(youtube_url)
        },



    },//methods


}
</script>


<style scoped>


/* mouse over link */
.actividad_checkbox:hover {
  background-color: #ecf0f1;
  transition:all .5s ease;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right:0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}

.txt_attachmnts{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px !important;
  font-weight: 400;

}

/* collaps*/




.collapse {
  visibility: hidden;
}
.collapse.show {
  visibility: visible;
  display: block;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: auto;
}
/* collaps*/
</style>


<style lang="scss" scoped>

$studioAPPColor:#952f81;

.boxScroll {
    height:800px;
    overflow-y: scroll;
}
.list-group-item.active {
    background-color: $studioAPPColor !important;
    border-color: $studioAPPColor !important;
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
    padding-left: 0 !important;

}
.content-page {
    margin-left: 0px !important;
    padding: 100px 0px 0px !important;

}

.btn-studioAPP{
     color: white !important;
     border:1px solid $studioAPPColor;
     background-color: $studioAPPColor !important;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* default*/

</style>