<template>
    <b-container>

<b-row>
    <b-col cols="12">
       <div class="card card-block">
        <div class="card-body p-2">
              <div class="d-flex justify-content-between align-items-center p-2">
                  <b-jumbotron lead="Calificable por rúbrica 📓"></b-jumbotron>
              </div>
              <b-row style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">    
                        <b-button @click="volver()"
                            class="btn-studioAPP" 
                            variant="outline-warning"> Volver
                        </b-button>
              </b-row>

              <b-row>
                  <b-col cols="8">
                    <iframe 
                            width="800"
                            height="700"
                            :src="this.url" 
                            frameborder="1"
                            >
                    </iframe>

                  </b-col>

                  <b-col cols="4">
                    <label for=""> {{this.rubrica.nombre}} </label>
                    <hr>
                      <b-input-group size="xl" prepend="%" append="de calificación 📃">
                      <b-form-input
                            ref="myInput"
                            size="xl"
                            placeholder=" __/100"
                            type="number"
                            step="1"
                            disabled
                            :value="calificacion"
                          ></b-form-input>
                           </b-input-group>
                          <hr>

                <div class="my-2">
                  <b>Comentarios</b>
                    <b-form-textarea
                      v-model="comentario"
                      placeholder="Comentarios opcionales..."
                      rows="2"
                      max-rows="3"
                      @keyup="updateComentario()"
                    ></b-form-textarea>                
                </div>



             <div v-for="(criterio,k) in this.rubrica.criterio" :key="k">

                <div class="my-1">
                  <p><b> {{criterio.titulo}}</b> </p>
                  <small> {{ criterio.descripcion }} </small>
                </div>

              <b-form-select 
                   v-model="selected[k]" 
                   class="mb-3"
                    @change="updateCalificacion($event, k, criterio.id)"
                >
                    <template #first>
                      <b-form-select-option disabled value="0"> Elegir opción </b-form-select-option>  
                    </template>

                    <b-form-select-option-group v-for="(nivel,j) in criterio.nivel" :key="j" :value="nivel.puntos" :label="nivel.descripcion" >
                        <b-form-select-option :value="( `${nivel.puntos},${nivel.id}`  )">{{nivel.puntos}} <small>pts</small> : {{nivel.titulo_nivel}}</b-form-select-option>
                    </b-form-select-option-group>

              </b-form-select>


             </div>

                  </b-col>
              </b-row>

        </div><!--card body-->

       </div>

    </b-col>
</b-row>


  </b-container>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import RubricaService from '@/services/RubricaService';
import toast from "@/mixins/ToastMixin.js";
import EntregableService from '@/services/EntregableService';

export default {
    mixins:[toast],
      name: "Preview-rubrica",
     
    mounted () {
        this.entregable   = this.$route.params.entregable;
        this.url          = (this.$route.params.entregable.tareaURL).replace('edit','preview');
        this.getRubrica(this.entregable.unidadActividad_id, this.entregable.unidad_id);
        this.calificacion = this.$route.params.entregable.calificacion;
        this.getComentario(this.entregable.idEntregable);

    },
    data() {
      return {  
        inputs: [],
        //colocar aquí los ptos, en este caso el 4 es la pos 0 del primer criterio formato.
        selected: [],
        entregable: [],
        idEntregable:0,
        url: '',
        rubrica :[],
        maxCalif:0,
        calificacion: 0,
        comentario:'',
      }
    },
    methods: {
        volver(){
          if(this.$route.params.vieneDeGrupos==true){
            this.$router.push(`/entregables/${this.entregable.grupo_usuario_aprende_id}`);
          }else{
            this.$router.push(`/entregas-general`);
          }
          
        },
        async getCriterios(rubrica_id, entregable_id){
          try {
                let response = await RubricaService.getCriteriosDeEntregable(rubrica_id, entregable_id);
                let {   message,
                        color,
                        resultSet,
                        } = response.data;

                    if(resultSet)
                    {
                      for(let i=0; i<resultSet.length;i++){    
                          this.selected.push( resultSet[i] ); 
                      
                          //metemos los valores de result a el array que maneja los selects
                      if (String(resultSet[i]).indexOf(',') > -1) { 
                              let val =  String( resultSet[i] ).split(',') 
                              this.inputs[i] =  {  criterio: i, calificacion: val[0] };
                          }
                      }
                    
                    }
 
                this.toast('b-toaster-bottom-center', true, message, color );            
           } catch (error) {
                this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
                        Bugsnag.notify(
                            new Error('Método: getCriterios() ' + error)
                        );
            }

        },
        //calificacion en % en la vista
        // eslint-disable-next-line no-unused-vars
        async updateCalificacion(calificacionStr,k, criterio_id){

           //separamos el string que se obtiene con una , los puntos y el id de nivels donde están los puntos del criterio
              let calificacionArr         = calificacionStr.split(',');
              let calificacion            =  parseInt(calificacionArr[0]);
              let calificacioniDNivel     =  parseInt(calificacionArr[1]);

              let total=0;
              if ( Array.isArray(this.inputs) ) 
              {
                this.inputs[k] =  {  criterio: k, calificacion:calificacion };
               
                for(let i = 0; i< this.inputs.length;i++){
                    total += parseInt(this.inputs[i].calificacion);
                }
                //  9 - 100%
                //  total -  x 
            
                this.calificacion = parseInt(  ( parseInt(total)*(100) )/ parseInt(this.rubrica.puntajeMax) );
              }

              if(this.calificacion>0){
                  try{
                        // eslint-disable-next-line no-unused-vars
                        let response = await EntregableService.updateEntregableCalifRubrica( this.entregable.idEntregable, this.calificacion, this.rubrica.id, criterio_id, calificacioniDNivel,  this.entregable.id_usuario_grupo_alumno );
                                       
                    } catch (error) {
                          this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                                Bugsnag.notify(
                                    new Error('Método: updateCalificacion() ' + error)
                                );
                    }
             }

        },
        async getRubrica(unidadActividad_id, unidad_id) {
            try {
                let response = await RubricaService.getRubrica(unidadActividad_id,unidad_id);
                let {   message,
                        color,
                        rubrica,
                        success
                        } = response.data; 

                if(success){
                      this.rubrica = rubrica;   
                      this.getCriterios(this.rubrica.id ,this.entregable.idEntregable);                 
                  }
                this.toast('b-toaster-bottom-center', true, message, color );
            } catch (error) {
                this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
                        Bugsnag.notify(
                            new Error('Método: getRubrica() ' + error)
                        );
            }
            
        },

        async getComentario(idEntregable){
 
          try {
                let response = await EntregableService.getComentario(idEntregable);
                let {   message,
                        color,
                        resultSet,
                        } = response.data;

                    if(resultSet){
                      this.comentario = resultSet;
                    }

                this.toast('b-toaster-bottom-center', true, message, color );            
           } catch (error) {
                this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
                        Bugsnag.notify(
                            new Error('Método: getComentario() ' + error)
                        );
            }

        },
        async updateComentario(){
          try {
                let response = await EntregableService.updateComentario(this.entregable.idEntregable, this.comentario);
                console.log(response.data+"") ;
           } catch (error) {
                this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
                        Bugsnag.notify(
                            new Error('Método: updateComentario() ' + error)
                        );
            }

        }

    },
}
</script>

<style>

</style>