<template>
  <b-container fluid="lg">

    <!-- modal resend email access -->
    <b-modal
        hide-header-close
        v-model="ModalResendAccess"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered>
      <b-container fluid>
        <b-row class="text-center">
          <b-col cols="12">
            ¿ Estás a punto de reenviar un correo al usuario con el siguiente correo : <b>"{{ invitado.email }}"</b> ?, Esto con el fin de que el usuario vuelva a entrar a su cuenta
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="HideModalResendInvConfirmacion"
          >
            Cancelar
          </b-button>
          <b-button
              variant="info"
              size="sm"
              class="float-right mx-2"
              @click="resendInvitation"
          >
           Reenviar correo
          </b-button>
        </div>
      </template>

    </b-modal>



    <!-- modal disable access -->
    <b-modal
        hide-header-close
        v-model="ModalDisableAccess"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="text-center">
          <b-col cols="12">
            ¿ Estás seguro de eliminar el acceso al usuario con el siguiente correo: <b>"{{ invitado.email }}"</b> ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="HideModalBorrarConfirmacion"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="disableAccess"
          >
            Eliminar acceso
          </b-button>
        </div>
      </template>

    </b-modal>

    <b-row>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">

            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron lead="Centro escolar"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              Esta sección te permite Administrar el  plantel escolar, la información del plantel escolar y los docentes que tienen acceso
            </b-alert>

            <div class="mx-5 ">
              <b-row class="d-flex align-items-center justify-content-center my-1">
                <div>
                  <b-card
                      :title="escuela.nombre"
                      img-src="https://picsum.photos/id/192/600/300"
                      img-alt="Image"
                      img-top
                      tag="article"
                      style="max-width: 20rem;"
                      class="mb-2"
                  >
                    <b-card-text>
                      <p>📧: {{this.escuela.domain}}</p>
                      <p>🏙️: {{this.escuela.ciudad}}</p>
                      <p>🚕: {{this.escuela.direccion}}</p>
                      <p>☎️ {{this.escuela.telefono}}</p>
                    </b-card-text>

                    <b-button @click="irAgestionEscolar()" v-b-tooltip.right="'Editar la información básica'" variant="primary">Editar ✏️</b-button>
                  </b-card>
                </div>
              </b-row>

              <b-row class="d-flex align-items-end justify-content-end my-1">
                <div class="col-sm-12 my-2">
                  <b-form-input  v-model="filter" debounce="500" placeholder="Buscar"  id="filter-input"></b-form-input>
                </div>

                <b-table class="table data-table"
                         striped="striped"
                         hover="hover"
                         outlined="outlined"
                         :items="invitados.items"
                         :fields="invitados.headers"
                         :current-page="currentPage"
                         :per-page="perPage"
                         :filter="filter"
                         :filter-included-fields="filterOn"
                         stacked="md"
                         show-empty
                         small
                >
                  <template #cell(updated_at)="row">
                    {{ (row.item.updated_at).substring(0, 10) }}
                  </template>

                  <template #cell(correo_alternativo)="row">
                    <small><i>{{ row.item.correo_alternativo }}</i></small>
                  </template>

                  <template #cell(name)="row">
                    <small><i>{{ row.item.name }}</i></small>
                  </template>

                  <template #empty="scope">
                    <h5 class="text-center">
                      {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                    </h5>
                  </template>
                  <template #emptyfiltered="scope">
                    <h5 class="text-center">
                      {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                    </h5>
                  </template>
                  <template #cell(estatus_user)="row">
                    <div v-if="`${row.item.estatus_user}`!=='0' ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </div>
                    <div v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                    </div>

                  </template>
                  <template #cell(actions)="row">
                    <div class="d-flex justify-content-around align-items-center">

                      <div v-if="`${row.item.estatus_user}`!=='0' ">
                        <b-button v-b-tooltip.right="'Eliminar acceso a Aprende'" variant="danger" size="sm" @click="ShowModalBorrarConfirmacion(row.item)" class="ml-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                          </svg>
                        </b-button>
                      </div>
                      <div v-else>
                        <b-button v-b-tooltip.right="'Reenviar correo para acceso a Aprende'" variant="success" v-b-modal.modal-prevent-closing size="sm"
                                  @click="ShowModalResendInvConfirmacion(row.item)" class="mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                          </svg>
                        </b-button>
                      </div>
                    </div>
                  </template>

                </b-table>
                <div class="d-flex justify-content-between row p-2">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                  <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div>
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          align="fill"
                          size="sm"
                          class="my-0">
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-row>

            </div>

          </div>

        </div>

      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import Bugsnag from "@bugsnag/js";
import Service from '@/services/CentroEscolar/CentroEscolarService.js';
import toast from "@/mixins/ToastMixin.js";

export default {
  name: "CentroEscolar",
  mixins:[toast],

  mounted () {
    this.getData();
    this.getDocenteInvitados();
  },
  data() {
    return {

      ModalDisableAccess:false,
      ModalResendAccess:false,

      escuela:{

          ciudad:"",
          direccion:"",
          domain:"",
          nombre:"",
          telefono:"",
        },

      invitado:{
          id:0,
          email:""
      },

      invitados:{
        items: [],
        headers:  [ { label: 'Correo',                 key: 'correo_alternativo' },
                    { label: 'Nombre',                key: 'name' },
                    { label: 'Primer inicio',           key: 'primer_inicio_sesion' },
                    { label: 'Último acceso',           key: 'updated_at' },
                    { label: 'Activo',                 key: 'estatus_user' },
                    { label: 'Acciones',               key: 'actions' , class: 'text-center' }
        ],
      },

      ///
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: '',
    }
  },

  methods: {

    resetForm(){
      this.invitado.id    = 0;
      this.invitado.email = "";
    },

    async resendInvitation(){

      try{
        let response = await Service.resendAccess(this.invitado.id);
        let {  success } = response.data;

        if(success){
          this.resetForm();
          this.ModalResendAccess = false;
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: resendInvitation() ' + error.message)
        );
      }

    },

    async disableAccess(){

      try{
        let response = await Service.disableAccess(this.invitado.id);
        let {  success } = response.data;

        console.log('response.data');
        console.log(response.data);

        if(success){
          this.resetForm();
          await this.getDocenteInvitados();
          this.ModalDisableAccess = false;

        }

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: disableAccessAlumno() ' + error.message)
        );
      }


    },

    HideModalResendInvConfirmacion(){
      this.resetForm();
      this.ModalResendAccess = false;
    },
    ShowModalResendInvConfirmacion(item){
      this.invitado.email   = item.correo_alternativo;
      this.invitado.id      = item.id;
      this.ModalResendAccess = true;
    },


    HideModalBorrarConfirmacion(){
      this.resetForm();
      this.ModalDisableAccess = false;
    },

    ShowModalBorrarConfirmacion(item){
      console.log(item);
      this.invitado.email   = item.correo_alternativo;
      this.invitado.id      = item.id;
      this.ModalDisableAccess = true;
    },

    irAgestionEscolar(){
        this.$router.push({
          path:`/admin-docente-escolar`
        });
    },



    async getDocenteInvitados(){

      try {
        let response = await Service.getInvitados();
        let { resultSet, cantidad, success } = response.data;

        if (success) {
          this.totalRows              = cantidad;
          this.invitados.items              = resultSet;
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getDocenteInvitados() ' + error.message)
        );
      }

    },
    async getData() {

      try {
        let response = await Service.get();
        let { resultSet, success } = response.data;

        if (success)
        {
          this.escuela.ciudad=resultSet.ciudad;
          this.escuela.direccion=resultSet.direccion;
          this.escuela.domain=resultSet.domain;
          this.escuela.nombre=resultSet.nombre;
          this.escuela.telefono=resultSet.telefono;
          console.log(this.escuela);
        }

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getData() ' + error.message)
        );
      }

    },
  },
}
</script>

<style scoped>
.bi-check-circle-fill {
  fill: green;
}

.bi-x-circle-fill {
  fill: red;
}
</style>