import api from '@/services/api';

export default{

    saveEventoCurso(formData){
        let url          = `/api/eventos/evento-curso/store`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
    },

    getEventosByCurso(curso_id){
        let url          = `/api/eventos/get-eventos-curso/${curso_id}`;
        return api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getEventos(sesion_id){
        let url          = `/api/eventos/getEventos/${sesion_id}`;
        return api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    getOrganizadores(){
        let url          = `/api/eventos/organizadores`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            } 
        });
        return response;
    },

    saveEvento(formData){
        let url          = `/api/eventos/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData 
        });
        return response;
    },

    updateEvento( evento_id, formData){
        let url          = `/api/eventos/actualizaEvento/${evento_id}`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

    deleteEvento(evento_id){
        
        let url = `/api/eventos/destroy/${evento_id}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
     
        return response;
    }

    }