<template>
  <b-container>

    <!-- modal borrar evento -->
    <b-modal
        v-model="ModalBorrarEvento"
        persistent
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar el evento con fecha <b> {{ this.evento_form.fecha_inicio}}</b> a hora <b> {{ this.evento_form.hora_inicio}}</b> ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalBorrarConfirmacion()"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="eliminarEvento()"
          >
            Eliminar
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- modal crear nuevo elemento -->
    <b-modal   no-close-on-backdrop centered size="lg"
               hide-footer
               ref="ModalCrearSesion"
               title="Creando evento para la sesión"
               v-model="ModalCrearEvento"
               persistent>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación especifique los siguientes campos
        </b-alert>
        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <b-form>

                <label> <small> Seleccionar video de Youtube</small></label>

                <v-select
                    label="title"
                    size="lg"
                    class="my-3"
                    placeholder="Elegir"
                    v-model="evento_form.youtube"
                    :options='youtubeItems'
                >
                  <!--
                  <template slot="option" slot-scope="option">
                    <b-col>
                      {{ option.title }}<br>
                      <small style="color:#dedede"> {{ option.Description_formatted }} </small>
                    </b-col>
                  </template>
                  -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No resultados para <em>{{ search }}</em
                    >.
                    </template>
                    <em v-else style="opacity: 0.5">Elegir video de youtube</em>
                  </template>
                </v-select>

                <b-row class="d-flex align-items-start justify-content-start my-3">
                  <b-col>
                    <b-form-datepicker locale="es"
                                       placeholder="Fecha de inicio"
                                       v-model="evento_form.fecha_inicio"
                                       size="sm"
                                       label-help=''
                                       label-today-button='Hoy'
                                       today-button
                                       label-no-date-selected='No se ha elegido fecha'
                    >
                    </b-form-datepicker>
                  </b-col>
                  <b-col>
                    <b-form-datepicker locale="es"
                                       placeholder="Fecha finalización"
                                       v-model="evento_form.fecha_finalizacion"
                                       size="sm"
                                       label-help=''
                                       label-today-button='Hoy'
                                       today-button
                                       label-no-date-selected='No se ha elegido fecha'
                    >
                    </b-form-datepicker>
                  </b-col>
                </b-row>
                <b-row class="d-flex align-items-start justify-content-start my-3">
                  <b-col>
                    <b-form-timepicker
                        size='sm'
                        now-button
                        label-now-button='Ahora'
                        label-hours='Hr'
                        label-minutes='Min'
                        hide-header
                        label-close-button='Cerrar'
                        placeholder="Hora inicio"
                        v-model="evento_form.hora_inicio" locale="es">
                    </b-form-timepicker>
                  </b-col>
                  <b-col>
                    <b-form-timepicker
                        size='sm'
                        now-button
                        label-now-button='Ahora'
                        label-hours='Hr'
                        label-minutes='Min'
                        hide-header
                        label-close-button='Cerrar'
                        placeholder="Hora fin"
                        v-model="evento_form.hora_fin" locale="es">
                    </b-form-timepicker>
                  </b-col>
                </b-row>
                <hr>
                <label for="">Vigencia</label>
                <b-row class="d-flex align-items-start justify-content-start my-3">
                  <b-col>
                    <b-form-datepicker locale="es"
                                       placeholder="Fecha de inicio"
                                       v-model="evento_form.fecha_vigencia_inicio"
                                       size="sm"
                                       label-help=''
                                       label-today-button='Hoy'
                                       today-button
                                       label-no-date-selected='No se ha elegido fecha'
                    >
                    </b-form-datepicker>
                  </b-col>
                  <b-col>
                    <b-form-datepicker locale="es"
                                       placeholder="Fecha finalización"
                                       v-model="evento_form.fecha_vigencia_finalizacion"
                                       size="sm"
                                       label-help=''
                                       label-today-button='Hoy'
                                       today-button
                                       label-no-date-selected='No se ha elegido fecha'
                    >
                    </b-form-datepicker>
                  </b-col>
                </b-row>

                <b-row class="d-flex align-items-start justify-content-start mt-3 my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="HideModalCrearEvento()"
                    >Cancelar
                    </b-button>

                    <b-button v-if="evento_form.editar==true"
                              variant="info"
                              size="sm"
                              class="float-right mx-2"
                              @click="actualizarEvento()"
                    >
                      Actualizar registro
                    </b-button>

                    <b-button v-if="evento_form.editar==false"
                              variant="success"
                              size="sm"
                              class="float-right mx-2"
                              @click="guardarEvento()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>


              </b-form>
            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>
    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron lead="Eventos del curso"></b-jumbotron>
            </div>

            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/cursos-all" class="">Cursos</router-link></li>
                <li class="breadcrumb-item active" aria-current="page"> Eventos </li>
              </ol>
            </nav>

            <b-row  style="display: flex; justify-content: space-between;" class="my-3 mx-3">
              <b-button @click="volver()"
                        class="btn-studioAPP"
                        variant="outline-warning"> Volver
              </b-button>
              <div>

                <b-button :disabled="limiteEventoAlcanzado"
                          @click="ShowModalCrearEvento"
                          class="btn-studioAPP"
                          variant="outline-warning"
                          v-b-tooltip.html
                          title="Sólo 1 evento por curso es permitido "
                        > Crear nuevo evento
                </b-button>
              </div>
            </b-row>

            <b-table class="table data-table"
                     :items="eventos_items"
                     :fields="eventos_headers"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <b-alert show variant="info">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</b-alert>
              </template>
              <template #emptyfiltered="scope">
                <b-alert show variant="warning">
                  {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                </b-alert>
              </template>

              <template #cell(horario)="row">
                <button type="button" class="btn btn-sm btn-success rounded-pill mt-2"><i class="ri-bill-fill"></i>{{row.item.horario}}</button>
              </template>

              <template #cell(detalles)="row">
                <b-button  pill variant="primary" size="sm" @click="unidades(row.item)" class="ml-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-trash-fill" viewBox="0 0 20 20">

                    <path d="M14.467,1.771H5.533c-0.258,0-0.47,0.211-0.47,0.47v15.516c0,0.414,0.504,0.634,0.802,0.331L10,13.955l4.136,4.133c0.241,0.241,0.802,0.169,0.802-0.331V2.241C14.938,1.982,14.726,1.771,14.467,1.771 M13.997,16.621l-3.665-3.662c-0.186-0.186-0.479-0.186-0.664,0l-3.666,3.662V2.711h7.994V16.621z"></path>
                  </svg>
                </b-button>
              </template>

              <template #cell(actions)="row">

                <div class="d-flex justify-content-around align-items-center">

                  <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                            @click="EditMode(row.item)" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 20 20">
                      <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </b-button>

                  <b-button variant="danger" size="sm" @click="showModalBorrarConfirmacion(row.item)" class="ml-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         class="bi bi-trash-fill" viewBox="0 0 20 20">
                      <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </b-button>
                </div>
              </template>


            </b-table>


          </div><!--card-body p-2-->

        </div><!--  card card-block -->


      </b-col><!-- cols 12-->



    </b-row>


  </b-container>
</template>

<script>
import firebase from '@/firebaseConfRtdb/init';
import firebaseMixin from '@/mixins/FirebaseMixin.js';
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from '@bugsnag/js';
import EventoService from '@/services/EventoService';
import EventoStreamService from "../../../services/Evento/EventoStreamService";

export default {

  mounted () {

    this.getEventos(this.$route.params.id);

  },
  mixins:[toast,firebaseMixin],
  name: "CursoEvento",
  data() {
    return {
      limiteEventoAlcanzado:true,
      youtubeItems:[],
      youtubeItems_count:0,

      ModalCrearEvento:false,
      ModalBorrarEvento:false,
      curso:{
        nombre:'',
        id:(this.$route.params.id) ? (this.$route.params.id) : 0,
      },
      evento_form:{
        id:0,
        editar:false,
        fecha_inicio: '',
        fecha_finalizacion : '',
        hora_inicio: null,
        hora_fin: null,

        youtube:{ label:'Elegir', id: 0 },
        fecha_vigencia_inicio : '',
        fecha_vigencia_finalizacion : ''
      },

      eventos_items:[],
      eventos_headers: [
        { label: '#',        key: 'evento_id'          ,  class: 'text-center'},
        { label: 'Título',   key: 'titulo_video'         , class: 'text-center'},
        { label: 'Fecha del Evento',   key: 'fecha_consumo_inicio'         , class: 'text-center'},
        { label: 'Hora del Evento',    key: 'horario'                      , class: 'text-center'},
        { label: '',                   key: 'actions',class: 'text-center'}
      ],

      /////////////
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: ''
    }
  },
  methods: {
    saveToFirebase(id_eventoStream){
      let postData = {
        chat_habilitado: 0,
        encuestas_habilitado: 0,
        evento_streaming_id:id_eventoStream,
        stream_iniciado:1
      };
      firebase.database().ref('streaming-events').push(postData);
    },
    async getYoutubeVideos(){
      //segundo select
      try {
        let response                = await EventoStreamService.getYoutubeVideos();
        let { resultSet}            = response.data;
        this.youtubeItems_count     = resultSet.count;
        this.youtubeItems           = resultSet.entries;
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getYoutubeVideos() ' + error.message)
        );
      }
    },
    async eliminarEvento(){
      try {
        const response   = await EventoService.deleteEvento(this.evento_form.id);
        let {   success,
          message,
          color
        } = response.data;

        if(success){
          this.hideModalBorrarConfirmacion();
          await this.getEventos(this.curso.id);
        }
        this.toast('b-toaster-bottom-center', true, message, color );

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
        Bugsnag.notify(
            new Error('Método: eliminarEvento() ' + error)
        );
      }
    },
    showModalBorrarConfirmacion(item){
   //   console.log(item);
      this.evento_form.id             =item.id;
      this.evento_form.fecha_inicio   =item.fecha_consumo_inicio;
      this.evento_form.hora_inicio    =item.hora_inicio;
      this.ModalBorrarEvento          = true;
    },
    hideModalBorrarConfirmacion(){
      this.ModalBorrarEvento = false;
      this.resetForm();
    },
    EditMode(item){
      console.log(item);
      this.evento_form = {
                        id :item.evento_id,
                        editar:true,
                        fecha_inicio: item.fecha_consumo_inicio,
                        fecha_finalizacion : item.fecha_consumo_termino,

                        fecha_vigencia_inicio: item.fecha_seleccion_inicio,
                        fecha_vigencia_finalizacion: item.fecha_seleccion_termino,
                        hora_inicio: item.hora_inicio,
                        hora_fin: item.hora_fin,
                        youtube:
                          {
                            label:item.titulo_video,
                            id:item.youtube_video_id,
                            title:item.titulo_video,
                            description:  item.descripcion_video,
                            thumbnails:{
                              default:{
                                url:item.thumbnail_video
                              }
                            }
                          },

      };
      this.ModalCrearEvento = true;
    },
    volver(){
      this.$router.push({name: 'Cursosall'});
    },
    resetForm(){
      this.evento_form = {
        id:0,
        editar:false,
        fecha_inicio: '',
        fecha_finalizacion : '',
        hora_inicio: null,
        hora_fin: null,

        youtube:{ label:'Elegir', id: 0 },
        fecha_vigencia_inicio : '',
        fecha_vigencia_finalizacion : ''
      }
    },

    HideModalCrearEvento(){
      this.ModalCrearEvento=false;
      this.resetForm();
    },
    ShowModalCrearEvento(){
      this.ModalCrearEvento=true;
    },
    async getEventos(curso_id) {

      try {
        await this.getYoutubeVideos();

        let response = await EventoService.getEventosByCurso(curso_id);
        let { resultSet} = response.data;
        this.eventos_items= resultSet;
        this.limiteEventoAlcanzado = (this.eventos_items.length >= 1);

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getEventos() ' + error.message)
        );
      }

    },
    async actualizarEvento(){

      console.log('evento_form.youtube');
      console.log(this.evento_form.youtube);

        const formData = new FormData();
          formData.append("fecha_inicio", this.evento_form.fecha_inicio);
          formData.append("fecha_finalizacion", this.evento_form.fecha_finalizacion);
          formData.append("hora_inicio", this.evento_form.hora_inicio);
          formData.append("hora_fin", this.evento_form.hora_fin);
          formData.append("youtube_video_id",                     this.evento_form.youtube.id);
          formData.append("titulo_video",                         this.evento_form.youtube.title);
          formData.append("descripcion_video",                    this.evento_form.youtube.description);
          formData.append("thumbnail_video",                      this.evento_form.youtube.thumbnails.default.url);
          formData.append("fecha_vigencia_inicio",                 this.evento_form.fecha_vigencia_inicio);
          formData.append("fecha_vigencia_finalizacion",           this.evento_form.fecha_vigencia_finalizacion);
          formData.append("_method", "PUT");

/*        for (const [name, value] of formData.entries()) {
          console.log(`Field: ${name}, Value: ${value}`);
        }*/

      try{
        const response = await EventoService.updateEvento(this.evento_form.id, formData);
        let {   success,
                message,
                color
        } = response.data;
        console.log('response.data');
        console.log(response.data);
        if(success){
          this.toast('b-toaster-bottom-center', true, message, color );
        }
        await this.getEventos(this.curso.id);
        this.HideModalCrearEvento();
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: actualizarEvento() ' + error.message)
        );
      }
    },
    async guardarEvento(){
      try {
        let formData = new FormData();
        formData.append("curso_id",                     this.curso.id);
        formData.append("fecha_inicio",                 this.evento_form.fecha_inicio);
        formData.append("fecha_finalizacion",           this.evento_form.fecha_finalizacion);
        formData.append("hora_inicio",                  this.evento_form.hora_inicio);
        formData.append("hora_fin",                     this.evento_form.hora_fin );

        formData.append("youtube_video_id",                     this.evento_form.youtube.id);
        formData.append("titulo_video",         this.evento_form.youtube.title);
        formData.append("descripcion_video",    this.evento_form.youtube.description);
        formData.append("thumbnail_video",      this.evento_form.youtube.thumbnails.default.url);

        formData.append("fecha_vigencia_inicio",                 this.evento_form.fecha_vigencia_inicio);
        formData.append("fecha_vigencia_finalizacion",           this.evento_form.fecha_vigencia_finalizacion);

        const response = await EventoService.saveEventoCurso(formData);
        let {   success,
                message,
                color,
                  id      //id eventoStream
        } = response.data;
        if(success){
          this.saveToFirebase(id);
          this.toast('b-toaster-bottom-center', true, message, color );
        }
        await this.getEventos(this.curso.id);
        this.HideModalCrearEvento();
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: guardarEvento() ' + error.message)
        );
      }
    }

  },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>