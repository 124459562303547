<template>
      <b-container>

          <b-button @click="showModal()">
              Modal
          </b-button>

    <b-modal
        v-model="ModalNovedades"
        no-close-on-esc 
        no-close-on-backdrop 
        hide-header-close
        scrollable
        title="Novedades"
        size="lg"
        centered
        >
            <b-container>
                <b-row class="text-justify">
                    <b-col cols="12"> 
                        <b-card no-body>
                            <b-tabs pills card vertical>
                                <b-tab title="20 Abril" active>
                                    <b-card-text>
                                        <p v-for="i in 20" :key="i">
                                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                                            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                                        </p>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="29 Abril">
                                    <b-card-text>
                                    <p v-for="i in 18" :key="i">
                                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                                            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                                        </p>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="15 Mayo">
                                    <b-card-text>
                                <p v-for="i in 15" :key="i">
                                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                                            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                                        </p>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>        
                    </b-col>
                </b-row>
            </b-container>
            <template #modal-footer>
                <div class="w-100">
                    <b-form>
                        <input type="checkbox" v-model="status" >
                        <label for="vehicle1" class="ml-2">  Acepto los términos y condiciones</label>
                        <b-button
                            v-b-tooltip.hover.placement.left 
                            title="Hay que aceptar los términos y condiciones para poder continuar"
                            :disabled='!status'
                            @click="hideModal()"
                            variant="link" 
                            size="sm" 
                            class="float-right mx-2 btn-studioAPP">
                            Aceptar
                        </b-button>  
                    </b-form>                      
                </div>
            </template>   
        </b-modal>
      </b-container>
</template>


<script>
export default {
data() {
    return {
        ModalNovedades: false,
        status: false,
    }
},
mounted () {
    
},
methods: {
    showModal() {
        this.ModalNovedades = true;
    },
    hideModal(){
        this.ModalNovedades=false;
    }
},
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>