import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from "../views/Home.vue";

/**NIVELANDO MATERIAL */
/*
import guia from "@/views/guia";
import videotutorial from "@/views/videotutorial";
*/



/**NIVELANDO RUTAS */
import CanopiIndex from '../views/Canopi/Index';
import canopiCursos from '../views/Canopi/Cursos';
import AltaDocente from '../views/Aprende/AdminCentroEscolar/AltaDocente';
import DocentePlantelAdminMain from '../views/Aprende/AdminCentroEscolar/Index';
import DocentePlantelAdmin from '../views/Aprende/AdminCentroEscolar/Create';
import VisorOnlineUsers from '../views/Aprende/VisorOnlineUsers/index';
import Landing from "../components/Landing";
import Analitica from '../views/Aprende/Reportes/Index.vue';
import gestionUsuarios from '../views/Aprende/Usuarios/Index';
import BancoReactivos from '../views/Aprende/BancoReactivos.vue';
import CursosEduca from '../views/Aprende/Cursos.vue';
import dashboardUnidades from '../views/Aprende/dashboardUnidades.vue';
import dashboardUnidadesObjetivos from '../views/Aprende/dashboardUnidadesObjetivos.vue';
import dashboardObjetivos from '../views/Aprende/dashboardObjetivos.vue';
import CrearContenidoCurricula from "../views/Aprende/CrearContenidoCurricula.vue";
import dashboardMateriales from '../views/Aprende/dashboardMateriales.vue';
import ofertaAcademica from '../views/Aprende/OfertaAcademica.vue';
import ContenidoCursoCurricula from '../views/Aprende/ContenidoCursoCurricula.vue';
import ContenidoCursoCurriculaVivo from '../views/Aprende/ContenidoCursoCurriculaVivo2.vue';
import CursosCurricula from "../views/Aprende/CursosCurricula.vue";
import Usuarios from "../views/Aprende/Usuarios.vue";
import EntregasGeneral from "../views/Aprende/Entregables/General.vue";
import CursoEvento from "../views/Aprende/CursoEvento/Index.vue";
import Sesiones from "../views/Aprende/Sesiones/Index.vue";
import SesionEventos from "../views/Aprende/Sesiones/Eventos/Index.vue";
import EventosStream from "../views/Aprende/Sesiones/Eventos/EventosStream";
import RubricaCreate from "../views/Aprende/Rubricas/Create.vue";
import RubricaEdit from "../views/Aprende/Rubricas/Edit.vue";
import Novedades from "../views/Aprende/Novedades/Index.vue";
import DudasCoachVista from "../views/Aprende/Dudas/Index.vue";
import Entregables from "../views/Aprende/Entregables/Index.vue";

import SalonCursos from "../views/Aprende/Entregables/GeneralCursos.vue";
import SalonCursosGrupo from "../views/Aprende/Entregables/GeneralCursosXgrupo.vue";
import Salon from "../views/Aprende/Entregables/Grupos.vue";
import PreviewRubrica from "../views/Aprende/Entregables/ArchivoPreviewRubrica.vue";
import CoachStreamEnVivo from "../views/Aprende/Sesiones/Eventos/StreamVivo/CoachStreamEnVivo";
import VSelect from "../views/PoC/VSelect";


/**NIVELANDO RUTAS */


/** PoC */

import VideojsTest from "../views/PoC/VideojsTest";
import FirebaseUseCase from "../views/PoC/FirebaseUseCase";
import FirebaseCrud from "../views/PoC/FirebaseCrud";

import Temporizador from "../views/PoC/Temporizador";
import PdfExport from "../views/PoC/PdfExport";
import Accordion from "../views/PoC/Accordion.vue";
import YoutubeEmb from "../views/PoC/YoutubeEmb.vue";
import LocalStorageUno from "../views/PoC/LocalstorageTest/Uno";
import LocalStorageDos from "../views/PoC/LocalstorageTest/Dos";
import ChatNivelando from "../views/PoC/ChatView.vue";
import Flex from "../views/PoC/Flex.vue";
import IframeDrive from "../views/PoC/IframeDrive.vue";
import PivotTable from "../views/PoC/PivotTable.vue";
import Sidebar from "../views/PoC/Sidebar.vue";
import ModalNovedades from "../views/PoC/ModalNovedades.vue";
import GooglePicker from "../views/PoC/GooglePicker.vue";
import Quiz from "../views/PoC/Quiz.vue";
import QuizParam from "../views/PoC/QuizParam.vue";
//import EmptyLayout from "../layouts/EmptyLayout";

/** PoC */


//Adding layouts router.
const BlankLayout = () => import("@/layouts/BlankLayout")
const Layout1 = () => import("@/layouts/backend/Layout-1")

//Adding page content router.
const Dashbord1 = () => import('@/views/backend/Dashboards/Dashbord1')
const Customer = () => import('@/views/backend/Dashboards/Customer')
const Product = () => import('@/views/backend/Dashboards/Product')
const Order = () => import('@/views/backend/Dashboards/Order')
const Calendar = () => import('@/views/backend/Dashboards/Calendar')
const ProductAdd = () => import('@/views/backend/Dashboards/ProductAdd')
const CustomerAdd = () => import('@/views/backend/Dashboards/CustomerAdd')
const CustomerEdit = () => import('@/views/backend/Dashboards/CustomerEdit')
const CustomerView = () => import('@/views/backend/Dashboards/CustomerView')
const OrderDetails = () => import('@/views/backend/Dashboards/OrderDetails')
const OrderNew = () => import('@/views/backend/Dashboards/OrderNew')

//ui elements
const UiAlerts = () => import('@/views/backend/Ui/UiAlerts')
const UiAvatars = () => import('@/views/backend/Ui/UiAvatars')
const UiBadges = () => import('@/views/backend/Ui/UiBadges')
const UiBoxShadows = () => import('@/views/backend/Ui/UiBoxShadows')
const UiBreadcrumbs = () => import('@/views/backend/Ui/UiBreadcrumbs')
const UiButtonGroups = () => import('@/views/backend/Ui/UiButtonGroups')
const UiButtons = () => import('@/views/backend/Ui/UiButtons')
const UiCards = () => import('@/views/backend/Ui/UiCards')
const UiCarousels = () => import('@/views/backend/Ui/UiCarousels')
const UiColors = () => import('@/views/backend/Ui/UiColors')
const UiEmbed = () => import('@/views/backend/Ui/UiEmbed')
const UiGrids = () => import('@/views/backend/Ui/UiGrids')
const UiHelperClasses = () => import('@/views/backend/Ui/UiHelperClasses')
const UiImages = () => import('@/views/backend/Ui/UiImages')
const UiListGroups = () => import('@/views/backend/Ui/UiListGroups')
const UiMediaObjects = () => import('@/views/backend/Ui/UiMediaObjects')
const UiModals = () => import('@/views/backend/Ui/UiModals')
const UiNotifications = () => import('@/views/backend/Ui/UiNotifications')
const UiPaginations = () => import('@/views/backend/Ui/UiPaginations')
const UiPopOvers = () => import('@/views/backend/Ui/UiPopOvers')
const UiProgressBars = () => import('@/views/backend/Ui/UiProgressBars')
const UiTabs = () => import('@/views/backend/Ui/UiTabs')
const UiTooltips = () => import('@/views/backend/Ui/UiTooltips')
const UiTypography = () => import('@/views/backend/Ui/UiTypography')

//Form elements
const Checkbox = () => import('@/views/backend/Forms/FormControls/Checkbox')
const Elements = () => import('@/views/backend/Forms/FormControls/Elements')
const Inputs = () => import('@/views/backend/Forms/FormControls/Inputs')
const Radio = () => import('@/views/backend/Forms/FormControls/Radio')
const Switch = () => import('@/views/backend/Forms/FormControls/Switch')
const TextArea = () => import('@/views/backend/Forms/FormControls/TextArea')
const Validations = () => import('@/views/backend/Forms/FormControls/Validations')
const Datepicker = () => import('@/views/backend/Forms/Formwidget/Datepicker')
const Fileupload = () => import('@/views/backend/Forms/Formwidget/Fileupload')
const FormQuill = () => import('@/views/backend/Forms/Formwidget/FormQuill')
const SelectComponents = () => import('@/views/backend/Forms/Formwidget/SelectComponents')
const Simple = () => import('@/views/backend/Forms/FormWizard/Simple')
const Validate = () => import('@/views/backend/Forms/FormWizard/Validate')
const Vertical = () => import('@/views/backend/Forms/FormWizard/Vertical')

//table elements
const BasicTable = () => import('@/views/backend/Table/BasicTable')
const DataTable = () => import('@/views/backend/Table/DataTable')
const EditTable = () => import('@/views/backend/Table/EditTable')

//icon elements
const Dripicons = () => import('@/views/backend/Icons/Dripicons')
const FontAwsome = () => import('@/views/backend/Icons/FontAwsome')
const LineAwsome = () => import('@/views/backend/Icons/LineAwsome')
const Remixicons = () => import('@/views/backend/Icons/Remixicons')

//chart elements
const AmChart = () => import('@/views/backend/Chart/AmChart')
const ApexChart = () => import('@/views/backend/Chart/ApexChart')
const HighChart = () => import('@/views/backend/Chart/HighChart')
const MorrisChart = () => import('@/views/backend/Chart/MorrisChart')

//map elements
const GoogleMap = () => import('@/views/backend/Map/GoogleMap')
const VectorMap = () => import('@/views/backend/Map/VectorMap')

//auth elements
const SignIn = () => import('@/views/backend/Auth/SignIn')
const SignUp = () => import('@/views/backend/Auth/SignUp')
const RecoverPassword = () => import('@/views/backend/Auth/RecoverPassword')
const LockScreen = () => import('@/views/backend/Auth/LockScreen')
const ConfirmMail = () => import('@/views/backend/Auth/ConfirmMail')

//pages elements
const Pricing1 = () => import('@/views/backend/Pages/Pricing/Pricing1')
const Pricing2 = () => import('@/views/backend/Pages/Pricing/Pricing2')
const Pricing3 = () => import('@/views/backend/Pages/Pricing/Pricing3')
const Pricing4 = () => import('@/views/backend/Pages/Pricing/Pricing4')
const Timeline1 = () => import('@/views/backend/Pages/Timeline/Timeline1')
const Timeline2 = () => import('@/views/backend/Pages/Timeline/Timeline2')
const Timeline3 = () => import('@/views/backend/Pages/Timeline/Timeline3')
const Timeline4 = () => import('@/views/backend/Pages/Timeline/Timeline4')
const Error404 = () => import('@/views/backend/Pages/Error/Error404')
const Error500 = () => import('@/views/backend/Pages/Error/Error500')
const BlankPage = () => import('@/views/backend/Pages/BlankPage')

// const CommingSoon = () => import('@/views/backend/Pages/CommingSoon')
const FAQ = () => import('@/views/backend/Pages/FAQ')
const Invoice = () => import('@/views/backend/Pages/Invoice')
const AddInvoice = () => import('@/views/backend/Pages/AddInvoice')
const ViewInvoice = () => import('@/views/backend/Pages/ViewInvoice')
const Maintainance = () => import('@/views/backend/Pages/Maintainance')
const Subsribers = () => import('@/views/backend/Pages/Subsribers')
const Comingsoon = () => import('@/views/backend/Pages/Comingsoon')

//app element
const UserAccountSetting = () =>import('@/views/backend/App/UserManagement/UserAccountSetting')
const UserAdd = () =>import('@/views/backend/App/UserManagement/UserAdd')
const UserList = () =>import('@/views/backend/App/UserManagement/UserList')
const UserProfile = () =>import('@/views/backend/App/UserManagement/UserProfile')
const userPrivacySettings = () =>import('@/views/backend/App/UserManagement/UserPrivacySetting')
const UserProfileEdit = () =>import('@/views/backend/App/UserManagement/UserProfileEdit')
const Chat = () =>import('@/views/backend/App/Chat')
const Todo = () =>import('@/views/backend/App/Todo')
const Accountsetting =() => import('@/views/backend/Pages/Extrapages/AccountSettings')
const privacypolicy = () => import('@/views/backend/Pages/Extrapages/PrivacyPolicy')
const PrivacySettings = () =>import('@/views/backend/Pages/Extrapages/PrivacySettings')
const TermsOfUse = () =>import('@/views/backend/Pages/Extrapages/TermsOfUse')
const Icon = () => import('@/views/backend/Pages/Extrapages/Icon')

Vue.use(VueRouter)

const childRoute = () => [
//MATERIALES
/*  {
    path: '/guia',
    name: 'guia',
    component: guia
  },
  {
    path: '/videotutorial',
    name: 'videotutorial',
    component: videotutorial
  },*/


//PoC//

  {
    path: '/videojs',
    name: 'VideojsTest',
    component: VideojsTest
  },
  {
    path: '/firebase',
    name: 'FirebaseUseCase',
    component: FirebaseUseCase
  },
  {
    path: '/firebaseCrud',
    name: 'firebaseCrud',
    component: FirebaseCrud
  },
  {
    path: '/temporizador',
    name: 'Temporizador',
    component: Temporizador
  },

  {
    path: '/v-select',
    name: 'VSelect',
    component: VSelect
  },
  {
    path: '/pdf-export',
    name: 'pdfExport',
    component: PdfExport
  },
  {
    path: '/banco-reactivos',
    name: 'BancoReactivos',
    component: BancoReactivos
  },
  {
    path: '/accordion',
    name: 'accordion',
    component: Accordion
  },
  {
    path: '/YoutubeEmb',
    name: 'YoutubeEmb',
    component: YoutubeEmb
  },
  {
    path: '/LocalStorageDos',
    name: 'LocalStorageDos',
    component: LocalStorageDos
  },
  {
    path: '/LocalStorageUno',
    name: 'LocalStorageUno',
    component: LocalStorageUno
  },
  {
    path: '/quizparam',
    name: 'QuizParam',
    component: QuizParam
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: Quiz
  },
  {
    path: '/chat-nivelando',
    name: 'chatNivelando',
    component: ChatNivelando
  },

  {
    path: '/flex',
    name: 'Flex',
    component: Flex
  },
{
  path: '/IframeDrive',
  name: 'IframeDrive',
  component: IframeDrive
},
{
  path: '/PivotTable',
  name: 'PivotTable',
  component: PivotTable
},
{
  path: '/google-picker',
  name: 'googlePicker',
  component: GooglePicker
},
{
  path: '/sidebar',
  name: 'sidebar',
  component: Sidebar
},
{
  path: '/modal-novedades',
  name: 'ModalNovedades',
  component: ModalNovedades
},
/*NIVELANDO RUTAS*/

  {
    path: '/registro-docentes-invita/:id',
    name: 'AltaDocente',
    component: AltaDocente
  },


  {
    path: '/centro-escolar',
    name: 'CentroEscolar',
    component: DocentePlantelAdminMain
  },
  {
    path: '/admin-docente-escolar',
    name: 'AdminCentroEscolar',
    component: DocentePlantelAdmin
  },
  {
    path: '/user-online-check',
    name: 'VisorOnlineUsers',
    component: VisorOnlineUsers
  },
{
  path: '/reportes',
  name: 'reportes',
  component: Analitica
},
{
  path: '/gestion-usuarios',
  name: 'gestionUsuarios',
  component: gestionUsuarios
},
{
  path: '/sesiones',
      name: 'Sesiones',
    component: Sesiones
},
{
  path: '/sesion-eventos',
  name: 'SesionEventos',
  component: SesionEventos
},
{
  path: '/eventos-stream',
  name: 'EventosStream',
  component: EventosStream
},
{
  path: '/preview-rubrica',
  name: 'Preview-rubrica',
  component: PreviewRubrica
},
  {
    path: '/entregas-general/:id',
    name: 'entregasGeneral',
    component: EntregasGeneral
  },
{
  path: '/miSalon',
  name: 'Salon',
  component: Salon
},
{
  path: '/salon-cursos',
  name: 'salon-cursos',
  component:SalonCursos
},
  {
    path: '/salon-cursos-grupo/:id',
    name: 'salon-cursos-grupo',
    component:SalonCursosGrupo
  },
{
  path: '/entregables/:id/c/:curso',
  name: 'Entregables',
  component: Entregables,
  props: true
},
{
  path: '/rubricas/nueva/:id/act/:ac',
  name: 'Creando rúbrica',
  component: RubricaCreate
},
{
  path: '/rubricas/edit/:id',
  name: 'Editando rúbrica',
  component: RubricaEdit
},
{
  path: '/noticias-panel',
  name: 'Noticias',
  component: Novedades
},
{
  path: '/dudas-coach',
  name: 'DudasCoachVista',
  component: DudasCoachVista
},
{
  path: '/cursos-all',
  name: 'Cursosall',
  component: CursosEduca
},
{
  path: '/Dashboard',
  name: 'Dashboard',

},
{
  path: '/dashboardUnidades',
  name: 'dashboardUnidades',
  component:dashboardUnidades
},
{                                   //id de la unidad
  path: '/dashboardUnidadesObjetivos/:id',
  name: 'dashboardUnidadesObjetivos',
  component:dashboardUnidadesObjetivos
},
{
  path: '/dashboardObjetivos',
  name: 'dashboardObjetivos',
  component:dashboardObjetivos,
},
{
  path: '/crear-contenido-curricula',
  name: 'CrearContenidoCurricula',
  component: CrearContenidoCurricula
},
{
  path: '/dashboardMateriales',
  name: 'dashboardMateriales',
  component: dashboardMateriales
},
{
  path: '/ofertaAcademica',
  name: 'ofertaAcademica',
  component:ofertaAcademica
},
{
  path: '/cursos-curricula',
  name: 'CursosCurricula',
  component: CursosCurricula
},
{
  path: '/categorias-indicadores',
  name: 'CategoriasIndicadores',

},

{
  path: '/contenido-curso-curricula',
  name: 'ContenidoCursoCurricula',
  component: ContenidoCursoCurricula
},

{
  path: '/contenido-curso-curricula-vivo',
  name: 'ContenidoCursoCurriculaVivo',
  component: ContenidoCursoCurriculaVivo
},

{
  path: '/sesionesdocente',
  name: 'Sesiones Docente',

},
  {
    path: '/curso-evento/:id',
    name:'CursoEvento',
    component: CursoEvento
  },
{
  path: '/eventos-stream',
  name: 'CoachStreamEnVivo',
  component: CoachStreamEnVivo
},
{
  path: '/usuarios',
  name: 'Usuarios',
  component: Usuarios
},

/*NIVELANDO RUTAS*/

  {
    //
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: '',
    name: 'layout.dashboard1',
    meta: {  name: 'Dashboard1' },
    component: Dashbord1
  },
  {
    path: 'customer',
    name: 'layout.customer',
    meta: {  name: 'customer' },
    component: Customer
  },
  {
    path: 'product',
    name: 'layout.product',
    meta: {  name: 'product' },
    component: Product
  },
  {
    path: 'order',
    name: 'layout.order',
    meta: {  name: 'order' },
    component: Order
  },
  {
    path: 'customeradd',
    name: 'customer.customeradd',
    meta: {  name: 'customeradd' },
    component: CustomerAdd
  },
  {
    path: 'customeredit',
    name: 'customer.customeredit',
    meta: {  name: 'customeredit' },
    component: CustomerEdit
  },
  {
    path: 'customerview',
    name: 'customer.customerview',
    meta: {  name: 'customerview' },
    component: CustomerView
  },
  {
    path: 'productadd',
    name: 'product.productadd',
    meta: {  name: 'productadd' },
    component: ProductAdd
  },
  {
    path: 'ordernew',
    name: 'order.ordernew',
    meta: {  name: 'ordernew' },
    component: OrderNew
  },
  {
    path: 'orderdetails',
    name: 'order.orderdetails',
    meta: {  name: 'orderdetails' },
    component: OrderDetails
  },
  {
    path: 'calendar',
    name: 'layout.calendar',
    meta: {  name: 'calendar' },
    component: Calendar
  },
  {
    path: 'ui-avatars',
    name: 'Ui.avatars',
    meta: {  name: 'Ui Avatars' },
    component: UiAvatars
  },
  {
    path: 'ui-alert',
    name: 'Ui.alerts',
    meta: {  name: 'Ui Alert' },
    component: UiAlerts
  },
  {
    path: 'ui-badges',
    name: 'Ui.badges',
    meta: {  name: 'Ui Badges' },
    component: UiBadges

  },
  {
    path: 'ui-boxshadows',
    name: 'Ui.boxshadows',
    meta: {  name: 'Ui Box Shadows' },
    component: UiBoxShadows
  },
  {
    path: 'ui-breadcrumbs',
    name: 'Ui.breadcrumbs',
    meta: {  name: 'Ui Breadcrumbs' },
    component: UiBreadcrumbs
  },
  {
    path: 'ui-button-groups',
    name: 'Ui.button-groups',
    meta: {  name: 'Ui Button Groups' },
    component: UiButtonGroups
  },
  {
    path: 'ui-buttons',
    name: 'Ui.buttons',
    meta: {  name: 'Ui Buttons' },
    component: UiButtons
  },
  {
    path: 'ui-cards',
    name: 'Ui.cards',
    meta: {  name: 'Ui Cards' },
    component: UiCards
  },
  {
    path: 'ui-carousels',
    name: 'Ui.carousels',
    meta: {  name: 'Ui Carousels' },
    component: UiCarousels
  },
  {
    path: 'ui-colors',
    name: 'Ui.colors',
    meta: {  name: 'Ui Colors' },
    component: UiColors
  },
  {
    path: 'ui-embed-videos',
    name: 'Ui.Embed-videos',
    meta: {  name: 'Ui Embed' },
    component: UiEmbed
  },
  {
    path: 'ui-grids',
    name: 'Ui.grids',
    meta: {  name: 'Ui Grids' },
    component: UiGrids
  },
  {
    path: 'ui-helper-classes',
    name: 'Ui.helper-classes',
    meta: {  name: 'Ui Helper Classes' },
    component: UiHelperClasses
  },
  {
    path: 'ui-images',
    name: 'Ui.images',
    meta: {  name: 'Ui Images' },
    component: UiImages
  },
  {
    path: 'ui-list-groups',
    name: 'Ui.list-groups',
    meta: {  name: 'Ui List Groups' },
    component: UiListGroups
  },
  {
    path: 'ui-media-objects',
    name: 'Ui.media-objects',
    meta: {  name: 'Ui Media Objects' },
    component: UiMediaObjects
  },
  {
    path: 'ui-modals',
    name: 'Ui.modals',
    meta: {  name: 'Ui Modals' },
    component: UiModals
  },
  {
    path: 'ui-notifications',
    name: 'Ui.notifications',
    meta: {  name: 'Ui Notifications' },
    component: UiNotifications
  },
  {
    path: 'ui-paginations',
    name: 'Ui.paginations',
    meta: {  name: 'Ui Pagination' },
    component: UiPaginations
  },
  {
    path: 'ui-popovers',
    name: 'Ui.popovers',
    meta: {  name: 'Ui popovers' },
    component: UiPopOvers
  },
  {
    path: 'ui-progressbars',
    name: 'Ui.progressbars',
    meta: {  name: 'Ui Progressbar' },
    component: UiProgressBars
  },
  {
    path: 'ui-tabs',
    name: 'Ui.tabs',
    meta: {  name: 'Ui Tabs' },
    component: UiTabs
  },
  {
    path: 'ui-tooltips',
    name: 'Ui.tooltips',
    meta: {  name: 'Ui Tooltips' },
    component: UiTooltips
  },
  {
    path: 'ui-typography',
    name: 'Ui.typographys',
    meta: {  name: 'Ui Typography' },
    component: UiTypography
  },
  {
    path: 'form-checkbox',
    name: 'controls.form-checkbox',
    meta: {  name: 'Form Checkbox' },
    component: Checkbox
  },
  {
    path: 'form-layouts',
    name: 'controls.form-layout',
    meta: {  name: 'Form Layouts' },
    component: Elements
  },
  {
    path: 'form-input',
    name: 'controls.form-input',
    meta: {  name: 'Form Input' },
    component: Inputs
  },
  {
    path: 'form-radio',
    name: 'controls.form-radio',
    meta: {  name: 'Form Radio' },
    component: Radio
  },
  {
    path: 'form-switch',
    name: 'controls.form-switch',
    meta: {  name: 'Form Switch' },
    component: Switch
  },
  {
    path: 'form-textarea',
    name: 'controls.form-textarea',
    meta: {  name: 'Form TextArea' },
    component: TextArea
  },
  {
    path: 'form-validation',
    name: 'controls.form-validation',
    meta: {  name: 'Form Validation' },
    component:Validations
  },
  {
    path: 'form-datepicker',
    name: 'widget.form-datepicker',
    meta: {  name: 'Form Datepicker' },
    component:Datepicker
  },
  {
    path: 'form-file-uploader',
    name: 'widget.form-file-uploader',
    meta: {  name: 'Form Fileupload' },
    component:Fileupload
  },
  {
    path: 'form-quill',
    name: 'widget.form-quill',
    meta: {  name: 'Form quill' },
    component:FormQuill
  },
  {
    path: 'form-select',
    name: 'widget.form-select',
    meta: {  name: 'Form Select2' },
    component:SelectComponents
  },
  {
    path: 'form-wizard',
    name: 'wizard.form-wizard',
    meta: {  name: 'Form Wizard' },
    component:Simple
  },{
    path: 'form-wizard-validate',
    name: 'wizard.form-wizard-validate',
    meta: {  name: 'Form Wizard Validate' },
    component:Validate
  },{
    path: 'form-wizard-vertical',
    name: 'wizard.form-wizard-vertical',
    meta: {  name: 'Form Wizard Vertical' },
    component:Vertical
  },
  {
    path: 'basic-table',
    name: 'table.basic-table',
    meta: {  name: 'Basic Table ' },
    component:BasicTable
  },
  {
    path: 'data-table',
    name: 'table.data-table',
    meta: {  name: 'Data Table ' },
    component:DataTable
  },
  {
    path: 'edit-table',
    name: 'table.edit-table',
    meta: {  name: 'Edit Table ' },
    component:EditTable
  },
  {
    path: 'icon-dripicons',
    name: 'icon.dripicon',
    meta: {  name: 'Dripicons ' },
    component:Dripicons
  },{
    path: 'icon-fontawsome',
    name: 'icon.fontawsome',
    meta: {  name: 'FontAwsome ' },
    component:FontAwsome
  },{
    path: 'icon-lineawsome',
    name: 'icon.lineawsome',
    meta: {  name: 'LineAwsome ' },
    component:LineAwsome
  },{
    path: 'icon-remixicon',
    name: 'icon.remixicon',
    meta: {  name: 'Remixicon ' },
    component:Remixicons
  },
  {
    path: 'am-chart',
    name: 'chart.amchart',
    meta: {  name: 'Amchart' },
    component:AmChart
  },
  {
    path: 'apex-chart',
    name: 'chart.apexchart',
    meta: {  name: 'Apexchart' },
    component:ApexChart
  },
  {
    path: 'high-chart',
    name: 'chart.highchart',
    meta: {  name: 'Highchart' },
    component:HighChart
  },
  {
    path: 'morris-chart',
    name: 'chart.morrischart',
    meta: {  name: 'Morrischart' },
    component:MorrisChart
  },
  {
    path: 'google-map',
    name: 'map.google',
    meta: {  name: 'Google Map' },
    component:GoogleMap
  },
  {
    path: 'vector-map',
    name: 'map.vector',
    meta: {  name: 'Vector Map' },
    component:VectorMap
  },
  {
    path: 'user-add',
    name: 'app.user-add',
    meta: {  name: 'user-add' },
    component:UserAdd
  },
  {
    path: 'user-list',
    name: 'app.user-list',
    meta: {  name: 'User Add' },
    component:UserList
  },
  {
    path: 'user-profile',
    name: 'app.user-profile',
    meta: {  name: 'User Profile' },
    component:UserProfile
  },
  {
    path: 'user-privacy-settings',
    name: 'app.user-privacy-setting',
    meta: {  name: 'user-privacy-setting' },
    component:userPrivacySettings
  },
  {
    path: 'User-profile-edit',
    name: 'app.user-profile-edit',
    meta: {  name: 'user-profile-edit' },
    component:UserProfileEdit
  },
  {
    path: 'User-account-setting',
    name: 'app.user-Account-setting',
    meta: {  name: 'user account setting' },
    component:UserAccountSetting
  },
  {
    path: 'chat',
    name: 'app.chat',
    meta: {  name: 'Chat' },
    component:Chat
  },
  {
    path: 'todo',
    name: 'app.todo',
    meta: {  name: 'Todo' },
    component:Todo
  },
  {
    path: 'privacy-settings',
    name: 'app.Privacysettings',
    meta: {  name: 'Privacysettings' },
    component:PrivacySettings
  },
  {
    path: 'terms-of-use',
    name: 'app.Termsofuse',
    meta: {  name: 'Termsofuse' },
    component:TermsOfUse
  },
  {
    path: 'privacy-policy',
    name: 'app.privacypolicy',
    meta: {  name: 'privacypolicy' },
    component:privacypolicy
  },
  {
    path: 'account-setting',
    name: 'app.Accountsetting',
    meta: {  name: 'Accountsetting' },
    component:Accountsetting
  },

]
const authchildRoute = () =>[
  {
    path: 'sign-in',
    name: 'auth.login',
    meta: {  name: 'SignIn' },
    component: SignIn
  },
  {
    path: 'sign-up',
    name: 'auth.register',
    meta: {  name: 'SignUp' },
    component: SignUp
  },
  {
    path: 'recover-password',
    name: 'auth.recover-password',
    meta: {  name: 'Recover Password' },
    component: RecoverPassword
  },
  {
    path: 'lock-screen',
    name: 'auth.lock-screen',
    meta: {  name: 'Lock Screen' },
    component: LockScreen
  },
  {
    path: 'confirm-mail',
    name: 'auth.confirm-mail',
    meta: {  name: 'Confirm Mail' },
    component: ConfirmMail
  },

]
const pageschildRoute = () =>[
  {
    path: 'error-404',
    name: 'error.404',
    meta: {  name: 'Error 404' },
    component: Error404
  },
  {
    path: 'error-500',
    name: 'error.500',
    meta: {  name: 'Error 500' },
    component: Error500
  },
  {
    path: 'pages-maintainance',
    name: 'pages.maintenance',
    meta: {  name: 'Maintaiance' },
    component: Maintainance
  },
  {
    path: 'pages-comingsoon',
    name: 'pages.comingsoon',
    meta: {  name: 'Coming soon' },
    component: Comingsoon
  },
]
const extrapageschildRoute = () =>[
  {
    path: 'timeline-1',
    name: 'time.line',
    meta: {  name: 'TimeLine-1' },
    component: Timeline1
  },
  {
    path: 'timeline-2',
    name: 'time.line1',
    meta: {  name: 'TimeLine-2' },
    component: Timeline2
  },
  {
    path: 'timeline-3',
    name: 'time.line2',
    meta: {  name: 'TimeLine-3' },
    component: Timeline3
  },
  {
    path: 'timeline-4',
    name: 'time.line3',
    meta: {  name: 'TimeLine-4' },
    component: Timeline4
  },
  {
    path: 'pricing-1',
    name: 'price.pay',
    meta: {  name: 'Pricing-1' },
    component: Pricing1
  },
  {
    path: 'pricing-2',
    name: 'price.pay1',
    meta: {  name: 'Pricing-2' },
    component: Pricing2
  },
  {
    path: 'pricing-3',
    name: 'price.pay2',
    meta: {  name: 'Pricing-3' },
    component: Pricing3
  },
  {
    path: 'pricing-4',
    name: 'price.pay3',
    meta: {  name: 'Pricing-4' },
    component: Pricing4
  },
  {
    path: 'pages-invoice',
    name: 'pages.invoices',
    meta: {  name: 'Pages Invoice' },
    component: Invoice
  },
  {
    path: 'pages-addinvoice',
    name: 'pages.addinvoices',
    meta: {  name: 'Pages Add Invoice' },
    component: AddInvoice
  },
  {
    path: 'pages-viewinvoice',
    name: 'pages.viewinvoices',
    meta: {  name: 'Pages View Invoice' },
    component: ViewInvoice
  },
  {
    path: 'pages-subscriber',
    name: 'pages.subscribers',
    meta: {  name: 'Pages Subscribers' },
    component: Subsribers
  },
  {
    path: 'pages-faq',
    name: 'pages.faq',
    meta: {  name: 'Pages FAQ' },
    component: FAQ
  },
  {
    path: 'pages-blank-page',
    name: 'pages.blank-page',
    meta: {  name: 'Pages Blank Page ' },
    component: BlankPage
  },
  {
    path: 'pages-icon',
    name: 'pages.icon',
    meta: {  name: 'Pages Icon' },
    component: Icon
  },
]

const routes = [

  {
    path: '/canopi',
    name: 'canopi',
    component: BlankLayout,
    children: [
      {
        path: '/canopi',
        name: 'canopi.index',
        meta: {  name: 'canopi index' },
        component: CanopiIndex
      },
    ]
  },

  {
    path: '/canopi-cursos',
    name: 'canopi',
    component: BlankLayout,
    children: [
      {
        path: '/canopi-cursos',
        name: 'canopi.index',
        meta: {  name: 'canopi index' },
        component: canopiCursos
      },
    ]
  },


  {
    path: '/',
    name: 'landing',
    component: BlankLayout,
    children: [
      {
        path: '/',
        name: 'landing.index',
        meta: {  name: 'Landing Page' },
        component: Landing
      },
    ]
  },
  {
    path: '/',
    name: '',
    component: Layout1,
    children: childRoute()
  },
  {
    path: '/auth',
    name: 'auth',
    component: BlankLayout,
    children: authchildRoute()
  },
  {
    path: '/pages',
    name: 'pages',
    component: BlankLayout,
    children: pageschildRoute()
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: Layout1,
    children: extrapageschildRoute()
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
