<template>
  <b-container>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Usuarios en línea"></b-jumbotron>
            </div>

            <b-alert
                class="p-3"
                show
                variant="info"
                dismissible
            >
              La sección muestra una lista de usuarios actualmente conectados, diferenciando entre docentes y alumnos. Cada usuario se representa con su nombre y un indicador de estado de conexión.

              El indicador de estado de conexión se representa mediante un círculo que puede ser verde o gris. El círculo verde indica que el usuario está conectado, mientras que el círculo gris indica que el usuario no está conectado.
            </b-alert>
            <b-row class="d-flex align-items-end justify-content-end my-1">
              <div class="col-sm-12 my-2">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar"
                >
                </b-form-input>
              </div>
            </b-row>

            <b-table class="table data-table"
                     striped="striped"
                     hover="hover"
                     outlined="outlined"
                     :items="usuarios_items"
                     :fields="usuarios_headers"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">
                  {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                </h5>
              </template>
              <template #emptyfiltered="scope">
                <h5 class="text-center">
                  {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                </h5>
              </template>
              <template #cell(progreso_obligatorio)="row">
                <div v-if="`${row.item.progreso_obligatorio}`!=='0' ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </div>
              </template>
              <template #cell(cursoEnVivo)="row">
                <div v-if="`${row.item.cursoEnVivo}`!=='0' ">
                  <!--                         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                             <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                           </svg>-->

                  <div class="d-flex justify-content-around align-items-center">
                    <b-button v-if="row.item.cursoEnVivo===1"
                              variant="secondary" v-b-modal.modal-prevent-closing size="sm"
                              @click="cursosEventos(row.item.id)" class="mr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-reels-fill" viewBox="0 0 16 16">
                        <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                        <path d="M9 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                        <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7z"/>
                      </svg>
                    </b-button>
                  </div>

                </div>
              </template>



              <template #cell(actions)="row">
                <div class="d-flex justify-content-around align-items-center">

                  <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                            @click="EditMode(row.item)" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </b-button>

                  <b-button variant="danger" size="sm" @click="ShowmodalBorrarConfirmacion(row.item)" class="ml-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </b-button>

                </div>
              </template>

            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>






    <b-card class="user-card">
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <div class="user-status-indicator" :class="{ 'user-online': isConnected, 'user-offline': !isConnected }"></div>
      </div>
      <div>
        <span>{{ userName }}</span>
      </div>
    </div>
    </b-card>
  </b-container>
</template>

<script>

//import toast from "@mixins/ToastMixin.js";
//import Bugsnag from "@bugsnag/js";
export default {
  name: "index",
  data() {
    return {
      userName: 'John Doe',
      isConnected: true, // Cambia el valor de isConnected según el estado de conexión del usuario

      usuarios_items:[

      ],

      usuarios_headers: [
        {  label: '-', key: 'fecha', class: 'text-right' },
        {   label: 'Nombre',        key: 'nombre', class: 'text-start'},
        {  label: 'Acciones',       key: 'actions', class: 'text-center' }
      ],


      ///
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: '',
    };
  },
}
</script>

<style scoped>
.user-card {
  max-width: 200px;
}
.user-status-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.user-online {
  background-color: green;
}

.user-offline {
  background-color: gray;
}
</style>