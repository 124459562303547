<template>
  <b-container>
    <!-- modal borrar curso -->
    <b-modal
        v-model="modalCrearPalabra"
        persistent
        title="Dar de alta palabra clave"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="my-1" >
          <b-col sm="3">
            <label>Palabra clave:</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
                v-model="palabraClave"

            ></b-form-input>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="closeModalPalabraClave()"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              :disabled="disabledBtnGuardar"
              @click="savePalabraClave()"
          >
            Guardar
          </b-button>
        </div>
      </template>

    </b-modal>
    <b-row class="m-1 text-left">
      <b-col cols="10">
          <v-select
            placeholder="Palabras clave"
            v-model="arrayPalabrasClave"
            multiple
            taggable
            push-tags
            :options="arreglo"
            >
            </v-select>
      </b-col>
      <b-col cols="2">
        <b-button
            variant="primary"
            size="sm"
            class="float-left"
            @click="openModalPalabraClave()"
        >
          Nueva palabra
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import toast from "../../mixins/ToastMixin.js";
import PalabraClaveService from "../../services/PalabraClaveService";
//import Bugsnag from "@bugsnag/js";
export default {
  name:"VueSelect",
  mixins:[toast],
  data() {
    return {
      modalCrearPalabra:false,
      disabledBtnGuardar:false,
      palabraClave:null,
      arreglo:[],
      arrayPalabrasClave:[],
    }
  },
  mounted() {
    this.getPalabrasClave()
  },
  methods: {
    async getPalabrasClave(){
      this.toast('b-toaster-bottom-center', true, "Un momento porfavor, cargando datos...", "info");

        let response = await PalabraClaveService.getPalabraClave();
        let {   message,
          palabrasClave,
          color,
          cantidad
        } = response.data;
        this.arreglo            = palabrasClave;
        this.totalRows              = cantidad;
        console.log("palabras clave:  ---->")
        console.log(this.arreglo)
        this.toast('b-toaster-bottom-center', true, message, color);
    },
    openModalPalabraClave(){
      this.palabraClave=null
      this.modalCrearPalabra=true
    },
    closeModalPalabraClave(){
      this.palabraClave = null
      this.modalCrearPalabra=false
    },
    async savePalabraClave()
    {
      this.toast('b-toaster-bottom-center', true, "Un momento porfavor, guardando palabra clave...", "info");
      this.closeModalPalabraClave
      //this.disabledBtnGuardar=true

      let formData = new FormData()
      formData.append("palabra_clave",this.palabraClave)
      formData.append("pregunta_id",1)
      const response = await PalabraClaveService.save(formData)

      let {
        success
/*
        ,message
        ,color
*/
      } = response.data;
      if(success===true){
        this.toast('b-toaster-bottom-center', true, "Palabra guardada exitosamente...", "info");
        await this.getPalabrasClave()
        //this.toast('b-toaster-bottom-center', true, message, color );

      }
    }
  },
}
</script>
