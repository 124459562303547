<template>
  <div id="app" class="container mt-5">

    <h2 class="border-bottom pb-2 mb-4">Pivot Table </h2>

    <div class="my-5">

      <pivot-table
          :data="asyncData"
          :row-fields="rowFields"
          :col-fields="colFields"
          :reducer="reducer"
          :is-data-loading="isDataLoading"
      >

        <template v-slot:value="{ value }">

          <div v-if="value">
            <input placeholder=" ____ / 100"
                   type="text"
                   @keyup="guardarCambios(value.idTarea1, value.idUser)"
                   @change="guardarCambios(value.idTarea1, value.idUser)"
            >
            <a :href="`${value.tareaURL}`">Ver</a>
          </div>

          <div v-else>
            <small class="txt_SinEntregar"> Sin entregar</small>
          </div>

        </template>

        <template v-slot:nombreNameHeader="{ value }">
          {{ value }}
        </template>



        <template v-slot:loading>
          <div class="text-center">
            <svg aria-hidden="true" data-prefix="fas" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-spinner fa-fw fa-pulse"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" class=""></path></svg>
            Cargando...
          </div>
        </template>


      </pivot-table>
    </div>


  </div>
</template>

<script>
import data from './data';
import PivotTable from './../../components/PivotTable';

export default {
  name: 'app',
  components: {  PivotTable },
  data: () => {
    return {
      data,
      asyncData: [],

      reducer: (acc, item) => item,

      rowFields: [{
        getter: item => item.nombre,
        label: 'nombre',
        headerSlotNames: [ 'nombreNameHeader']
      }],


      colFields: [{
        getter: item => item.tarea,
        label: 'Tarea'
      }],

    }
  },
  methods: {
    guardarCambios(idTarea1,idUser){
      console.log(`tarea id: ${idTarea1}  y   user ${idUser} `);
    },
    showModal(idTarea1,idUser ) {
      console.log(`tarea id: ${idTarea1}  y   user ${idUser} `);
    }
  },
  created: function() {
    // Simulate async data loading
    this.isDataLoading = true
    setTimeout(() => {
      this.asyncData = data
      this.isDataLoading = false
    }, 1000)
  }
}
</script>

<style scoped lang="scss">


.txt_SinEntregar{
  letter-spacing: .01785714em;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #c5221f;
}

$enable-rounded: false;
$table-cell-padding: .5rem; // default in bs5
$table-cell-padding-sm: .25rem; // default in bs5
@import '~bootstrap/scss/bootstrap.scss';

/* Table with aria-busy attribute */
table[aria-busy='true'] {
  opacity: 0.6;
}

/* FontAwesome icons */
.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em !important;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
