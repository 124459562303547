<template>
 <b-container fluid="lg">
      
<!-- modal contestar dudas sin respuesta -->
     <b-modal no-close-on-backdrop centered size="xl" 
                hide-footer
                   v-model="modalContestar"
                title="Espacio para contestar dudas abiertas">
            <b-card
                    tag="article"
                    class="mb-2">
                <b-card-text>
                     <b-row>    
                        <div class="col-sm-1"></div>
                        <div class="col-sm-10">
                            <b-card
                                    :title="current_duda_msj"
                                    tag="article"
                                    class="mb-2"
                                >
                                <b-card-text>
                                    {{ this.current_duda_curricula }} / {{ this.current_duda_curso }} / {{ this.current_duda_unidad }} / {{ this.current_duda_objetivo }} 
                                </b-card-text>
                            </b-card>  
                        </div>
                        <div class="col-sm-1"></div>
                    </b-row>   
                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                            <b-form-textarea
                                ref="descripcion"
                                v-model="duda_respuesta"
                                placeholder="Espacio para responder la duda..."
                                rows="4"
                                max-rows="7"
                            ></b-form-textarea>         
                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="col-sm-12 my-2">
                                    <b-button 
                                            variant="primary" 
                                            size="sm" 
                                            class="float-right mx-2 my-1"
                                            @click="CerrarModalContestarDuda()"
                                        >
                                    Cancelar
                                    </b-button>   
   
                                    <b-button 
                                        variant="success" 
                                        size="sm" 
                                        class="float-right mx-2 my-1"
                                        @click="guardarRespuestaDuda()"
                                    >
                                    Guardar
                                    </b-button> 

                                </div>
                        </b-row>
    
                                   


                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

<!-- modal dudas contestadas  -->
     <b-modal no-close-on-backdrop centered size="xl" 
                hide-footer
                   v-model="modalEditar"
                title="Espacio para contestar">
            <b-card
                    tag="article"
                    class="mb-2">
                <b-card-text>

                     <b-row>    
                        <div class="col-sm-1"></div>
                        <div class="col-sm-10">
                            <b-card
                                    :title="current_duda_msj"
                                    tag="article"
                                    class="mb-2"
                                >
                                <b-card-text>
                                    {{ this.current_duda_curricula }} / {{ this.current_duda_curso }} / {{ this.current_duda_unidad }} / {{ this.current_duda_objetivo }} 
                                </b-card-text>
                            </b-card>  
                        </div>
                        <div class="col-sm-1"></div>
                    </b-row>   

                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                       
                        <div v-if="this.editableRespuesta==1">
                            <b-form-textarea
                                    ref="descripcion"
                                    v-model="duda_Contestada"
                                    placeholder="Espacio para responder la duda..."
                                    rows="4"
                                    max-rows="7"
                            ></b-form-textarea>
                        </div>
                        <div v-else>
                             <small>
                                <b-alert show variant="warning">Sólo el autor de la respuesta puede editarla</b-alert>
                            </small>
                            <b-form-textarea
                                disabled
                                ref="descripcion"
                                v-model="duda_Contestada"
                                placeholder="Espacio para responder la duda..."
                                rows="4"
                                max-rows="7"
                            ></b-form-textarea>
                        </div>

                        <b-row class="d-flex align-items-start justify-content-start my-1">

                                <div class="col-sm-12 my-2">

                                    <b-button 
                                            variant="primary" 
                                            size="sm" 
                                            class="float-right mx-2 my-1"
                                            @click="CerrarModalEditarDuda()">
                                    Cancelar
                                    </b-button>   
                                    <div v-if="this.editableRespuesta==1">
                                        <b-button 
                                        v-b-tooltip.hover.placement.bottom 
                                                title="Sólo el autor puede actualizar su respuesta de una duda"
                                                variant="info" 
                                                size="sm" 
                                                class="float-right mx-2 my-1"
                                            @click="actualizarRespuestaDuda()"
                                            >
                                            Actualizar
                                        </b-button>    
                                
                                        <b-button 
                                                v-b-tooltip.hover.placement.bottom 
                                                title="Sólo el autor puede eliminar su respuesta de una duda"
                                                variant="danger" 
                                                size="sm" 
                                                class="float-right mx-2 my-1"
                                                @click="eliminarRespuestaDuda()"
                                            >
                                            Eliminar
                                            </b-button>   
                                    </div>
                                    <div v-else>
                                    
                                    <b-button 
                                            v-b-tooltip.hover.placement.bottom 
                                                title="Sólo el autor puede actualizar su respuesta de una duda"
                                                variant="info" 
                                                size="sm" 
                                                class="float-right mx-2 my-1"
                                           disabled
                                            >
                                            Actualizar
                                        </b-button>    
                                    
                                            <b-button 
                                                v-b-tooltip.hover.placement.bottom 
                                                title="Sólo el autor puede eliminar su respuesta de una duda"
                                                variant="danger" 
                                                size="sm" 
                                                class="float-right mx-2 my-1"
                                              disabled
                                            >
                                            Eliminar
                                            </b-button>   
                                    
                                    </div>   
                                </div>
                        </b-row>
    
                                   


                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

      <b-row>
        <b-col cols="12">

            <div class="card card-block">
                <div class="card-body p-2">

                    <div class="d-flex justify-content-between align-items-center p-2">
                        <b-jumbotron lead="Dudas"></b-jumbotron>
                    </div>
                    <b-alert
                            class="p-3"
                            show
                            variant="primary"
                            dismissible>
                       En esta sección se muestran todas las dudas que se han generado por los docentes así como el poder contestarlas.
                    </b-alert>

                    <b-row class="p-2">

                        <b-tabs content-class="mt-3" justified style="width:100%">
                            <b-tab title="Dudas" active @click="getDudasAbiertas()">

                                    <div class="col-lg-12 my-2">
                                        <b-form-input
                                                id="filter-input"
                                                v-model="filter"
                                                type="search"
                                                placeholder="Buscar"
                                                >
                                        </b-form-input>
                                    </div>
                                
                                    <b-table
                                        fixed
                                        responsive
                                        class="table data-table"
                                        :items="dudas_items"
                                        :fields="dudas_fields"
                                        :current-page="currentPage"
                                        :per-page="perPage"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                            stacked="md"
                                            show-empty
                                            small
                                        >
                                        <template #empty="scope">
                                            <b-alert show variant="info">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</b-alert>
                                        </template>
                                        <template #emptyfiltered="scope">
                                            <b-alert show variant="warning">
                                                {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                                            </b-alert>
                                        </template>                                                                
                                        <template #cell(actions)="row">   
                                        <div class="d-flex justify-content-around align-items-center">
                                            <b-button variant="info" size="sm" @click="ShowModalContestarDuda(row.item, 0)" class="ml-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-left-square" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm10.096 8.803a.5.5 0 1 0 .707-.707L6.707 6h2.768a.5.5 0 1 0 0-1H5.5a.5.5 0 0 0-.5.5v3.975a.5.5 0 0 0 1 0V6.707l4.096 4.096z"/>
                                                </svg>
                                            </b-button>  
                                            </div>               
                                        </template>
               

                                    </b-table>
                    <div class="d-flex justify-content-between row p-2">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <div>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                >
                                </b-pagination>
                            </div>
                            </div>
                    </div>

                            </b-tab>
                            <b-tab title="Contestadas" @click="getDudasContestadas()">
                                <div class="col-lg-12 my-2">
                                    <b-form-input
                                            id="filter-input"
                                            v-model="filter2"
                                            type="search"
                                            placeholder="Buscar"
                                            >
                                    </b-form-input>

                                        <b-table fixed
                                                 responsive
                                            class="table data-table"
                                            :items="dudasContestadas_items"
                                            :fields="dudasContestadas_fields"
                                            :current-page="currentPage2"
                                            :per-page="perPage2"
                                            :filter="filter2"
                                            :filter-included-fields="filterOn2"
                                                stacked="md"
                                                show-empty
                                                small>
                                        <template #empty="scope">
                                            <b-alert show variant="info">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</b-alert>
                                        </template>
                                        <template #emptyfiltered="scope">
                                            <b-alert show variant="warning">
                                                {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                                            </b-alert>
                                        </template>                                                                                                   
                                        <template #cell(actions)="row">   
                                        <div class="d-flex justify-content-around align-items-center">
                                            <b-button variant="info" size="sm" @click="ShowModalEditarDuda(row.item)" class="ml-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-left-square" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm10.096 8.803a.5.5 0 1 0 .707-.707L6.707 6h2.768a.5.5 0 1 0 0-1H5.5a.5.5 0 0 0-.5.5v3.975a.5.5 0 0 0 1 0V6.707l4.096 4.096z"/>
                                                </svg>
                                            </b-button>  
                                            </div>               
                                        </template>
                                    </b-table>
                    <div class="d-flex justify-content-between row p-2">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <div>
                                <b-pagination
                                    v-model="currentPage2"
                                    :total-rows="totalRows2"
                                    :per-page="perPage2"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                >
                                </b-pagination>
                            </div>
                            </div>
                    </div>                                    
                                </div>
                            </b-tab>
                        </b-tabs>
                    </b-row>
                </div><!--card-body p-2-->
            </div><!--card--->
            
        </b-col>
      </b-row>

  </b-container>
</template>

<script>
import toast from "../../../mixins/ToastMixin.js";
export default {
  mixins:[toast],
    name: "Index"
,mounted () {
    this.getDudasAbiertas();
},

data() {
    return {
             modalEditar:false,
             editableRespuesta:0,
             respuesta_id:0,
             dudasContestadas_items:[],
             dudasContestadas_fields: [
                            {   label: 'Alumno',  key: 'student.name',      class: 'text-start' },
                            {   label: 'Duda',  key: 'duda_msj',            class: 'text-start' },
                            {   label: 'Objetivo',  key: 'objetivo',        class: 'text-center' },
                            {  label: 'Unidad',     key: 'unidad',          class: 'text-center' },
                            {  label: 'Curso',      key: 'curso',           class: 'text-center' },
                            {  label: 'Currícula',  key: 'curricula',       class: 'text-center' },
                            {  label: 'Coach',  key: 'COACH.name',               class: 'text-center' },
                            {  label: 'Acciones',   key: 'actions',         class: 'text-center' }
                    ],

            duda_Contestada:'',
            duda_respuesta:'',
            modalContestar: false,

            current_duda_curricula      :null,
            current_duda_curso          :null,
            current_duda_unidad         :null,
            current_duda_objetivo       :null,
            current_duda_msj            :null,

            current_duda_id:0,
        
            dudas_items:[],
            dudas_fields: [
                            {   label: 'Alumno',  key: 'student.name',      class: 'text-start' },
                            {   label: 'Duda',  key: 'duda_msj',        class: 'text-start' },
                            {   label: 'Objetivo',  key: 'objetivo',     sortable: true,    class: 'text-center' },
                            {  label: 'Unidad',     key: 'unidad',       sortable: true,   class: 'text-center' },
                            {  label: 'Curso',      key: 'curso',        sortable: true,   class: 'text-center' },
                            {  label: 'Curricula',  key: 'curricula',    sortable: true,   class: 'text-center' },
                            {  label: 'Fecha',  key: 'fecha',    sortable: true,   class: 'text-center' },

                            {  label: 'Acciones',   key: 'actions',         class: 'text-center' }
                    ],
        
            /////////////
            totalRows2: 1,
            currentPage2: 1,
            perPage2: 8,
            pageOptions2: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter2: null,
            filterOn2: [],
            search2: '', 

            totalRows: 1,
            currentPage: 1,
            perPage: 8,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter: null,
            filterOn: [],
            search: '', 
    }
},
methods: {
        ShowModalEditarDuda(item){
                this.modalEditar=true;
                this.getRespuestaDeDuda(item.id);
                this.current_duda_id            =item.id;
                this.current_duda_curricula     =item.curricula;
                this.current_duda_curso         =item.curso;
                this.current_duda_unidad        =item.unidad;
                this.current_duda_objetivo      =item.objetivo;
        },
        CerrarModalEditarDuda(){
            this.modalEditar = false;
            this.resetForm()
        },
        async getRespuestaDeDuda(duda_id){
            const response = await this.$api.get(`/api/dudas/contestadas/respuesta/${duda_id}`);
            let {success} = response.data;   
                if(success){
                    this.editableRespuesta  = response.data.respuesta[0].editable;
                    this.respuesta_id       = response.data.respuesta[0].id;
                    this.duda_Contestada    = response.data.respuesta[0].respuesta_duda_msj;      
                }
        },
        async eliminarRespuestaDuda(){
            const config = {  headers: { 'content-type': 'multipart/form-data' }  }
            let url      = `/api/dudas/respuesta/eliminar`;

            let formData = new FormData();
                formData.append("respuesta_id",  this.respuesta_id);   
                formData.append("_method", "DELETE");
            
            const  response   = await this.$api.post(url, formData, config );
            let {  success,
                    message,
                    color,
                } = response.data;

                if(success){
                    this.CerrarModalContestarDuda();
                    this.getDudasContestadas();
                }
                this.toast('b-toaster-bottom-center', true, message, color );
        },
        async actualizarRespuestaDuda(){
            const config = {  headers: { 'content-type': 'multipart/form-data' }  }
            let url      = `/api/dudas/respuesta/actualizar`;

            let formData = new FormData();
                formData.append("respuesta_id",  this.respuesta_id);  
                formData.append("respuesta",  this.duda_Contestada);  
                formData.append("_method", "PUT");
            
            const  response   = await this.$api.post(url, formData, config );
            let {  success,
                    message,
                    color,
                } = response.data;

                if(success){
                    this.CerrarModalEditarDuda();
                    this.getDudasContestadas();
                }
                this.toast('b-toaster-bottom-center', true, message, color );
        },
        CerrarModalContestarDuda(){
            this.modalContestar = false;
            this.resetForm()
        },
        ShowModalContestarDuda(item){

            this.current_duda_id            =item.id;
            this.current_duda_curricula     =item.curricula;
            this.current_duda_curso         =item.curso;
            this.current_duda_unidad        =item.unidad;
            this.current_duda_objetivo      =item.objetivo;
            this.current_duda_msj           =item.duda_msj;

            this.modalContestar     = true;
        
        },
        resetForm(){
                this.current_duda_curricula     =null;
                this.current_duda_curso         =null;
                this.current_duda_unidad        =null;
                this.current_duda_objetivo_id   =null;
                this.current_duda_msj           =null;
                this.current_duda_id            =0;
                this.respuesta_id               =null;
                this.duda_respuesta             ='';
                this.duda_Contestada            ='';
        }
        ,async guardarRespuestaDuda(){
            
            const config = {  headers: { 'content-type': 'multipart/form-data' }  }
            let url      = `/api/dudas/respuesta`;
            let formData = new FormData();
                formData.append("current_duda_id",  this.current_duda_id);  
                formData.append("duda_respuesta",  this.duda_respuesta);  

            const  response   = await this.$api.post(url, formData, config );
            let {  success,
                    message,
                    color,
                } = response.data;

                if(success){
                    this.CerrarModalContestarDuda();
                    await this.getDudasAbiertas();
                }
                this.toast('b-toaster-bottom-center', true, message, color );

        },
        async getDudasContestadas(){
            const response = await this.$api.get(`/api/dudas/contestadas`);
            let {      success,
                        message,
                        color,
                        dudasContestadas,
                    }   = response.data;
                if(success){
                    this.dudasContestadas_items      = dudasContestadas;
                    //console.log(dudasContestadas)
                    this.totalRows2                  = dudasContestadas.length;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
        },//getDudasContestadas
        async getDudasAbiertas() {
                const response = await this.$api.get(`/api/dudas`);
                let {   success,
                        message,
                        color,
                        dudas
                    }   = response.data;

                if(success){
                    this.dudas_items    = dudas;      
                    this.totalRows      = dudas.length;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
        }//getDudasAbiertas
},

}
</script>

<style>

</style>