import api from '@/services/api';

export default{

    saveSesion(formData){
        let url          = `/api/sesiones/store`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
    },

    getOfertasCurriculas(){
        let url = `/api/sesiones/curricula-curso-oferta`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getSesiones(){
        let url = `/api/sesiones`
        return api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    getCursosDisponibles(curricula_id){
        let url          = `/api/sesiones/getcursosDisponibles/${curricula_id}`;
        return api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    updateSesion(sesion_id, formData){
        return api({
            url:  `/api/sesiones/update/${sesion_id}`,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    deleteSesion(sesion_id){
        let url = `/api/sesiones/destroy/${sesion_id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    }
}