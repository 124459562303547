<template>
    <b-container>

<!-- modal ver objetivos que NO tiene asignados esta unidad -->
    <b-modal
        v-model="ModalObjetivos"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    <b-alert
                            class="p-3"
                            show
                            variant="warning"
                            dismissible
                        >
                     A continuación, se muestra un listado con los objetivos disponibles actualmente que pueden ser asignados a esta unidad " {{ this.nombre_unidad }} "
                    </b-alert>
                </b-col>   
            </b-row>
                 <b-row class="d-flex align-items-end justify-content-end my-1">
                       <div class="col-sm-12 my-2">
                       <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Buscar"
                                  >
                        </b-form-input>
                        </div>
                  </b-row>
                    <b-table class="table data-table"
                              striped="striped"
                              hover="hover"
                              outlined="outlined"
                              :items="objetivos"
                              :fields="headersobjetivos"
                              :current-page="currentPage"
                              :per-page="perPage"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                              stacked="md"
                              show-empty
                              small
                       >

                    <template #empty="scope">
                        <h5 class="text-center">
                            {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                        </h5>
                    </template>        
                    <template #emptyfiltered="scope">
                        <h5 class="text-center">
                            {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                        </h5>
                    </template> 

                    <template #cell(actions)="row">   
                        <div class="d-flex justify-content-around align-items-center">
                            <b-button variant="success" size="sm" @click="anexarOjetivo(row.item)" class="ml-1">                  
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                              </svg>
                            </b-button>
                        </div>               
                    </template>

                    </b-table>
                  <div class="d-flex justify-content-between row p-2">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <div>
                                <b-pagination
                                v-model="currentPageObjetivos"
                                :total-rows="totalRowsObjetivos"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                                >
                                </b-pagination>
                            </div>
                            </div>
                    </div>
        </b-container>
            <template #modal-footer>
              <div class="w-100">
                  <b-button
                          variant="primary"
                          size="sm"
                          class="float-right"
                          @click=" ModalObjetivos= false"
                      >
                          Cancelar
                    </b-button>    
              </div>
            </template>
            
        </b-modal>

<!-- modal borrar objetivo de la unidad -->
    <b-modal
        v-model="ModalQuitarObjetivo"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de remover el objetivo: <b>"{{ nombre_objetivo }}"</b> de la unidad <b>"{{ nombre_unidad }}"</b> ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click=" ModalQuitarObjetivo= false"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="borrarObjetivoDeUnidad()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>


      <b-col cols="12">
           <div class="card card-block">
                    <div class="d-flex justify-content-between align-items-center p-2">
                      <h5 class="font-weight-bold"></h5>
                    </div>

                      <b-alert
                            class="p-3"
                            show
                            variant="info"
                            dismissible
                        >
                        Aquí podrá administrar los objetivos que formarán parte de su unidad
                    </b-alert>
                  <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/cursos-all" class="">Cursos</router-link></li>
                        <li class="breadcrumb-item"><router-link to="/dashboardUnidades" class="">Unidades</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page"> Objetivos de la unidad   <b class="mx-1"> "{{nombre_unidad}} <i>( {{clave_interna}} )</i> "</b> </li>
                      </ol>
                    </nav>

                  <b-row class="d-flex align-items-end justify-content-end my-3">
                          <div class="col-sm-12 col-md-6 text-right ">
                               <b-button 
                                        @click="showModalObjetivos"
                                        class="btn-studioAPP" 
                                        variant="outline-warning"> Agregar objetivos a esta unidad
                              </b-button>
                          </div>
                  </b-row>


                    <b-table class="table data-table"
                    striped="striped"
                    hover="hover"
                    outlined="outlined"
                    :items="unidadesobjetivos"
                    :fields="headers"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        small
                       >

                    <template #empty="scope">
                        <h5 class="text-center">
                            {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                        </h5>
                    </template>        
                    <template #emptyfiltered="scope">
                        <h5 class="text-center">
                            {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda .' }}
                        </h5>
                    </template> 

                    <template #cell(actions)="row">   
                        <div class="d-flex justify-content-around align-items-center">
                            <b-button variant="danger" size="sm" @click="ShowmodalBorrarUnidadObjetivoConfirmacion(row.item)" class="ml-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                            </b-button>
                        </div>               
                    </template>

                    </b-table>
                  <div class="d-flex justify-content-between row p-2">
                            <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                            <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <div>
                                <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                                >
                                </b-pagination>
                            </div>
                            </div>
                    </div>
           </div><!-- card block -->
      </b-col>


    </b-container>
</template>

<script>
export default { 
    data() {
      return {
          nombre_objetivo:'',
          unidad_objetivo_id:0,
          ModalQuitarObjetivo:false,

        ModalObjetivos:false,
        
        objetivos:[],
        unidadesobjetivos:[],
        headers: [
          
            { label: 'Objetivo'     , key: 'nombre_objetivo'  ,class: 'text-left' },
            { label: 'Clave'        , key: 'clave_interna'    ,class: 'text-center'},
            { label: 'Descripcion'  , key: 'descripcion'      ,class: 'text-left'},
            { label: 'Acciones'     , key: 'actions'          ,class: 'text-center'},
        ],
        headersobjetivos: [
            { label: 'Nombre'             , key: 'nombre_objetivo'  ,class: 'text-left'},
            { label: 'Clave'              , key: 'clave_interna'    ,class: 'text-center'},
            { label: 'Descripcion'        , key: 'descripcion'      ,class: 'text-left'},
            { label: 'Acciones'           , key: 'actions'          ,class: 'text-center'},
        ],

        totalRowsObjetivos:1,
        currentPageObjetivos:1,

        totalRows: 1,
        currentPage: 1,
        perPage: 8,
        pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
        filter: null,
        filterOn: [],
        search: '',
        id_unidad:this.$route.params.id,
        nombre_unidad:'',
        clave_interna:'',
        
      }
    },
    mounted () {
      this.getUnidad(this.id_unidad)
    },
    methods: {

        async borrarObjetivoDeUnidad(){

                const response   = await this.$api.delete(`/api/unidadobjetivos/destroy/${this.unidad_objetivo_id}`);
                let {   success,   
                        message,
                        color
                    } = response.data;
                if(success){
                    await this.getUnidadesObjetivos(this.id_unidad)
                    this.ModalQuitarObjetivo=false;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
        },
        ShowmodalBorrarUnidadObjetivoConfirmacion( item ){
            this.unidad_objetivo_id = item.unidad_objetivo_id;
            this.nombre_objetivo    = item.nombre_objetivo;
            this.ModalQuitarObjetivo = true;
        },
        async getUnidadesObjetivos(id_unidad){
              //el listado general que muestra SOLO los objetivos anexados a la unidad
              if(id_unidad==null || id_unidad=='' || id_unidad==0){  this.toast('b-toaster-bottom-center', true, "No se puede obtener registros", 'danger' );  return false}
              let url                     = `/api/unidadobjetivos/show/${id_unidad}`;
              const response              = await this.$api.get(url);

              let {   objetivosDeLaUnidad,
                      success,
                      message,
                      color
                      } = response.data;
              if(success){
                this.unidadesobjetivos  = objetivosDeLaUnidad;
                this.totalRows          = objetivosDeLaUnidad.length;
              }
              this.toast('b-toaster-bottom-center', true, message, color );
        },
        showModalObjetivos(){
           this.ModalObjetivos = true;
           //objetivos del modal disponibles para la UNIDAD actual
           this.getObjetivosDisponibles(this.id_unidad);  
        },
        async getObjetivosDisponibles(id_unidad){
          //listado de objetivos que la UNIDAD NO TIENE y están ACTIVOS
                const response              = await this.$api.get(`/api/unidadobjetivos/objetivosDisponiblesParaLaUnidad/${id_unidad}`);
                let {   objetivos,
                        success,
                        message,
                        color
                        } = response.data;
                if(success){
                  //info para el modal de objetivos para la unidad
                    console.log(this.objetivos);
                    this.objetivos          = objetivos;
                    this.totalRowsObjetivos = objetivos.length;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
        },

        async anexarOjetivo(item){
                let formData = new FormData();
                    formData.append("objetivo_id", item.id);
                    formData.append("unidad_id",   this.id_unidad);
                        let url          = `/api/unidadobjetivos/store`;
                        const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
                        let {   success,
                                message,
                                color
                                } = response.data;
                        if(success){
                          //modal
                            await this.getObjetivosDisponibles(this.id_unidad);
                          //data grid
                            await this.getUnidadesObjetivos(this.id_unidad);
                        }
                        this.toast('b-toaster-bottom-center', true, message, color );

        },
        async getUnidad(id_unidad){
                const response              = await this.$api.get(`/api/unidades/get/${id_unidad}`);
                this.clave_interna          = response.data.unidad[0].clave_interna;
                this.nombre_unidad          = response.data.unidad[0].nombre_unidad;
                await this.getUnidadesObjetivos(id_unidad);
        },
          //método del toast
           toast(toaster, append = false,content,color) {
            this.counter++
            this.$bvToast.toast(content, {
              title: 'Nivelando',
              autoHideDelay: 3500,
              toaster: toaster,
              variant: color,
              appendToast: append
            });
          },
    },

}
</script>
<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>