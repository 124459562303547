<template>
  <b-container >

    <!-- modal borrar sesion -->
    <b-modal
        v-model="ModalBorrar"
        persistent
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar este evento de stream ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalBorrar()"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="eliminarEventoStream()"
          >
            Eliminar
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- modal crear nuevo elemento -->
    <b-modal   no-close-on-backdrop centered size="lg"
               hide-footer
               ref="ModalCrearSesion"
               title="Creando nueva sesión"
               v-model="ModalCrear"
               persistent>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="primary" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          A continuación especifique los siguientes campos
        </b-alert>
        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <b-form>

                <b-row class="d-flex align-items-start justify-content-start my-3">
                  <b-col>
                    <label> <small> Seleccionar evento</small></label>
                    <v-select
                        size="lg"
                        class="my-3"
                        placeholder="Elegir"
                        v-model="stream_form.evento"
                        :options='itemsEventosSesiones'
                    >
                      <template slot="option" slot-scope="option">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle mr-1" viewBox="0 0 16 16">
                          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                        </svg>
                        {{ option.label }}
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No resultados para <em>{{ search }}</em
                        >.
                        </template>
                        <em v-else style="opacity: 0.5">Elegir currícula primero.</em>
                      </template>
                    </v-select>

                    <br>
                    <label> <small> Seleccionar video de Youtube</small></label>

                    <v-select
                        label="title"
                        size="lg"
                        class="my-3"
                        placeholder="Elegir"
                        v-model="stream_form.youtube"
                        :options='youtubeItems'
                    >
                      <!--
                      <template slot="option" slot-scope="option">
                        <b-col>
                          {{ option.title }}<br>
                          <small style="color:#dedede"> {{ option.Description_formatted }} </small>
                        </b-col>
                      </template>
                      -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No resultados para <em>{{ search }}</em
                        >.
                        </template>
                        <em v-else style="opacity: 0.5">Elegir video de youtube</em>
                      </template>
                    </v-select>

                    <!--
                    <b-form-select
                        size="sm"
                        placeholder="Tipo de noticia"
                        v-model="stream_form.youtube"
                        :options="youtubeItems">
                      <template #first>
                        <b-form-select-option :value="0" disabled>-- Elegir una opción --</b-form-select-option>
                      </template>
                    </b-form-select>
-->
                  </b-col>
                </b-row>

                <b-row class="d-flex align-items-start justify-content-start my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModaNuevoStream()"
                    >Cancelar
                    </b-button>

                    <b-button
                              variant="success"
                              size="sm"
                              class="float-right mx-2"
                              @click="guardarStreamEvento()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>

              </b-form>
            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>
    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron lead="Eventos de streaming"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              Aquí podrá administrar los eventos en vivo de cada evento creado dentro de la sección de cursos, paralos que sean en-vivo, así como tener el control para  habilitar el stream, chat
            </b-alert>

            <b-row style="display: flex; justify-content:flex-end;" class="my-3 mx-3">
                <div style=" display: none">
                  <b-button @click="showModalNuevoStream()"
                            class="btn-studioAPP"
                            variant="outline-warning"> Crear nuevo stream
                  </b-button>
                </div>
            </b-row>
            <b-row class="d-flex align-items-end justify-content-end my-1">
              <div class="col-sm-12 my-2">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar"
                >
                </b-form-input>
              </div>
            </b-row>
            <b-table class="table data-table"
                     :items="eventosStream_items"
                     :fields="eventosStream_header"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <b-alert show variant="info">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</b-alert>
              </template>

              <template #emptyfiltered="scope">
                <b-alert show variant="warning">
                  {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                </b-alert>
              </template>

              <template #cell(thumbnail)="row">
                <b-img
                    rounded
                    fluid
                    :src ="row.item.thumbnail_video"
                ></b-img>
              </template>

              <template #cell(descripcion_video)="row">
                  <small> {{row.item.descripcion_video.substr(0,90)}}...</small>
              </template>

              <template #cell(habilitar_stream)="row">
                <!--  <b-form-checkbox :value="( (`${row.item.entregable}`!=0) ? '1':0  )" :checked="( (`${row.item.entregable}`!=0) ? '1':null  )" switch size="lg" @change="showModalEntregableEleccionTipo(row.item.id)"/>
        -->
                <b-form-checkbox :value="( (`${row.item.id}`!==0) ? '1':0  )" switch size="lg"/>
              </template>

              <template #cell(habilitar_chat)="row">
                <b-form-checkbox :value="( (`${row.item.id}`!==0) ? '1':0  )" switch size="lg"/>
              </template>

              <template #cell(stream)="row">
                <b-button @click="irStreamCoach(row.item)" variant="secondary" size="sm" class="ml-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-slides-fill" viewBox="0 0 16 16">
                    <path d="M7 7.78V5.22c0-.096.106-.156.19-.106l2.13 1.279a.125.125 0 0 1 0 .214l-2.13 1.28A.125.125 0 0 1 7 7.778z"/>
                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM5 4h6a.5.5 0 0 1 .496.438l.5 4A.5.5 0 0 1 11.5 9h-3v2.016c.863.055 1.5.251 1.5.484 0 .276-.895.5-2 .5s-2-.224-2-.5c0-.233.637-.429 1.5-.484V9h-3a.5.5 0 0 1-.496-.562l.5-4A.5.5 0 0 1 5 4z"/>
                  </svg>
                </b-button>
              </template>

              <template #cell(actions)="row">
                <div class="d-flex justify-content-around align-items-center">

                  <!--
                  <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                            @click="EditMode(row.item)" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </b-button>
                  -->


                  <b-button @click="showModalBorrar(row.item)" variant="danger" size="sm" class="ml-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </b-button>

                </div>
              </template>

            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>
          </div><!--card-body p-2-->
        </div><!--card card-block-->
      </b-col>
    </b-row>

  </b-container>
</template>

<script>

import firebase from '@/firebaseConfRtdb/init';
import firebaseMixin from '@/mixins/FirebaseMixin.js';
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";
import EventoStreamService from "../../../../services/Evento/EventoStreamService";

export default {
  name: "EventosStream",
  mixins:[toast,firebaseMixin],
  data() {
    return {
      youtubeItems:[],
      youtubeItems_count:0,

      itemsEventosSesiones : [
        { id: '1', label:'Sesión en Vivo (Evento #1 se lleva a cabo el 8/10/2021 de 18:00:00 - 19:00:00)', text: 'Sesión en Vivo (Evento #1 se lleva a cabo el 8/10/2021 de 18:00:00 - 19:00:00)' },
        { id: '2', label:'Evento de Prueba (Evento #2 se lleva a cabo el 22/10/2021 de 19:00:00 - 20:00:00)', text: 'Evento de Prueba (Evento #2 se lleva a cabo el 22/10/2021 de 19:00:00 - 20:00:00)' },
        { id: '3', label:'Evento de Prueba Programado (Evento #3 se lleva a cabo el 24/11/2021 de 13:00:00 - 14:00:00)', text: 'Evento de Prueba Programado (Evento #3 se lleva a cabo el 24/11/2021 de 13:00:00 - 14:00:00)' },
        { id: '4', label:'Evento de Prueba Programado (Evento #4 se lleva a cabo el 11/1/2022 de 12:00:00 - 18:09:00)', text: 'Evento de Prueba Programado (Evento #4 se lleva a cabo el 11/1/2022 de 12:00:00 - 18:09:00)' }

      ],
      stream_form:{
              id:0,
              evento:{ label:'Elegir', id: 0 },
              youtube:{ label:'Elegir', id: 0 },
      },
      ModalCrear:false,
      ModalBorrar:false,
      eventosStream_items:[],
      eventosStream_header : [
      /*  { label : '#', key : 'id' , class: 'text-center'},*/
        { label : 'Foto', key : 'thumbnail' },
        { label : 'Nombre', key : 'titulo_video' },
        { label : 'Evento', key : 'evento_id' },
        { label : 'Detalles', key : 'descripcion_video' },
          /*
        { label : 'Stream', key : 'habilitar_stream' , class: 'text-center' },
        { label : 'Chat', key : 'habilitar_chat' , class: 'text-center'},
        **/
     //   { label : 'Habilitar encuestas', key : 'habilitar_encuestas' , class: 'text-center'},//
        { label : 'Stream', key : 'stream' , class: 'text-center'},
        { label : 'Eliminar', key : 'actions' , class: 'text-center'},
      ],

      /////////////
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: ''
    }
  },
  mounted() {
        this.getStreamingVideos();
       // this.getEventosSesiones();  //primer select
       // this.getYoutubeVideos();    //segundo select
    },
  methods: {

    saveToFirebase(id_eventoStream){
      let postData = {
        chat_habilitado: 0,
        encuestas_habilitado: 0,
        evento_streaming_id:id_eventoStream,
        stream_iniciado:1
      };
      firebase.database().ref('streaming-events').push(postData);
    },
    irStreamCoach(item){
      this.$router.push( { name: 'CoachStreamEnVivo', params: {  eventoStream:item  } } );
    },
    resetForm(){
          this.stream_form.id       = 0;
          this.stream_form.evento   = { label:'Elegir', id: 0 };
          this.stream_form.youtube  = { label:'Elegir', id: 0 };
    },
    EditMode(item){
      console.log(item);
      this.ModalCrear = true;
    },
    showModalBorrar(item){
      this.stream_form.id = item.id;
      this.ModalBorrar = true;
    },
    hideModalBorrar(){
      this.resetForm();
      this.ModalBorrar = false;
    },
    hideModaNuevoStream(){
      this.ModalCrear = false;
    },
    showModalNuevoStream() {
      this.getEventosSesiones();
      this.ModalCrear = true;
    },
    async getEventosSesiones(){
        //primer select
      try {
        let response = await EventoStreamService.getEventosSesiones();
        let { resultSet} = response.data;
        this.itemsEventosSesiones= resultSet;
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getEventosSesiones() ' + error.message)
        );
      }

    },
    async getYoutubeVideos(){
      //segundo select
      try {
          let response                = await EventoStreamService.getYoutubeVideos();
          let { resultSet}            = response.data;
          this.youtubeItems_count     = resultSet.count;
          this.youtubeItems           = resultSet.entries;
      } catch (error) {
          this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getYoutubeVideos() ' + error.message)
        );
      }
    },
    async getStreamingVideos(){
      //b-table
      try {
        let response = await EventoStreamService.get();
        let { resultSet} = response.data;
        this.eventosStream_items= resultSet;
        this.totalRows = resultSet.length;
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getStreamingVideos() ' + error.message)
        );
      }
    },
    async guardarStreamEvento(){
      try {
        let formData = new FormData();
            formData.append("evento_id",            this.stream_form.evento.id);
            formData.append("youtube_video_id",     this.stream_form.youtube.id);
            formData.append("titulo_video",         this.stream_form.youtube.title);
            formData.append("descripcion_video",    this.stream_form.youtube.description);
            formData.append("thumbnail_video",      this.stream_form.youtube.thumbnails.default.url);

        const response = await EventoStreamService.save(formData);
        let {   success,
                message,
                color,
                id
              } = response.data;
        if(success===true){
          this.saveToFirebase(id);
          await this.getStreamingVideos();
          this.toast('b-toaster-bottom-center', true, message, color );
          this.ModalCrear = false;
          this.resetForm();
        }
      } catch (error) {
        Bugsnag.notify(
            new Error('Método: guardarStreamEvento() ' + error)
        );
      }


    },
    async eliminarEventoStream(){
      try {
        const response   = await EventoStreamService.delete(this.stream_form.id);
        let {   success,
              message,
              color
        } = response.data;
        if(success){
          await this.getStreamingVideos();
          this.hideModalBorrar();
        }
        this.toast('b-toaster-bottom-center', true, message, color );

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
        Bugsnag.notify(
            new Error('Método: eliminarEventoStream() ' + error)
        );
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  $studioAPPColor:#952f81;

  .btn-studioAPP{
    color: $studioAPPColor !important;
    border:1px solid $studioAPPColor;
  }

  .btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
  }

</style>