<template>
    <b-container>
        
       <b-form-checkbox @change="someHandler" v-model="checked" name="check-button" switch></b-form-checkbox>

         <button role="button" class="btn btn-danger" data-toggle="collapse" data-target="#demo">
                horizontal collapse
        </button>

         <div id="demo" class="collapse width" :class=" collaps ? 'show' : '' ">
            <div style="width:400px;">
                <p>Works smoother when element has defined width. Egestas eget quam. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
            </div>
        </div>

    </b-container>
</template>

<script>
  export default {
      methods: {
          someHandler() {
              console.log(this.checked);
              this.collaps = !this.collaps;
          }
      },
    data() {
      return {
        collaps:false,
      }
    }
  }
</script>

<style type="text/css">
  .collapse {
 /* visibility: hidden;*/
 display: none;
}
.collapse.show {
  /*visibility: visible;*/
  display: block;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: auto;
}
</style>