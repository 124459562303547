<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron lead="Todos los entregables de todos los grupos"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              En esta sección se muestran todos los alumnos respecto a las actividades de la unidad que tenga entregables activos.
            </b-alert>
            <b-row style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">
              <b-button @click="volver()"
                        class="btn-studioAPP"
                        variant="outline-warning"> Volver
              </b-button>

            </b-row>

            <b-tabs content-class="mt-3" justified style="width:100%">
              <b-tab title="Entregable Sencillo" @click="getEntregablesSencillosGeneral()">

                <div style="overflow-y:scroll; height: 900px"
                    v-if="empty==false">

                  <pivot-table style="overflow-x: auto;"
                               :data="asyncData"
                               :row-fields="rowFields"
                               :col-fields="colFields"
                               :reducer="reducer"
                               no-data-warning-text="Cargando..."
                  >
                    <template v-slot:value="{ value }">
                      <div v-if="value.tareaURL != null" style="display: flex; justify-content: center; align-items:center">

                        <div v-if=" value.calificacion>0 " style="display: flex; justify-content: space-around;">
<!--                          <b-form-checkbox
                              value="1"
                              checked="1"
                              size="lg"
                              disabled
                          />-->
                          <b-link class="ml-2" target="_blank" :href="`${value.tareaURL}`">Ver</b-link>

                        </div>

                        <div v-else >

<!--                          <b-form-checkbox
                              :value="( (`${value.calificacion}`>0) ? '1':0  )"
                              :checked="( (`${value.calificacion}`>0) ? '1':null  )"
                              size="lg"
                              @change="calificarEntregaComoRecibido(value.idEntregable)"
                          />-->

                          <b-link class="ml-2"  target="_blank" :href="`${value.tareaURL}`">Ver</b-link>
                        </div>

                      </div>
                      <div v-else style="display: flex; justify-content: space-evenly; align-items:center">
                        <small class="txt_SinEntregar"> Sin entregar</small>
                      </div>


                    </template>
                    <template v-slot:nombreNameHeader="{ value }">
                      {{ value }}
                    </template>

                  </pivot-table>

                </div>
                <div v-else>
                  <b-card no-body>
                    <b-card-text class="text-center my-5">
                      <b-jumbotron lead="No hay información de momento con este grupo"></b-jumbotron>
                    </b-card-text>
                  </b-card>
                </div>

              </b-tab><!--Entregable sencillo-->

            <b-tab title="Entregable Calificable" @click="getEntregablesCalificablesGeneral()">

                             <div v-if="empty2==false">
                              <pivot-table style="overflow-x: auto;"
                                 :data="asyncData2"
                                :row-fields="rowFields2"
                                :col-fields="colFields2"
                                :reducer="reducer2" 
                                  :is-data-loading="isDataLoading"
                                  no-data-warning-text="Cargando..."
                              >
                                <template v-slot:value="{ value }">
                                  
                                  <div v-if="value.tareaURL != null" style="display: flex; justify-content: space-evenly; align-items:center">
                                      <b-form-input
                                            ref="myInput"
                                            size="sm"
                                            placeholder=" __/100"
                                            type="number"
                                            step="1"
                                            :value="value.calificacion"
                                            @keyup="updateCuadernoCalificaciones(value.idEntregable, $event.target.value)"
                                          ></b-form-input>
                                      <b-link class="ml-2" :href="`${value.tareaURL}`">Ver</b-link>
                                  </div>

                                  <div v-else style="display: flex; justify-content: space-evenly; align-items:center">
                                    <small class="txt_SinEntregar"> Sin entregar</small>
                                  </div>

                                </template>

                                <template v-slot:nombreNameHeader="{ value }">
                                  {{ value }} 
                                </template>
            <!--
                                <template v-slot:loading>
                                  <div class="text-center">
                                    <svg aria-hidden="true" data-prefix="fas" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-spinner fa-fw fa-pulse"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" class=""></path></svg>
                                    Cargando...
                                  </div>
                                </template>
            -->
                              </pivot-table>
                        </div>
                        <div v-else>
                          <b-card no-body>
                              <b-card-text class="text-center my-5">
                                  <b-jumbotron lead="No hay información de momento con este grupo"></b-jumbotron>
                              </b-card-text>
                          </b-card>
                        </div>

            </b-tab><!--Entregable Calificable-->

            <b-tab title="Entregable por Rúbrica" @click="getCalificablesRubricasGeneral()">
 <div v-if="empty3==false">
                      <pivot-table style="overflow-x: auto;"
                          :data="asyncData3"
                          :row-fields="rowFields3"
                          :col-fields="colFields3"
                          :reducer="reducer3"
                          no-data-warning-text="Cargando..."
                      >
                        <template v-slot:value="{ value }">
                    
                          <div v-if="value.tareaURL != null" style="display: flex; justify-content: space-evenly; align-items:center">

                                  <div v-if="value.tareaURL != null" style="display: flex; justify-content: space-evenly; align-items:center">
                                      <b-form-input
                                            disabled
                                            size="sm"
                                            type="number"
                                            step="1"
                                            :value="value.calificacion"
                                          ></b-form-input>
                              <b-button
                                @click="updateCuadernoCalificacionesPorRubrica(value)"
                                  size="sm"
                                  class="btn-studioAPP ml-2" 
                                  variant="outline-warning"> Revisar
                              </b-button>                                   
                                  </div>

                          </div>

                          <div v-else style="display: flex; justify-content: space-evenly; align-items:center">
                            <small class="txt_SinEntregar"> Sin entregar</small>
                          </div>

                        </template>

                        <template v-slot:nombreNameHeader="{ value }">
                          {{ value }} 
                        </template>
                      </pivot-table>
                </div>
                  <div v-else>
                    <b-card no-body>
                        <b-card-text class="text-center my-5">
                            <b-jumbotron lead="No hay información de momento con este grupo"></b-jumbotron>
                        </b-card-text>
                    </b-card>
                  </div>
            </b-tab><!--Entregable Rúbrica-->

            </b-tabs>


          </div>
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>

import EntregableService from '@/services/EntregableService';
import Bugsnag from "@bugsnag/js";
import toast from "@/mixins/ToastMixin.js";
import PivotTable from '@/components/PivotTable';

export default {
  name: "General.vue",
  mixins:[toast],
  components: {  PivotTable },

  data() {
    return {
    curso_id: 0,
      //1 PIVOTE TABLE
      empty:false,
      asyncData: [],
      reducer: (acc, item) => item,
      rowFields: [{
        getter: item => item.nombre,
        label: 'nombre',
        headerSlotNames: [ 'nombreNameHeader']
      }],

      colFields: [{
        getter: item => item.tarea,
        label: 'Tarea'
      }],

      //2 PIVOTE TABLE
      empty2:false,
      asyncData2: [],
      reducer2: (acc, item) => item,
      rowFields2: [{
        getter: item => item.nombre,
        label: 'nombre',
        headerSlotNames: [ 'nombreNameHeader']
      }],
      colFields2: [{
        getter: item => item.tarea,
        label: 'Tarea',
      }],

      //3 PIVOTE TABLE
      empty3:false,
      asyncData3: [],
      reducer3: (acc, item) => item,
      rowFields3: [{
        getter: item => item.nombre,
        label: 'nombre',
        headerSlotNames: [ 'nombreNameHeader']
      }],
      colFields3: [{
        getter: item => item.tarea,
        label: 'Tarea'
      }],


    }
  },

  mounted() {
    this.curso_id = this.$route.params.id;
    this.getEntregablesSencillosGeneral();
  },
  created: function() {
    this.isDataLoading = false;
  },
  methods: {
    async updateCuadernoCalificacionesPorRubrica(item){   

      if(item){
        this.$router.push({
              name: 'Preview-rubrica',
              params          : {
                entregable   : item,
                vieneDeGrupos: false
              }
          });   
      }


    },
    async getEntregablesSencillosGeneral(){
      try{
        let response = await EntregableService.getEntregablesSencillosGeneral(this.curso_id);
        let {
          message,
          color,
          resultSet,
          empty,
        } = response.data;

        this.empty = empty;
        this.asyncData = resultSet;

        this.toast('b-toaster-bottom-center', true, message, color );
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
        Bugsnag.notify(
            new Error('Método: getEntregablesSencillosGeneral() ' + error)
        );
      }
    },
    async getEntregablesCalificablesGeneral(){
      try{
        let response = await EntregableService.getEntregablesCalificablesGeneral(this.curso_id);
        let {
          message,
          color,
          resultSet,
          empty,
        } = response.data;
        this.empty2 = empty;
        this.asyncData2 = resultSet;

      console.log(this.asyncData2);

        this.toast('b-toaster-bottom-center', true, message, color );
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
        Bugsnag.notify(
            new Error('Método: getEntregablesCalificablesGeneral() ' + error)
        );
      }
    },
    async getCalificablesRubricasGeneral(){
      try{
        let response = await EntregableService.getCalificablesRubricasGeneral(this.curso_id);
        let {
          message,
          color,
          resultSet,
          empty,
        } = response.data;
        this.empty3     = empty;
        this.asyncData3 = resultSet;
        this.toast('b-toaster-bottom-center', true, message, color );
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
        Bugsnag.notify(
            new Error('Método: getCalificablesRubricasGeneral() ' + error)
        );
      }
    },

    volver(){
      this.$router.push({
        path:`/salon-cursos`
      });
    },

  },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

.txt_SinEntregar{
  letter-spacing: .01785714em;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #c5221f;
}


</style>