<template>
    <b-container>


<!-- modal borrar item de la actividad -->
    <b-modal
        v-model="modalBorrarItemConfirmacion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar esta rúbrica ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalEliminar()">
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="eliminarRubrica(rubrica.id)">
                        Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>

        <b-row>
             <b-col cols="12">
                  <div class="card card-block">
                    <div class="card-body p-2">
                        <div class="d-flex justify-content-between align-items-start p-2">
                            <b-jumbotron lead="Editando rúbrica"></b-jumbotron>
                        </div>

                    <b-alert
                            class="p-3"
                            show
                            variant="info"
                            dismissible
                        >
                       En esta sección se pueden <b class="mx-1">editar</b> los criterios de evaluación del entregable de la unidad
                    </b-alert>

                    <b-row  style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">
                                <b-button @click="volver()"
                                    class="btn-studioAPP" 
                                    variant="outline-warning"> Volver
                                </b-button>
                            <div>
                                <b-button @click="showModalEliminar()" variant="danger">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </b-button>                             
                                <b-button @click="guardarCambios()"
                                        class="btn-studioAPP ml-2" 
                                        variant="outline-warning"> Guardar cambios
                                </b-button>
                           </div>
                    </b-row>

                    <b-form >
                         <b-form-input
                                ref="nombreRubrica"
                                class="my-2"
                                v-model="rubrica.nombre"
                                type="text"
                                placeholder="Nombre para la rúbrica"          
                                >
                        </b-form-input>
                    
                   
                    <div v-for="(input,k) in inputs" :key="k">

                        <div class="mx-3 mt-3 p-2 criterio" >

                                <div class="d-flex justify-content-center" >
 
                                        <div
                                            @click="remove(k)"
                                            v-show="k || ( !k && inputs.length > 1)"
                                            class="m-1"
                                            >
                                            <svg style="color : #c0392b;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                         </div>
                                
                            <input 
                                    type="hidden" 
                                    v-model="input.idCriterio"
                            >
                            <b-form-input
                                    ref="tituloCriterio"
                                    class="my-2"
                                    v-model="input.tituloCriterio"
                                    type="text"
                                    placeholder="Título del criterio"          
                                    >
                            </b-form-input>
                        
                            <b-form-input
                                    ref="desCriterio"
                                    class="my-2"
                                      v-model="input.desCriterio"
                                    type="text"
                                    placeholder="Descripción del criterio"          
                                    >
                            </b-form-input>
                                                        

                            <b-row>
                                <b-col sm="3"  v-for="(answer,j) in input.answers" :key="j">

                            <b-card style="max-width: 20rem; border:1px #dedede solid;  border-radius: 15px;" >
                                <b-card-text>
                                    <input 
                                        type="hidden" 
                                        v-model="answer.idNivels"
                                    ><!-- ID ADDED FOR UPDATE FEATURE -->
                                    <b-form-input
                                            size="sm"
                                            class="my-2"
                                            v-model="answer.puntosNivel"
                                            type="number"
                                            step="1"
                                            placeholder="Puntos"          
                                            >
                                    </b-form-input>
                                
                                    <b-form-input
                                            size="sm"
                                            class="my-2"
                                            v-model="answer.tituloNivel"
                                            type="text"
                                            placeholder="Título del nivel"          
                                            >
                                    </b-form-input>

                                    <b-form-input
                                            size="sm"
                                            class="my-2"
                                            v-model="answer.descripcionNivel"
                                            type="text"
                                            placeholder="Descripción"          
                                            >
                                    </b-form-input>
                                    <div class="d-flex justify-content-center" >
                                            <div
                                                @click="remove2(j,k)"
                                                v-show="j || ( !j && input.answers.length > 1)"
                                                class="text-center m-1">
                                                <svg style="color : #c0392b;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </div>                                        
                                        <div
                                            @click="add2(k)"
                                            v-show="(j == input.answers.length-1)  "
                                            class="text-center m-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" style="color : #2980b9;" width="24" height="24" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                        </div>

                                        </div>
                                    </b-card-text>


                                </b-card>

        

                                </b-col>

                            </b-row>   
                                    <div class="d-flex justify-content-center" >

                                        <div
                                            @click="add(k)"
                                            v-show="k == inputs.length-1"
                                            class="m-1"
                                            >
                                            <svg style="color : #2980b9;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                            </svg>
                                        </div>
                                    </div>
                    </div>

                    </div>
                    </b-form>

 

                    </div><!--card-body-->
                  </div><!--card-->

             </b-col>

        </b-row>

    </b-container>

</template>

<script>
import Bugsnag from '@bugsnag/js';
import RubricaService from '@/services/RubricaService';
import toast from "@/mixins/ToastMixin.js";

export default {
    mixins:[toast],
    data() {
        return {
            modalBorrarItemConfirmacion:false,
            nombreRubrica:null,
            inputs: [
                        { idCriterio      :0, tituloCriterio: '', desCriterio:'' , answers: [] }
                        
                    ],
            deliDCriterios: [],
            deliDNiveles: [],
            rubrica: {
                id:(this.$route.params.id) ? (this.$route.params.id) : 0,
                nombre:null,
                unidad:{
                    id:0,
                },
            }
        }
    },//data

    mounted () {
            if(this.rubrica.id==0){
                this.$router.push({
                    path:`/dashboardUnidades`
                });
            }else{
                this.getData(this.rubrica.id);
            } 
    },
    methods: {
        showModalEliminar(){
            this.modalBorrarItemConfirmacion=true;
        },
        hideModalEliminar(){
            this.modalBorrarItemConfirmacion=false;
        },

        async eliminarRubrica(rubrica_id){
            try {
                         
                const response   = await RubricaService.deleteRubrica(rubrica_id);
                let {   success,   
                        message,
                        color
                    } = response.data;

                console.log(response.data);
                if(success){
                 
                    this.modalBorrarItemConfirmacion=false;
                    this.$router.push({
                            path:`/dashboardUnidades`
                        });                    
                }
                this.toast('b-toaster-bottom-center', true, message, color );

            } catch (error) {
                 this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                        Bugsnag.notify(
                            new Error('Método: eliminarRubrica() ' + error)
                        );
            }
        },

        async getData(rubrica_id) {
            try {
                let response = await RubricaService.getRubricaEdit(rubrica_id);
            
                let {   success,
                        message,
                        color,
                        rubrica
                        } = response.data;
                        console.log(response.data);
                if(success){
                    this.rubrica.nombre     = rubrica.nombre;
                    this.rubrica.unidad.id  = rubrica.unidad_id;
                    this.inputs             = rubrica.criterios;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
            } catch (error) {
                 this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                        Bugsnag.notify(
                            new Error('Método: getData() ' + error)
                        );
            }

        },

        volver(){
            this.$router.push({
                path:`/dashboardUnidades`
            });
        },

        isValid(){

                if( (this.rubrica.nombre === "" || !this.rubrica.nombre || this.rubrica.nombre==null) ) 
                { 
                   this.toast('b-toaster-bottom-center', true,  'Hace falta especificar un nombre para esta rúbrica', 'danger' );
                   return false;                            
                }

            for (let index = 0; index <  this.inputs.length; index++) {
                if( (this.inputs[index].tituloCriterio === "" || !this.inputs[index].tituloCriterio) ) 
                { 
                   this.toast('b-toaster-bottom-center', true,  'El título del criterio NO DEBE estar vacío', 'danger' );
                   return                             
                }

                if( (this.inputs[index].desCriterio === "" || !this.inputs[index].desCriterio) ) 
                { 
                   this.toast('b-toaster-bottom-center', true,  'Hace falta la descripción en un criterio', 'danger' );
                   return                             
                }



                for (let j = 0; j <  this.inputs[index].answers.length; j++) 
                {
                    if( this.inputs[index].answers[j].puntosNivel === "" || !this.inputs[index].answers[j].puntosNivel )
                    { 
                        this.toast('b-toaster-bottom-center', true,  'Hace falta definir el puntaje en un nivel de un criterio', 'danger' );
                        return           
                    }

                    if( this.inputs[index].answers[j].tituloNivel === "" || !this.inputs[index].answers[j].tituloNivel )
                    { 
                        this.toast('b-toaster-bottom-center', true,  'Hace falta definir el título del nivel de un criterio', 'danger' );
                        return           
                    }

                    if( this.inputs[index].answers[j].descripcionNivel === "" || !this.inputs[index].answers[j].descripcionNivel )
                    { 
                        this.toast('b-toaster-bottom-center', true,  'Hace falta agregar una descripción del nivel de un criterio', 'danger' );
                        return           
                    }

                }

            }
        },

        async guardarCambios(){
        
            this.isValid();

            let nombreRubrica       = (this.rubrica.nombre)   ? this.rubrica.nombre : 0;
            let criterios           = (this.inputs)           ? this.inputs         : 0;
            let id                  = (this.rubrica.id)       ? this.rubrica.id     : 0;
            let criteriosEliminar   = (this.deliDCriterios)   ? this.deliDCriterios : [];
            let nivelesEliminar     = (this.deliDNiveles)     ? this.deliDNiveles   : [];

            try {
                    let response = await RubricaService.updateRubrica(nombreRubrica, criterios,id, criteriosEliminar, nivelesEliminar);
                
                    let {   success,
                            message,
                            color,
                            } = response.data;

                        this.getData(this.rubrica.id);
                        if(success){
                            this.toast('b-toaster-bottom-center', true, message, color );
                            /*
                            this.$router.push({
                                path:`/rubricas/${this.unidad.id}`
                            });
                            */
                        }                    
                        console.log(nivelesEliminar);
                        console.log(response.data);
            } catch (error) {
                 this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger" );
                        Bugsnag.notify(
                            new Error('Método: guardarCambios() ' + error)
                        );
            }
               
        },


        remove2(nivel,criterio) {    

            //agregamos los ids de los niveles que van a eliminarse
            this.deliDNiveles.push( this.inputs[criterio].answers[nivel].idNivels );//pure id
            this.inputs[criterio].answers.splice(nivel,1);
             
        },

        add() {
            this.inputs.push(  {    idCriterio      :0,
                                    tituloCriterio  : '', 
                                    desCriterio     :'',
                                    answers         : [ { 
                                                            idNivels:0,
                                                            puntosNivel:'',
                                                            tituloNivel:'',
                                                            descripcionNivel:'',
                                                        }  
                                                      ] 
                                }
                            );
        },//add

        add2(criterio) {
                this.inputs[criterio].answers.push( {  idNivels:0, puntosNivel: '', tituloNivel:'', descripcionNivel:'' } );
        },
   
        remove(criterio) {
            //this.delInputs.push( {idCriterio: this.inputs[criterio].idCriterio } );
             //agregamos los ids de los criterios que van a eliminarse junto a los respectivos niveles de puntos que estos contienen
             this.deliDCriterios.push( this.inputs[criterio].idCriterio );//pure id
            this.inputs.splice(criterio, 1);
            
            
        },
    },//methods

}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.criterio{
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     border:2px #dedede solid;  
     border-radius: 15px;
}

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>