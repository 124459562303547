<template>
  <b-row style="display: flex; justify-content: center; " class="my-3 mx-3">
    <b-button @click="navegar()"
              variant="outline-primary"> Navegar a Dos
    </b-button>
  </b-row>
</template>

<script>
export default {
  name: "LocalStorageUno",
  data() {
    return {
      current_curricula: {
          nombre:'nunito explorador',
          id:66,
          estatus:1,
          user_id:54
        },
      curso: {
        nombre:'Oferta de verano',
        id: 34,
        grupo:19,
        estatus:1
      }
    }
  },
  methods: {
    navegar(){
      this.$router.push( {
        name: 'LocalStorageDos',
        params          : {
          curricula       : this.current_curricula,
          curso           : this.curso
        }
      });
    },
  },
}

</script>

<style scoped>

</style>