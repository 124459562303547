import firebase from '@/firebaseConfRtdb/init';
export default {
    data() {
        return {
            auth_user: null,
            auth:null
        }
    },
    mounted() {
        this.auth = firebase.auth();
        this.auth.onAuthStateChanged(firebaseUser => {
            if (firebaseUser) {
                // this.initFirebase();
            } else {
                this.email_firebase = 'nivelando@g.nive.la';
                this.password_firebase = '123456789';
                this.registerUser(this.email_firebase, this.password_firebase);
            }
        });

        },
    methods: {
        loginUser(email, password) {
            //Llamamos al método de login de firebase
            this.auth.signInWithEmailAndPassword(email, password)
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                this.password_firebase = "";
            });
        }, //loginUser

        registerUser(email, password) {
            this.auth.createUserWithEmailAndPassword(email, password).then( () => {
                // console.log("createUserWithEmailAndPassword OK!!!");
            }).catch(err => {
                console.log(err)
                this.loginUser(this.email_firebase, this.password_firebase);
            }).finally(() => {
                this.password_firebase = "";
            });
        }, //register user


    }
}