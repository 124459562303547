import api from '@/services/api';

export default{

    getCursosConEntregablesPorGrupo(grupo_id){
        return api({
            url: `/api/cursos/conEntregablesPorGrupo/${grupo_id}`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getCursosConEntregables(){
        return api({
            url: `/api/cursos/conEntregables`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getCursos(){
        return api({
            url: `/api/cursos`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    delete(id){
        let url = `/api/cursos/destroy/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    save(formData){
        let url          = `/api/cursos/store`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    update(curso_id, formData){
        return api({
            url:  `/api/cursos/update/${curso_id}`,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },
}