<template>
    <b-container>
        <b-row>
             <b-col cols="12">
                  <div class="card card-block">
                    <div class="card-body p-2">

                        <div class="d-flex justify-content-between align-items-start p-2">
                            <b-jumbotron lead="Nueva rúbrica"></b-jumbotron>
                        </div>

                        <b-alert
                                class="p-3"
                                show
                                variant="info"
                                dismissible
                            >
                            En esta sección se pueden <b class="mx-1">crear/editar</b> los criterios de evaluación del entregable de la unidad
                        </b-alert>

                    <b-row  style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">
                            
                                <b-button @click="volver()"
                                    class="btn-studioAPP" 
                                    variant="outline-warning"> Volver
                                </b-button>
                            
                                <b-button @click="guardarRubrica()"
                                    class="btn-studioAPP" 
                                    variant="outline-warning"> Guardar
                                </b-button>
                    </b-row>

                    <b-form >
                         <b-form-input
                                ref="nombreRubrica"
                                class="my-2"
                                v-model="nombreRubrica"
                                type="text"
                                placeholder="Inresa el nombre de para la rúbrica"          
                                >
                        </b-form-input>

                    <div v-for="(input,k) in inputs" :key="k">

                        <div class="mx-3 mt-3 p-2 criterio" >

                                <div class="d-flex justify-content-center" >

                                   <div
                                        @click="add(k)"
                                        v-show="k == inputs.length-1"
                                        class="m-1"
                                        >
                                      <svg style="color : #2980b9;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                            </svg>
                                          
                                    </div>
                                        <div
                                            @click="remove(k)"
                                         v-show="k || ( !k && inputs.length > 1)"
                                            class="m-1"
                                            >
                                            <svg style="color : #c0392b;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                         </div>
                            <b-form-input
                                    ref="tituloCriterio"
                                    class="my-2"
                                    v-model="input.tituloCriterio"
                                    type="text"
                                    placeholder="Título del criterio"
                                    >
                            </b-form-input>
                        
                            <b-form-input
                                    ref="desCriterio"
                                    class="my-2"
                                      v-model="input.desCriterio"
                                    type="text"
                                    placeholder="Descripción del criterio"          
                                    >
                            </b-form-input>
                                        
 
                        

                            <b-row>
                                <b-col sm="3"  v-for="(answer,j) in input.answers" :key="j">

                            <b-card style="max-width: 20rem; border:1px #dedede solid;  border-radius: 15px;" >
                                <b-card-text>

                                    <b-form-input
                                            size="sm"
                                            class="my-2"
                                            v-model="answer.puntosNivel"
                                            type="number"
                                            step="1"
                                            placeholder="Puntos"          
                                            >
                                    </b-form-input>
                                
                                    <b-form-input
                                            size="sm"
                                            class="my-2"
                                            v-model="answer.tituloNivel"
                                            type="text"
                                            placeholder="Título del nivel"          
                                            >
                                    </b-form-input>

                                    <b-form-input
                                            size="sm"
                                            class="my-2"
                                            v-model="answer.descripcionNivel"
                                            type="text"
                                            placeholder="Descripción"          
                                            >
                                    </b-form-input>
                                    <div class="d-flex justify-content-center" >
                                            <div
                                                @click="remove2(j,k)"
                                                v-show="j || ( !j && input.answers.length > 1)"
                                                class="text-center m-1">
                                                <svg style="color : #c0392b;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </div>                                        
                                        <div
                                            @click="add2(k)"
                                            v-show="(j == input.answers.length-1)  "
                                            class="text-center m-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" style="color : #2980b9;" width="24" height="24" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                        </div>

                                        </div>
                                    </b-card-text>

                                </b-card>

        

                                </b-col>

                            </b-row>   
                        
                    </div>

                    </div>
                    </b-form>

 

                    </div><!--card-body-->
                  </div><!--card-->

             </b-col>

        </b-row>

    </b-container>

</template>

<script>
import RubricaService from '@/services/RubricaService';
import toast from "@/mixins/ToastMixin.js";

export default {
    mixins:[toast],
    data() {
        return {
            nombreRubrica:null,
            inputs: [
                        { tituloCriterio: '', desCriterio:'' , answers: [] }
                        
                    ],

            unidad: {
              id:(this.$route.params.id) ? (this.$route.params.id) : 0,
              actividad:{
                  id:(this.$route.params.ac) ? (this.$route.params.ac) : 0,
              }
            }
        }
    },//data

    mounted () {
 
        this.inputs[0].answers.push( {  puntosNivel: '', tituloNivel:'', descripcionNivel:'' } );
        if(this.unidad.id==0){
                    this.$router.push({
                        path:`/dashboardUnidades`
                    });
        } 
    },

    methods: {
   
        volver(){
            this.$router.push({
                path:`/dashboardUnidades`
            });
        },

        isValid(){

                if( (this.nombreRubrica === "" || !this.nombreRubrica || this.nombreRubrica==null) ) 
                { 
                   this.toast('b-toaster-bottom-center', true,  'Hace falta especificar un nombre para esta rúbrica', 'danger' );
                   return false;                            
                }

            for (let index = 0; index <  this.inputs.length; index++) {
                if( (this.inputs[index].tituloCriterio === "" || !this.inputs[index].tituloCriterio) ) 
                { 
                   this.toast('b-toaster-bottom-center', true,  'El título del criterio NO DEBE estar vacío', 'danger' );
                   return                             
                }

                if( (this.inputs[index].desCriterio === "" || !this.inputs[index].desCriterio) ) 
                { 
                   this.toast('b-toaster-bottom-center', true,  'Hace falta la descripción en un criterio', 'danger' );
                   return                             
                }



                for (let j = 0; j <  this.inputs[index].answers.length; j++) 
                {
                    if( this.inputs[index].answers[j].puntosNivel === "" || !this.inputs[index].answers[j].puntosNivel )
                    { 
                        this.toast('b-toaster-bottom-center', true,  'Hace falta definir el puntaje en un nivel de un criterio', 'danger' );
                        return           
                    }

                    if( this.inputs[index].answers[j].tituloNivel === "" || !this.inputs[index].answers[j].tituloNivel )
                    { 
                        this.toast('b-toaster-bottom-center', true,  'Hace falta definir el título del nivel de un criterio', 'danger' );
                        return           
                    }

                    if( this.inputs[index].answers[j].descripcionNivel === "" || !this.inputs[index].answers[j].descripcionNivel )
                    { 
                        this.toast('b-toaster-bottom-center', true,  'Hace falta agregar una descripción del nivel de un criterio', 'danger' );
                        return           
                    }

                }

            }
        },

        async guardarRubrica(){
        
            this.isValid();

            let nombreRubrica   = (this.nombreRubrica)          ? this.nombreRubrica            : 0;
            let criterios       = (this.inputs)                 ? this.inputs                   : 0;
            let unidad          = (this.unidad.id)              ? this.unidad.id                : 0;
            let actividad       = (this.unidad.actividad.id)    ? this.unidad.actividad.id      : 0;

            let response = await RubricaService.saveRubrica(nombreRubrica,criterios,unidad,actividad);

            let {   success,
                    message,
                    color,
                    } = response.data;
                       
                if(success){
                    this.toast('b-toaster-bottom-center', true, message, color );
                    this.$router.push({
                                    path:`/dashboardUnidades`
                                });
                }
               
        },


        remove2(respuesta,pregunta) {
            this.inputs[pregunta].answers.splice(respuesta,1);
        },

        add() {
            this.inputs.push(  {    tituloCriterio: '', 
                                    desCriterio:'',
                                    answers: [ { 
                                                    puntosNivel:'',
                                                    tituloNivel:'',
                                                    descripcionNivel:'',
                                                }  
                                            ] 
                                }
                            );

        },//add

        add2(index2) {
                this.inputs[index2].answers.push( {  puntosNivel: '', tituloNivel:'', descripcionNivel:'' } );
        },
   
        remove(index) {
            this.inputs.splice(index, 1);
        },
    },//methods

}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.criterio{
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     border:2px #dedede solid;  
     border-radius: 15px;
}

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>