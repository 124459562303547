<template>
    <b-container>

<!-- modal crear nuevo elemento -->
     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="ModalCrearObjetivo" 
                title="Creando nuevo objetivo"
                v-model="ModalCrearObjetivo"
                persistent
        >
            <b-card
                    tag="article"
                    class="mb-2">
                <b-alert show variant="primary" class="p-3 text-justify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                       A continuación especifique los siguientes campos y la clave a utilizarse, esta debe ser única e irrepetible
                </b-alert>
                <b-card-text>
                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                         <b-form-input
                                ref="objetivo_form.nombre_objetivo"
                                class="my-2"
                                v-model="objetivo_form.nombre_objetivo"
                                type="text"
                                placeholder="Ingresa el nombre del objetivo"
                                >
                        </b-form-input>
                         <b-form-input
                                ref="objetivo_form.clave_objetivo"
                                class="my-2"
                                v-model="objetivo_form.clave_objetivo"
                                type="text"
                                placeholder="Especifique una clave/código para este objetivo"          
                                >
                        </b-form-input>
                        <b-form-textarea
                                ref="objetivo_form.descripcion"
                                v-model="objetivo_form.descripcion_objetivo"
                                placeholder="Descripción breve del objetivo"
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>
                        
                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="w-100">
                            <b-button
                                    variant="primary"
                                    size="sm"
                                    class="float-right"
                                    @click="cerrarModalCrearObjetivo()"
                                >
                                    Cancelar
                                </b-button>  

                                 
                                <b-button v-if="objetivo_form.editar==true"
                                    variant="info" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="actualizarObjetivo()"
                                >
                                Actualizar registro
                                </b-button>   
                                   <b-button v-if="objetivo_form.editar==false"
                                    variant="success" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="guardarObjetivo()"
                                >
                                Guardar
                                </b-button>                             
                            </div>
                        </b-row>
                

                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>
 
<!-- modal borrar objetivo de la unidad -->
    <b-modal
        v-model="ModalBorrarObjetivo"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar el objetivo: <b>"{{ objetivo_form.nombre_objetivo }}"</b> ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="cerrarModalBorrarObjetivo()"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="borrarObjetivo()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>

      <b-row>
  <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
              <div class="d-flex justify-content-between align-items-start p-2">
                <b-jumbotron lead="Objetivos"></b-jumbotron>
              </div>

                      <b-alert
                            class="p-3"
                            show
                            variant="info"
                            dismissible
                        >
                        <div>Aquí podrá <strong>administrar</strong> los objetivos y agregarle <strong>materiales</strong>, entre otros</div>
                    </b-alert>
                  <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Objetivos</a></li>
                          <li class="breadcrumb-item"><a href="#"></a></li>
                    </ol>
                    </nav>

                  <b-row class="d-flex align-items-end justify-content-end my-3">
                          <div class="col-sm-12 col-md-6 text-right ">
                               <b-button 
                                        @click="showModalCrearObjetivo"
                                        class="btn-studioAPP" 
                                        variant="outline-warning"> Crear nueva objetivo
                              </b-button>
                          </div>
                  </b-row>
                  <b-row class="d-flex align-items-end justify-content-end my-1">
                       <div class="col-sm-12 my-2">
                       <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Buscar"
                                  >
                        </b-form-input>
                        </div>
                  </b-row>
             
                      <b-table class="table data-table"
                                striped="striped"
                                hover="hover"
                                outlined="outlined"
                                :items="itemsObjetivos"
                                :fields="headersObjetivos"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                    stacked="md"
                                    show-empty
                                    small
                                >

                                <template #empty="scope">
                                    <h5 class="text-center">
                                        {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                                    </h5>
                                </template>        
                                <template #emptyfiltered="scope">
                                    <h5 class="text-center">
                                        {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda .' }}
                                    </h5>
                                </template> 

                                <template #cell(materiales)="row">   
                                    <div class="d-flex justify-content-around align-items-center">

                                        <b-button variant="primary" size="sm" @click="irMaterialesObjetivos(row.item)" class="ml-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard2-check" viewBox="0 0 16 16">
                                            <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z"/>
                                            <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z"/>
                                            <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z"/>
                                        </svg>
                                        </b-button>
                                    </div>               
                                </template>

                        <template #cell(videos)="row">
                          <div class="d-flex justify-content-around align-items-center">

                            <b-button variant="outline-light" size="sm" @click="irMaterialesObjetivos(row.item)" class="ml-1">
                              📺
                            </b-button>
                          </div>
                        </template>

                                <template #cell(actions)="row">   
                                    <div class="d-flex justify-content-around align-items-center">

                                        <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                                    @click="EditMode(row.item)" class="mr-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path
                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                            <path fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                            </svg>
                                        </b-button>

                                        <b-button variant="danger" size="sm" @click="ShowModalBorrarObjetivo(row.item)" class="ml-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                            </svg>
                                        </b-button>
                                    </div>               
                                </template>

                    </b-table>
                   
                    <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div style="float:right">
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                    >
                                    </b-pagination>
                                </div>
                                </div>
                        </div>
              </div>
            </div>
  </b-col>
      </b-row>


    </b-container>
</template>

<script>
export default {
data() {
    return {

            ModalBorrarObjetivo: false,
            ModalCrearObjetivo:false,

            currentObjetivoId:0,
            objetivo_form: {
                nombre_objetivo: '',
                clave_objetivo: '',
                descripcion_objetivo: '',
                editar:false,
            },
                itemsObjetivos:[],
                headersObjetivos: [
                        {label: 'Nombre',           key: 'nombre_objetivo'},
                        {label: 'Clave',            key: 'clave_interna'},
                        /*{label: 'Indicadores',      key: 'indicadores', class: 'text-center'},*/
                        {label: 'Videos',          key: 'videos',  class: 'text-center'},
                        {label: 'Materiales',       key: 'materiales',  class: 'text-center'},
                        {label: 'Acciones',         key: 'actions',     class: 'text-center'},
                    ],
        ///
            totalRows: 1,
            currentPage: 1,
            perPage: 8,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter: null,
            filterOn: [],
            search: '', 
    }
},
mounted () {
    this.getObjetivos();
},
methods: {
  irVideosObjetivos(item){
    this.$router.push({
      path: '/dashboardMateriales',
      query: {
        idObjetivo: (item.id),
        nombre_objetivo: (item.nombre_objetivo),
        descripcion: (item.descripcion)
      }
    });
  },

    irMaterialesObjetivos(item){
              this.$router.push({
                        path: '/dashboardMateriales',
                        query: {
                        idObjetivo: (item.id),
                        nombre_objetivo: (item.nombre_objetivo),
                        descripcion: (item.descripcion)
                        }
                    });
    },
    async borrarObjetivo(){
                const response   = await this.$api.delete(`/api/objetivos/destroy/${this.currentObjetivoId}`);
                let {   success,   
                        message,
                        color
                    } = response.data;
                if(success){
                    await this.getObjetivos();
                    this.ModalBorrarObjetivo=false;
                    this.resetForm();
                }
                this.toast('b-toaster-bottom-center', true, message, color );
    },
    cerrarModalBorrarObjetivo(){
        this.ModalBorrarObjetivo=false;
        this.resetForm();
    },
    ShowModalBorrarObjetivo(item){
        this.ModalBorrarObjetivo                =true;
        this.objetivo_form.nombre_objetivo      =item.nombre_objetivo;
        this.currentObjetivoId                  =item.id;
    },
    EditMode(item){
        console.log(item);
        this.objetivo_form.editar=true;
        
        this.objetivo_form.nombre_objetivo      =item.nombre_objetivo;
        this.objetivo_form.descripcion_objetivo =item.descripcion;
        this.objetivo_form.clave_objetivo       =item.clave_interna;
        this.currentObjetivoId                  =item.id;
        this.ModalCrearObjetivo=true;
        
    },
    async actualizarObjetivo(){
                let url = `/api/objetivos/update/${this.currentObjetivoId}`;
                const response   = await this.$api.put(url, 
                                                {   nombre_objetivo:    this.objetivo_form.nombre_objetivo,  
                                                    descripcion:        this.objetivo_form.descripcion_objetivo, 
                                                    clave_interna:      this.objetivo_form.clave_objetivo 
                                                });
               let {    success,   
                        message,
                        color
                    } = response.data;
                if(success){
                    this.ModalCrearObjetivo=false;
                    this.getObjetivos();
                    this.resetForm()
                }
                this.toast('b-toaster-bottom-center', true, message, color );
    },
    async guardarObjetivo(){
                let formData = new FormData();
                    formData.append("nombre_objetivo"  , this.objetivo_form.nombre_objetivo);
                    formData.append("descripcion"       , this.objetivo_form.descripcion_objetivo);
                    formData.append("clave_interna"       , this.objetivo_form.clave_objetivo);

                        let url          = `/api/objetivos/store`;
                        const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
                        let {   success,
                                message,
                                color
                                } = response.data;
                        if(success){
                            this.getObjetivos();
                            this.ModalCrearObjetivo = false;
                            this.resetForm();
                        }
                        this.toast('b-toaster-bottom-center', true, message, color );

    },
    cerrarModalCrearObjetivo(){
         this.ModalCrearObjetivo=false;
         this.resetForm();
    }
    ,showModalCrearObjetivo() {
        this.ModalCrearObjetivo=true;
    },
    async getObjetivos() {
                const response = await this.$api.get(`/api/objetivos`);
                let {   objetivos,
                        success,
                        message,
                        color,
                        cantidad
                        } = response.data;
                if(success){
                    this.itemsObjetivos   = objetivos;
                    this.totalRows        = cantidad;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
                console.log(response.data);

    },
    resetForm(){
        this.objetivo_form.nombre_objetivo      = '';
        this.objetivo_form.clave_objetivo       = '';
        this.objetivo_form.descripcion_objetivo = '';
        this.objetivo_form.editar               = false;
    },
        //método del toast
    toast(toaster, append = false,content,color) {
    this.counter++
    this.$bvToast.toast(content, {
        title: 'Nivelando',
        autoHideDelay: 3500,
        toaster: toaster,
        variant: color,
        appendToast: append
    });
    },
},
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>