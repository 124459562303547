import api from '@/services/api';

export default{

    generateReportCurricula(formData){
        let url          = `/api/analitica/curriculas`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    getData(){
        return api({
            url: `/api/analitica`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getDataByGroups(formData){
        let url          = `/api/analitica/byGroups`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });

    },
}