<template>
    <b-container>

<!-- modal borrar objetivo de la unidad -->
    <b-modal
        v-model="ModalBorrarOferta"
        persistent
        title=" Confirmación"
        size="lg"
        centered
        >
    <b-container fluid>
        <b-row class="m-1 text-left">
                <b-col cols="12">  
                    ¿ Estás seguro de eliminar la oferta: <b>"{{ oferta_form.nombre_oferta_academica }}"</b> ?
                </b-col>   
            </b-row>
        </b-container>
            <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="cerrarModalBorrarOferta()"
                    >
                        Cancelar
                    </b-button>   
                    <b-button
                        variant="danger" 
                        size="sm" 
                        class="float-right mx-2"
                        @click="borrarOferta()"
                    >
                    Eliminar
                    </b-button>                             
            </div>
            </template>
            
        </b-modal>

     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="ModalCrearObjetivo" 
                title="Ofertas Académicas"
                v-model="ModalCrearOferta"
                persistent>
            <b-card
                    tag="article"
                    class="mb-2">
                <b-alert show variant="primary" class="p-3 text-justify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                       A continuación especifique los siguientes campos
                </b-alert>
                <b-card-text>
                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                         <b-form-input
                                ref="oferta_form.nombre_oferta_academica"
                                class="my-2"
                                v-model="oferta_form.nombre_oferta_academica"
                                type="text"
                                placeholder="Ingresa el nombre para esta oferta"          
                                >
                        </b-form-input>
                        <b-form-textarea
                                ref="oferta_form.descripcion"
                                v-model="oferta_form.descripcion"
                                placeholder="Agregar breves instrucciones"
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>

                            <div v-if="oferta_form.editar && oferta_form.imagen != '#' " style="display: flex; justify-content: space-evenly; align-items:center" class="my-2">
                                <b-button  @click="reemplazarImagen()" variant="outline-primary" class="btn-studioAPP" sm >Reemplazar imagen 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                                    <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                                    </svg>
                                </b-button>
                                <b-img rounded  :src="oferta_form.imagen" height="200" width="200" fluid></b-img>
                            </div>

                            <div v-else>
                                <b-form-file class="my-3"
                                    size="sm"
                                    v-model="oferta_form.imagenNueva"
                                    :state="Boolean(oferta_form.imagenNueva)"
                                    placeholder="Archivo adjunto..."
                                    accept="image/*"
                                    drop-placeholder="Arrastrar un archivo para adjuntar."
                                ></b-form-file>  
                            </div>


                          <label>Grupos que visualizará la oferta</label>
                                <v-select
                                      class="my-3"
                                      placeholder="Grupos que visualizarán la oferta "
                                      v-model="oferta_form.grupo_usuario_aprende_id"
                                      multiple
                                      :options="gruposAprende_items">
                                  </v-select>


<!--                              <b-form-select
                                    placeholder="Grupo que visualizará la oferta "
                                    :selected="oferta_form.grupo_usuario_aprende_id"
                                     v-model="oferta_form.grupo_usuario_aprende_id"
                                    :options="gruposAprende_items"
                            ></b-form-select>-->

                              <v-select
                                  class="my-3"
                                  placeholder="Elegir una currícula "
                                  v-model="oferta_form.idCurricula"
                                  :options="curriculas_items">
                            </v-select>
                              
                             <!--
                              <b-form-select 
                                  :selected="oferta_form.idCurricula"
                                    v-model="oferta_form.idCurricula"
                                    label="Tipo de material"
                                    :options="curriculas_items"
                            ></b-form-select> 
                            -->
                            <b-row class="d-flex align-items-start justify-content-start my-3">
                                <b-col>
                                     <b-form-datepicker locale="es" 
                                                placeholder="Fecha de inicio"
                                                v-model="oferta_form.fecha_inicio"  
                                                size="sm" 
                                                label-help=''
                                                label-today-button='Hoy'
                                                 today-button
                                                 label-no-date-selected='No se ha elegido fecha'
                                                >
                                                </b-form-datepicker>
                                </b-col>
                                <b-col> 
                                    <b-form-datepicker locale="es" 
                                                placeholder="Fecha finalización"
                                                v-model="oferta_form.fecha_finalizacion"  
                                                size="sm" 
                                                label-help=''
                                                label-today-button='Hoy'
                                                 today-button
                                                 label-no-date-selected='No se ha elegido fecha'
                                                >
                                                </b-form-datepicker>
                                </b-col>

                            </b-row>
                            <b-row>
                                 <b-col> 
                                    <b-form-checkbox v-model="oferta_form.switchpublicado" switch>
                                        Oferta publicada : <span v-if="oferta_form.switchpublicado"> <b>SI</b> </span><span v-else> <b>NO</b> </span>
                                    </b-form-checkbox>
                                 </b-col>
                            </b-row>

                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="w-100">
                            <b-button
                                    variant="primary"
                                    size="sm"
                                    class="float-right"
                                    @click="cerrarModalCrearOferta()"
                                >
                                    Cancelar
                                </b-button>  

                                 
                                <b-button v-if="oferta_form.editar==true"
                                    variant="info" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="actualizarOferta()"
                                >
                                Actualizar registro
                                </b-button>   
                                   <b-button v-if="oferta_form.editar==false"
                                    variant="success" 
                                    size="sm" 
                                    class="float-right mx-2"
                                    @click="guardarOferta()"
                                >
                                Guardar
                                </b-button>                             
                            </div>
                        </b-row>
                

                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>
      <b-row>
        <b-col cols="12">
                  <div class="card card-block">
                    <div class="card-body p-2">
                    <div class="d-flex justify-content-between align-items-start p-2">
                      <b-jumbotron lead="Ofertas Académicas"></b-jumbotron>
                    </div>

                    <b-alert
                            class="p-3"
                            show
                            variant="info"
                            dismissible
                        >
                        <div>
                          Puede administrar sus <strong> ofertas académicas</strong> para que los estudiantes puedan inscribirse a ellas y ver su contenido
                        </div>
                    </b-alert>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="#">Ofertas Académicas</a></li>
                        <li class="breadcrumb-item"><a href="#"></a></li>
                  </ol>
                  </nav>
                  <b-row class="d-flex align-items-end justify-content-end my-3">
                          <div class="col-sm-12 col-md-6 text-right ">
                               <b-button 
                                        @click="showModalCrearOferta()"
                                        class="btn-studioAPP" 
                                        variant="outline-warning"> Crear nueva oferta 
                              </b-button>
                          </div>
                  </b-row>
                  <b-row class="d-flex align-items-end justify-content-end my-1">
                       <div class="col-sm-12 my-2">
                       <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Buscar"
                                  >
                        </b-form-input>
                        </div>
                  </b-row>

                    <b-table class="table data-table"
                                striped="striped"
                                hover="hover"
                                outlined="outlined"
                                :items="ofertas_items"
                                :fields="ofertas_headers"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                    stacked="md"
                                    show-empty
                                    small
                                >

                                <template #empty="scope">
                                    <h5 class="text-center">
                                        {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                                    </h5>
                                </template>        
                                <template #emptyfiltered="scope">
                                    <h5 class="text-center">
                                        {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                                    </h5>
                                </template> 


                              <template #cell(publicado)="row">  
                                      <div v-if="`${row.item.publicado}`!='0' || `${row.item.publicado}`!=0">
                                             <!--<b-form-checkbox size="lg" disabled value="0" switch></b-form-checkbox> -->
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style="color:#4834d4" class="bi bi-check" viewBox="0 0 16 16">
                                              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                          </svg>                                             
                                      </div> 
                                      <div v-else>
                                     <!--     <b-form-checkbox size="lg" disabled value="1" checked="1" switch></b-form-checkbox>   -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style="color:#eb4d4b" class="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                          </svg>                                     
    
                                      </div>   
                              </template>

                                <template #cell(actions)="row">   
                                    <div class="d-flex justify-content-around align-items-center">

                                        <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                                    @click="EditMode(row.item)" class="mr-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path
                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                            <path fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                            </svg>
                                        </b-button>

                                        <b-button variant="danger" size="sm" @click="showModalBorrarOferta(row.item)" class="ml-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                            </svg>
                                        </b-button>
                                    </div>               
                                </template>

                    </b-table>
                    <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div style="float:right">
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                    >
                                    </b-pagination>
                                </div>
                                </div>
                        </div>
                    </div>
                    </div><!--card card-block-->
        </b-col>
      </b-row>
        
    </b-container>
</template>

<script>
export default {
data() {
  return {

        ModalBorrarOferta:false,

        gruposAprende_items:[],
        curriculas_items:[],
        ModalCrearOferta:false,
        ofertas_headers: [
                  {label: 'Nombre Oferta Académica',  key: 'nombre_oferta_academica'},
                  {label: 'Publicado',                key: 'publicado',             class: 'text-center'},
                  {label: 'Fecha inicio',             key: 'fecha_inicio',          class: 'text-center'},
                  {label: 'Fecha finalización',       key: 'fecha_finalizacion',    class: 'text-center'},
                  {label:'Grupo/s',                   key: 'GRUPOS'},
                  {label: 'Acciones',                 key: 'actions',               class: 'text-center'},
                ],
        ofertas_items: [],
        oferta_form: {
                nombre_oferta_academica     : '',
                descripcion                 : '',
                grupo_usuario_aprende_id    : [],
                imagen                      :null,
                imagenNueva                 :null,
                idCurricula                 :[],
                fecha_inicio                :'',
                fecha_finalizacion          :'',
                switchpublicado             :false,
                id                          :0,
                editar                      :false,
                reemplazarImagen            :false,
            },


       ///
            totalRows: 1,
            currentPage: 1,
            perPage: 8,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter: null,
            filterOn: [],
            search: '',                 
  }
},

mounted () {
    this.getOfertas();
    this.getCurriculas();
    this.getGruposAprende();
},
methods: {
    async borrarOferta(){

            let url      = `/api/ofertaAcademica/destroy`;
            const config = {  headers: { 'content-type': 'multipart/form-data' }  }
            let formData = new FormData();
                formData.append("id_oferta_academica"   , this.oferta_form.id);
                formData.append("idCurricula"           , this.oferta_form.idCurricula);

               const response   = await this.$api.post(url, formData, config );
                let {   success,
                        message,
                        color
                        } = response.data;
            if(success){
                this.getOfertas();
                this.ModalBorrarOferta = false;
                this.resetForm();
            }
            this.toast('b-toaster-bottom-center', true, message, color ); 
    },
    cerrarModalBorrarOferta(){
        this.ModalBorrarOferta = false;
        this.resetForm();
    },
    showModalBorrarOferta(item){
        this.oferta_form.id                         = item.id;
        this.oferta_form.idCurricula                = item.detalles_oferta_curricula[0].curricula_id;
        this.oferta_form.nombre_oferta_academica    = item.nombre_oferta_academica;
        this.ModalBorrarOferta = true;
    },

    reemplazarImagen(){
        this.oferta_form.imagen     = '#';
        this.reemplazarImagen       = true;
    },   
    async actualizarOferta(){
        
          let url       = `/api/ofertaAcademica/update`;
          const config = {  headers: { 'content-type': 'multipart/form-data' }  }
          let formData = new FormData();
                formData.append("id"                    , this.oferta_form.id);   
                formData.append("nombre_oferta_academica"   , this.oferta_form.nombre_oferta_academica);
                formData.append("descripcion"               , this.oferta_form.descripcion);

                formData.append("fecha_inicio"              , this.oferta_form.fecha_inicio);   
                formData.append("fecha_finalizacion"        , this.oferta_form.fecha_finalizacion);   
                formData.append("curricula_id"              , this.oferta_form.idCurricula.id);

                let arrGrupos = [];
                this.oferta_form.grupo_usuario_aprende_id.forEach(el => {
                  arrGrupos.push(el.id);
                });
                formData.append("grupo_usuario_aprende_id"  , arrGrupos);

                //  laravel lo recibe como imagen                           imagen en form input file
                formData.append("imagen"                    , this.oferta_form.imagenNueva); 
                                                            //true si se va a reemplazar false si no 
                formData.append("reemplazarImagen"          , this.oferta_form.reemplazarImagen);   
                                            /// //URL DE LA IMAGEN ACTUAL
                formData.append("url_image"                 , this.oferta_form.imagen);  

                if(this.oferta_form.switchpublicado==true){
                    formData.append("publicado"                 , 1);
                }else{
                    formData.append("publicado"                 , 0);
                }
                
            const response   = await this.$api.post(url, formData, config );
            let {   success,
                    message,
                    color
                    } = response.data;
            if(success){
              console.log(response.data);

              console.log(response.data);
                await this.getOfertas();
                this.ModalCrearOferta = false;
                this.resetForm();
            }
            this.toast('b-toaster-bottom-center', true, message, color ); 

    },

    async getNombreCurricula(curricula_id){
            const response = await this.$api.get(`/api/curriculas/nombreCurricula/${curricula_id}`);
            let {CurriculaNombre} = response.data;
                return  CurriculaNombre[0].label;
    },

    async EditMode(item){

      //console.log(item);

        let iDcurricula     = item.detalles_oferta_curricula[0].curricula_id;
        let nombreCurricula = await this.getNombreCurricula(item.detalles_oferta_curricula[0].curricula_id);

        this.oferta_form.nombre_oferta_academica        = item.nombre_oferta_academica;
        this.oferta_form.descripcion                    = item.descripcion;

      let GruposParaSelect = [];
      let GrupoCompelto=[];

      console.log('item');
      console.log(item);

      for (var i = 0; i < item.arrGruposId.length; i++) {
        GrupoCompelto = this.gruposAprende_items.find(element => element.id == item.arrGruposId[i]);
        GruposParaSelect.push( {  id: GrupoCompelto.id, label:GrupoCompelto.label} );
      }
      this.oferta_form.grupo_usuario_aprende_id     = GruposParaSelect;


        this.oferta_form.imagen                         = item.imagen;
        this.oferta_form.idCurricula                    =  { label: nombreCurricula, id: iDcurricula }; ////{ label: 'One', id: 1 }
        this.oferta_form.fecha_inicio                   = item.fecha_inicio;
        this.oferta_form.fecha_finalizacion             = item.fecha_inicio;

        if(item.publicado == 1){
            this.oferta_form.switchpublicado=true;
        }else{
             this.oferta_form.switchpublicado=false;
        }
        this.oferta_form.id                   = item.id;
        this.oferta_form.editar               = true;
        this.ModalCrearOferta                 = true;
    },

    async guardarOferta(){
          let url      = `/api/ofertaAcademica/store`;
          const config = {  headers: { 'content-type': 'multipart/form-data' }  }
          let formData = new FormData();
                formData.append("nombre_oferta_academica"   , this.oferta_form.nombre_oferta_academica);
                formData.append("descripcion"               , this.oferta_form.descripcion);

                formData.append("fecha_inicio"              , this.oferta_form.fecha_inicio);   
                formData.append("fecha_finalizacion"        , this.oferta_form.fecha_finalizacion);   
                formData.append("curricula_id"              , this.oferta_form.idCurricula.id);

                //formData.append("grupo_usuario_aprende_id"  , this.oferta_form.grupo_usuario_aprende_id);
                let arrGrupos = [];
                this.oferta_form.grupo_usuario_aprende_id.forEach(el => {
                  arrGrupos.push(el.id);
                });
                formData.append("grupo_usuario_aprende_id"                 , arrGrupos);
                console.log("grupos id");
                console.log(arrGrupos);

              formData.append("imagen"                    , this.oferta_form.imagenNueva);

                if(this.oferta_form.switchpublicado==true){
                    formData.append("publicado"                 , 1);
                }else{
                    formData.append("publicado"                 , 0);
                }

            const response   = await this.$api.post(url, formData, config );
            let {   success,
                    message,
                    color
                    } = response.data;
            if(success){
                await this.getOfertas();
                this.ModalCrearOferta = false;
                this.resetForm();
            }
            this.toast('b-toaster-bottom-center', true, message, color );

           
    },
    async getGruposAprende() {
        const response = await this.$api.get("/api/users/GruposUsuario");
        let {gruposUsuarios} = response.data;
        this.gruposAprende_items = gruposUsuarios;
                console.log(response.data);
        },

    async getCurriculas(){
         const response = await this.$api.get(`/api/curriculas/select`);
                let {   Curriculas,
                        success,
                        } = response.data;
                if(success){
                  console.log('response.data');
                    console.log(Curriculas);
                    this.curriculas_items   = Curriculas;
                }
 },
  cerrarModalCrearOferta(){
    this.ModalCrearOferta = false;
    this.resetForm();
  },
  showModalCrearOferta() {
    this.ModalCrearOferta = true;
  },

  async getOfertas(){
                const response = await this.$api.get(`/api/ofertaAcademica`);
                let {   ofertas,
                        success,
                        message,
                        cantidad,
                        color,     
                        } = response.data;
                if(success){
                    console.log(response.data);
                    this.ofertas_items   = ofertas;
                    this.totalRows       = cantidad;
                }
                this.toast('b-toaster-bottom-center', true, message, color );
  },
  resetForm(){
        this.oferta_form.nombre_oferta_academica      = '';
        this.oferta_form.descripcion                  = '';
        this.oferta_form.grupo_usuario_aprende_id     = [];
        this.oferta_form.imagen                       = '';
        this.oferta_form.imagenNueva                  = null;
        this.oferta_form.reemplazarImagen             = false;
        this.oferta_form.idCurricula                  = [];
        this.oferta_form.fecha_inicio                 = '';
        this.oferta_form.fecha_finalizacion           = '';
        this.oferta_form.switchpublicado              = false;
        this.oferta_form.id                           = '';
        this.oferta_form.editar                       = false;
    },
  //método del toast
  toast(toaster, append = false,content,color) {
  this.counter++
  this.$bvToast.toast(content, {
      title: 'Nivelando',
      autoHideDelay: 3500,
      toaster: toaster,
      variant: color,
      appendToast: append
  });
  },  
},
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>