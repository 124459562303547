<template>
  <div style="text-align: center">
    <h2>Create User</h2>
    <input v-model="newUser.username" placeholder="usuario" />
    <input v-model="newUser.userId" placeholder="id" />
    <input v-model="newUser.email" placeholder="correo" />
    <button @click="createOrUpdateUser">{{ editingUser ? 'Update' : 'Create' }}</button>

    <br>
    <button class="my-5" @click="logout()">LogOut</button>

    <br>

    <h2>Users</h2>
    <ul>
      <li v-for="(user, key) in users" :key="key">
        {{ user.username }} ({{ user.userId }}) - {{ user.email }}
        <button @click="editUser(user)">Edit</button>
        <button @click="deleteUser(key)">Delete</button>
      </li>
    </ul>


  </div>


</template>

<script>
import firebase from '@/firebaseConfRtdb/init';
//import { mapActions } from "vuex";
export default {
  name: "firebaseCrud",
  data() {
    return {
      newUser: {
        userId: "",
        roleId: 2,
        username: "",
        email: "",
        isOnline:1

      },
      users: {},
      currentUsername: "",
      currentUserId: "",
      currentUserEmail: "",
      editingUser: false,
      editingUserKey: ""
    };
  },
  created() {
    this.databaseRef = firebase.database().ref('users');
    this.fetchUsers();
  },
  methods: {
   // ...mapActions(["updateIsOnline"]),
    logout() {
    //  this.updateIsOnline(this.$store.getters.usuario.id);
      this.$store.dispatch('updateIsOnline', this.$store.getters.usuario.id);
      // Perform other logout-related actions
    },
    createOrUpdateUser() {
      if (this.editingUser) {
        this.updateUser();
      } else {
        this.createUser();
      }
    },
    createUser() {
      const newUserRef = this.databaseRef.push();
      newUserRef.set(this.newUser);

      this.clearForm();
    },
    updateUser() {
      const { userId, roleId, username, email, online } = this.newUser;
      const updatedUser = {
        userId,
        roleId,
        username,
        email,
        online
      };
      const userRef = this.databaseRef.child(this.editingUserKey);
      userRef.update(updatedUser);

      this.clearForm();
    },
    deleteUser(key) {
      const userRef = this.databaseRef.child(key);
      userRef.remove();
    },
    editUser(user) {
      this.newUser = { ...user };
      this.editingUser = true;
      this.editingUserKey = Object.keys(this.users).find(key => this.users[key] === user);
    },
    fetchUsers() {
      this.databaseRef.on('value', (snapshot) => {
        this.users = snapshot.val();
      });
    },
    clearForm() {
      this.newUser = {
        username: "",
        userId: "",
        email: ""
      };
      this.editingUser = false;
      this.editingUserKey = "";
    }
  }
};
</script>

<style scoped>
</style>
